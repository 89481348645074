import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { FilterEmptyItemComponent } from '@app/views/view-controls/view-filter/filter-empty-first-item/filter-empty-item.component'
import { TbSelectValue } from '@components-library/tb-select/models'
import {
    Field,
    FieldEntities,
    FieldType,
    FieldTypes,
    FilterCombinationTypes,
    FilterGroupsCombinationTypes,
    Folder,
} from '@core/models'
import { UntilDestroy } from '@ngneat/until-destroy'
import { isArray, filter, map } from 'lodash-es'
import { FormFilterService } from '@app/views/view-controls/view-filter/form-filter.service'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbMenuComponent } from '@components-library/tb-menu/tb-menu.component'
import { FieldNameComponent } from '@shared/field-name/field-name.component'
import { TbOptionComponent } from '@components-library/tb-select/components/tb-option/tb-option.component'
import { TbSelectComponent } from '@components-library/tb-select/components/tb-select/tb-select.component'
import { FilterItemComponent } from '../filter-item/filter-item.component'
import { FilterCombinationToggleComponent } from '../filter-combination-toggle/filter-combination-toggle.component'
import { NgTemplateOutlet } from '@angular/common'
import { TranslocoModule } from '@ngneat/transloco'

@UntilDestroy()
@Component({
    selector: 'app-filter-group',
    templateUrl: './filter-group.component.html',
    styleUrls: ['./filter-group.component.sass'],
    standalone: true,
    imports: [
        TranslocoModule,
        ReactiveFormsModule,
        FilterCombinationToggleComponent,
        NgTemplateOutlet,
        FilterItemComponent,
        TbSelectComponent,
        TbOptionComponent,
        FieldNameComponent,
        TbMenuComponent,
        TbButtonComponent,
        TbMenuListComponent,
        TbMenuListItemComponent,
        FilterEmptyItemComponent,
    ],
})
export class FilterGroupComponent {
    @Input()
    fields!: FieldEntities

    @Input()
    fieldTypes!: FieldType[]

    @Input()
    single!: boolean

    @Input()
    selectedFolder!: Folder

    @Input()
    isEmpty!: boolean

    @Input()
    groupIndex!: number

    @Output()
    deleteGroup: EventEmitter<void> = new EventEmitter<void>()

    @Output()
    clearAll: EventEmitter<void> = new EventEmitter<void>()

    filterGroupsCombinationTypes = FilterGroupsCombinationTypes
    filterCombinationTypes = FilterCombinationTypes

    constructor(public formFilterService: FormFilterService) {}

    get isCombinationGroupToggleDisabled() {
        return this.groupIndex !== 1
    }

    get fieldGuids() {
        return this.fieldsAvailableForFilter()
    }

    get first() {
        return !this.groupIndex
    }

    get filterCombinationType() {
        return this.formGroup.get('filterCombinationType')!.value
    }

    get formItems() {
        return this.formFilterService.getFilterGroupValues(this.groupIndex).controls as FormGroup[]
    }

    get formGroup() {
        return this.formFilterService.getFilterFormArray.at(this.groupIndex) as FormGroup
    }

    deleteFilter(itemIndex: number) {
        this.formFilterService.deleteFilterItem(this.groupIndex, itemIndex)
    }

    addFilterFromEmpty(guid: TbSelectValue, itemIndex: number) {
        this.deleteFilter(itemIndex)
        this.addFilterItem(guid)
    }

    addFilterItem(guid: TbSelectValue) {
        if (isArray(guid) || this.formGroup.invalid) {
            return
        }

        this.addFilter(guid as string)
    }

    addFilter(guid: string) {
        this.formFilterService.addFilterItem(this.groupIndex, guid)
    }

    getFieldByGuid(fieldGuid: string): Field {
        return this.fields[fieldGuid]
    }

    isShowFilterCombinationToggle() {
        return (
            !this.isEmpty && this.formFilterService.getFilterGroupValues(this.groupIndex).length > 1
        )
    }

    groupCombinationTypeChange(filterGroupsCombinationType: string) {
        this.formFilterService.setCombinationGroupType(filterGroupsCombinationType)
    }

    combinationTypeChange(filterCombinationType: string) {
        this.formFilterService.setCombinationType(this.groupIndex, filterCombinationType)
    }

    private fieldsAvailableForFilter() {
        const excludedFieldTypes = [FieldTypes.DATE, FieldTypes.DATE_TIME, FieldTypes.DATE_RANGE]

        return map(
            filter(
                this.fields,
                (field) => !excludedFieldTypes.includes(field.field_type_code as FieldTypes),
            ),
            (field) => field.guid,
        )
    }
}
