@if (fieldTypes$ | async; as fieldTypes) { @if (cells && fields) {
<div class="w-full d-flex flex-col" *transloco="let tTable; read: 'table'">
    @for (record of records; track record) {
    <div class="w-full d-flex">
        @for (field of fields; track field) {
        <div class="w-6/12 d-flex content-center h-[48px]">
            <app-cell-container
                [fieldTypes]="fieldTypes"
                [field]="field"
                [guid]="field.guid"
                [record]="record"
                [value]="cells[record.guid][field.guid].value"
                class="text-start"
                [disabled]="true"
            ></app-cell-container>
        </div>
        }
    </div>
    } @if (deletedRecords.length) {
    <div class="w-100 d-flex content-center h-[48px]">
        <span class="w-6/12 flex-start-center">
            {{tTable(('deleted_records'), { count: deletedRecords.length })}}
        </span>
        <!-- change to app-tb-button after new design will be implemented -->
        <button (click)="cleanDeletedRecords()" class="w-6/12 flex-start-center" mat-button>
            Clean deleted
        </button>
    </div>
    }
    <mat-select
        [formControl]="recordsControl"
        (openedChange)="updateValue($event)"
        class="w-6/12 max-w-[180px]"
        [placeholder]="tTable('select_record')"
        multiple
    >
        <mat-select-trigger> {{ tTable('select_records') }} </mat-select-trigger>
        @for (record of allSchemaRecords; track record) {
        <mat-option [value]="record.guid"> {{ record.name.value }} </mat-option>
        }
    </mat-select>
</div>
} }
