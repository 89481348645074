import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

enum ErrorRoutes {
    SomethingWrong = 'something-wrong',
    AccessDenied = 'access-denied',
}

@Injectable({
    providedIn: 'root',
})
export class ErrorNavigatorService {
    isRedirecting = false

    constructor(private router: Router) {}

    goToSomethingWentWrongPage() {
        this.redirectWrapper(this.router.navigate([ErrorRoutes.SomethingWrong]))
    }

    goToAccessDeniedPage() {
        this.redirectWrapper(this.router.navigate([ErrorRoutes.AccessDenied]))
    }

    clearData() {
        this.isRedirecting = false
    }

    prepareSomethingWentWrongPage() {
        this.isRedirecting = true
        return this.router.createUrlTree([ErrorRoutes.SomethingWrong])
    }

    private redirectWrapper(route: Promise<boolean>) {
        this.isRedirecting = true
        route.then(() => {
            this.isRedirecting = false
        })
    }
}
