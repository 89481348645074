<mat-button-toggle-group
    *transloco="let tTypes; read: translateKey"
    (change)="toggle($event.value)"
    [disabled]="disabled"
>
    @for (combinationKey of combinationTypesKeys; track combinationKey) {
        <mat-button-toggle
            [value]="combinationTypes[combinationKey]"
            [checked]="combinationTypes[combinationKey] === value"
        >
            {{ tTypes(combinationKey) }}
        </mat-button-toggle>
    }
</mat-button-toggle-group>
