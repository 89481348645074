import { Component, Inject, Input, OnInit } from '@angular/core'
import { HeaderOptionsMenuComponent } from '@app/feature/record-card/header/header-options-menu/header-options-menu.component'
import { TbIconToggleButtonComponent } from '@components-library/tb-icon-toggle-button/tb-icon-toggle-button.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { StatusComponent } from '@app/shared/cell-types/select/status'
import { MatIconModule } from '@angular/material/icon'
import { AsyncPipe } from '@angular/common'
import { CardComponent } from '@app/feature/record-card/card.component'
import { UpdateResponseModel } from '@core/models'
import { AutomationExecuteModel } from '@models/ui/automation.model'
import { RecordsService } from '@services/records.service'
import { AutomationFacadeService, FolderFacadeService } from '@services/store-facade'

@Component({
    selector: 'app-card-header',
    templateUrl: './card-header.component.html',
    styleUrls: ['./card-header.component.sass'],
    standalone: true,
    imports: [
        MatIconModule,
        StatusComponent,
        TbIconComponent,
        TbIconToggleButtonComponent,
        TbMenuComponent,
        TbMenuListComponent,
        TbMenuListItemComponent,
        HeaderOptionsMenuComponent,
        AsyncPipe,
    ],
})
export class CardHeaderComponent implements OnInit {
    readonly folder$ = this.folderFacadeService.selectSelectedFolder$

    readonly automations$ = this.automationFacadeService.selectSelectedTableSchemaAutomations$

    @Input() schemaName!: string

    @Input() isFavorite = false

    disableFavorite = false

    constructor(
        private automationFacadeService: AutomationFacadeService,
        private folderFacadeService: FolderFacadeService,
        private recordsService: RecordsService,
        @Inject(CardComponent) private card: CardComponent,
    ) {}

    ngOnInit() {
        this.disableFavorite = this.isFavoriteDisabled()
    }

    executeAutomation(guid: string) {
        const executeModel: AutomationExecuteModel = {
            guid,
            objectTypeGuid: this.card.record.schemaGuid,
            records: [this.card.record],
        }

        this.automationFacadeService.executeAutomation(executeModel)
    }

    toggleFavorite(isActive?: boolean) {
        if (!this.disableFavorite) {
            this.isFavorite = isActive ?? !this.isFavorite

            this.disableFavorite = this.isFavoriteDisabled()

            this.card
                .wrapResponseObservable<UpdateResponseModel>(
                    this.recordsService.toggleFavorite(this.card.record, this.card.user.guid),
                )
                .subscribe()
        }
    }

    private isFavoriteDisabled() {
        return (
            this.isFavorite &&
            this.card.cells[this.card.watchField.guid].value.split(',').length === 1 &&
            !!this.card.watchField.is_required
        )
    }
}
