import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { GlobalLoadingComponent } from '@app/feature/global-loading/global-loading.component'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [RouterOutlet, GlobalLoadingComponent],
})
export class AppComponent {}
