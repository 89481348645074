import { Component, EventEmitter, Output } from '@angular/core'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { MatIconModule } from '@angular/material/icon'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-filter-no-results',
    templateUrl: './filter-no-results.component.html',
    standalone: true,
    imports: [TranslocoModule, MatIconModule, TbButtonComponent],
})
export class FilterNoResultsComponent {
    @Output() changeFilters = new EventEmitter<void>()
}
