<app-tb-menu
    class="h-full w-full"
    [class.pr-12]="!isHoverIcons && !focused && isExtraPadding"
    #select
    [template]="selectOptions"
    [disabled]="readonly || disabled || loadingState"
>
    @if (!errorState) {
        <div class="w-full h-full flex items-center justify-center" trigger>
            <div
                class="w-full min-h-[1.5rem] h-full flex gap-1 items-center whitespace-nowrap justify-start rounded cursor-pointer py-1 px-2"
                [class]="getOptionClass(selectedOption.guid!)"
                [style.background-color]="selectedOption.color"
            >
                @if (selectedOption.icon) {
                    <app-tb-icon [icon]="selectedOption.icon"></app-tb-icon>
                }
                <span class="max-w-[12rem] body-1 truncate">{{ selectedOption.label }}</span>
            </div>
        </div>
    }
</app-tb-menu>

<ng-template #selectOptions>
    <app-tb-menu-list>
        @for (option of selectData; track option) {
            <div
                class="w-full h-full flex items-center justify-center rounded"
                [class]="getBorderForOption(option.guid ?? '')"
            >
                <div
                    (click)="setSelectValue(option.guid ?? '')"
                    class="w-60 min-h-[1.5rem] h-full flex gap-1 items-center justify-start rounded cursor-pointer m-1 py-1 px-2"
                    [style.background-color]="option.color"
                    [class]="getOptionClass(option.guid ?? '')"
                >
                    @if (option.icon) {
                        <app-tb-icon [icon]="option.icon"></app-tb-icon>
                    }
                    <span class="truncate body-1">{{ option.label }}</span>
                </div>
            </div>
        }
    </app-tb-menu-list>
</ng-template>
