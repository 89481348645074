import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'
import { ColorPickerService } from '@app/shared/color-picker/color-picker.service'
import { SetType } from '@app/shared/models/picker'
import { TbPickerComponent } from '@components-library/tb-picker/tb-picker.component'

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrl: './color-picker.component.sass',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TbPickerComponent],
})
export class ColorPickerComponent implements OnInit {
    @ViewChild('triggerItem') private triggerItem!: ElementRef

    @Input() name?: string

    @Input() color?: string

    @Input() set!: SetType

    @Output() colorChange = new EventEmitter<string>()

    pickerItems!: string[]

    selectedColor!: string

    constructor(private colorPickerService: ColorPickerService) {}

    ngOnInit() {
        this.pickerItems = this.colorPickerService.getColorSet(this.set)

        this.selectedColor = this.getColor()

        if (!this.color) {
            this.colorChange.emit(this.selectedColor)
        }
    }

    selectColor(color: string | null) {
        this.selectedColor = color ?? this.colorPickerService.getDefaultColor(this.set)
        this.colorChange.emit(this.selectedColor)
    }

    open() {
        this.triggerItem.nativeElement.click()
    }

    private getColor() {
        if (!this.color) {
            return this.colorPickerService.getDefaultColor(this.set)
        }

        return this.color
    }
}
