import { UntilDestroy } from '@ngneat/until-destroy'
import { Component, Input } from '@angular/core'
import { TbCheckboxComponent } from '@components-library/tb-checkbox/checkbox.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { MatIconModule } from '@angular/material/icon'

@UntilDestroy()
@Component({
    selector: 'app-field-configuration-type',
    templateUrl: './field-configuration-type.component.html',
    standalone: true,
    imports: [MatIconModule, TbButtonComponent, TbCheckboxComponent],
})
export class FieldConfigurationTypeComponent {
    @Input() icon!: string
    @Input() customIcon!: string
    @Input() isField!: boolean
    @Input() name!: string
    @Input() description!: string
}
