<div
    class="flex flex-row justify-start items-start w-full"
    *transloco="let tSearchDialog; read: 'search.dialog'"
>
    <div class="flex-col justify-start items-start flex">
        <div [formGroup]="formGroup" class="w-full pr-5">
            <mat-form-field
                class="title border-[1px] border-solid border-white h-[50px] w-full flex flex-row justify-start items-center rounded-sm"
            >
                <input
                    autocomplete="off"
                    class="h-[50px]"
                    formControlName="searchInput"
                    matInput
                    name="search"
                    placeholder="{{ tSearchDialog('search_placeholder') }}"
                    type="text"
                />
            </mat-form-field>
            <div class="w-full">
                <div class="w-full h-full py-2">
                    <mat-button-toggle-group
                        aria-label="search Filter"
                        formControlName="searchFilter"
                    >
                        <mat-button-toggle [checked]="true" value="all">
                            {{ tSearchDialog('button.all') }}
                        </mat-button-toggle>
                        <mat-button-toggle value="records">
                            {{ tSearchDialog('button.records') }}
                        </mat-button-toggle>
                        <mat-button-toggle value="doc">
                            {{ tSearchDialog('button.doc') }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="w-full h-full">
                    <app-search-results
                        [searchFilterValue]="searchFilterValue"
                        [searchValue]="searchValue"
                    ></app-search-results>
                </div>
            </div>
        </div>
    </div>
    <div class="border-l-[1px] border-solid border-white px-2">
        <div class="mb-0 title w-full">{{ tSearchDialog('quick_filters.name') }}</div>
        <mat-list role="list">
            <mat-list-item role="listitem">
                {{ tSearchDialog('quick_filters.assigned') }}
            </mat-list-item>
            <mat-list-item role="listitem">
                {{ tSearchDialog('quick_filters.created') }}
            </mat-list-item>
            <mat-list-item role="listitem">
                {{ tSearchDialog('quick_filters.item') }}
            </mat-list-item>
        </mat-list>
        <div class="mt-5 title mb-0">{{ tSearchDialog('task_filters.name') }}</div>
        <mat-list role="list">
            <mat-list-item role="listitem">
                {{ tSearchDialog('task_filters.active') }}
            </mat-list-item>
            <mat-list-item role="listitem">
                {{ tSearchDialog('task_filters.closed') }}
            </mat-list-item>
            <mat-list-item role="listitem">
                {{ tSearchDialog('task_filters.archived') }}
            </mat-list-item>
        </mat-list>
    </div>
</div>
