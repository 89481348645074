import { AsyncPipe, NgTemplateOutlet } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { ActionPanelService } from '@app/feature/actions-panel/action-panel.service'
import { CardComponent, DialogCardInput } from '@app/feature/record-card/card.component'
import { BoardCardMenuComponent } from '@app/views/board/board-card-menu/board-card-menu.component'
import { BoardColumnHeaderMenuComponent } from '@app/views/board/board-column-header-menu/board-column-header-menu.component'
import { ViewDataService } from '@app/views/services/view-data.service'
import { MovedItem } from '@components-library/tb-board/tb-board-column/tb-board-column.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { isNonNull } from '@core/global-util'
import {
    BusinessRecord,
    BusinessRecords,
    CellEntities,
    Field,
    Folder,
    RecordGroup,
    ViewData,
} from '@core/models'
import { RecordsService } from '@core/services/records.service'
import {
    FieldTypeFacadeService,
    FolderFacadeService,
    RecordFacadeService,
    SchemaFacadeService,
} from '@core/services/store-facade'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'
import { NotificationService } from '@services/notification.service'
import { filter, Observable, of } from 'rxjs'
import { concatMap, map, switchMap } from 'rxjs/operators'
import { TbBoardCardComponent } from '@components-library/tb-board/tb-board-card/tb-board-card.component'
import { TbBoardColumnComponent } from '@components-library/tb-board/tb-board-column/tb-board-column.component'
import { TbBoardComponent } from '@components-library/tb-board/tb-board.component'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'

import { ActionsPanelComponent } from '@app/feature/actions-panel/actions-panel.component'
import { GroupingValuesComponent } from '@shared/grouping-values/grouping-values.component'
import { BoardCardFieldsComponent } from './board-card-fields/board-card-fields.component'

@UntilDestroy()
@Component({
    selector: 'app-board-view',
    templateUrl: './board-view.component.html',
    styleUrls: ['./board-view.component.sass'],
    standalone: true,
    imports: [
        TbBoardComponent,
        TbBoardColumnComponent,
        GroupingValuesComponent,
        TranslocoModule,
        BoardCardFieldsComponent,
        TbBoardCardComponent,
        NgTemplateOutlet,
        TbMenuListComponent,
        TbMenuListItemComponent,
        TbDividerComponent,
        TbIconComponent,
        ActionsPanelComponent,
        AsyncPipe,
        BoardCardMenuComponent,
        BoardColumnHeaderMenuComponent,
    ],
})
export class BoardViewComponent implements OnInit {
    viewData!: ViewData
    visibleFields: string[] = []
    fields: string[] = []

    cells!: { [recordGuid: string]: CellEntities }

    selectedFolder$: Observable<Folder> = this.folderFacadeService.selectSelectedFolder$.pipe(
        filter(isNonNull),
    )
    fieldTypes$ = this.fieldTypeFacadeService.selectFieldTypeEntities$

    NO_GROUPED_RECORDS_KEY = 'NO_GROUPED_RECORDS_KEY'

    boardItemsKey: keyof BusinessRecord = 'guid'
    allRecords: Dictionary<BusinessRecords> = {}

    fields$ = this.selectedFolder$.pipe(
        concatMap((selectedFolder) =>
            this.schemaFacadeService.selectSelectedTableSchemaFieldEntitiesFiltered$(
                selectedFolder,
            ),
        ),
        filter(isNonNull),
    )

    selectedRecords: BusinessRecords[] = []

    get groups(): RecordGroup[] | undefined {
        if (this.viewData && this.viewData.data instanceof Map) {
            return [...this.viewData.data.values()]
        }
        return
    }

    constructor(
        private recordService: RecordsService,
        private schemaFacadeService: SchemaFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
        private recordFacadeService: RecordFacadeService,
        private notificationService: NotificationService,
        private modalFlowManagerService: ModalFlowManagerService,
        private viewDataService: ViewDataService,
        private actionPanelService: ActionPanelService,
    ) {
        actionPanelService
            .getSelectedRecords$()
            .pipe(untilDestroyed(this))
            .subscribe((records) => {
                this.selectedRecords = records
            })

        recordFacadeService.selectRecordEntities$
            .pipe(untilDestroyed(this))
            .subscribe((records) => {
                this.allRecords = records
            })
    }

    ngOnInit(): void {
        this.viewDataService.viewDataChanges$
            .pipe(
                untilDestroyed(this),
                switchMap(() => {
                    const viewData = this.viewDataService.getViewData()
                    if (!viewData || viewData.data instanceof Map) return of(viewData)
                    return this.changeArrayDataToGroup(viewData)
                }),
            )
            .subscribe((viewData) => {
                this.viewData = viewData
                this.cells = this.viewDataService.getCells()
                this.visibleFields = [...this.viewData.columns.columns]
            })
    }

    drop(group: RecordGroup, movedItem: MovedItem<BusinessRecords>) {
        if (group.field && group.value) {
            this.moveCardToAnotherColumn(group.field, movedItem, group.value)
        }
    }

    openCardDialog(record: BusinessRecords) {
        this.modalFlowManagerService.openDialog<CardComponent, DialogCardInput>({
            component: CardComponent,
            data: { recordGuid: record.guid, isFolder: true },
        })
    }

    toggleSelection(record: BusinessRecords) {
        this.actionPanelService.toggle(record)
    }

    toggleGroup(selected: boolean, group: RecordGroup) {
        if (selected) {
            this.actionPanelService.selectRecords(group.data)
        } else {
            this.actionPanelService.deselectRecords(group.data)
        }
    }

    isRecordSelected(record: BusinessRecords) {
        return this.actionPanelService.isSelected(record)
    }

    private moveCardToAnotherColumn(field: Field, item: MovedItem<BusinessRecords>, value: string) {
        const moveCard = (item.previousItems as BusinessRecords[]).find(
            (record) => record.guid === item.item.guid,
        )

        if (!moveCard) return

        if (this.isNoGroupedStatus(field.field_type_code, value)) {
            this.notificationService.openErrorNotification([
                {
                    message: 'NO_STATUS_GROUP',
                    object_field_guid: '',
                    object_record_guid: '',
                },
            ])
            return
        }

        this.recordService
            .updateRecord({
                record: moveCard!,
                cell: this.cells[moveCard.guid][field.guid],
                value: value !== this.NO_GROUPED_RECORDS_KEY ? value : '',
            })
            .subscribe()
    }

    private changeArrayDataToGroup(viewData: ViewData) {
        return this.folderFacadeService.selectSelectedFolderStatusField$.pipe(
            switchMap((folderStatus) => {
                if (folderStatus) return of(folderStatus)
                return this.folderFacadeService.selectGlobalFolderStatus$
            }),
            map((folderStatus) => {
                if (!folderStatus || !viewData) return viewData

                return this.changeViewData(folderStatus, viewData)
            }),
        )
    }

    private changeViewData(folderStatus: Field, viewData: ViewData) {
        const data = this.recordFacadeService.generateGroup(
            viewData.data as BusinessRecords[],
            folderStatus.guid,
            folderStatus,
        )

        return {
            ...viewData,
            data: data,
        }
    }

    private isNoGroupedStatus(fieldType: string, value: string) {
        return fieldType === 'field_type_status' && value === this.NO_GROUPED_RECORDS_KEY
    }
}
