import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'
import { ColorPickerService } from '@app/shared/color-picker/color-picker.service'
import { IconPickerService } from '@app/shared/icon-picker/icon-picker.service'
import { SetType } from '@app/shared/models/picker'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbPickerComponent } from '@components-library/tb-picker/tb-picker.component'

@Component({
    selector: 'app-icon-picker',
    templateUrl: './icon-picker.component.html',
    styleUrl: './icon-picker.component.sass',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TbPickerComponent, TbIconComponent, TbButtonComponent],
})
export class IconPickerComponent implements OnInit {
    @ViewChild('triggerItem') private triggerItem!: ElementRef

    @Input() set!: SetType

    @Input() icon?: string

    @Input() name?: string

    @Input() iconColor?: string

    @Output() iconChange = new EventEmitter<string | null>()

    pickerItems!: string[]

    filled = false

    defaultStatusIcon!: string

    selectedIcon!: string | null

    defaultColor!: string

    constructor(
        private iconPickerService: IconPickerService,
        private colorPickerService: ColorPickerService,
    ) {}

    ngOnInit() {
        this.pickerItems = this.iconPickerService.getIcons()
        this.filled = this.iconPickerService.getIconFill(this.set)
        this.defaultStatusIcon = this.iconPickerService.getDefaultIcon(SetType.STATUS)
        this.defaultColor = this.getDefaultColor()

        this.selectedIcon = this.getIcon()

        if (!this.icon) {
            this.iconChange.emit(this.selectedIcon)
        }
    }

    getDefaultColor() {
        if (this.set === SetType.STATUS) return ''

        return this.colorPickerService.getDefaultColor(this.set)
    }

    selectIcon(icon: string | null) {
        this.selectedIcon = icon ?? this.defaultStatusIcon
        this.iconChange.emit(icon)
    }

    open() {
        this.triggerItem.nativeElement.click()
    }

    private getIcon() {
        if (!this.icon) {
            return this.iconPickerService.getDefaultIcon(this.set)
        }

        return this.icon
    }
}
