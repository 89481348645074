<app-modal-layout
    [containerLayout]="containerLayout"
    (closeModal)="forceWithValidation()"
    *transloco="let t; read: 'share_record'"
>
    <div title>{{ t('title') }}</div>
    <div subtitle>{{ t('subtitle') }}</div>

    <div class="md:max-w-[600px]">
        <app-tb-select
            [formControl]="control"
            [multiple]="true"
            [placeholder]="t('select_placeholder')"
        >
            @for (folder of workspaceOptions; track folder) {
            <app-tb-option
                [value]="folder.guid"
                [icon]="folder.icon"
                [iconColor]="folder.iconColor"
            >
                {{ folder.name }}
            </app-tb-option>
            }
        </app-tb-select>
    </div>

    <div class="flex gap-2 justify-end" buttons *transloco="let tButtons; read: 'buttons'">
        <app-tb-button appearance="outlined" (click)="cancelShare()"
            >{{ tButtons('cancel') }}</app-tb-button
        >
        <app-tb-button (click)="applyShare()">{{ tButtons('save') }}</app-tb-button>
    </div>
</app-modal-layout>
