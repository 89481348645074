import { Injectable } from '@angular/core'
import { icons } from '@app/shared/icon-picker/icons'
import { SetType } from '@app/shared/models/picker'
import { random } from 'lodash-es'

@Injectable({
    providedIn: 'root',
})
export class IconPickerService {
    private DEFAULT_STATUS_ICON = 'add_reaction'

    private DEFAULT_RATING_ICON = 'star_rounded'

    constructor() {}

    getIcons() {
        return icons
    }

    getDefaultIcon(pickerType: SetType) {
        if (pickerType === SetType.STATUS) {
            return this.DEFAULT_STATUS_ICON
        }

        if (pickerType === SetType.RATING) {
            return this.DEFAULT_RATING_ICON
        }

        return icons[random(0, icons.length - 1)]
    }

    getIconFill(pickerType: SetType) {
        return pickerType !== SetType.FOLDER
    }
}
