import { Injectable } from '@angular/core'
import { ViewFilterService, ViewGroupService, ViewSortService } from '@app/views/view-controls'
import { View } from '@models/ui'
import { TranslocoService } from '@ngneat/transloco'
import { FilterStorageService } from '@services/local-storage/filter-storage.service'
import { GroupStorageService } from '@services/local-storage/group-storage.service'
import { SortStorageService } from '@services/local-storage/sort-storage.service'
import { SchemaFacadeService, ViewFacadeService } from '@services/store-facade'
import { keys } from 'lodash-es'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

export enum ViewErrorType {
    filterInvalidField = 'filterInvalidField',
    sortInvalidField = 'sortInvalidField',
    groupInvalidField = 'groupInvalidField',
}

export interface ViewErrorEntity {
    type: ViewErrorType
    message: string
}

@Injectable({
    providedIn: 'root',
})
export class ViewValidatorService {
    constructor(
        private viewFacadeService: ViewFacadeService,
        private viewFilterService: ViewFilterService,
        private sortService: ViewSortService,
        private groupService: ViewGroupService,
        private filterStorageService: FilterStorageService,
        private groupStorageService: GroupStorageService,
        private sortStorageService: SortStorageService,
        private schemaFacadeService: SchemaFacadeService,
        private translation: TranslocoService,
    ) {}

    private errors!: ViewErrorEntity[]

    private schemaFieldsGuids!: string[]

    validateViewErrors(options?: {
        filter?: ViewErrorType[]
    }): Observable<ViewErrorEntity[] | null> {
        return combineLatest([
            this.combineViewSettingsFieldGuids(),
            this.schemaFacadeService.selectSelectedTableSchemaFieldEntitiesFiltered$(),
            this.translation.selectTranslateObject('errors'),
        ]).pipe(
            map(([viewSettingFieldsGuids, schemaFields, t]) => {
                const { filterFieldGuids, sortFields, groupFields } = viewSettingFieldsGuids
                this.errors = []
                this.schemaFieldsGuids = keys(schemaFields || {})

                this.checkSettingsError(
                    filterFieldGuids,
                    ViewErrorType.filterInvalidField,
                    t['invalid_field.filter_invalid_field'],
                )

                this.checkSettingsError(
                    sortFields,
                    ViewErrorType.sortInvalidField,
                    t['invalid_field.sort_invalid_field'],
                )

                this.checkSettingsError(
                    groupFields,
                    ViewErrorType.groupInvalidField,
                    t['invalid_field.group_invalid_field'],
                )

                const errorsFiltered = this.errors.filter((error) => {
                    if (!!options?.filter?.length) {
                        return options.filter.includes(error.type)
                    }

                    return true
                })

                return errorsFiltered.length ? errorsFiltered : null
            }),
        )
    }

    filterHasNoResults(view: View, viewIsEmpty = false) {
        const filterGroups = this.viewFilterService.getFilterGroupByView(view)

        return filterGroups.length > 0 && viewIsEmpty
    }

    private combineViewSettingsFieldGuids() {
        return combineLatest([
            this.viewFacadeService.selectedView$,
            this.filterStorageService.getStore$(),
            this.sortStorageService.getStore$(),
            this.groupStorageService.getStore$(),
        ]).pipe(
            map(([view]) => {
                const filter = view ? this.viewFilterService.getFilterGroupByView(view) : []

                // Gets used field guids from filters
                const filterFieldGuids = filter.reduce(
                    (prev, curr) => [...prev, ...keys(curr.values)],
                    [] as string[],
                )

                const sortFields = keys(view ? this.sortService.getSortByView(view) : {})

                const groupGuid = view ? this.groupService.getGroupByView(view) : null
                const groupFields = groupGuid ? [groupGuid] : []

                return {
                    filterFieldGuids,
                    sortFields,
                    groupFields,
                }
            }),
        )
    }

    private checkSettingsError(
        settingFieldGuids: string[],
        errorType: ViewErrorType,
        errorMessage: string,
    ) {
        if (settingFieldGuids.some((guid) => !this.schemaFieldsGuids.includes(guid))) {
            this.errors.push({
                type: errorType,
                message: errorMessage,
            })
        }
    }
}
