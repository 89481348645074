@for (fieldGuid of fields; track fieldGuid) {
    @if (cells[fieldGuid].value && fieldEntities[fieldGuid]; as field) {
        <div class="flex flex-row justify-start items-center">
            <div class="pr-2">
                @if (selectedFolder) {
                    <app-field-name
                        [field]="field"
                        [folder]="selectedFolder"
                        [showIcon]="false"
                    ></app-field-name>
                }
            </div>
            @if (hasNewContainerSupport(field.field_type_code)) {
                <app-input-cell-container
                    [field]="field"
                    [value]="cells[fieldGuid].value"
                    appearance="board"
                ></app-input-cell-container>
            } @else {
                <app-cell-container
                    [disabled]="true"
                    [fieldTypes]="fieldTypes"
                    [field]="field"
                    [guid]="fieldGuid"
                    [value]="cells[fieldGuid].value"
                    class="flex flex-row justify-start items-center text-center"
                ></app-cell-container>
            }
        </div>
    }
}
