import { Component, ViewChild } from '@angular/core'
import { BaseSelectCellComponent } from '@app/feature/input-cells/base-select-cell/base-select-cell.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { SelectOption } from '@models/response/select-object-options'

@Component({
    selector: 'app-dropdown-select-cell',
    standalone: true,
    imports: [TbIconComponent, TbMenuComponent, TbMenuListComponent],
    templateUrl: './dropdown-select-cell.component.html',
    styleUrl: './dropdown-select-cell.component.sass',
})
export class DropdownSelectCellComponent extends BaseSelectCellComponent {
    private noneOption: SelectOption = {
        guid: 'none',
        label: 'None',
        color: 'newNeutral3',
    }

    @ViewChild('select') dropdownMenu!: TbMenuComponent

    get selectMenu(): TbMenuComponent {
        return this.dropdownMenu
    }

    protected prepareSelectData(): SelectOption[] {
        const selectData: SelectOption[] = Object.keys(this.selectObject).map((guid) => {
            return {
                guid,
                ...this.selectObject![guid],
            }
        })

        if (!this.field.is_required) {
            selectData.unshift(this.noneOption)

            this.selectObject = {
                ...this.selectObject,
                ['none']: this.noneOption,
            }
        }

        return selectData
    }

    protected setInitialValue(): void {
        if (!this.value && !this.field.is_required) {
            this.value = this.noneOption.guid!
        }
    }
}
