import { NgTemplateOutlet } from '@angular/common'
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { BaseCellComponent } from '@app/feature/input-cells/base-container-content'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbTooltipComponent } from '@components-library/tb-tooltip/tb-tooltip-component/tb-tooltip.component'
import { InputContainerAppearance } from '@models/input'

@Component({
    selector: 'app-tb-outline-container',
    standalone: true,
    imports: [
        MatFormFieldModule,
        TbIconComponent,
        MatProgressSpinnerModule,
        TbButtonComponent,
        NgTemplateOutlet,
        TbTooltipComponent,
    ],
    templateUrl: './tb-outline-container.component.html',
    styleUrl: './tb-outline-container.component.sass',
})
export class TbOutlineContainerComponent implements AfterViewInit, OnInit {
    @ViewChild('content', { read: ViewContainerRef })
    contentContainer!: ViewContainerRef

    @Input()
    contentComponent!: ComponentRef<BaseCellComponent>

    @Input()
    readonly = false

    @Input()
    disabled = false

    @Input()
    locked = false

    @Input()
    loading = false

    @Input()
    activeState = false

    @Input()
    editControls = false

    @Input()
    hoverIcons: TemplateRef<any> | null = null

    @Input()
    appearance = InputContainerAppearance.BORDERED

    errorMessage = ''

    active = false
    focused = false
    disabledForActive = false
    keepHoverIcons = false

    onFocusBound = this.onFocus.bind(this)
    toggleIcons = this.toggleIconsForHover.bind(this)

    get containerClasses() {
        return {
            'hover:border': this.appearance !== InputContainerAppearance.NO_BORDER,
            'focused': this.focused || this.loading,
            'background': this.background,
            'error': this.invalid && !this.readonly,
            'hover-background':
                (this.disabled || this.locked || this.readonly) &&
                this.appearance !== InputContainerAppearance.NO_BORDER,
            'active': this.active,
            [this.appearance]: true,
        }
    }

    get invalid() {
        return this.contentComponent.instance.errorState
    }

    get background() {
        return (
            this.appearance === InputContainerAppearance.BORDERED &&
            (this.locked || this.disabled || this.readonly) &&
            !this.invalid
        )
    }

    constructor(public elementRef: ElementRef, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.disabledForActive = this.activeState
    }

    ngAfterViewInit(): void {
        this.contentContainer?.insert(this.contentComponent.hostView)
        this.cdr.detectChanges()
    }

    @HostListener('document:click', ['$event'])
    handleClickOutside(event: MouseEvent) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            if (this.focused && !this.editControls) {
                this.contentComponent.instance.onOutsideClick()
            }

            if (this.activeState && !this.focused) {
                this.resetActive()
            }
        }
    }

    onContainerClick() {
        if (this.activeState && !this.focused && !this.invalid) {
            if (this.active) {
                return
            }

            this.active = true
            return
        }

        this.onContainerDoubleClick()
    }

    onContainerDoubleClick() {
        if (this.active) {
            this.disableActive()
        }

        if (!this.disabled && !this.readonly && !this.locked) {
            this.contentComponent.instance.onContainerClick()
        }
    }

    toggleIconsForHover() {
        this.keepHoverIcons = !this.keepHoverIcons
    }

    setErrorMessage(message: string) {
        this.errorMessage = message
    }

    applyValue() {
        this.contentComponent.instance.removeFocus()
        this.contentComponent.instance.emitValue()
        this.resetActive()
    }

    resetValue() {
        this.contentComponent.instance.removeFocus()
        this.contentComponent.instance.resetValue()
        this.resetActive()
    }

    resetActive() {
        this.active = false

        if (this.activeState) {
            this.disabledForActive = true
        }
    }

    makeFocused() {
        this.focused = true
    }

    private onFocus() {
        this.focused = true
        this.contentComponent.instance.onContainerClick()
        this.disableActive()
    }

    private disableActive() {
        this.active = false
        this.disabledForActive = false
    }
}
