import { UntilDestroy } from '@ngneat/until-destroy'
import { Component } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatExpansionModule } from '@angular/material/expansion'
import { TranslocoModule } from '@ngneat/transloco'

@UntilDestroy()
@Component({
    selector: 'app-template-selector',
    templateUrl: './template-selector.component.html',
    standalone: true,
    imports: [TranslocoModule, MatExpansionModule, MatIconModule],
})
export class TemplateSelectorComponent {}
