<div class="relative sm:flex w-full sm:h-fit h-14 overflow-hidden">
    <mat-toolbar-row
        class="table-toolbar h-fit w-inherit max-w-inherit overflow-y-hidden overflow-x-auto sm:mx-6 mx-4 sm:mt-2 p-0"
    >
        <app-view-filter class="ml-auto"></app-view-filter>
        <app-view-sort></app-view-sort>
        <app-view-group></app-view-group>
        <app-customize-view></app-customize-view>
    </mat-toolbar-row>
</div>
