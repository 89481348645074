<div class="inline-block h-6 w-6 rounded-full shrink-0 overflow-hidden">
    @if (userUrl) {
        <img
            class="flex-shrink-0 h-6 w-6"
            [src]="userUrl"
            alt=""
            onerror="this.src='../../../../assets/img/avatar.png'"
        />
    } @else {
        <img alt="userName" class="flex-shrink-0 h-6 w-6" src="../../../assets/img/avatar.png" />
    }
</div>
