import { Component, OnInit } from '@angular/core'
import { SelectObjectOptions } from '@core/models/response/select-object-options'
import { TranslocoService } from '@ngneat/transloco'
import { CellComponent } from '../../cell.component'
import { GetContrastColorPipe } from '@core/pipes/get-contrast-color.pipe'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { NgStyle } from '@angular/common'

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.sass'],
    standalone: true,
    imports: [MatMenuModule, NgStyle, MatIconModule, TbIconComponent, GetContrastColorPipe],
})
export class DropdownComponent extends CellComponent implements OnInit {
    dropdownData: SelectObjectOptions = {}

    default!: string

    constructor(private translation: TranslocoService) {
        super()
    }

    optionKeys() {
        if (this.dropdownData) {
            return Object.keys(this.dropdownData)
        }
        return []
    }

    ngOnInit(): void {
        if (this.field?.select_object_field) {
            if (!this.field.select_object_field) return

            this.dropdownData = this.field.select_object_field
            this.default = Object.keys(this.dropdownData).find(
                (guid) => this.dropdownData[guid].is_default == 1,
            )!
            if (!this.default) {
                throw new Error('error no default')
            }
        }
    }

    checkActive(id: string) {
        if (this.value != id) {
            this.cellValueChanged.emit(id)
        }
    }

    getTitleColor(id: string | undefined) {
        if (this.dropdownData) {
            if (this.dropdownData[id!]) {
                return this.dropdownData[id!].color
            } else {
                return '#aa2255'
            }
        }
        return ''
    }

    getTitle(id: string | undefined) {
        if (this.dropdownData) {
            if (this.dropdownData[id!]) {
                return this.dropdownData[id!].label
            } else {
                return this.translation.translate('cell_types.dropdown_default_title')
            }
        }
        return ''
    }

    getIcon(id: string | undefined) {
        if (this.dropdownData) {
            if (this.dropdownData[id!]) {
                return this.dropdownData[id!].icon
            } else {
                return 'beach_access'
            }
        }
        return ''
    }
}
