import { createAction, props } from '@ngrx/store'

export const openViewFilterDialog = createAction('[UI] Open filter dialog')

export const openViewSortDialog = createAction('[UI] Open sort dialog')

export const openViewGroupDialog = createAction(
    '[UI] Open group dialog',
    props<{ groupGuid?: string }>(),
)

export const openCustomizeViewDialog = createAction('[UI] Open customize view dialog')

export const showViewLoader = createAction('[UI] Show view loader')

export const hideViewLoader = createAction('[UI] Hide view loader')
