<div class="flex items-center">
    <div class="empty:hidden flex">
        @if (showCurrentUser) {
            <app-user-avatar
                class="flex relative mr-1 border border-newPrimaryLight rounded-full"
                [userName]="currentUser.fullName"
                [userUrl]="currentUser.avatar_url"
            ></app-user-avatar>

            @if (selectedUsers.size === 1) {
                <span class="body-1" *transloco="let tUserList; read: 'user_list'">{{
                    tUserList('me', { name: currentUser.fullName })
                }}</span>
            }

            @if (selectedUsers.size > 1) {
                <div class="w-[1px] h-full bg-newNeutral4 mr-1"></div>
            }
        } @else if (isFavorite && !isNoBorderedAppearance) {
            <app-tb-icon-toggle-button
                icon="star_outline"
                [class]="{ 'pointer-events-none': !focused }"
                [isActive]="isCurrentUserSelected"
                (toggle)="toggleFavorite($event)"
            ></app-tb-icon-toggle-button>

            @if (!focused && moreThanOneSelected) {
                <div class="w-[1px] h-[24px] bg-newNeutral4 mr-1"></div>
            }
        }
    </div>

    <app-users-list
        class="pointer-events-none"
        [formControl]="selectedUsersControl"
        [editMode]="isEdit"
        [class]="{ invisible: focused && isFavorite }"
        [fullNameForOneUser]="!isFavorite || (isFavorite && isNoBorderedAppearance)"
    >
    </app-users-list>
</div>
