import { Component, Inject } from '@angular/core'
import { ManageFieldsService } from '@app/feature/manage-fields/manage-fields.service'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { Field, FieldType, Folder } from '@models/ui'
import { UntilDestroy } from '@ngneat/until-destroy'
import { FieldTypeFacadeService } from '@services/store-facade'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { TbIconComponent } from '../../@components-library/tb-icon/tb-icon.component'
import { TypedTemplateDirective } from '../typed-template.directive'
import { TbButtonComponent } from '../../@components-library/tb-button/tb-button.component'
import { TbDividerComponent } from '../../@components-library/tb-divider/tb-divider.component'
import { NgTemplateOutlet, AsyncPipe } from '@angular/common'
import { ModalLayoutComponent } from '../../@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { TranslocoModule } from '@ngneat/transloco'

export interface FieldTypeSelectorData {
    selectedFolder?: Folder
    newFolder?: boolean
}

interface FieldTypeSelectorFields {
    onePerFolder: FieldType[]
    multiple: FieldType[]
}

@UntilDestroy()
@Component({
    selector: 'app-field-type-selector',
    templateUrl: './field-type-selector.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        ModalLayoutComponent,
        NgTemplateOutlet,
        TbDividerComponent,
        TbButtonComponent,
        TypedTemplateDirective,
        TbIconComponent,
        AsyncPipe,
    ],
})
export class FieldTypeSelectorComponent extends ModalContainerComponent<
    FieldTypeSelectorData,
    Field | undefined
> {
    fieldTypes$: Observable<FieldTypeSelectorFields> =
        this.fieldTypeFacadeService.selectAvailableFieldTypeByFolder$.pipe(
            map(this.filterFieldTypes),
        )

    contextType = {} as { fieldTypes: FieldType[]; onePerFolder: boolean }

    constructor(
        @Inject(ModalContainerDataToken) protected data: FieldTypeSelectorData,
        protected modalManagerService: ModalManagerService,
        confirmationPopupService: ConfirmationDialogService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private manageFieldsService: ManageFieldsService,
    ) {
        super(data, modalManagerService, confirmationPopupService)
    }

    addField(type: FieldType) {
        this.manageFieldsService
            .addField(
                {
                    field_type_code: type.guid,
                    folder_guid: this.data.selectedFolder?.guid,
                    new_folder: this.data.newFolder,
                },
                this.modalManagerService,
            )
            .subscribe((field) => {
                this.close(field)
            })
    }

    private filterFieldTypes(fieldTypes: FieldType[]) {
        return fieldTypes.reduce(
            (res: FieldTypeSelectorFields, type: FieldType) => {
                if (type.operationCode.multiple) {
                    res.multiple.push(type)
                } else {
                    res.onePerFolder.push(type)
                }
                return res
            },
            { onePerFolder: [], multiple: [] },
        )
    }
}
