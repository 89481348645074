import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { devStorageInstance, EnvironmentMode } from '@services/local-storage/dev-storage.service'
import { getEnvMode } from '@test/dev-utils'

@Directive({
    selector: '[appEnv]',
    standalone: true,
})
@UntilDestroy()
export class EnvDirective implements OnInit {
    @Input('appEnv') env: EnvironmentMode = 'prod'
    @Input({
        required: true,
        alias: 'appEnvVisible',
    })
    visible = false

    constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {}

    ngOnInit(): void {
        devStorageInstance.envMode$.pipe(untilDestroyed(this)).subscribe(() => {
            const mode = getEnvMode()

            const correctEnv =
                (this.visible && this.env === mode) || (!this.visible && this.env !== mode)

            if (correctEnv) {
                this.viewContainer.createEmbeddedView(this.templateRef)
                return
            }

            this.viewContainer.clear()
        })
    }
}
