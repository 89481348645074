import { Component, OnInit } from '@angular/core'
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { FilterTypesComponent } from '@app/views/view-controls/view-filter/filter-types/filter-types.component'
import {
    prepareSelectOptionModel,
    SelectObjectOptions,
    SelectOption,
} from '@core/models/response/select-object-options'
import { cloneDeep } from 'lodash-es'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbCheckboxComponent } from '@components-library/tb-checkbox'
import { CdkAccordionModule } from '@angular/cdk/accordion'
import { TranslocoModule } from '@ngneat/transloco'
import { NgStyle } from '@angular/common'
import { TbMenuComponent } from '@components-library/tb-menu'

@Component({
    selector: 'app-status-filter-container',
    templateUrl: './status-filter.component.html',
    styleUrls: ['./status-filter.component.sass'],
    standalone: true,
    imports: [
        TbMenuComponent,
        NgStyle,
        TranslocoModule,
        ReactiveFormsModule,
        CdkAccordionModule,
        TbCheckboxComponent,
        TbIconComponent,
    ],
})
export class StatusFilterComponent extends FilterTypesComponent implements OnInit {
    statusData!: SelectObjectOptions
    statusList: string[] = []
    selectedOption: SelectOption[] = []
    activeOptions: string[] = []
    formGroup!: FormGroup<{
        statusListChbx: FormArray<
            FormGroup<{
                statusListInnerChbx: FormArray<FormControl<boolean | null>>
                label: FormControl<boolean | null>
                labelName: FormControl<string | null>
            }>
        >
    }>

    constructor() {
        super()
    }

    optionValues() {
        if (this.statusData) {
            return Object.values(this.statusData)
        }
        return []
    }

    optionValuesItem(item: string) {
        if (this.statusData) {
            return Object.values(this.statusData).filter((el) => el.tags === item)
        }
        return []
    }

    ngOnInit() {
        if (this.field) {
            this.statusData = cloneDeep(this.field.select_object_field!)
            this.statusList = [...new Set(this.optionValues().map((option) => option.tags!))]
        }
        prepareSelectOptionModel(this.statusData)
        this.activeOptions = this.getActiveOptions()
        this.selectedOption = this.activeOptions.map((option) => this.statusData[option])

        this.formGroup = new FormGroup({
            statusListChbx: this.initStatusList(this.statusList, this.optionValues()),
        })
    }

    getBgColor(id: string) {
        if (this.statusData) {
            return this.statusData[id].color
        }
        return ''
    }

    initStatusList(category: string[], status: SelectOption[]) {
        return new FormArray(
            category.map(
                (item: string) =>
                    new FormGroup({
                        statusListInnerChbx: this.createFormArrayStatus(status, item),
                        label: new FormControl<boolean>({
                            value: this.allCompete(item),
                            disabled: false,
                        }),
                        labelName: new FormControl({
                            value: item,
                            disabled: false,
                        }),
                    }),
            ),
        )
    }

    createFormArrayStatus(origin: SelectOption[], label: string) {
        return new FormArray(
            origin
                .filter((item) => item.tags == label)
                .map(
                    (item: SelectOption) =>
                        new FormControl<boolean>({
                            value: this.selectedOption.includes(item),
                            disabled: false,
                        }),
                ),
        )
    }

    setAll(completed: boolean, item: string) {
        this.optionValues().forEach((option) => {
            if (option.tags == item) {
                if (completed) {
                    if (!this.selectedOption.includes(option)) {
                        this.selectedOption.push(option)
                    }
                } else {
                    this.selectedOption.splice(this.selectedOption.indexOf(option), 1)
                }
            }
        })

        this.updateChbxValueOnSetAll(item, completed)

        this.sendOptions(this.selectedOption)
    }

    updateAllComplete(completed: boolean, parentLabel: string, childItem: SelectOption) {
        if (completed) {
            this.selectedOption.push(childItem)
        } else {
            this.selectedOption.splice(this.selectedOption.indexOf(childItem), 1)
        }
        this.someComplete(parentLabel)
        this.updateAllLabelValue(parentLabel)
        this.sendOptions(this.selectedOption)
    }

    someComplete(parentItem: string): boolean {
        return this.optionValues().some(
            (option) =>
                option.tags == parentItem &&
                this.selectedOption.includes(option) &&
                !this.allCompete(parentItem),
        )
    }

    allCompete(parentItem: string) {
        let complete: boolean = true
        this.optionValues().some((option) => {
            if (option.tags == parentItem && !this.selectedOption.includes(option)) {
                complete = false
            }
        })
        return complete
    }

    sendOptions(options: SelectOption[]) {
        this.addActiveOptions(options)
        this.form.controls['value'].setValue(this.activeOptions)
        this.form.controls['value'].markAsTouched()
    }

    addActiveOptions(options: SelectOption[]) {
        if (options.length > 0) {
            this.activeOptions = options.map((option) => option.guid as string)
        } else {
            this.activeOptions = []
        }
    }

    private updateChbxValueOnSetAll(item: string, completed: boolean) {
        const itemControl = this.formGroup.controls.statusListChbx.controls.find((control) => {
            return control.controls.labelName.value === item
        })

        itemControl?.controls.statusListInnerChbx.controls.forEach((control) => {
            control.setValue(completed)
        })
    }

    private updateAllLabelValue(parentLabel: string) {
        const isAllCompleted = this.allCompete(parentLabel)

        const itemControl = this.formGroup.controls.statusListChbx.controls.find((control) => {
            return control.controls.labelName.value === parentLabel
        })

        itemControl?.controls.label.setValue(isAllCompleted)
    }

    private getActiveOptions() {
        const control = this.form.controls['value']
        if (!control.value || !Array.isArray(control.value)) return []

        return control.value.filter((option) => !!this.statusData[option])
    }
}
