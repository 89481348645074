import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { SharedModule } from '@app/shared/shared.module'
import { TbBoardModule } from '@components-library/tb-board/tb-board.module'
import { TranslocoModule } from '@ngneat/transloco'
import { BoardViewModule } from 'src/app/views/board/board-view.module'
import { ActionsPanelModule } from '@app/feature/actions-panel/actions-panel.module'

//TODO: [page extraction] review view modules. create base view module and move related imports and declarations to new module
@NgModule({
    imports: [
        CommonModule,
        BoardViewModule,
        TranslocoModule,
        MatIconModule,
        FormsModule,
        MatDialogModule,
        SharedModule,
        MatButtonModule,
        TbBoardModule,
        ActionsPanelModule,
    ],
})
export class ViewsModule {}
