import { Component } from '@angular/core'
import { MatDividerModule } from '@angular/material/divider'

@Component({
    selector: 'app-tb-divider',
    templateUrl: './tb-divider.component.html',
    standalone: true,
    imports: [MatDividerModule],
})
export class TbDividerComponent {}
