import { selectFieldTypeEntities } from '@core/@ngrx'
import { Dictionary } from '@ngrx/entity'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { cloneDeep, reduce, values } from 'lodash-es'
import { FieldEntities, FieldType, Schema, SystemTypeCode } from '../../models'
import { AppState } from '../app.state'
import { StoreFeatureNames } from '../store-feature-names'
import { schemaAdapter, schemaFeature, SchemaState } from './schema.reducer'

const selectSchemaState = createFeatureSelector<SchemaState>(StoreFeatureNames.Schema)

const { selectIds, selectEntities, selectAll, selectTotal } =
    schemaAdapter.getSelectors(selectSchemaState)

export const selectSchemaIds = selectIds
export const selectSchemaTotal = selectTotal
export const selectSelectedTableSchemaGuid = schemaFeature.selectSelectedTableSchemaGuid

function updateSchemaFieldsOperationCode(
    schema: Schema,
    fieldTypeDictionary: Dictionary<FieldType>,
) {
    schema.fieldEntities = reduce(
        schema.fieldEntities,
        (fields, field, guid) => {
            if (!field.operationCode) {
                field.operationCode = fieldTypeDictionary[field.field_type_code]?.operationCode
                fields[guid] = field
            }
            return fields
        },
        schema.fieldEntities,
    )
    return schema
}

export const selectSchemaEntities = createSelector<
    AppState,
    [Dictionary<Schema>, Dictionary<FieldType>],
    Dictionary<Schema>
>(
    selectEntities,
    selectFieldTypeEntities,
    (schemaDictionary: Dictionary<Schema>, fieldTypeDictionary) => {
        const schemaDictionaryClone = cloneDeep(schemaDictionary)
        return reduce(
            schemaDictionaryClone,
            (schemas, schema, guid) => {
                if (schema) {
                    schemas[guid] = updateSchemaFieldsOperationCode(schema, fieldTypeDictionary)
                }
                return schemas
            },
            schemaDictionaryClone,
        )
    },
)

export const selectAllSchemas = createSelector<
    AppState,
    [Schema[], Dictionary<FieldType>],
    Schema[]
>(selectAll, selectFieldTypeEntities, (schemas: Schema[], fieldTypeDictionary) => {
    return cloneDeep(schemas).map((schema) => {
        return updateSchemaFieldsOperationCode(schema, fieldTypeDictionary)
    })
})

export const selectTableSchemas = createSelector<AppState, [Dictionary<Schema>], Schema[]>(
    selectSchemaEntities,
    (schemaDictionary: Dictionary<Schema>) => {
        return Object.values(schemaDictionary).filter((schema) => {
            if (!schema) return false

            return schema.system_object_type_code === SystemTypeCode.TABLE
        }) as Schema[]
    },
)

export const selectSelectedTableSchema = createSelector<
    AppState,
    [string | null, Dictionary<Schema>],
    Schema | undefined
>(
    selectSelectedTableSchemaGuid,
    selectSchemaEntities,
    (schemaGuid: string | null, schemaDictionary: Dictionary<Schema>) => {
        console.info('selectSelectedTableSchema')
        if (!schemaGuid) return

        return schemaDictionary[schemaGuid]
    },
)

export const selectSelectedTableSchemaFieldEntities = createSelector<
    AppState,
    [Schema | undefined],
    FieldEntities | undefined
>(selectSelectedTableSchema, (schema: Schema | undefined) => {
    return schema?.fieldEntities
})

export const selectConfigSchema = createSelector<AppState, Schema[], Schema | undefined>(
    selectAllSchemas,
    (schemas: Schema[]) => {
        return schemas.find((schema) => schema.system_object_type_code === SystemTypeCode.CONFIG)
    },
)
