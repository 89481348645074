import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core'

@Directive({
    selector: '[appAutoFocus]',
    standalone: true,
})
export class AutoFocusDirective implements OnChanges {
    @Input() appAutoFocus!: boolean

    constructor(private el: ElementRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.appAutoFocus.currentValue) {
            this.el.nativeElement.focus()
        }
    }
}
