@if (field) {
<div class="flex items-center max-w-full">
    @if (showIcon) {
    <app-tb-icon [icon]="getFieldIcon()" color="newTextLight" [class]="bgIconColor"> </app-tb-icon>
    }
    <app-tb-tooltip-component class="w-full" [tooltip]="getTooltipText()">
        <div class="max-w-full flex whitespace-nowrap">
            @if (showFolderName) {
            <span class="flex-auto overflow-hidden text-ellipsis"
                >{{ field.folder_name!.name }} </span
            ><span>&nbsp;/&nbsp;</span>
            }
            <span class="flex-auto overflow-hidden text-ellipsis">{{ field.name }}</span>
        </div>
    </app-tb-tooltip-component>
</div>
}
