<div [formGroup]="formGroup" class="mb-5" *transloco="let tFilter; read: 'filter'">
    @if (!single && !first) {
        <app-filter-combination-toggle
            class="flex w-fit mx-auto my-4 andOrToggle"
            [disabled]="isCombinationGroupToggleDisabled"
            [value]="formFilterService.getGroupsCombinationType"
            [combinationTypes]="filterGroupsCombinationTypes"
            (valueChange)="groupCombinationTypeChange($event)"
            translateKey="filter.groups_combination_types"
        ></app-filter-combination-toggle>
    }
    <div [class.notEmpty]="!isEmpty" [formGroup]="formGroup">
        <div [class.emptyTemplate]="isEmpty">
            @if (!isEmpty) {
                <ng-container *ngTemplateOutlet="filterItem"></ng-container>
            }
            @if (fieldGuids) {
                <div class="p-0 w-full">
                    @if (isEmpty) {
                        <span class="flex start space-x-4" *transloco="let tFilter; read: 'filter'">
                            <div class="py-3">{{ tFilter('where_label') }}</div>
                            <app-tb-select
                                class="w-full"
                                [placeholder]="tFilter('select_filter')"
                                [focusInitial]="true"
                                (selectionChange)="addFilterClick($event)"
                            >
                                @for (fieldGuid of fieldGuids; track fieldGuid) {
                                    <app-tb-option [value]="fieldGuid">
                                        <app-field-name
                                            [field]="getFieldByGuid(fieldGuid)"
                                            [folder]="selectedFolder"
                                            [showIcon]="false"
                                        ></app-field-name>
                                    </app-tb-option>
                                }
                            </app-tb-select>
                        </span>
                    } @else {
                        <app-tb-menu
                            [template]="menuContent"
                            [class.disabled-menu-trigger]="formGroup.invalid"
                        >
                            <div
                                trigger
                                class="flex justify-between"
                                *transloco="let tFilter; read: 'filter'"
                            >
                                <app-tb-button
                                    [disabled]="formGroup.invalid"
                                    [appearance]="'text'"
                                    [icon]="'add_circle_outline'"
                                >
                                    <span class="body-2">{{ tFilter('add_filter') }}</span>
                                </app-tb-button>
                                @if (!single) {
                                    <app-tb-button
                                        [icon]="'close'"
                                        [appearance]="'text'"
                                        [color]="'secondary'"
                                        (click)="deleteGroup.emit()"
                                    >
                                        <span class="body-2">{{
                                            tFilter('delete_condition_group')
                                        }}</span>
                                    </app-tb-button>
                                }
                            </div>
                        </app-tb-menu>
                        <ng-template #menuContent let-layout>
                            <app-tb-menu-list [containerLayout]="layout">
                                @for (fieldGuid of fieldGuids; track fieldGuid) {
                                    <app-tb-menu-list-item
                                        (click)="
                                            $event.stopPropagation(); addFilterClick(fieldGuid)
                                        "
                                    >
                                        <app-field-name
                                            [field]="getFieldByGuid(fieldGuid)"
                                            [folder]="selectedFolder"
                                            [showIcon]="false"
                                        ></app-field-name>
                                    </app-tb-menu-list-item>
                                }
                            </app-tb-menu-list>
                        </ng-template>
                    }
                </div>
            }
        </div>
    </div>
    <ng-template #filterItem>
        <div [class.sm:mb-4]="!isEmpty" class="flex items-center p-0 text-primarySecondary">
            <div>{{ tFilter('where_label') }}</div>
            @if (isShowFilterCombinationToggle()) {
                <div class="flex items-center">
                    <app-filter-combination-toggle
                        class="mx-4"
                        [value]="filterCombinationType"
                        [combinationTypes]="filterCombinationTypes"
                        (valueChange)="combinationTypeChange($event)"
                    ></app-filter-combination-toggle>
                    <div>{{ tFilter('where_conditions_match') }}</div>
                </div>
            }
        </div>
        @if (formGroup.value) {
            @for (formItem of formItems; track formItem; let itemIndex = $index) {
                <app-filter-item
                    (deleteFilter)="deleteFilter(itemIndex)"
                    (deleteGroup)="deleteGroup.emit()"
                    [itemIndex]="itemIndex"
                    [groupIndex]="groupIndex"
                    [fieldTypes]="fieldTypes"
                    [fieldGuids]="fieldGuids"
                    [totalItems]="formFilterService.getFilterGroupValues(this.groupIndex).length"
                    [fields]="fields"
                    [selectedFolder]="selectedFolder"
                ></app-filter-item>
            }
        }
    </ng-template>
</div>
