import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { CellComponent } from '../../cell.component'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { CURRENCY_LIST, Field } from '@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MatOptionModule } from '@angular/material/core'

import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'

@UntilDestroy()
@Component({
    selector: 'app-money-settings',
    templateUrl: './money-settings.component.html',
    styleUrls: ['./money-settings.component.sass'],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatOptionModule],
})
export class MoneySettingsComponent extends CellComponent implements OnInit {
    @Output()
    fieldChanged: EventEmitter<Field> = new EventEmitter<Field>()

    @ViewChild('textInput') textInput!: ElementRef

    selectFormControl!: FormControl<string | null>
    currencies: { [key: string]: any[] } = CURRENCY_LIST

    currencyListKeys() {
        return Object.keys(this.currencies)
    }

    ngOnInit(): void {
        this.selectFormControl = new FormControl<string>('', this.validatorOrOpts)

        this.selectFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            console.log('value', value)
            if (value) this.currencyChanged(value)
        })
    }

    editText() {
        this.textInput.nativeElement.focus()
        this.textInput.nativeElement.classList.add('active')
    }

    private currencyChanged(value: string) {
        this.fieldChanged.emit({
            ...this.field,
            configure_json: JSON.stringify({
                currency: value,
                currencySymbol: this.currencies[value][4],
            }),
        })
    }
}
