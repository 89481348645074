@if (fieldTypes && formItem) {
    <div
        [formGroup]="formItem"
        class="relative flex items-center max-sm:pl-4 max-sm:flex-wrap max-sm:mt-4 sm:mb-4"
    >
        <div
            class="flex-1"
            [class.invalid-field-indicator]="
                formItem.controls['fieldGuid'].errors?.['invalidField']
            "
        >
            <div class="flex flex-1">
                <div
                    class="hidden max-sm:block absolute top-0 left-0 h-[calc(100%-16px)] w-[4px] bg-primaryLight rounded"
                ></div>
                <div class="flex flex-1">
                    <app-tb-select
                        class="flex-1 max-sm:mb-4 mr-2 noHintError min-w-[120px]"
                        formControlName="fieldGuid"
                        [placeholder]="getFieldControlPlaceholder()"
                    >
                        @for (fieldGuid of fieldGuids; track fieldGuid) {
                            <app-tb-option [value]="fieldGuid">
                                <app-field-name
                                    [field]="fields[fieldGuid]"
                                    [folder]="selectedFolder"
                                    [showIcon]="false"
                                ></app-field-name>
                            </app-tb-option>
                        }
                    </app-tb-select>
                    <app-tb-select
                        *transloco="let tOption; read: 'filter.filter_option'"
                        class="max-sm:mb-4 max-w-fit mr-2 min-w-[110px] noHintError"
                        formControlName="option"
                    >
                        @for (item of fieldFilterOptions(fieldGuid); track item) {
                            <app-tb-option [value]="item"> {{ tOption(item) }} </app-tb-option>
                        }
                    </app-tb-select>
                </div>
                <div class="flex items-center flex-1">
                    @if (formItem.contains('value')) {
                        <div class="flex-1 min-w-[120px] max-sm:mb-4 mr-2">
                            <app-filter-types-container
                                [field]="fields[formItem.controls['fieldGuid'].value]"
                                [form]="formItem"
                            ></app-filter-types-container>
                        </div>
                    }
                </div>
            </div>
            <div>
                @if (formItem.controls['fieldGuid'].errors?.['invalidField']) {
                    <span class="text-red-500 text-xs">{{
                        'errors.invalid_field.selected_field_not_exist' | transloco
                    }}</span>
                }
            </div>
        </div>
        <app-tb-icon
            icon="custom_trash"
            class="cursor-pointer"
            color="newPrimaryLight"
            (click)="removeFilter()"
        ></app-tb-icon>
    </div>
}
