export type FieldGuid = string

export interface RequestCreateCell {
    value: string
}

export interface RequestUpdateCell {
    value: string
    revision: number
}
