import { Component } from '@angular/core'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbTooltipComponent } from '@components-library/tb-tooltip/tb-tooltip-component/tb-tooltip.component'
import { FolderFacadeService } from '@core/services/store-facade'
import { FoldersListComponent } from './folders-list/folders-list.component'
import { MatIconModule } from '@angular/material/icon'
import { TbMenuComponent } from '@components-library/tb-menu/tb-menu.component'
import { AsyncPipe } from '@angular/common'
import { MatMenuModule } from '@angular/material/menu'

@Component({
    selector: 'app-folder-controls',
    templateUrl: './folder-controls.component.html',
    styleUrl: './folder-controls.component.sass',
    standalone: true,
    imports: [
        TbMenuComponent,
        MatIconModule,
        AsyncPipe,
        TbTooltipComponent,
        TbIconComponent,
        MatMenuModule,
    ],
})
export class FolderControlsComponent {
    constructor(private folderFacadeService: FolderFacadeService) {}

    menuComponent = FoldersListComponent

    selectedFolder$ = this.folderFacadeService.selectSelectedFolder$
}
