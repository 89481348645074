import { LinkDefinition } from '../ui'
import { FieldTypes } from './field-type.model'
import { SelectObjectOptions } from './select-object-options'

export enum SystemTypeCode {
    FOLDER = 'folder',
    VIEW = 'view',
    TABLE = 'table',
    CONFIG = 'configuration',
    SUBTASK = 'subtask',
}

export interface ResponseSchema {
    guid: string
    name: string
    is_system?: number
    system_object_type_code: SystemTypeCode | string
    revision: number
    field: ResponseFieldEntities
    object_type_code?: string
    acl?: number
    parent_sot_guid?: string
}

export interface ResponseFieldEntities {
    [guid: string]: ResponseField
}

export interface ResponseField {
    name?: string
    is_required?: number
    is_primary?: number
    is_readonly?: number
    field_type_code: FieldTypes | string
    configure_json?: string
    revision?: number
    validation?: string
    folder_guid?: string
    system_name?: string
    select_object_field?: SelectObjectOptions
    operation_code?: string
    shared_with_folder?: string
    link_definition?: LinkDefinition
    virtual_link?: string
    system_guid?: string
    is_on_top?: number
    default_value?: string
}
