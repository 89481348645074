import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { ManageFieldsService } from '@app/feature/manage-fields/manage-fields.service'
import { getBoolean } from '@core/global-util'
import { Field, Folder } from '@models/ui'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { ShowIfOperationCodeMatchExpectedOperationDirective } from '@shared/show-if-operation-code-match-expected-operation.directive'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'

import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-manage-field-field-options',
    templateUrl: './field-options-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TranslocoModule,
        TbMenuListComponent,
        ShowIfOperationCodeMatchExpectedOperationDirective,
        TbMenuListItemComponent,
        TbDividerComponent,
        TbIconComponent,
    ],
})
export class FieldOptionsMenuComponent {
    @Input() field!: Field
    @Input() selectedFolder!: Folder

    @Output() deleteField = new EventEmitter()

    get isGlobalFolder(): boolean {
        return getBoolean(this.selectedFolder.is_global.value)
    }

    constructor(private manageFieldsService: ManageFieldsService) {}

    editField() {
        this.manageFieldsService.editField(this.field)
    }

    duplicateField() {
        this.manageFieldsService.duplicateField(this.field)
    }
}
