<app-tb-menu-list *transloco="let tBoardCard; read: 'board.board_card'">
    <app-tb-menu-list-item icon="custom_copy" (click)="openCard()"
        >{{ tBoardCard('open_record') }}</app-tb-menu-list-item
    >
    <app-tb-menu-list-item icon="control_point_duplicate" (click)="duplicate()"
        >{{ tBoardCard('duplicate') }}</app-tb-menu-list-item
    >
    <app-tb-menu-list-item icon="edit_note" (click)="manageFields()"
        >{{ tBoardCard('manage_fields') }}</app-tb-menu-list-item
    >

    <app-tb-divider></app-tb-divider>

    <app-tb-menu-list-item (click)="delete()">
        <app-tb-icon color="red-600" icon="custom_trash" item-icon></app-tb-icon>
        <span class="text-red-600" item-title>{{ tBoardCard('delete_record') }}</span>
    </app-tb-menu-list-item>
</app-tb-menu-list>
