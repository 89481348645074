import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'getContrastColor',
    standalone: true,
})
export class GetContrastColorPipe implements PipeTransform {
    transform(color: string): string {
        const hex = color.replace(/#/, '')
        const r = parseInt(hex.substr(0, 2), 16)
        const g = parseInt(hex.substr(2, 2), 16)
        const b = parseInt(hex.substr(4, 2), 16)
        let luminance = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255
        if (luminance < 0.5) {
            return '#333333'
        } else {
            return '#ffffff'
        }
    }
}
