import { Component, EventEmitter, Input, Output } from '@angular/core'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'

@Component({
    selector: 'app-pinned-record',
    templateUrl: './pin-record.component.html',
    styleUrls: ['./pin-record.component.sass'],
    standalone: true,
    imports: [TbButtonComponent],
})
export class PinRecordComponent {
    @Input()
    name!: string

    @Output()
    delete: EventEmitter<any> = new EventEmitter<any>()

    @Output()
    openRecord: EventEmitter<any> = new EventEmitter<any>()

    openPinItem() {
        this.openRecord.emit()
    }

    removePinItem() {
        this.delete.emit()
    }
}
