@if (fieldTypes$ | async; as fieldTypes) { @if (selectedFolder$ | async; as selectedFolder) {
<app-tb-board class="px-6" [columnTemplate]="columnTemplate"></app-tb-board>
<ng-template #columnTemplate>
    @for (group of groups; track group) {
    <app-tb-board-column
        [items]="group.data || []"
        [card]="card"
        [columnItem]="group"
        [headerMenu]="cardHeaderMenu"
        [disableDragIntoList]="group.value === NO_GROUPED_RECORDS_KEY"
        [selectedItems]="selectedRecords"
        (columnMoved)="drop(group, $event)"
        (selectColumn)="toggleGroup($event, group)"
    >
        <div header class="h-full w-full">
            @if (group.field && fieldTypes) {
            <div class="flex items-center h-full w-full relative">
                <app-grouping-values
                    class="w-100"
                    [fieldType]="fieldTypes[group.field.field_type_code]"
                    [field]="group.field"
                    [value]="group.value ?? ''"
                ></app-grouping-values>
            </div>
            } @else {
            <span *transloco="let tBoard; read: 'board'"> {{tBoard('template')}} </span>
            }
        </div>
    </app-tb-board-column>
    }
</ng-template>
<ng-template #businessCardContent let-fieldTypes="fieldTypes" let-record="record">
    @if (fields$ | async; as fields) {
    <app-board-card-fields
        [fieldTypes]="fieldTypes"
        [fields]="visibleFields"
        [fieldEntities]="fields"
        [selectedFolder]="selectedFolder"
        [cells]="cells[record.guid]"
    ></app-board-card-fields>
    }
</ng-template>
<ng-template #card let-record="item">
    <app-tb-board-card
        [item]="record"
        [title]="record.name.value"
        [key]="boardItemsKey"
        [moreMenu]="cardMoreMenu"
        [selected]="isRecordSelected(record)"
        [selectedItems]="selectedRecords"
        (selectCard)="toggleSelection(record)"
        (click)="openCardDialog(record)"
    >
        <ng-container
            *ngTemplateOutlet="businessCardContent; context: { fieldTypes: fieldTypes, record: record }"
        ></ng-container>
        <ng-template #cardMoreMenu>
            <app-board-card-menu [record]="record"></app-board-card-menu>
        </ng-template>
    </app-tb-board-card>
</ng-template>
<ng-template #cardHeaderMenu let-data="data">
    <app-board-column-header-menu [data]="data"></app-board-column-header-menu>
</ng-template>
} }
