<div class="inline-block rounded-full shrink-0 relative" [class]="avatarClass">
    @if (userUrl && !isImgUnavailable) {
        <img
            class="flex-shrink-0 h-full w-full rounded-full"
            [src]="'https://tikipanda.com' + userUrl"
            alt=""
            (error)="imgUnavailable()"
        />
    } @else {
        <div class="rounded-full flex items-center justify-center" [class]="iconBgClass">
            <app-tb-icon
                [class]="small ? 'icon-d' : 'icon-m'"
                [icon]="unknown ? 'person_off' : 'person'"
                [filled]="true"
                color="newNeutral1"
            ></app-tb-icon>
        </div>
    }
</div>
