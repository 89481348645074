import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms'
import { INPUT_CONTAINER } from '@models/input'

@Component({
    template: '',
})
export abstract class BaseCellComponent implements ControlValueAccessor {
    @Input()
    disabled = false

    @Input()
    placeholder = ''

    @Input()
    readonly = false

    @Input()
    loading = false

    @Input()
    value: string = ''

    @Output()
    valueChange = new EventEmitter<string>()

    touched = false
    focused = false

    protected readonly inputContainer = inject(INPUT_CONTAINER)

    /**
     * The instance of the parent FormControl, it can be null if we do not use FormControls on FormContainer component
     * */
    protected readonly parentControl = inject(NgControl, { optional: true })?.control

    /**
     * Control Accessor implementation API
     * */
    onChange = (_: any) => {}

    /**
     * Control Accessor implementation API
     * */
    onTouched = () => {}

    /**
     * Control Accessor implementation API
     * */
    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    /**
     * Control Accessor implementation API
     * */
    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    protected makeTouchedAndUnfocused() {
        this.touched = true
        this.focused = false
        this.inputContainer.focused = false
        this.onTouched()
    }

    /**
     * Control Accessor implementation API
     * */
    abstract writeValue(value: string): void

    /**
     * Control Accessor implementation API
     * */
    abstract setDisabledState(isDisabled: boolean): void

    /**
     * Called when we click on InputContainer(TbOutlineInputComponent)
     * */
    abstract onContainerClick(): void

    /**
     * Called when we click outside the InputContainer
     * */
    abstract onOutsideClick(): void

    /**
     * Called by InputContainer when we call Save/Cancel buttons
     * */
    abstract removeFocus(): void

    /**
     * Called by InputContainer when we call Save/Cancel buttons
     * If forces emit of new value
     * */
    abstract emitValue(): void

    /**
     * Called by InputContainer when we call Save/Cancel buttons
     * If forces restoring to the previously saved value
     * */
    abstract resetValue(): void

    abstract get errorState(): boolean

    abstract get loadingState(): boolean

    protected mergeValidators(control: FormControl) {
        if (this.parentControl && this.parentControl.validator) {
            control.addValidators(this.parentControl.validator)
        }
    }
}
