import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { Folder, Schema } from '@core/models'
import {
    RecordSettingsContentComponent,
    RecordSettingsData,
} from './record-settings-content/record-settings-content.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { EnvDirective } from '@shared/env.directive'
import { NgTemplateOutlet } from '@angular/common'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-add-record-settings-section',
    templateUrl: './add-record-settings-section.component.html',
    styleUrl: './add-record-settings-section.component.sass',
    standalone: true,
    imports: [TranslocoModule, NgTemplateOutlet, EnvDirective, TbIconComponent],
})
export class AddRecordSettingsSectionComponent {
    @Input() folders!: Folder[]
    @Input() schemas!: Schema[]
    @Input() selectedFolder!: Folder
    @Input() selectedSchema!: Schema

    @Output() saveSettings = new EventEmitter<{
        folderGuid: string
        schemaGuid: string
    }>()

    constructor(protected modalManagerService: ModalManagerService) {}

    openDialog() {
        const dialogRef = this.modalManagerService.openDialog<
            RecordSettingsContentComponent,
            RecordSettingsData,
            { folderGuid: string; schemaGuid: string }
        >({
            component: RecordSettingsContentComponent,
            data: {
                folders: this.folders,
                schemas: this.schemas,
                selectedFolderGuid: this.selectedFolder.guid,
                selectedSchemaGuid: this.selectedSchema.guid,
            },
        })

        dialogRef.subscribe((data) => {
            if (data) {
                this.saveSettings.emit({
                    folderGuid: data.folderGuid,
                    schemaGuid: data.schemaGuid,
                })
            }
        })
    }
}
