<div
    class="flex p-2 w-full rounded-lg border box-border h-[2.5rem] items-center group/container overflow-hidden"
    [class]="containerClasses"
    data-testid="input-container"
>
    <div class="grow flex items-center w-full" [class.pointer-events-none]="disabledForActive">
        @if (!locked) {
            <ng-container #content></ng-container>
        } @else {
            <app-tb-icon icon="lock" color="newTextLight"></app-tb-icon>
        }
    </div>

    <div class="icon-container flex items-center ps-1" (click)="$event.stopPropagation()">
        @if (hoverIcons && !focused && !invalid) {
            <div
                class="group-hover/container:visible"
                [class.invisible]="!keepHoverIcons"
                (click)="$event.stopPropagation()"
            >
                <ng-container
                    *ngTemplateOutlet="
                        hoverIcons;
                        context: {
                            onFocus: onFocusBound,
                            onCopy: onCopy,
                            onClear: onClear,
                            toggleIcons: toggleIconsBound,
                            isFocusDisabled: isFocusDisabledBound,
                        }
                    "
                ></ng-container>
            </div>
        }

        @if (invalid && !readonly) {
            <app-tb-tooltip-component [tooltip]="errorMessage">
                <app-tb-icon
                    data-testid="error-icon"
                    icon="error_outline"
                    color="newErrorLight"
                ></app-tb-icon>
            </app-tb-tooltip-component>
        } @else if (loading) {
            <app-tb-spinner [size]="20"></app-tb-spinner>
        } @else if (editControls && focused) {
            <app-tb-button
                appearance="icon"
                icon="check"
                color="newPrimary"
                data-testid="input-container-apply-btn"
                (click)="applyValue()"
            ></app-tb-button>
        }
        @if (editControls && (focused || invalid)) {
            <app-tb-button
                appearance="icon"
                icon="close"
                color="newTextLight"
                class="ml-1"
                data-testid="input-container-reset-btn"
                (click)="resetValue()"
            ></app-tb-button>
        }
    </div>
</div>
