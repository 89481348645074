<!-- correct date input with date picker will be implemented in V1 -->
<input
    #dateInput
    class="body-1 w-full bg-transparent"
    type="text"
    [formControl]="control"
    (focusin)="onFocusIn()"
    (focusout)="onFocusOut($event)"
    [placeholder]="placeholder"
    [readOnly]="readonly || loadingState"
/>
