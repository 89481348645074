import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { CellComponent } from '../../cell.component'
import { Field } from '@core/models'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { MatSliderModule } from '@angular/material/slider'
import { MatIconModule } from '@angular/material/icon'

@Component({
    selector: 'app-rating-settings',
    templateUrl: './rating-settings.component.html',
    styleUrls: ['./rating-settings.component.sass'],
    standalone: true,
    imports: [MatIconModule, MatSliderModule, ReactiveFormsModule],
})
export class RatingSettingsComponent extends CellComponent implements OnInit {
    countStarsInArray!: number[]
    sliderControl!: FormControl<string | null>
    field!: Field
    @Output()
    fieldChanged: EventEmitter<Field> = new EventEmitter<Field>()

    ngOnInit(): void {
        this.sliderControl = this.field.configure_json
            ? new FormControl<string>(JSON.parse(this.field.configure_json).count)
            : new FormControl<string>('5')
        if (this.sliderControl.value !== null) {
            this.getArrayFromValue(parseInt(this.sliderControl.value))
        }
    }

    getArrayFromValue(val: number) {
        this.countStarsInArray = [...Array(Number(val)).keys()]
    }

    valueChanged() {
        if (this.sliderControl.value !== null) {
            this.getArrayFromValue(parseInt(this.sliderControl.value))
        }
        this.fieldChanged.emit({
            ...this.field,
            configure_json: JSON.stringify({ count: this.sliderControl.value }),
        })
    }
}
