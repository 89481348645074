import { AsyncPipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { devStorageInstance } from '@services/local-storage/dev-storage.service'
import { environment } from 'src/environments/environment'
import { MatMenuModule } from '@angular/material/menu'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        TbButtonComponent,
        MatMenuModule,
        AsyncPipe,
        TbIconComponent,
        TbMenuComponent,
    ],
})
export class VersionComponent implements OnInit {
    version!: { branch?: string; commit?: string; date?: string }
    mode$ = devStorageInstance.envMode$

    constructor() {}

    ngOnInit(): void {
        this.version = {
            branch: environment.version.branch,
            commit: environment.version.commit,
            date: environment.version.date,
        }
    }
}
