import { NgOptimizedImage } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TranslocoModule } from '@ngneat/transloco'
import { ViewFacadeService } from '@services/store-facade'
import { ViewErrorEntity, ViewErrorType } from '@services/view-validator.service'

@Component({
    selector: 'app-view-invalid-message',
    standalone: true,
    imports: [TranslocoModule, MatIconModule, TbButtonComponent, NgOptimizedImage],
    templateUrl: './view-invalid-message.component.html',
})
export class ViewInvalidMessageComponent {
    @Input() errors!: ViewErrorEntity[]

    get isFilterError() {
        return this.errors.find((error) => error.type === ViewErrorType.filterInvalidField)
    }

    constructor(private viewFacadeService: ViewFacadeService) {}

    openFiltersMenu() {
        this.viewFacadeService.openViewFilterMenu()
    }
}
