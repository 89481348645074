<div
    (click)="openSearchItem()"
    class="flex flex-col items-start justify-start w-full p-2 border-[1px] border-solid border-white w-full rounded-sm mb-3.5"
    *transloco="let tSearchResults; read: 'search.results'"
>
    <div class="flex flex-row justify-start items-center">{{ name }}, {{ assignee }}</div>
    <div class="flex flex-row justify-start items-center">
        @if (status) {
            <div class="flex flex-row justify-start items-center">
                <span>{{ status.label }}</span>
                <div [style.background-color]="status.color" class="rounded icon-d"></div>
            </div>
        }
        {{ tSearchResults('folder') }}, {{ tSearchResults('created') }}
    </div>
</div>
