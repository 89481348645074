import { Component, forwardRef, Injector, Input, OnInit, TemplateRef } from '@angular/core'
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    NgControl,
    ReactiveFormsModule,
} from '@angular/forms'
import { TbInputErrors } from '../models'
import { MatInputModule } from '@angular/material/input'
import { TbIconComponent } from '../tb-icon/tb-icon.component'
import { NgTemplateOutlet } from '@angular/common'
import { MatFormFieldModule } from '@angular/material/form-field'

@Component({
    selector: 'app-tb-input',
    templateUrl: './tb-input.component.html',
    styleUrl: './tb-input.component.sass',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TbInputComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        MatFormFieldModule,
        NgTemplateOutlet,
        TbIconComponent,
        MatInputModule,
        ReactiveFormsModule,
    ],
})
export class TbInputComponent implements ControlValueAccessor, OnInit {
    @Input()
    label = ''

    @Input()
    hint = ''

    @Input()
    placeholder = ''

    @Input()
    type: 'text' | 'number' = 'text'

    @Input()
    icon = ''

    @Input()
    prefixIconTemplate: TemplateRef<any> | null = null

    @Input()
    suffixIconTemplate: TemplateRef<any> | null = null

    @Input()
    errors: TbInputErrors = []

    @Input() minValueLength = 0
    @Input() maxValueLength = 999

    onTouched: (() => void) | null = null
    formControl = new FormControl('')
    errorMessage = ''

    constructor(private injector: Injector) {}

    ngOnInit(): void {
        const parentControl = this.injector.get(NgControl, {})?.control
        if (parentControl && parentControl.validator) {
            this.formControl.setValidators(parentControl.validator)
        }
    }

    writeValue(value: any): void {
        this.formControl.setValue(value)
    }

    registerOnChange(fn: any): void {
        this.formControl.valueChanges.subscribe((value) => {
            fn(value)
            this.errorMessage = this.getErrorMessage()
        })
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.formControl.disable() : this.formControl.enable()
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    private getErrorMessage() {
        return this.errors.find((error) => this.formControl.hasError(error.code))?.message || ''
    }
}
