<app-tb-menu [template]="menu">
    <div trigger class="w-full">some div</div>
</app-tb-menu>

<ng-template #menu>
    <div (click)="$event.stopPropagation()">
        <app-tb-input></app-tb-input>
        <app-tb-button>button</app-tb-button>
    </div>
</ng-template>
