import { Injectable } from '@angular/core'
import { SelectObjectOptions, SelectOption } from '@models/response/select-object-options'
import { switchMap, take, tap } from 'rxjs/operators'
import {
    CreateFolderData,
    Field,
    Folder,
    FolderTemplate,
    getSotUpdateConfiguration,
    NewFolderTemplateData,
    Schema,
    UpdateFolderChanges,
} from '@core/models'
import {
    AccessModel,
    FolderCreateField,
    FolderCreateModel,
    FolderCreateSotType,
    FolderDeleteModel,
    FolderUpdateModel,
} from '@core/models/request'
import { FolderFacadeService } from '@core/services/store-facade'
import { NavigatorService } from '@core/services/navigator.service'
import { map } from 'lodash-es'

@Injectable({ providedIn: 'root' })
export class FolderService {
    constructor(
        private folderFacadeService: FolderFacadeService,
        private navigatorService: NavigatorService,
    ) {}

    createFolder(data: CreateFolderData) {
        const model: FolderCreateModel = {
            access: data.access,
            configuration: getSotUpdateConfiguration(data.configuration),
            fields: data.fields,
            views: data.views,
        }

        return this.folderFacadeService.createFolderRequest(model)
    }

    updateFolder(folder: Folder, changes: UpdateFolderChanges, access?: AccessModel) {
        const model: FolderUpdateModel = {
            record_guid: folder.guid,
            record_revision: folder.revision,
            configuration: getSotUpdateConfiguration(changes.configuration),
            access,
        }

        return this.folderFacadeService.updateFolderRequest(model)
    }

    deleteFolder(folder: Folder) {
        return this.folderFacadeService.selectSelectedFolderGuid$.pipe(
            take(1),
            switchMap((selectedFolderGuid) => {
                const model: FolderDeleteModel = {
                    record_guid: folder.guid,
                    record_revision: folder.revision,
                }

                return this.folderFacadeService.deleteFolderRequest(model).pipe(
                    tap(() => {
                        if (selectedFolderGuid === folder.guid) {
                            this.navigatorService.goToGlobalFolder()
                        }
                    }),
                )
            }),
        )
    }

    getNewFolderTemplate(folderTemplate: FolderTemplate): NewFolderTemplateData {
        return {
            views: folderTemplate.views,
            fields: this.combineFolderFields(
                folderTemplate.fieldRelatedSchemas,
                folderTemplate.systemDataSchemas,
            ),
        }
    }

    private combineFolderFields(fieldSchemas: Schema[], systemSchemas: Schema[]) {
        return fieldSchemas.reduce(
            (res, schema) => {
                const object_type_code = schema.object_type_code as FolderCreateSotType

                res[object_type_code] = Object.keys(schema.fieldEntities).map((next) => {
                    const field = this.combineField(
                        schema.fieldEntities[next],
                        systemSchemas,
                        object_type_code,
                    )

                    return this.combineFolderCreateField(field)
                })

                return res
            },
            {} as Record<FolderCreateSotType, FolderCreateField[]>,
        )
    }

    private combineField(field: Field, systemSchemas: Schema[], code: FolderCreateSotType) {
        if (!field.guid) return field

        let systemField = systemSchemas.find((item) => item.object_type_code === code)
            ?.fieldEntities[field.guid]

        if (!systemField) return field

        return {
            ...field,
            ...systemField,
        }
    }

    private combineFolderCreateField(field: Field) {
        return {
            name: field.name ?? field.field_type_code,
            field_type_code: field.field_type_code,
            select_object_field: this.parseSelectOptionsToArray(field.select_object_field),
            template_field_guid: field.guid,
            default_value: field.default_value,
        }
    }

    private parseSelectOptionsToArray(
        selectOptions?: SelectObjectOptions,
    ): SelectOption[] | undefined {
        return map(selectOptions, (value, guid) => ({ guid, ...value }))
    }
}
