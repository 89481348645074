import { Injectable } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { FieldExistsInSchemaValidator } from '@app/views/view-controls/validators'
import {
    FilterCombinationTypes,
    FilterGroup,
    FilterGroupsCombinationTypes,
    FilterOptionTypes,
    FilterValue,
} from '@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@Injectable()
@UntilDestroy()
export class FormFilterService {
    private readonly filterFormArray!: FormArray
    private filterGroupsCombination: string = FilterGroupsCombinationTypes.AND

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly fieldExistsInSchemaValidator: FieldExistsInSchemaValidator,
    ) {
        this.filterFormArray = this.formBuilder.array([])
    }

    get getFilterFormArray() {
        return this.filterFormArray
    }

    get getGroupsCombinationType() {
        return this.filterGroupsCombination
    }

    get canAddFilterGroup() {
        return this.filterFormArray.controls.every(
            (control) => control.value?.values?.length && control.valid,
        )
    }

    addFilterGroup(filterCombinationType: string) {
        this.filterFormArray.push(
            this.formBuilder.group({
                filterGroupsCombinationType: this.formBuilder.control(this.filterGroupsCombination),
                filterCombinationType: this.formBuilder.control(filterCombinationType),
                values: this.formBuilder.array([]),
            }),
        )
        this.filterFormArray.markAsTouched()
    }

    addDefaultFilterGroup() {
        this.addFilterGroup(FilterCombinationTypes.ALL)
    }

    addFilterItem(groupIndex: number, fieldGuid: string, option?: string, value?: any) {
        const values = this.getFilterGroupValues(groupIndex)
        const optionControl = this.formBuilder.control(option)

        const filterItemFormGroup = this.formBuilder.group({
            // TODO: implement showing red input when failed validation
            fieldGuid: this.formBuilder.control(`${fieldGuid}`, {
                asyncValidators: [this.fieldExistsInSchemaValidator.validate],
            }),
            option: optionControl,
            value: this.formBuilder.control(value, Validators.required),
        })

        values.push(filterItemFormGroup)
        values.markAsTouched()

        if (option) {
            this.addOrRemoveValueControl(filterItemFormGroup)
        }

        optionControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            console.log('option changed')
            this.addOrRemoveValueControl(filterItemFormGroup)
        })
    }

    initForm(filterGroups: FilterGroup[]) {
        this.filterFormArray.clear()

        if (!filterGroups.length) {
            this.addDefaultFilterGroup()
            this.getFilterFormArray.markAsPristine()
            this.getFilterFormArray.markAsUntouched()
            return
        }

        filterGroups.forEach((group, groupIndex) => {
            this.filterGroupsCombination = group.filterGroupsCombinationType
            this.addFilterGroup(group.filterCombinationType)
            if (group.values) {
                Object.values(group.values).forEach((item: FilterValue) =>
                    this.addFilterItem(groupIndex, item.fieldGuid, item.option, item.value),
                )
            }
        })
        this.setCombinationGroupType(this.filterGroupsCombination)
        this.filterFormArray.markAsPristine()
        this.filterFormArray.markAsUntouched()
    }

    getFilterGroupValues(groupIndex: number): FormArray {
        const group = this.filterFormArray.at(groupIndex) as FormGroup
        return group.get('values') as FormArray
    }

    getFilterItem(groupIndex: number, itemIndex: number) {
        return this.getFilterGroupValues(groupIndex).at(itemIndex)
    }

    deleteFilterGroup(groupIndex: number) {
        this.filterFormArray.removeAt(groupIndex)
        this.filterFormArray.markAsTouched()
    }

    deleteFilterItem(groupIndex: number, itemIndex: number) {
        const values = this.getFilterGroupValues(groupIndex)
        values.removeAt(itemIndex)
        this.filterFormArray.at(groupIndex).markAsTouched()
    }

    prepareToSave(): FilterGroup[] {
        const rawFilterGroups = this.filterFormArray.getRawValue()

        return rawFilterGroups.map((group) => {
            const values = group.values.reduce(
                (acc: Record<string, FilterValue>, item: FilterValue) => {
                    acc[item.fieldGuid] = item
                    return acc
                },
                {},
            )

            return {
                ...group,
                values,
            }
        }) as FilterGroup[]
    }

    setCombinationGroupType(combinationGroupType: string) {
        this.filterGroupsCombination = combinationGroupType

        for (let i = 0; i < this.filterFormArray.length; i++) {
            this.filterFormArray.at(i).patchValue({
                filterGroupsCombinationType: this.filterGroupsCombination,
            })
            this.filterFormArray.at(i).markAsTouched()
        }
    }

    setCombinationType(groupId: number, combinationType: string) {
        this.filterFormArray.at(groupId).patchValue({
            filterCombinationType: combinationType,
        })
        this.filterFormArray.at(groupId).markAsTouched()
    }

    addOrRemoveValueControl(filterItemFormGroup: FormGroup) {
        if (!this.showValueField(filterItemFormGroup.controls['option'].value)) {
            return filterItemFormGroup.removeControl('value')
        }

        if (!filterItemFormGroup.contains('value')) {
            return filterItemFormGroup.addControl(
                'value',
                new FormControl<string | string[] | undefined>('', Validators.required),
            )
        }
    }

    private showValueField(option?: string) {
        if (!option) {
            return true
        }

        const optionsWithoutValue = [
            FilterOptionTypes.IS_SET,
            FilterOptionTypes.IS_NOT_SET,
            FilterOptionTypes.CONTAINS_ME,
        ]

        return !optionsWithoutValue.includes(option)
    }
}
