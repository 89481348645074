import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Subject } from 'rxjs'

enum ErrorRoutes {
    SomethingWrong = 'something-wrong',
    AccessDenied = 'access-denied',
}

@Injectable({
    providedIn: 'root',
})
export class ErrorNavigatorService {
    isRedirecting = false

    private tryAgain$$ = new Subject<void>()

    constructor(private router: Router) {}

    goToSomethingWentWrongPage() {
        this.redirectWrapper(this.router.navigate([ErrorRoutes.SomethingWrong]))
    }

    goToAccessDeniedPage() {
        this.redirectWrapper(this.router.navigate([ErrorRoutes.AccessDenied]))
    }

    clearData() {
        this.isRedirecting = false
    }

    prepareSomethingWentWrongPage() {
        this.isRedirecting = true
        return this.router.createUrlTree([ErrorRoutes.SomethingWrong])
    }

    callTryAgain() {
        this.tryAgain$$.next()
    }

    getTryAgain$() {
        return this.tryAgain$$.asObservable()
    }

    private redirectWrapper(route: Promise<boolean>) {
        this.isRedirecting = true
        route.then(() => {
            this.isRedirecting = false
        })
    }
}
