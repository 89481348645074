@if (!disabled) {
    <div
        [matMenuTriggerFor]="dropdownMenu"
        [ngStyle]="{
            background: getTitleColor(value),
            color: getTitleColor(value) | getContrastColor,
        }"
        class="cursor-pointer dropdown-title_wrap"
    >
        <mat-icon class="status-icon"> {{ getIcon(value) }} </mat-icon>
        <span class="dropdown-title"> {{ getTitle(value) }} </span>
    </div>
} @else {
    <div
        [ngStyle]="{
            background: getTitleColor(value),
            color: getTitleColor(value) | getContrastColor,
        }"
        class="cursor-pointer dropdown-title_wrap"
    >
        <mat-icon class="status-icon"> {{ getIcon(value) }} </mat-icon>
        <span class="dropdown-title"> {{ getTitle(value) }} </span>
    </div>
}
<mat-menu #dropdownMenu="matMenu">
    <ng-template matMenuContent>
        <!-- change to app-tb-button after new design will be implemented -->
        @if (!field.is_required) {
            <button (click)="checkActive('')" class="text-center" mat-menu-item>
                <app-tb-icon class="text-newNeutral4" icon="remove"></app-tb-icon>
                @if (!value) {
                    <app-tb-icon class="active-check-label" icon="check"></app-tb-icon>
                }
            </button>
        }
        <!-- change to app-tb-button after new design will be implemented -->
        @for (key of optionKeys(); track key) {
            <button
                (click)="checkActive(key)"
                [ngStyle]="{ background: dropdownData[key].color, color: key | getContrastColor }"
                class="text-center"
                mat-menu-item
            >
                {{ dropdownData[key].label }}
                <mat-icon [ngStyle]="{ color: key | getContrastColor }" class="dropdown-icon">{{
                    dropdownData[key].icon
                }}</mat-icon>
                @if (key === value) {
                    <mat-icon
                        [ngStyle]="{ color: key | getContrastColor }"
                        class="active-check-label"
                        >check
                    </mat-icon>
                }
                @if (key === default) {
                    <mat-icon
                        [ngStyle]="{ color: key | getContrastColor }"
                        class="active-check-label"
                        >ac_unit
                    </mat-icon>
                }
            </button>
        }
    </ng-template>
</mat-menu>
