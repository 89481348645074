<span class="body-1 mb-1">{{ label }}</span>
<mat-form-field appearance="outline" [class]="{ noHintError: !hint }">
    @if(iconTemplate) {
    <ng-container matIconPrefix>
        <ng-container [ngTemplateOutlet]="iconTemplate"></ng-container>
    </ng-container>
    } @else if(icon) {
    <app-tb-icon [icon]="icon" color="newTextLight" matIconPrefix></app-tb-icon>
    }

    <input
        class="body-1"
        matInput
        [minLength]="minValueLength"
        [maxLength]="maxValueLength"
        (focusout)="onTouched && onTouched()"
        [formControl]="formControl"
        [placeholder]="placeholder"
        [type]="type"
    />

    @if(hint) {
    <mat-hint class="caption-1">{{ hint }}</mat-hint>
    } @if (errorMessage) {
    <mat-error> {{ errorMessage }} </mat-error>
    } @if (formControl.invalid && formControl.touched) {
    <app-tb-icon icon="error_outline" color="red-200" matIconSuffix></app-tb-icon>
    }
</mat-form-field>
