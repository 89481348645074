@if (selectedFolder$ | async; as selectedFolder) {
    <app-tb-menu #menu [container]="menuComponent" [replaceable]="true">
        <div trigger>
            <div
                class="flex items-center content-center cursor-pointer hover:bg-white hover:shadow-md rounded-lg py-1 pr-2 pl-4"
            >
                <div class="flex items-center">
                    <app-tb-icon
                        class="mr-2"
                        [icon]="selectedFolder.icon.value"
                        [style.color]="selectedFolder.color.value"
                    ></app-tb-icon>
                    <app-tb-tooltip-component
                        class="flex-1"
                        [tooltip]="selectedFolder.name.value"
                        tooltipPosition="below"
                    >
                        <div
                            class="max-w-[160px] overflow-hidden subheading-2 whitespace-nowrap text-ellipsis"
                        >
                            {{ selectedFolder.name.value }}
                        </div>
                    </app-tb-tooltip-component>
                    <app-tb-icon
                        class="icon-m ml-2"
                        [icon]="menu.menuOpen ? 'expand_less' : 'expand_more'"
                        color="newText"
                    ></app-tb-icon>
                </div>
            </div>
        </div>
    </app-tb-menu>
}
