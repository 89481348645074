import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { OperationCode } from '@core/models'

@Directive({
    selector: '[appOperation]',
    standalone: true,
})
export class ShowIfOperationCodeMatchExpectedOperationDirective implements OnInit {
    @Input('appOperation')
    code?: OperationCode
    @Input('appOperationCondition')
    condition = true
    @Input('appOperationTarget')
    target!: 'create' | 'freeze' | 'delete' | 'update' | 'copy'

    constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {}

    ngOnInit(): void {
        if (!this.code || !this.target) {
            return
        }

        if (this.code.system) return

        let res: boolean | undefined
        switch (this.target) {
            case 'create':
                res = this.code.create
                break
            case 'copy':
                res = this.code.multiple && this.code.create
                break
            case 'delete':
                res = this.code.delete
                break
            case 'freeze':
                // if freeze is true, then we can't move field to another folder
                res = !this.code.freeze
                break
            case 'update':
                res = this.code.update
                break
            default:
                console.error(new Error('unknown expected operation'))
        }
        if (res && this.condition) {
            this.viewContainer.createEmbeddedView(this.templateRef)
        }
    }
}
