<div
    class="flex flex-col max-h-[96vh] relative"
    [class]="containerClass"
    (click)="$event.stopPropagation()"
>
    <div class="empty:hidden">
        <div class="flex">
            <div class="flex w-full title"><ng-content select="[title]"></ng-content></div>
            @if (containerLayout === ModalContainer.Dialog) {
            <div class="button-container">
                @if (!headerButtons.childElementCount) {
                <app-tb-button appearance="icon" (click)="closeModal.emit()">
                    <app-tb-icon class="icon-m" icon="close"></app-tb-icon>
                </app-tb-button>
                }
                <div class="flex" #headerButtons>
                    <ng-content select="[headerButtons]"></ng-content>
                </div>
            </div>
            }
        </div>
        <div class="caption-1 text-newTextLight"><ng-content select="[subtitle]"></ng-content></div>
    </div>
    <div class="modal-content overflow-auto flex-1 relative">
        <div>
            <ng-content></ng-content>
        </div>
    </div>
    @if (showLoader) {
    <div class="absolute inset-0 flex items-center justify-center z-50">
        <div class="w-full h-full bg-newNeutral1 opacity-50"></div>
        <mat-spinner class="absolute"></mat-spinner>
    </div>
    } @if (errors) {
    <div class="text-red-600 pt-4">
        @for (error of errors; track error) {
        <div>{{ error }}</div>
        }
    </div>
    }
    <div class="empty:hidden modal-buttons">
        <ng-content select="[buttons]"></ng-content>
    </div>
</div>
