import { Injectable } from '@angular/core'
import { ShowObject } from '@app/views/view-controls/customize-view/customize-view.service'
import { DataStorageService } from '@services/local-storage/view-settings-storage/data-storage.service'
import { ViewSettingsStorageService } from '@services/local-storage/view-settings-storage/view-settings-storage.service'

@Injectable({
    providedIn: 'root',
})
export class CustomizeViewStorageService extends ViewSettingsStorageService<ShowObject> {
    constructor(localStorageService: DataStorageService) {
        super(localStorageService, 'customize-view')
    }

    get(key: string): ShowObject | null {
        const showValue = super.get(key)

        if (!showValue) return null

        if (typeof showValue !== 'string') return showValue

        return showValue.length ? JSON.parse(showValue) : null
    }
}
