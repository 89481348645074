import { DOCUMENT } from '@angular/common'
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core'
import { TooltipPosition, MatTooltipModule } from '@angular/material/tooltip'
import { fromEvent } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

@Component({
    selector: 'app-tb-tooltip-component',
    templateUrl: './tb-tooltip.component.html',
    styleUrl: './tb-tooltip.component.sass',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatTooltipModule],
})
export class TbTooltipComponent implements AfterViewInit {
    @Input() tooltip = ''
    @Input() tooltipPosition: TooltipPosition = 'above'
    @Input() tooltipDisabled = false
    @Input() tooltipShowDelay = 0
    @Input() tooltipHideDelay = 0
    @Input() showOnlyOnOverflow = false

    @ViewChild('container') container!: ElementRef<HTMLElement>

    constructor(private cdr: ChangeDetectorRef) {
        const debounceMilliseconds = 100
        fromEvent(window, 'resize')
            .pipe(debounceTime(debounceMilliseconds))
            .subscribe(() => this.showTooltipForEllipsis())
    }

    ngAfterViewInit(): void {
        this.showTooltipForEllipsis()
    }

    private showTooltipForEllipsis() {
        const showTooltip =
            this.showOnlyOnOverflow && !this.recursiveEllipsisCheck(this.container.nativeElement)
        if (this.tooltipDisabled !== showTooltip) {
            this.tooltipDisabled = showTooltip
            this.cdr.detectChanges()
        }
    }

    //TODO: feel free to recommend anything else for "ellipsis-only" tooltips in components with folding
    // as alternative it could be used in each specific sub component
    private recursiveEllipsisCheck(element: HTMLElement): boolean {
        if (element.offsetWidth < element.scrollWidth) {
            return true
        } else if (element.children.length !== 0) {
            return Array.from(element.children)
                .map((child) => child as HTMLElement)
                .some((child) => {
                    return this.recursiveEllipsisCheck(child)
                })
        }

        return false
    }
}
