<div
    class="group/board-column-header header-container flex justify-between w-100"
    [class]="stateClass"
>
    <div class="flex items-center grow w-full">
        <ng-content></ng-content>
        <span
            class="flex justify-center min-w-[1.25rem] px-0.5 h-5 body-2 bg-newNeutral3 rounded-lg"
        >
            {{ items.length }}
        </span>
    </div>
    <div class="flex items-center justify-end">
        <app-tb-button
            appearance="icon"
            icon="keyboard_double_arrow_left"
            class="collapse-btn visible md:invisible group-hover/board-column-header:visible icon-sm"
            (click)="setCollapse()"
            color="newTextLight"
            [class]="collapsed ? 'mb-2' : 'mr-2'"
        ></app-tb-button>
        @if (moreMenu) {
            <app-tb-menu
                class="more-menu visible md:invisible group-hover/board-column-header:visible"
                [template]="moreMenu"
                [replaceable]="true"
                [data]="{ columnItem: columnItem, collapsed, toggleCollapse: toggleCollapseBound }"
                [class]="collapsed ? 'mb-2' : 'mr-2'"
            >
                <app-tb-button
                    appearance="icon"
                    icon="more_vert"
                    color="newTextLight"
                    class="icon-sm"
                    trigger
                >
                </app-tb-button>
            </app-tb-menu>
        }
        <app-tb-checkbox
            class="select-checkbox visible group-hover/board-column-header:visible"
            [class]="selectedItems.length > 0 ? 'visible' : 'md:invisible'"
            [class]="collapsed ? 'mb-2' : 'mr-2'"
            (valueChange)="selectColumn.emit($event)"
            [value]="isSomeItemsSelected"
            [disabled]="items.length === 0"
            [indeterminate]="isIndeterminate"
            size="small"
        ></app-tb-checkbox>
    </div>
</div>
