import { BehaviorSubject } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

export enum DevKeys {
    EnvMode = 'environmentMode',
    SolutionType = 'solutionType',
}

export type EnvironmentMode = 'prod' | 'dev' | 'mock'
export type SolutionType = 'TM' | 'CRM'

export class DevStorageService {
    private readonly _envMode$ = new BehaviorSubject(this.getEnvironmentMode())
    readonly envMode$ = this._envMode$.asObservable().pipe(distinctUntilChanged())

    getSolutionType() {
        return localStorage.getItem(DevKeys.SolutionType)
    }

    setSolutionType(val: SolutionType) {
        localStorage.setItem(DevKeys.SolutionType, val)
    }

    getEnvironmentMode() {
        const fromFile = this.getEnvModeFromFile()
        const fromStorage = localStorage.getItem(DevKeys.EnvMode) as EnvironmentMode | null

        if (fromFile === 'mock') {
            return fromStorage || fromFile
        }
        return fromFile
    }

    setEnvironmentMode(value: EnvironmentMode) {
        if (this.getEnvironmentMode() !== value) {
            this._envMode$.next(value)
            localStorage.setItem(DevKeys.EnvMode, value)
        }
    }

    getEnvModeFromFile() {
        return environment.mode as EnvironmentMode
    }
}

export const devStorageInstance = new DevStorageService()
