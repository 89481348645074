import { Injectable } from '@angular/core'
import {
    hideViewLoader,
    openCustomizeViewDialog,
    openViewFilterDialog,
    openViewGroupDialog,
    openViewSortDialog,
    selectViewLoader,
    showViewLoader,
} from '@core/@ngrx/ui'
import { Store } from '@ngrx/store'
import { finalize, Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class UiFacadeService {
    readonly viewLoader$ = this.store.select(selectViewLoader)

    constructor(private store: Store) {}

    showViewLoader() {
        this.store.dispatch(showViewLoader())
    }

    hideViewLoader() {
        this.store.dispatch(hideViewLoader())
    }

    wrapRequestWithViewLoader(observable: Observable<unknown>) {
        this.showViewLoader()
        return observable.pipe(finalize(() => this.hideViewLoader()))
    }

    openViewFilterDialog() {
        this.store.dispatch(openViewFilterDialog())
    }

    openViewSortDialog() {
        this.store.dispatch(openViewSortDialog())
    }

    openViewGroupDialog(groupGuid?: string) {
        this.store.dispatch(openViewGroupDialog({ groupGuid }))
    }

    openCustomizeViewDialog() {
        this.store.dispatch(openCustomizeViewDialog())
    }
}
