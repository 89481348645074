<div class="sm:h-7 h-8 sm:bg-transparent bg-white rounded group/filter">
    <app-tb-button
        (click)="openDialog()"
        [color]="getButtonColor(hasErrors)"
        class="hover:bg-newNeutral3 rounded-lg"
        [disabled]="hasErrors && !hasFilterErrors"
        appearance="text"
        icon="filter_list"
    >
        <ng-container *ngTemplateOutlet="buttonInside"></ng-container>
    </app-tb-button>
    <ng-template #buttonInside>
        <div *transloco="let tFilter; read: 'filter'" class="flex items-center">
            <span class="body-2">{{ tFilter('filter') }}</span>
            @if (hasFilterErrors) {
                <span
                    class="flex ml-2 caption-2 rounded-full items-center justify-center py-0 px-1 h-4 min-w-[1rem] text-white"
                >
                    <app-tb-icon
                        icon="error"
                        [filled]="true"
                        class="text-newErrorLight group-hover/filter:text-newError"
                    ></app-tb-icon>
                </span>
            } @else if (filterGroups.length || (!filterGroups.length && isPreview)) {
                @if (filterCount === 1 && filterGroups[0].values && !isPreview) {
                    <span>:&nbsp;</span>
                    <app-field-name
                        [field]="filterField"
                        [folder]="selectedFolder"
                        [showIcon]="false"
                        class="group-name max-w-[160px]"
                    ></app-field-name>
                } @else {
                    <span
                        class="flex items-center ml-2 justify-center caption-2 rounded-full py-0 px-1 h-4 min-w-[1rem] bg-newSecondaryLight group-hover/filter:bg-newSecondary text-white"
                    >
                        {{ filterCount }}
                    </span>
                }

                @if (isPreview) {
                    <span
                        class="flex items-center ml-2 justify-center caption-2 rounded-full py-0 px-1 h-4 min-w-[1rem] bg-newSecondaryLight group-hover/filter:bg-newSecondary text-white"
                    >
                        {{ tFilter('preview') }}
                    </span>
                }
            }
        </div>
    </ng-template>
</div>
