<div class="fixed bottom-4 right-4 sm:right-6 sm:bottom-6 opacity-80">
    <div
        class="flex flex-row items-center justify-end w-full"
        *transloco="let tButtons; read: 'buttons'"
    >
        @if (pinObjects$ | async; as allObjects) {
            @if (pinRecordsMore$ | async; as pinRecordsMore) {
                @if (pinRecordsDraftMore$ | async; as pinDraftRecordsMore) {
                    @for (item of pinRecords$ | async; track item) {
                        <app-pinned-record
                            (openRecord)="openPinItem(item.guid)"
                            (delete)="removePinItem(item.guid)"
                            [name]="item.name"
                        >
                        </app-pinned-record>
                    }
                    @for (item of pinRecordsDraft$ | async; track item) {
                        <app-pinned-record
                            (openRecord)="openPinDraftItem(item)"
                            (delete)="removePinItem(item.guid)"
                            [name]="item.name"
                        >
                        </app-pinned-record>
                    }
                    @if (showMoreBtn(pinRecordsMore, pinDraftRecordsMore)) {
                        <app-tb-button
                            [appearance]="'text'"
                            [icon]="'add'"
                            #triggerMore="matMenuTrigger"
                            [matMenuTriggerFor]="moreRecordMenu"
                        >
                            {{ showRecordCount }}/{{ allObjects.length }}
                        </app-tb-button>
                    }
                    <mat-menu #moreRecordMenu="matMenu" class="px-2.5 py-5">
                        <ng-template matMenuContent>
                            <div (click)="$event.stopPropagation(); $event.preventDefault()">
                                @for (item of pinDraftRecordsMore; track item) {
                                    <app-pinned-record
                                        (openRecord)="openPinDraftItem(item)"
                                        (delete)="removePinItem(item.guid)"
                                        [name]="item.name"
                                    >
                                    </app-pinned-record>
                                }
                                @for (item of pinRecordsMore; track item) {
                                    <app-pinned-record
                                        (openRecord)="openPinItem(item.guid)"
                                        (delete)="removePinItem(item.guid)"
                                        [name]="item.name"
                                    >
                                    </app-pinned-record>
                                }
                            </div>
                        </ng-template>
                    </mat-menu>
                }
            }
        }
        @if (schemas$ | async; as schemas) {
            @if (schemas.length > 1) {
                <app-tb-menu [template]="menuData">
                    <app-tb-button
                        [appearance]="'fab'"
                        [color]="'secondary'"
                        [icon]="'add'"
                        trigger
                    >
                    </app-tb-button>
                </app-tb-menu>
                <ng-template #menuData>
                    <app-tb-menu-list *transloco="let tNavMenu; read: 'nav_menu'">
                        @for (schema of schemas; track schema) {
                            <app-tb-menu-list-item
                                size="subheading-1"
                                [icon]="schemaIconByName[schema.name]"
                                (click)="openDialogWithSelectedSchema(schema.guid)"
                            >
                                <ng-container item-title>{{ tNavMenu(schema.name) }}</ng-container>
                            </app-tb-menu-list-item>
                        }
                    </app-tb-menu-list>
                </ng-template>
            } @else {
                <ng-container *ngTemplateOutlet="singleButton"></ng-container>
            }
        } @else {
            <app-tb-button
                [appearance]="'fab'"
                [color]="'secondary'"
                [icon]="'add'"
                (click)="openEmptyDialog()"
            >
            </app-tb-button>
        }
        <ng-template #singleButton>
            <app-tb-button
                [appearance]="'fab'"
                [color]="'secondary'"
                [icon]="'add'"
                (click)="openEmptyDialog()"
            >
            </app-tb-button>
        </ng-template>
    </div>
</div>
