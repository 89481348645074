import { Component, Input } from '@angular/core'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'

export enum MessageType {
    ERROR = 'error',
    WARNING = 'warning',
    ANNOUNCEMENT = 'announcement',
}

@Component({
    selector: 'app-tb-flash-message',
    standalone: true,
    imports: [TbIconComponent],
    templateUrl: './tb-flash-message.component.html',
    styleUrl: './tb-flash-message.component.sass',
})
export class TbFlashMessageComponent {
    @Input({ required: true }) type!: MessageType

    @Input({ required: true }) messages!: string[]

    get messageClass() {
        const messages = {
            error: 'error-message',
            warning: 'warning-message',
            announcement: 'announcement-message',
        }

        return messages[this.type]
    }

    closeMessage(index: number) {
        this.messages.splice(index, 1)
    }

    protected readonly MessageType = MessageType
}
