import {
    AfterViewInit,
    ApplicationRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    createComponent,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { InputCellContainerComponent } from '@app/feature/input-cells/input-cell-container/input-cell-container.component'
import { getBoolean } from '@core/global-util'

import { BusinessRecord, Field, FieldType, FieldTypes, ValueJson } from '@core/models'
import { InputContainerAppearance } from '@models/input'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'

// dynamic imports to avoid circular dependencies
// should be rewritten in the future
export const fieldTypeComponents: { [key: string]: () => Promise<any> } = {
    [FieldTypes.NAME]: () => import('./name/name.component'),
    [FieldTypes.TEXT]: () => import('./text-one-line/text-one-line.component'),
    [FieldTypes.MONEY]: () => import('./money/money.component'),
    [FieldTypes.EMAIL]: () => import('./email/email.component'),
    [FieldTypes.WEBSITE]: () => import('./url/url.component'),
    [FieldTypes.NUMBER]: () => import('./number/number.component'),
    [FieldTypes.STATUS]: () => import('./select/status'),
    [FieldTypes.DROPDOWN]: () => import('./select/dropdown'),
    [FieldTypes.ASSIGNEE]: () => import('./people/assign/assign.component'),
    [FieldTypes.PEOPLE]: () => import('./people/assign/assign.component'),
    [FieldTypes.BOOL]: () => import('./boolean/boolean.component'),
    [FieldTypes.RATING]: () => import('./rating/rating.component'),
    [FieldTypes.DATE_TIME]: () => import('./date-time/date-time.component'),
    [FieldTypes.DATE_RANGE]: () => import('./range/range.component'),
    [FieldTypes.DATE]: () => import('./date/date.component'),
    [FieldTypes.WATCH]: () => import('./people/watch/watch.component'),
    [FieldTypes.MULTILINE_TEXT]: () => import('./text-multiline/text-multi-line.component'),
    [FieldTypes.LINK]: () => import('./link/link.component'),
    [FieldTypes.RICH_TEXT]: () => import('./rich-text/rich-text.component'),
    [FieldTypes.LINK_REFERENCE]: () => import('./link-reference/link-reference.component'),
}

export const fieldTypeNameComponents: { [key: string]: string } = {
    [FieldTypes.NAME]: 'NameComponent',
    [FieldTypes.TEXT]: 'TextOneLineComponent',
    [FieldTypes.MONEY]: 'MoneyComponent',
    [FieldTypes.EMAIL]: 'EmailComponent',
    [FieldTypes.WEBSITE]: 'UrlComponent',
    [FieldTypes.NUMBER]: 'NumberComponent',
    [FieldTypes.STATUS]: 'StatusComponent',
    [FieldTypes.DROPDOWN]: 'DropdownComponent',
    [FieldTypes.ASSIGNEE]: 'AssignComponent',
    [FieldTypes.PEOPLE]: 'AssignComponent',
    [FieldTypes.BOOL]: 'BooleanComponent',
    [FieldTypes.RATING]: 'RatingComponent',
    [FieldTypes.DATE_TIME]: 'DateTimeComponent',
    [FieldTypes.DATE_RANGE]: 'RangeComponent',
    [FieldTypes.DATE]: 'DateComponent',
    [FieldTypes.WATCH]: 'WatchComponent',
    [FieldTypes.MULTILINE_TEXT]: 'TextMultiLineComponent',
    [FieldTypes.LINK]: 'LinkComponent',
    [FieldTypes.RICH_TEXT]: 'RichTextComponent',
    [FieldTypes.LINK_REFERENCE]: 'LinkReferenceComponent',
}

export const NEW_CONTAINER_CELLS: string[] = [FieldTypes.NUMBER]

@UntilDestroy()
@Component({
    selector: 'app-cell-container',
    templateUrl: './cell-container.component.html',
    styleUrls: ['./cell-container.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class CellContainerComponent implements OnDestroy, AfterViewInit, OnChanges {
    @Input() field!: Field
    @Input() value!: string
    @Input() valueJson?: ValueJson
    @Input() guid!: string
    @Input() record!: BusinessRecord
    @Input() fieldTypes!: Dictionary<FieldType>
    @Input() disabled: boolean = false
    @Input() isCard?: boolean

    @Input() hoverIcons?: TemplateRef<any>
    @Input() appearance: InputContainerAppearance = InputContainerAppearance.MIXED

    @Output() cellValueChanged: EventEmitter<any> = new EventEmitter()

    @ViewChild('cellArea', { read: ViewContainerRef }) cellArea!: ViewContainerRef

    cmpRef!: ComponentRef<any>
    private isViewInitialized: boolean = false

    constructor(private cdr: ChangeDetectorRef, private appRef: ApplicationRef) {}

    ngOnChanges() {
        this.updateComponent()
    }

    updateComponent() {
        if (!this.isViewInitialized) {
            return
        }

        if (this.cmpRef) {
            this.cmpRef.destroy()
        }

        const componentName = fieldTypeComponents[this.field.field_type_code]
        if (!componentName) {
            console.log(new Error(`unknown field type ${this.field.field_type_code}`))
            return
        }

        if (NEW_CONTAINER_CELLS.includes(this.field.field_type_code)) {
            this.createUpdatedCell()
            return
        }

        componentName().then((module) => {
            this.cmpRef = this.cellArea.createComponent(
                module[fieldTypeNameComponents[this.field.field_type_code]],
            )

            this.setCmpRefInstance()

            this.cdr.detectChanges()
        })
    }

    ngAfterViewInit() {
        this.isViewInitialized = true
        this.updateComponent()
    }

    ngOnDestroy() {
        if (this.cmpRef) {
            this.cmpRef.destroy()
        }
    }

    createUpdatedCell() {
        this.cmpRef = createComponent(InputCellContainerComponent, {
            environmentInjector: this.appRef.injector,
        })

        this.cmpRef.instance.type = this.field.field_type_code
        this.cmpRef.instance.disabled = this.disabled
        this.cmpRef.instance.value = this.value

        this.cmpRef.instance.hoverIcons = this.hoverIcons
        this.cmpRef.instance.appearance = this.appearance

        this.cmpRef.instance.valueChange.pipe(untilDestroyed(this)).subscribe((value: any) => {
            this.cellValueChanged.emit(value)
        })

        this.cellArea.insert(this.cmpRef.hostView)

        this.cdr.detectChanges()
    }

    private setCmpRefInstance() {
        if (this.value) {
            this.cmpRef.instance.value = this.value
        }

        if (this.valueJson) {
            this.cmpRef.instance.valueJson = this.valueJson
        }

        this.cmpRef.instance.fieldType = this.fieldTypes[this.field.field_type_code]
        this.cmpRef.instance.field = this.field
        this.cmpRef.instance.cellValueChanged = this.cellValueChanged
        this.cmpRef.instance.record = this.record
        this.cmpRef.instance.disabled =
            this.disabled || getBoolean(this.field.is_readonly) || getBoolean(this.field.is_lock)

        if (this.field.field_type_code === FieldTypes.RICH_TEXT) {
            this.cmpRef.instance.isCard = this.isCard
        }
    }
}
