import { CDK_DROP_LIST_GROUP, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop'
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'

@Component({
    selector: 'app-tb-board',
    templateUrl: './tb-board.component.html',
    styleUrls: ['./tb-board.component.sass'],
    standalone: true,
    imports: [CdkDropListGroup],
})
export class TbBoardComponent implements AfterViewInit {
    @Input({ required: true })
    columnTemplate!: TemplateRef<any>

    @ViewChild('columnContainer', { read: ViewContainerRef }) columnContainer!: ViewContainerRef
    @ViewChild(CdkDropListGroup) cdkDropListGroup!: CdkDropListGroup<CdkDropList>

    constructor(private cd: ChangeDetectorRef, private injector: Injector) {}

    ngAfterViewInit() {
        const injector = Injector.create({
            providers: [],
            parent: Injector.create({
                providers: [
                    {
                        provide: CDK_DROP_LIST_GROUP,
                        useValue: this.cdkDropListGroup,
                    },
                ],
                parent: this.injector,
            }),
        })

        this.columnContainer.createEmbeddedView(this.columnTemplate, {}, { injector })
        this.cd.detectChanges()
    }
}
