<div
    class="flex flex-wrap-reverse xsm:flex-nowrap items-center px-4 py-4 sm:px-6 bg-primaryBackground"
>
    <app-header-name class="flex items-center h-fit w-full xsm:w-fit"></app-header-name>
    <div class="flex items-center h-fit ml-auto">
        <app-folder-controls class=""></app-folder-controls>
        <app-search *appEnv="'prod'; visible: false" class="mx-6 xl:ml-4 sm:mr-5"></app-search>
        <app-notifications-menu *appEnv="'prod'; visible: false"></app-notifications-menu>
    </div>
</div>
