<app-tb-menu
    [template]="menu"
    yPosition="below"
    class="h-full w-full"
    [panelClass]="'cell-menu'"
    (stateChanged)="menuStateIsChanged()"
    [disabled]="hideList"
>
    <div trigger class="w-full flex items-center">
        <ng-content></ng-content>

        @if (selectedUsersWithoutCurrent[0] && !hideList) {
            <app-user-avatar
                class="flex relative"
                [userName]="selectedUsersWithoutCurrent[0].fullName"
                [userUrl]="selectedUsersWithoutCurrent[0].avatar_url"
                [status]="selectedUsersWithoutCurrent[0].status"
                [unknown]="isUserUnknown(selectedUsersWithoutCurrent[0])"
                [small]="small"
                [border]="selectedUsers.length === 1 && fullNameForOneUser ? false : border"
            ></app-user-avatar>

            @if (selectedUsers.length === 1 && fullNameForOneUser) {
                <span class="body-1 ms-1">{{ selectedUsersWithoutCurrent[0].fullName }}</span>
            } @else if (selectedUsersWithoutCurrent.length > 1) {
                <span class="caption-2">+{{ selectedUsersWithoutCurrent.length - 1 }}</span>
            }
        }
    </div>
</app-tb-menu>

<ng-template #menu let-containerLayout="containerLayout">
    <app-modal-layout
        [containerLayout]="containerLayout"
        *transloco="let tUserList; read: 'user_list'"
    >
        <div
            (click)="$event.stopPropagation()"
            class="max-h-80 min-w-[320px]"
            [formGroup]="formGroup"
        >
            @if (editMode) {
                <app-tb-input
                    placeholder="Search"
                    class="mb-3 block"
                    formControlName="search"
                    icon="custom_search"
                ></app-tb-input>
            }
            <div class="flex flex-col">
                @if (showUserInList) {
                    <div
                        class="flex items-center w-full mb-3 cursor-pointer"
                        (click)="toggle(currentUser.guid)"
                        formGroupName="users"
                    >
                        <app-user-avatar
                            class="mr-2 flex"
                            [userName]="currentUser.fullName"
                            [userUrl]="currentUser.avatar_url"
                            [unknown]="isUserUnknown(currentUser)"
                            [small]="true"
                        ></app-user-avatar>

                        <span
                            class="body-1 grow"
                            [attr.data-testid]="'user-name-' + currentUser.guid"
                            >{{ tUserList('me', { name: currentUser.fullName }) }}
                        </span>

                        @if (editMode) {
                            <app-tb-checkbox
                                [attr.data-testid]="'user-check-' + currentUser.guid"
                                (click)="$event.stopPropagation()"
                                [formControlName]="currentUser.guid"
                            ></app-tb-checkbox>
                        }
                    </div>

                    @if (editMode || selectedUsersWithoutCurrent.length > 0) {
                        <app-tb-divider class="mb-3"></app-tb-divider>
                    }
                }
                <div formGroupName="users">
                    @for (
                        user of renderedUsersList
                            | filterBy: ['fullName'] : formGroup.controls.search.value;
                        track user.guid
                    ) {
                        @if (canShowUserInList(user)) {
                            <div
                                class="flex items-center w-full mb-3 cursor-pointer last:m-0"
                                (click)="toggle(user.guid)"
                            >
                                <app-user-avatar
                                    class="mr-2 flex"
                                    [userName]="user.fullName"
                                    [userUrl]="user.avatar_url"
                                    [unknown]="isUserUnknown(user)"
                                    [status]="user.status"
                                    [small]="true"
                                ></app-user-avatar>
                                <span
                                    class="body-1 grow"
                                    [attr.data-testid]="'user-name-' + currentUser.guid"
                                    >{{ user.fullName }}</span
                                >

                                @if (editMode) {
                                    <app-tb-checkbox
                                        [attr.data-testid]="'user-check-' + user.guid"
                                        (click)="$event.stopPropagation()"
                                        [formControlName]="user.guid"
                                    ></app-tb-checkbox>
                                }
                            </div>
                        }
                    }
                </div>
            </div>
        </div>
    </app-modal-layout>
</ng-template>
