import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Palettes } from '../models'
import { upperFirst } from 'lodash-es'

import { TbIconComponent } from '../tb-icon/tb-icon.component'
import { TbTooltipComponent } from '../tb-tooltip/tb-tooltip-component/tb-tooltip.component'

@Component({
    selector: 'app-tb-icon-toggle-button',
    templateUrl: './tb-icon-toggle-button.component.html',
    styleUrl: './tb-icon-toggle-button.component.sass',
    standalone: true,
    imports: [TbIconComponent, TbTooltipComponent],
})
export class TbIconToggleButtonComponent {
    @Input() color = Palettes.primary

    @Input() isActive = false

    @Input({ required: true }) icon!: string

    @Input() toggledIcon?: string

    @Input() label!: string

    @Input() disabled = false

    @Input() tooltip!: string

    @Output() toggle = new EventEmitter<boolean>()

    get togglingIcon() {
        if (!this.toggledIcon) return this.icon
        return this.isActive ? this.toggledIcon : this.icon
    }

    resetToggle() {
        this.isActive = false
    }

    toggleButton() {
        if (!this.disabled) {
            this.isActive = !this.isActive
            this.toggle.emit(this.isActive)
        }
    }

    getClasses() {
        const classes = ['hover:bg-newNeutral3']
        if (this.isActive) {
            classes.push(`text-new${upperFirst(this.color)}`)
            classes.push(`hover:text-new${upperFirst(this.getDark(this.color))}`)
        } else {
            classes.push(`text-new${upperFirst(this.getLight(this.color))}`)
            classes.push(`hover:text-new${upperFirst(this.color)}`)
        }

        if (this.label) {
            classes.push('py-[0.125rem]')
            classes.push('px-2')
        } else {
            classes.push('p-0.5')
        }

        return classes
    }

    getDark(color: string) {
        return `${color}Dark`
    }

    getLight(color: string) {
        return `${color}Light`
    }
}
