import { Injectable } from '@angular/core'
import { compare } from '@core/global-util'
import { BusinessRecords, CellEntities, View } from '@core/models'
import { SortDirection, SortObject, SortObjectEntities } from '@core/models/ui/sort.model'
import { SortStorageService } from '@core/services/local-storage/sort-storage.service'
import { cloneDeep } from 'lodash-es'
import { BehaviorSubject } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class ViewSortService {
    private readonly sortGuidFromColumn$$ = new BehaviorSubject<string | null>(null)
    getSortGuidFromColumn$ = this.sortGuidFromColumn$$.asObservable()

    constructor(private sortStorageService: SortStorageService) {}

    applySortToArray(
        records: BusinessRecords[],
        cells: { [recordGuid: string]: CellEntities },
        sortObjects: SortObject[],
    ) {
        return cloneDeep(records).sort((a, b) => {
            return this.compareBySortObject(cells[a.guid], cells[b.guid], sortObjects)
        })
    }

    getSortByView(view: View): SortObjectEntities {
        const sortValue = this.sortStorageService.get(view.guid)
        if (sortValue) return sortValue

        return view.sort.value.length ? JSON.parse(view.sort.value) : {}
    }

    setSortToStorage(viewGuid: string, sortEntity: SortObjectEntities) {
        this.sortStorageService.set(viewGuid, sortEntity)
    }

    updateSortMenuFromColumnData(guid: string) {
        this.sortGuidFromColumn$$.next(guid)
    }

    clearSortGuid() {
        this.sortGuidFromColumn$$.next(null)
    }

    private compareBySortObject(a: CellEntities, b: CellEntities, sortObjects: SortObject[]) {
        let compareResult = 0
        sortObjects.forEach((sortObject) => {
            if (!compareResult) {
                const isAsc = sortObject.sortDirection === SortDirection.ASC
                const compared = compare(
                    a[sortObject.fieldGuid].value,
                    b[sortObject.fieldGuid].value,
                    isAsc,
                )
                if (compared !== 0) {
                    compareResult = compared
                }
            }
        })
        return compareResult
    }
}
