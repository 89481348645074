import { Injectable } from '@angular/core'
import { ViewGroupService } from '@app/views/view-controls'
import { isNonNull } from '@core/global-util'
import {
    BusinessRecords,
    Cell,
    CellEntities,
    prepareCellsForRecords,
    prepareFromGroups,
    RecordGroup,
    View,
    ViewData,
} from '@models/ui'
import { SortDirection } from '@models/ui/sort.model'
import { RecordFacadeService, ViewFacadeService } from '@services/store-facade'
import { BehaviorSubject, filter } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class ViewDataService {
    private viewData!: ViewData & { selectedView: View }
    private cells!: { [recordGuid: string]: CellEntities }
    private groupDirection!: SortDirection

    readonly viewDataChanges$ = new BehaviorSubject<void>(undefined)

    groups!: RecordGroup[] | undefined

    constructor(
        private recordFacadeService: RecordFacadeService,
        private viewFacadeService: ViewFacadeService,
        private viewGroupService: ViewGroupService,
    ) {
        this.recordFacadeService
            .selectViewData$()
            .pipe(filter(isNonNull))
            .subscribe((data) => {
                this.viewData = data

                this.groupDirection = this.viewGroupService.getGroupDirectionByView(
                    data.selectedView!,
                )

                this.groups = this.setGroups()
                this.prepareCells()
                this.viewDataChanges$.next()
            })
    }

    get records(): BusinessRecords[] | undefined {
        if (this.viewData && this.viewData.data instanceof Array) {
            return this.viewData.data
        }
        return
    }

    getViewData() {
        return this.viewData
    }

    getCells() {
        return this.cells
    }

    isViewEmpty() {
        return (
            (!this.records || this.records.length === 0) &&
            (!this.groups || this.groups.length === 0)
        )
    }

    private prepareCells() {
        if (this.records?.length) {
            this.cells = prepareCellsForRecords(this.records)
        } else if (this.groups?.length) {
            this.cells = prepareFromGroups(this.groups)
        }
    }

    pinColumn(guid: string) {
        return this.viewFacadeService.updateViewRequest(this.viewData.selectedView, {
            columns_pinned: {
                cell: this.viewData.selectedView.columns_pinned,
                newValue: guid,
            },
        })
    }

    unPinColumn(guid: string) {
        const previousPinned = this.viewData.columns.pinnedColumns || []
        previousPinned.splice(previousPinned.indexOf(guid), 1)

        return this.viewFacadeService.updateViewRequest(this.viewData.selectedView, {
            columns_pinned: {
                cell: this.viewData.selectedView.columns_pinned,
                newValue: previousPinned.join(','),
            },
        })
    }

    hideColumn(guid: string) {
        const previousHidden = this.viewData.columns.hiddenColumns || []
        if (previousHidden.indexOf(guid) == -1) {
            previousHidden.push(guid)
        }

        return this.viewFacadeService.updateViewRequest(this.viewData.selectedView, {
            columns_hide: {
                cell: this.viewData.selectedView.columns_hide,
                newValue: previousHidden.join(','),
            },
        })
    }

    resetColumnWidth(guid: string) {
        const columnsWidth = { ...this.viewData?.columns.columnsWidth }
        delete columnsWidth[guid]

        return this.viewFacadeService.updateViewRequest(this.viewData?.selectedView as View, {
            columns_width: {
                cell: this.viewData?.selectedView?.columns_width as Cell,
                newValue: JSON.stringify(columnsWidth),
            },
        })
    }

    resizeColumn(guid: string, width: number) {
        const columnsWidthValue = this.viewData?.columns.columnsWidth
            ? {
                  ...this.viewData?.columns.columnsWidth,
                  [guid]: width,
              }
            : { [guid]: width }

        return this.viewFacadeService.updateViewRequest(this.viewData.selectedView, {
            columns_width: {
                cell: this.viewData.selectedView.columns_width,
                newValue: JSON.stringify(columnsWidthValue),
            },
        })
    }

    columnHasCustomWidth(guid: string) {
        return !!this.viewData.columns.columnsWidth?.[guid]
    }

    hasPinned() {
        return !!this.viewData?.selectedView?.columns_pinned?.value?.length
    }

    isPinned(guid: string) {
        return !!this.viewData?.columns.pinnedColumns?.includes(guid)
    }

    private setGroups() {
        if (this.viewData && this.viewData.data instanceof Map) {
            if (this.groupDirection === SortDirection.DESC) return [...this.viewData.data.values()]

            return [...this.viewData.data.values()].reverse()
        }
        return
    }
}
