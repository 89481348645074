import { Router } from '@angular/router'
import { inject } from '@angular/core'
import { ErrorNavigatorService } from '@services/error-navigator.service'

export const allowOnRedirect = (redirectTo?: string) => {
    return () => {
        const router = inject(Router)
        const errorNavigatorService = inject(ErrorNavigatorService)

        if (!errorNavigatorService.isRedirecting) {
            if (redirectTo) return router.createUrlTree([redirectTo])

            return false
        } else {
            return true
        }
    }
}

export const clearErrorState = () => {
    return () => {
        inject(ErrorNavigatorService).clearData()
        return true
    }
}
