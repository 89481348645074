import {
    CDK_DROP_LIST,
    CdkDragDrop,
    CdkDragEnter,
    CdkDragExit,
    CdkDragPlaceholder,
    CdkDropList,
    moveItemInArray,
} from '@angular/cdk/drag-drop'
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core'
import { NgTemplateOutlet } from '@angular/common'
import {
    CollapseCallType,
    TbBoardColumnHeaderComponent,
} from '../tb-board-column-header/tb-board-column-header.component'

export type MovedItem<T> = {
    item: T
    previousItems: unknown[]
    items: unknown[]
    previousIndex: number
    currentIndex: number
}

@Component({
    selector: 'app-tb-board-column',
    templateUrl: './tb-board-column.component.html',
    styleUrls: ['./tb-board-column.component.sass'],
    standalone: true,
    imports: [CdkDropList, TbBoardColumnHeaderComponent, NgTemplateOutlet, CdkDragPlaceholder],
})
export class TbBoardColumnComponent<Item> implements AfterViewInit {
    @Input({ required: true })
    items!: Item[]

    @Input({ required: true })
    card!: TemplateRef<any>

    @Input({ required: true })
    columnItem!: unknown

    @Input()
    headerMenu!: TemplateRef<any>

    @Input()
    selectedItems: Item[] = []

    @Input()
    disableDragIntoList!: boolean

    @Input()
    collapsed = false

    @Input()
    isCollapsedSet = false

    @Output()
    columnMoved = new EventEmitter<MovedItem<Item>>()

    @Output()
    selectColumn = new EventEmitter<boolean>()

    @Output()
    collapseChange = new EventEmitter<boolean>()

    @ViewChild(CdkDropList) cdkDropList!: CdkDropList

    injector!: Injector
    dragOn = false

    isDisabledDragIntoTheListBound = this.isDisabledDragIntoTheList.bind(this)

    constructor(
        private cd: ChangeDetectorRef,
        private componentInjector: Injector,
    ) {}

    ngAfterViewInit() {
        this.injector = Injector.create({
            providers: [],
            parent: Injector.create({
                providers: [
                    {
                        provide: CDK_DROP_LIST,
                        useValue: this.cdkDropList,
                    },
                ],
                parent: this.componentInjector,
            }),
        })
        this.cd.detectChanges()
    }

    drop(event: CdkDragDrop<{ items: Item[]; collapsed: any }>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data.items, event.previousIndex, event.currentIndex)
            return
        }

        this.columnMoved.emit({
            item: event.item.data,
            previousItems: event.previousContainer.data.items,
            items: event.container.data.items,
            previousIndex: event.previousIndex,
            currentIndex: event.currentIndex,
        })
    }

    enter(event: CdkDragEnter<{ collapsed: any; items: Item[] }>) {
        if (event.container.data.collapsed) {
            this.dragOn = true
        }
    }

    exit(event: CdkDragExit<{ collapsed: any; items: Item[] }>) {
        if (event.container.data.collapsed) {
            this.dragOn = false
        }
    }

    toggleCollapse(collapseState: CollapseCallType) {
        this.collapsed = collapseState.check

        if (collapseState.click) {
            this.collapseChange.emit(this.collapsed)
        }
    }

    private isDisabledDragIntoTheList() {
        return !this.disableDragIntoList
    }
}
