import { InjectionToken } from '@angular/core'
import { TbOutlineContainerComponent } from '@components-library/tb-outline-container/tb-outline-container.component'

export enum InputContainerAppearance {
    board = 'board',
    create_record = 'create_record',
    record_card = 'record_card',
    table = 'table',
    filter = 'filter',
}

export const INPUT_CONTAINER = new InjectionToken<TbOutlineContainerComponent>('INPUT_CONTAINER')
