<table class="mt-8" mat-table [dataSource]="subtaskRecords">
    @for (column of columns; track column) { @if (getFieldByGuid(column); as field) {
    <ng-container [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef class="text-center">{{field.name}}</th>
        <td mat-cell *matCellDef="let element">
            @if (getCells(element); as cells) { @if (field.field_type_code !== 'field_type_persent')
            {
            <app-cell-container
                class="flex-start-center text-center"
                [fieldTypes]="fieldTypes"
                [field]="field"
                [record]="element"
                [value]="cells[field.guid].value"
                (cellValueChanged)="updateSubtask(element, cells[field.guid], $event)"
            ></app-cell-container>
            } @else {
            <app-text-one-line
                class="flex-start-center text-center"
                [field]="field"
                [record]="element"
                [value]="cells[field.guid].value"
                (cellValueChanged)="updateSubtask(element, cells[field.guid], $event)"
            ></app-text-one-line>
            } }
        </td>
    </ng-container>
    } }
    <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef class="text-center">Delete</th>
        <td mat-cell *matCellDef="let element">
            <div class="flex-start-center text-center">
                <app-tb-button
                    icon="delete"
                    appearance="text"
                    (click)="deleteSubtask(element)"
                ></app-tb-button>
            </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
</table>
<div class="mt-8">
    <app-card-create-subtask
        [parentRecordGuid]="record.guid"
        [subtaskSchema]="subtaskSchema"
    ></app-card-create-subtask>
</div>
