<div
    class="flex flex-column xsm:flex-row mb-6"
    *transloco="let tAddRecordContent; read: 'add_record_content'"
>
    <div class="w-full">
        <ng-container
            *ngTemplateOutlet="
                settingsItem;
                context: { name: tAddRecordContent('workspace'), icon: 'workspaces_outline', selectedName: selectedFolder.name.value }"
        ></ng-container>

        <ng-container *appEnv="'prod'; visible: false">
            <ng-container
                *ngTemplateOutlet="
                    settingsItem;
                    context: { name: tAddRecordContent('template'), icon: 'person_outline', selectedName: '$Test' }"
            ></ng-container>
        </ng-container>
    </div>

    <div *transloco="let tSchemaReadableName; read: 'nav_menu'" class="xsm:ml-2 w-full">
        <ng-container
            *ngTemplateOutlet="
                settingsItem;
                context: { name: tAddRecordContent('type'), icon: 'custom_deals', selectedName: tSchemaReadableName(selectedSchema.name) }"
        ></ng-container>

        <!-- will be changed after ACL implementation -->
        <ng-container *appEnv="'prod'; visible: false">
            <ng-container
                *ngTemplateOutlet="
                    settingsItem;
                    context: { name: tAddRecordContent('access'), icon: 'person_outline', selectedName: '$Everyone' }"
            ></ng-container>
        </ng-container>
    </div>
</div>

<ng-template #settingsItem let-icon="icon" let-name="name" let-selectedName="selectedName">
    <div
        (click)="openDialog()"
        class="mb-2 py-1 px-2 rounded-lg bg-primaryBackground cursor-pointer recordInfoBlock"
    >
        <div class="flex items-center w-full">
            <div class="text-primarySecondary caption-1 mr-auto">{{ name }}</div>
            <!-- TODO: change size to correct when icons size typography will be added -->
            <app-tb-icon
                icon="custom_edit"
                class="text-primarySecondary leading-4 editIcon"
            ></app-tb-icon>
        </div>
        <div class="flex">
            <app-tb-icon [icon]="icon" class="mr-2 text-primarySecondary leading-4"></app-tb-icon>
            <div class="text-primaryDark body-1">{{ selectedName }}</div>
        </div>
    </div>
</ng-template>
