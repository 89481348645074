export const environment = {
    mode: 'dev',
    api_host: 'http://10.0.0.129:80',
    version: {
        branch: import.meta?.env?.NG_APP_BRANCH_NAME,
        commit: import.meta?.env?.NG_APP_COMMIT,
        date: import.meta?.env?.NG_APP_DATE,
    },
    ws: {
        endpoints: {
            on_update: 'send',
            init: 'initialize',
            log: 'loggingView',
            create_field: 'fieldCreate',
            update_field: 'fieldUpdate',
            delete_field: 'fieldDelete',
            create_record: 'recordCreate',
            update_record: 'recordUpdate',
            delete_record: 'recordDelete',
            update_link: 'linkOperation',
            create_config: 'configurationCreate',
            update_config: 'configurationUpdate',
            delete_config: 'configurationDelete',
            create_view: 'viewCreate',
            update_view: 'viewUpdate',
            delete_view: 'viewDelete',
            create_folder: 'folderCreate',
            update_folder: 'folderUpdate',
            delete_folder: 'folderDelete',
            create_automation: 'automationCreate',
            delete_automation: 'automationDelete',
            update_automation: 'automationUpdate',
            execute_automation: 'automationExecute',
            create_subtask: 'subtaskCreate',
            update_subtask: 'subtaskUpdate',
            delete_subtask: 'subtaskDelete',
            reset: 'reset',
            repeat: 'repeat',
        },
        response_types: {
            init: 'initialize',
            record_update: 'object.record.update',
            field_update: 'solution.object.field',
            record_delete: 'object.record.delete',
            solution_reset: 'solution.reset',
            folder_create: 'solution.object.folder.create',
            folder_update: 'solution.object.folder.update',
            folder_delete: 'solution.object.folder.delete',
            view_create: 'solution.object.view.create',
            view_update: 'solution.object.view.update',
            view_delete: 'solution.object.view.delete',
            config_create: 'solution.object.configuration.create',
            config_update: 'solution.object.configuration.update',
            config_delete: 'solution.object.configuration.delete',
            create_automation: 'automation.create',
            delete_automation: 'automation.delete',
            update_automation: 'automation.update',
            subtask_create: 'solution.object.subtask.create',
            subtask_update: 'solution.object.subtask.update',
            subtask_delete: 'solution.object.subtask.delete',
            template_folder: 'template.folder',
        },
        status: {
            success: 'success',
            error: 'error',
        },
    },
}
