import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { BaseCellComponent } from '@app/feature/input-cells/base-container-content'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { isNil } from 'lodash-es'

@UntilDestroy()
@Component({
    selector: 'app-number-cell',
    standalone: true,
    imports: [ReactiveFormsModule],
    templateUrl: './number-cell.component.html',
    styleUrl: './number-cell.component.sass',
})
export class NumberCellComponent extends BaseCellComponent implements OnInit {
    @ViewChild('input')
    inputElement!: ElementRef<HTMLElement>

    compareValue: number | null = null

    control = new FormControl<number | null>(null)

    ngOnInit(): void {
        if (this.value) {
            this.control.setValue(Number(this.value))
        }

        this.mergeValidators(this.control)

        this.compareValue = this.control.value

        if (this.disabled) {
            this.control.disable()
        }

        this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.value = value?.toString() || ''
        })
    }

    emitValue() {
        const value = this.control.value
        if (this.compareValue === value) return

        this.compareValue = value
        this.valueChange.emit(value?.toString())
        this.onChange(value?.toString())
    }

    writeValue(value: string): void {
        this.value = value
        this.control.setValue(value ? Number(value) : null)
        this.compareValue = this.control.value
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled

        isDisabled ? this.control.disable() : this.control.enable()
    }

    onContainerClick() {
        if (!this.readonly) {
            this.inputElement.nativeElement.focus()
        }
    }

    onOutsideClick(): void {}

    onFocusIn() {
        if (!this.focused && !this.readonly) {
            this.focused = true
            this.inputContainer.makeFocused()
        }
    }

    onFocusOut(event: FocusEvent) {
        const contains = this.inputContainer.elementRef.nativeElement.contains(
            event.relatedTarget as Element,
        )

        if (this.inputContainer.editControls && !contains) {
            return
        }

        if (!contains) {
            !this.control.invalid && this.emitValue()
            this.makeTouchedAndUnfocused()
        }
    }

    removeFocus() {
        this.makeTouchedAndUnfocused()
        this.inputElement.nativeElement.blur()
    }

    resetValue() {
        this.control.setValue(this.compareValue)
    }

    get errorState() {
        return this.control.invalid && this.touched
    }

    get loadingState() {
        return this.loading
    }

    get lessThanZero() {
        return this.control.value && this.control.value < 0
    }
}
