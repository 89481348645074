import { Component, OnInit } from '@angular/core'
import {
    FilterDialogData,
    FilterModalComponent,
} from '@app/views/view-controls/view-filter/filter-modal/filter-modal.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { openViewFilterMenu } from '@core/@ngrx'
import { FilterGroup, View } from '@core/models'
import { ViewFacadeService } from '@core/services/store-facade'
import { TranslocoService, TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Actions, ofType } from '@ngrx/effects'
import { FilterStorageService } from '@services/local-storage/filter-storage.service'
import { ViewErrorType, ViewValidatorService } from '@services/view-validator.service'
import { combineLatest } from 'rxjs'
import { take } from 'rxjs/operators'
import { ViewFilterService } from 'src/app/views/view-controls/view-filter/view-filter.service'
import { AsyncPipe, NgTemplateOutlet } from '@angular/common'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'

@UntilDestroy()
@Component({
    selector: 'app-view-filter',
    templateUrl: './view-filter.component.html',
    styleUrls: ['./view-filter.component.sass'],
    standalone: true,
    imports: [TbButtonComponent, NgTemplateOutlet, TranslocoModule, AsyncPipe],
})
export class ViewFilterComponent implements OnInit {
    filterGroups!: FilterGroup[]
    selectedView!: View

    sessionFilterCount: string = ''
    isSessionFilterSet: boolean = false

    hasNoFilterErrors$ = this.viewValidatorService
        .validateViewErrors({
            filter: [ViewErrorType.groupInvalidField, ViewErrorType.sortInvalidField],
        })
        .pipe(untilDestroyed(this))

    constructor(
        private viewFacadeService: ViewFacadeService,
        private filterStorageService: FilterStorageService,
        private filterService: ViewFilterService,
        private translocoService: TranslocoService,
        private modalFlowManagerService: ModalFlowManagerService,
        private actions$: Actions,
        private viewValidatorService: ViewValidatorService,
    ) {
        this.subscribeOnOpenAction()
    }

    //TODO refactor this getter latter
    get filterCountText() {
        if (this.isSessionFilterSet) {
            return this.sessionFilterCount
        }
        if (this.filterGroups && this.filterGroups.length === 1) {
            if (!this.filterGroups[0].values) return '1'
            const keys = Object.keys(this.filterGroups[0].values)
            if (keys.length) {
                return keys.length.toString()
            }
        }
        return this.filterGroups ? this.filterGroups.length.toString() : ''
    }

    ngOnInit(): void {
        this.filterStorageService
            .isSet$()
            .pipe(untilDestroyed(this))
            .subscribe((state) => (this.isSessionFilterSet = state))

        combineLatest([
            this.viewFacadeService.selectedView$,
            this.translocoService.selectTranslate('filter.session_count'),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([view, sessionFilterCount]) => {
                this.sessionFilterCount = sessionFilterCount
                if (!view) return

                this.selectedView = view
                console.log('this.selectedView', this.selectedView)
                this.filterGroups = this.filterService.getFilterGroupByView(view)
                this.filterStorageService.updateIsSetValue(view.guid)
            })
    }

    isFilterTextSessionOrNotApplied() {
        if (!this.selectedView?.filter?.value && this.isSessionFilterSet) {
            return false
        }

        return this.filterGroups && this.filterGroups.length !== 0
    }

    subscribeOnOpenAction() {
        this.actions$.pipe(ofType(openViewFilterMenu), untilDestroyed(this)).subscribe(() => {
            this.openDialog()
        })
    }

    openDialog() {
        this.modalFlowManagerService
            .openDialog<FilterModalComponent, FilterDialogData>({
                component: FilterModalComponent,
                data: {
                    filterGroups: this.filterGroups,
                    selectedView: this.selectedView,
                },
            })
            .pipe(take(1))
            .subscribe(() => {
                this.filterGroups = this.filterService.getFilterGroupByView(this.selectedView)
                this.filterStorageService.updateIsSetValue(this.selectedView.guid)
            })
    }
}
