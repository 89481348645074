<ng-container *transloco="let tRecordSettings; read: 'record_settings'">
    <app-modal-layout [containerLayout]="containerLayout" (closeModal)="forceWithValidation()">
        <ng-container title>
            <div>{{ tRecordSettings('record_settings') }}</div>
        </ng-container>
        <ng-container [formGroup]="settingsFormGroup">
            <div class="mb-6">
                <div class="mb-1 text-primaryDark">{{ tRecordSettings('workspace') }}</div>
                <app-tb-select formControlName="folder">
                    @for (folder of data.folders; track folder) {
                        <app-tb-option [value]="folder.guid">
                            {{ folder.name.value }}
                        </app-tb-option>
                    }
                </app-tb-select>
            </div>
            <div *transloco="let tSchemaReadableName; read: 'nav_menu'" class="mb-6">
                <div class="mb-1 text-primaryDark">{{ tRecordSettings('type') }}</div>
                <app-tb-select formControlName="schema">
                    @for (schema of data.schemas; track schema) {
                        <app-tb-option [value]="schema.guid">
                            {{ tSchemaReadableName(schema.name) }}
                        </app-tb-option>
                    }
                </app-tb-select>
            </div>
            <div *appEnv="'prod'; visible: false" class="mb-6">
                <div class="mb-1 text-primaryDark">{{ tRecordSettings('template') }}</div>
                <app-tb-select>
                    <app-tb-option value="values">$Values here</app-tb-option>
                    <app-tb-option>$Values here</app-tb-option>
                    <app-tb-option>$Values here</app-tb-option>
                </app-tb-select>
            </div>
            <app-access-selector *appEnv="'prod'; visible: false"></app-access-selector>
        </ng-container>
        <ng-container *transloco="let tButtons; read: 'buttons'" buttons>
            <div class="flex justify-end py-0 px-5 max-sm:px-0">
                <app-tb-button
                    class="mr-5"
                    [appearance]="'outlined'"
                    (click)="cancelSettingsChange()"
                >
                    {{ tButtons('cancel') }}
                </app-tb-button>
                <app-tb-button (click)="applySettingsChange()">
                    {{ tButtons('apply') }}
                </app-tb-button>
            </div>
        </ng-container>
    </app-modal-layout>
</ng-container>
