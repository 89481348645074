<div class="flex-start-center">
    @if (!disabled) { @for (reit of countStarsInArray; track reit) {
    <mat-icon
        (click)="setRating(reit)"
        (mouseenter)="changeHoverStyles($event, reit)"
        (mouseleave)="backHoverStyles()"
        [class.hoverStar]="(selectValue != undefined)&&(reit <= selectValue)||(hoverValue != undefined)&&(reit <= hoverValue)"
        class="star-item"
    >
        star
    </mat-icon>
    } } @else { @for (reit of countStarsInArray; track reit) {
    <mat-icon
        [class.hoverStar]="(selectValue != undefined)&&(reit <= selectValue)||(hoverValue != undefined)&&(reit <= hoverValue)"
        class="star-item"
    >
        star
    </mat-icon>
    } }
</div>
