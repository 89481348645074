import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { BaseCellComponent } from '@app/feature/input-cells/base-container-content'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbInputComponent } from '@components-library/tb-input/tb-input.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-assignee-cell',
    standalone: true,
    imports: [TbMenuComponent, TbInputComponent, TbButtonComponent],
    templateUrl: './assignee-cell.component.html',
    styleUrl: './assignee-cell.component.sass',
})
export class AssigneeCellComponent extends BaseCellComponent implements AfterViewInit {
    @ViewChild(TbMenuComponent) menu!: TbMenuComponent
    menuJustClosed = false

    ngAfterViewInit(): void {
        this.menu.closed.pipe(untilDestroyed(this)).subscribe(() => {
            this.menuJustClosed = true
        })
    }

    writeValue(value: string): void {}
    setDisabledState(isDisabled: boolean): void {}
    onContainerClick(): void {
        if (!this.readonly) {
            this.menu.openMenu()
            this.focused = true
            this.inputContainer.focused = true
        }
    }

    onOutsideClick(): void {
        if (!this.menuJustClosed && this.focused) {
            this.makeTouchedAndUnfocused()
            this.inputContainer.resetActive()
        }
        this.menuJustClosed = false
    }

    removeFocus(): void {
        this.inputContainer.focused = false
    }
    emitValue(): void {
        this.valueChange.emit(this.value.toString())
    }
    resetValue(): void {}
    get errorState(): boolean {
        return false
    }
    get loadingState(): boolean {
        return false
    }
}
