import { Component, OnChanges, SimpleChanges, ViewChild } from '@angular/core'
import { BaseSelectCellComponent } from '@app/feature/input-cells/base-select-cell/base-select-cell.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { SelectOption } from '@models/response/select-object-options'

@Component({
    selector: 'app-status-select-cell',
    standalone: true,
    imports: [TbIconComponent, TbMenuComponent, TbMenuListComponent],
    templateUrl: './status-select-cell.component.html',
    styleUrl: './status-select-cell.component.sass',
})
export class StatusSelectCellComponent extends BaseSelectCellComponent implements OnChanges {
    @ViewChild('select') statusMenu!: TbMenuComponent

    isNoDefault = false

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes)
    }

    get selectMenu(): TbMenuComponent {
        return this.statusMenu
    }

    override get errorState() {
        return super.errorState || this.isNoDefault
    }

    protected prepareSelectData(): SelectOption[] {
        return Object.keys(this.selectObject).map((guid) => {
            return {
                guid,
                ...this.selectObject![guid],
            }
        })
    }

    protected setInitialValue(): void {
        const defaultOptionGuid = this.field.default_value

        if (!this.value) {
            if (!defaultOptionGuid) {
                this.isNoDefault = true
                throw new Error('error no default')
            }

            this.value = this.parentControl?.value ?? defaultOptionGuid
        }
    }
}
