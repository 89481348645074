<app-modal-layout [containerLayout]="containerLayout" class="mt-3">
    @if (selectedFolder$ | async; as selectedFolder) {
        @if (folders$ | async; as folders) {
            <div class="h-full flex flex-col max-h-[35rem] overflow-hidden w-[15.25rem] -mr-4 pr-4">
                <div>
                    @if (folders.global) {
                        <app-folder
                            [folder]="folders.global"
                            [canDelete]="false"
                            [showSelectedIcon]="containerLayout === 'BottomSheet'"
                            [selectedFolderGuid]="selectedFolder.guid"
                            (selectFolder)="selectFolder($event)"
                            (edit)="openFolderEditDialog(folders.global)"
                        ></app-folder>
                    }
                    <mat-divider
                        class="max-sm:mt-4 mb-4 sm:mb-2 h-0.5 bg-newNeutral3"
                    ></mat-divider>
                </div>
                <div class="overflow-auto -mr-[0.875rem]">
                    <div class="pr-[0.875rem]">
                        @for (folder of folders.nonGlobal; track folder) {
                            <app-folder
                                [folder]="folder"
                                [selectedFolderGuid]="selectedFolder.guid"
                                [showSelectedIcon]="containerLayout === 'BottomSheet'"
                                (selectFolder)="selectFolder($event)"
                                (edit)="openFolderEditDialog(folder)"
                            ></app-folder>
                        }
                    </div>
                </div>
                <div>
                    <app-tb-button
                        *transloco="let tButtons; read: 'buttons'"
                        appearance="text"
                        color="primary"
                        icon="add_circle_outline"
                        (click)="openFolderCreateDialog()"
                    >
                        <span class="font-semibold">{{ tButtons('add_workspace') }}</span>
                    </app-tb-button>
                </div>
            </div>
        }
    }
</app-modal-layout>
