<div class="w-[20.75rem] h-full flex flex-col" [class.collapsed]="collapsed">
    @if (collapsed) {
        <div
            class="px-2"
            cdkDropList
            [cdkDropListData]="{ items, collapsed }"
            [cdkDropListEnterPredicate]="isDisabledDragIntoTheListBound"
            (cdkDropListDropped)="drop($event)"
            (cdkDropListEntered)="enter($event)"
            (cdkDropListExited)="exit($event)"
        >
            <ng-container *ngTemplateOutlet="header"></ng-container>
        </div>
    } @else {
        <div class="px-2">
            <ng-container *ngTemplateOutlet="header"></ng-container>
        </div>
    }

    <div
        [class.hidden]="collapsed"
        class="overflow-auto h-full p-2"
        cdkDropList
        [cdkDropListData]="{ items, collapsed }"
        [cdkDropListEnterPredicate]="isDisabledDragIntoTheListBound"
        (cdkDropListDropped)="drop($event)"
        (cdkDropListEntered)="enter($event)"
        (cdkDropListExited)="exit($event)"
    >
        @if (injector) {
            @for (item of items; track item) {
                <div class="mb-2">
                    <ng-container
                        *ngTemplateOutlet="card; injector: injector; context: { item }"
                    ></ng-container>
                </div>
            }
        }
    </div>
</div>

<ng-template #header>
    <app-tb-board-column-header
        [items]="items"
        [moreMenu]="headerMenu"
        [columnItem]="columnItem"
        [selectedItems]="selectedItems"
        [dragOn]="dragOn"
        [collapsed]="collapsed"
        [controlCollapse]="isCollapsedSet"
        (collapseChanged)="toggleCollapse($event)"
        (selectColumn)="selectColumn.emit($event)"
    >
        <ng-content select="[header]"></ng-content>
    </app-tb-board-column-header>
</ng-template>
