<div class="flex items-center w-full min-h-[38px]">
    @if (isFieldNonText) { @if (field.field_type_code !== FieldTypes.LINK) {
    <!-- TODO: https://chat.anveo.com/git/AnveoTikibase_ui/pulls/1270#issuecomment-26953 -->
    <app-cell-container
        [class.static]="field.field_type_code === FieldTypes.STATUS || field.field_type_code === FieldTypes.DROPDOWN"
        [fieldTypes]="fieldTypes"
        [field]="field"
        [value]="fieldValueFormControl.value ?? ''"
        (cellValueChanged)="cellValueChanged($event)"
    ></app-cell-container>
    } @else {
    <mat-form-field appearance="outline" class="flex items-center w-full max-h-[38px] mb-2">
        <mat-select
            multiple
            [disabled]="fieldValueFormControl.disabled"
            [value]="linkValue"
            (selectionChange)="setLinkValue($event)"
            placeholder="Select"
        >
            @for (record of linkRecords; track record) {
            <mat-option [value]="record.guid"> {{record.name.value}} </mat-option>
            }
        </mat-select>
    </mat-form-field>
    }
    <!-- TODO: will be reworked in task #1263 -->
    } @else if(supportNewContainer) {
    <app-input-cell-container
        [type]="field.field_type_code"
        [formControl]="fieldValueFormControl"
        [appearance]="inputContainerAppearance.BORDERED"
    >
    </app-input-cell-container>
    } @else {
    <app-tb-input
        [formControl]="fieldValueFormControl"
        (keydown.enter)="updateOnEnter()"
        class="w-full"
    ></app-tb-input>
    }
</div>
