import { Injectable } from '@angular/core'
import { generateUserEntities } from '@core/models/factories'
import { ActionTypeModel } from '@core/models/response/action-type.model'
import { SourceTypeModel } from '@core/models/response/source-type.model'
import { Group } from '@core/models/ui/group.model'
import { Role } from '@core/models/ui/role.model'
import { AutomationEntities, ResponseAutomation } from '@models/response/automation.model'
import { FolderNameEntities } from '@models/response/folder-names'
import { GroupEntities } from '@models/response/group'
import { LinkEntities } from '@models/response/link'
import { ResponseError } from '@models/response/response-error'
import { RoleEntities } from '@models/response/role'
import { Automation } from '@models/ui/automation.model'
import { SubtaskRecord } from '@models/ui/subtask-record.model'
import { TranslocoService } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary, Update } from '@ngrx/entity'
import { ErrorNavigatorService } from '@services/error-navigator.service'
import { cloneDeep, isNumber, pickBy, reduce } from 'lodash-es'
import { combineLatest, of, switchMap } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { isNonNull } from '../global-util'
import {
    AppRecord,
    BusinessRecords,
    DataItem,
    FolderTemplate,
    FolderTemplateResponseModel,
    FolderViewRecord,
    generateFieldTypes,
    generateRecord,
    generateRecords,
    generateSchema,
    generateUpdatedRecords,
    InitResponseModel,
    ObjectType,
    regenerateCells,
    ResponseFieldEntities,
    ResponseFieldTypeEntities,
    ResponseSchema,
    ResponseUserModelEntities,
    Schema,
    SolutionModel,
    SystemTypeCode,
    TableModel,
    UpdateResponseModel,
    User,
} from '../models'
import { LinkReferenceService } from './link-reference.service'
import { NotificationService } from './notification.service'
import {
    AutomationFacadeService,
    CommonFacadeService,
    FieldTypeFacadeService,
    FolderFacadeService,
    GroupFacadeService,
    RecordFacadeService,
    RoleFacadeService,
    SchemaFacadeService,
    SubtaskFacadeService,
    UserFacadeService,
} from './store-facade'
import { SystemRecordService } from './system-record.service'

export interface DataObject {
    automations?: AutomationEntities
    users?: ResponseUserModelEntities
    fieldTypes?: ResponseFieldTypeEntities
    solution?: SolutionModel
    tables: TableModel[]
    schemas: ResponseSchema[]
    role?: RoleEntities
    group?: GroupEntities
    actionTypes?: ActionTypeModel
    sourceTypes?: SourceTypeModel
    systemFields?: ResponseFieldEntities
    link?: LinkEntities
    folderNames?: FolderNameEntities
    errors?: ResponseError[]
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class SaveDataService {
    constructor(
        private commonFacadeService: CommonFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private systemRecordService: SystemRecordService,
        private recordFacadeService: RecordFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private folderFacadeService: FolderFacadeService,
        private subtaskFacadeService: SubtaskFacadeService,
        private userFacadeService: UserFacadeService,
        private automationFacadeService: AutomationFacadeService,
        private groupFacadeService: GroupFacadeService,
        private roleFacadeService: RoleFacadeService,
        private linkReferenceService: LinkReferenceService,
        private notificationService: NotificationService,
        private errorNavigatorService: ErrorNavigatorService,
        private translate: TranslocoService,
    ) {}

    public saveInitData(response: InitResponseModel) {
        const dataObject: DataObject = this.getDataObjectFromResponse(cloneDeep(response))

        this.saveUsers(dataObject)
        this.saveRoles(dataObject)
        this.saveFieldTypes(dataObject)
        this.saveSolution(dataObject)
        this.saveSystemFields(dataObject)
        this.saveCurrentUser(response)
        this.saveFolderNames(dataObject)
        this.saveAutomations(dataObject)
        this.saveActionTypes(dataObject)
        this.saveSourceTypes(dataObject)
        this.saveGroups(dataObject)

        const dataObjectWithVirtualFields = this.initVirtualFieldsInSchemasAndTables(dataObject)
        this.saveSchemas(dataObjectWithVirtualFields)
        this.saveRecords(dataObjectWithVirtualFields)

        this.commonFacadeService.dataInitialized()
    }

    private initVirtualFieldsInSchemasAndTables(dataObject: DataObject) {
        this.linkReferenceService.initLinkRefStore(dataObject.schemas, dataObject.tables)
        const schemasWithVirtualFields = this.linkReferenceService.setStoredVirtualFieldsToSchemas(
            dataObject.schemas,
        )
        const dataTablesWithVirtualFields = this.linkReferenceService.setStoredVirtualCellsToTables(
            schemasWithVirtualFields,
            dataObject.tables,
        )

        return {
            ...cloneDeep(dataObject),
            schemas: schemasWithVirtualFields,
            tables: dataTablesWithVirtualFields,
        }
    }

    public saveResetData(response: InitResponseModel) {
        const dataObject: DataObject = this.getDataObjectFromResponse(cloneDeep(response))

        this.resetRecords()
        this.resetSubtasks()

        this.commonFacadeService.selectSystemFields$
            .pipe(take(1), untilDestroyed(this))
            .subscribe((systemFields) => {
                if (systemFields) {
                    dataObject.systemFields = systemFields

                    this.saveCurrentUser(response)
                    this.saveFolderNames(dataObject)
                    this.saveSchemas(dataObject)
                    this.saveRecords(dataObject, true)
                } else {
                    console.error(new Error('no system fields on reset'))
                }
            })
    }

    public saveUpdatedData(response: UpdateResponseModel) {
        if (isNumber(response.responseId)) {
            this.commonFacadeService.setResponseID(response.responseId)
        }

        // TODO update logic for new types
        const dataObject: DataObject = this.getDataObjectFromResponse(cloneDeep(response))

        this.notificationService.open(dataObject)

        if (dataObject.tables.length) {
            dataObject.tables.forEach((table) => {
                this.updateTable(table)
            })
        } else if (dataObject.schemas.length) {
            this.saveUpdatedSchemas(dataObject)
        } else if (dataObject.users) {
            this.saveUsers(dataObject)
        } else if (dataObject.fieldTypes) {
            this.saveFieldTypes(dataObject)
        } else if (dataObject.automations) {
            this.saveUpdatedAutomations(dataObject.automations)
        } else if (dataObject.solution) {
            console.log('dataObject.solution', dataObject.solution)
        }
    }

    saveTemplateData(response: FolderTemplateResponseModel) {
        this.saveFolderTemplateData(response)
    }

    private saveFolderTemplateData(response: FolderTemplateResponseModel) {
        this.folderFacadeService.selectFolderNames$.pipe(take(1)).subscribe((folderNames) => {
            if (response.front_data && response.system_data) {
                const folderTemplate = this.combineFolderTemplateData(
                    response.front_data.view,
                    response.front_data.field,
                    response.system_data,
                    folderNames,
                )

                this.folderFacadeService.initFolderTemplate(folderTemplate)
            }
        })
    }

    private combineFolderTemplateData(
        views: FolderViewRecord,
        fieldSchemas: DataItem<ResponseSchema>[],
        systemSchemas: DataItem<ResponseSchema>[],
        folderNames: FolderNameEntities,
    ): FolderTemplate {
        const fieldRelatedSchemas = fieldSchemas.map((fieldSchema) => {
            return generateSchema(fieldSchema.object, folderNames)
        })
        const systemDataSchemas = systemSchemas.map((systemSchema) => {
            return generateSchema(systemSchema.object, folderNames)
        })

        return {
            views,
            fieldRelatedSchemas,
            systemDataSchemas,
        }
    }

    private saveUpdatedSchemas(dataObject: DataObject) {
        combineLatest([
            dataObject.folderNames ? of(undefined) : this.folderFacadeService.selectFolderNames$,
            this.commonFacadeService.selectSystemFields$,
            this.schemaFacadeService.selectAllSchemas$,
        ])
            .pipe(take(1), untilDestroyed(this))
            .subscribe(
                ([folderNames, systemFields, allSchemas]: [
                    FolderNameEntities | undefined,
                    ResponseFieldEntities | null,
                    Schema[],
                ]) => {
                    if (!folderNames) {
                        throw Error('Unable to find folderNames!')
                    }

                    if (!systemFields) {
                        throw Error('Unable to find systemFields!')
                    }

                    dataObject.folderNames = folderNames
                    dataObject.systemFields = systemFields

                    const schemasWithSystemFields = this.createSchemasWithSystemFieldsAndVirtual(
                        dataObject,
                        allSchemas,
                    )

                    const schemas: Update<Schema>[] = this.saveUpdatedRecordsBySchemaUpdate(
                        schemasWithSystemFields,
                        dataObject,
                    )

                    this.linkReferenceService.updateSchemasWithVirtualFields(schemas)

                    console.log('schemas from save', schemas)

                    schemasWithSystemFields.forEach((schema) => {
                        this.updateSchemaRecords(schema)
                    })

                    this.schemaFacadeService.updateSchemas(schemas)
                },
            )
    }

    private createSchemasWithSystemFieldsAndVirtual(dataObject: DataObject, prevSchemas: Schema[]) {
        return dataObject.schemas
            .map((responseSchema: ResponseSchema) => {
                return {
                    ...responseSchema,
                    field: {
                        ...responseSchema.field,
                        ...dataObject.systemFields,
                    },
                }
            })
            .map((responseSchema) => {
                return this.fillSchemaWithVirtualFieldsFromPreviousSchema(
                    prevSchemas,
                    responseSchema,
                )
            })
    }

    private fillSchemaWithVirtualFieldsFromPreviousSchema(
        allSchemas: Schema[],
        newResponseSchema: ResponseSchema,
    ) {
        const prevSchema = allSchemas.find(
            (prevSchema) => prevSchema.guid === newResponseSchema.guid,
        )
        if (!prevSchema) return cloneDeep(newResponseSchema)

        const virtualFields = pickBy(prevSchema.fieldEntities, (field) => !!field.virtual_link)

        return reduce(
            virtualFields,
            (schema, field) => {
                schema.field[field.guid] = field
                return schema
            },
            cloneDeep(newResponseSchema),
        )
    }

    private saveUpdatedRecordsBySchemaUpdate(
        responseSchemas: ResponseSchema[],
        dataObject: DataObject,
    ) {
        return responseSchemas.map((responseSchema: ResponseSchema) => {
            return {
                id: responseSchema.guid,
                changes: generateSchema(responseSchema, dataObject.folderNames!),
            }
        })
    }

    private updateSchemaRecords(responseSchema: ResponseSchema) {
        this.recordFacadeService
            .selectDataTableRecordsBySchemaGuid$(responseSchema.guid)
            .pipe(take(1), untilDestroyed(this))
            .subscribe((recordsArr: AppRecord[]) => {
                const records: Update<AppRecord>[] = recordsArr.map((record: AppRecord) => {
                    const updatedRecord = generateUpdatedRecords(record, responseSchema)
                    return {
                        id: updatedRecord.guid!,
                        changes: updatedRecord,
                    }
                })
                this.recordFacadeService.updateRecordsFromResponse(records)
            })
    }

    private saveRecords({ tables, schemas }: DataObject, isReset = false) {
        const schemaEntities: Dictionary<ResponseSchema> = schemas.reduce(
            (res: Dictionary<ResponseSchema>, next: ResponseSchema) => {
                return { [next.guid]: next, ...res }
            },
            {},
        )

        tables.forEach((tableModel: TableModel) => {
            const schema = schemaEntities[tableModel.guid]
            if (!schema) {
                console.error('incorrect schema guid', tableModel)
                return
            }
            if (schema.is_system) {
                this.systemRecordService.initSystemRecordByObjectType(schema, tableModel.record!)
            } else if (schema.system_object_type_code === SystemTypeCode.SUBTASK) {
                const subtasks = generateRecords(schema, tableModel.record!) as SubtaskRecord[]

                this.subtaskFacadeService.initSubtasks(subtasks)
            } else {
                const records = generateRecords(schema, tableModel.record!) as BusinessRecords[]

                if (isReset) records.forEach((record) => regenerateCells(record))
                this.recordFacadeService.initRecords(records)
            }
        })
    }

    private saveSchemas(dataObject: DataObject) {
        const schemas: Schema[] = dataObject.schemas.map((responseSchema: ResponseSchema) => {
            const responseFields = responseSchema.field
            if (responseSchema.system_object_type_code === SystemTypeCode.TABLE) {
                if (!this.checkPrimaryFieldAvailable(responseFields)) {
                    this.navigateToSomethingWentWrongPage(responseSchema.name, responseSchema.guid)
                    throw Error('Is primary not found!')
                }
            }

            responseSchema.field = {
                ...responseFields,
                ...this.modifySystemFields(dataObject.systemFields),
            }
            if (dataObject.folderNames) {
                return generateSchema(responseSchema, dataObject.folderNames)
            } else {
                throw Error('Unable to find folder names!')
            }
        })

        this.schemaFacadeService.initSchemas(schemas)
    }

    private checkPrimaryFieldAvailable(responseFields: ResponseFieldEntities) {
        return !!Object.keys(responseFields).find((guid) => !!responseFields[guid].is_primary)
    }

    private navigateToSomethingWentWrongPage(schemaName: string, schemaGuid: string) {
        this.translate
            .selectTranslate(`nav_menu.${schemaName}`)
            .pipe(
                switchMap((name) => {
                    return this.translate.selectTranslate('errors.primary_error', {
                        guid: schemaGuid,
                        name: name,
                    })
                }),
            )
            .subscribe((message) => {
                this.errorNavigatorService.goToSomethingWentWrongPage()
                this.commonFacadeService.setLoading(false)
                throw Error(message)
            })
    }

    private modifySystemFields(systemFields?: ResponseFieldEntities) {
        if (!systemFields) return systemFields

        return Object.keys(systemFields).reduce((acc, key) => {
            acc[key] = {
                is_readonly: 1,
                ...systemFields[key],
            }

            return acc
        }, {} as ResponseFieldEntities)
    }

    private saveCurrentUser(response: InitResponseModel) {
        if (response.user) {
            this.userFacadeService.setCurrentUser(response.user)
        } else {
            console.log(new Error(`No current user`))
        }
    }

    private saveRoles({ role }: DataObject) {
        if (!role) {
            console.error(`No roles!`)
            return
        }

        const rolesArr: Role[] = Object.keys(role).map((guid) => {
            return {
                guid,
                name: role[guid].name,
            }
        })

        this.roleFacadeService.setRoles(rolesArr)
    }

    private saveFolderNames(dataObject: DataObject) {
        if (dataObject.folderNames) {
            const folderNames = dataObject.folderNames
            this.folderFacadeService.initFolderNames(folderNames)
        } else {
            console.log(new Error('no folder object in response'))
        }
    }

    private saveUsers(dataObject: DataObject) {
        if (dataObject.users) {
            const userEntities = generateUserEntities(dataObject.users)
            this.userFacadeService.setUsers(Object.values(userEntities) as User[])
        } else {
            console.log(new Error('no users object in response'))
        }
    }

    private saveFieldTypes(dataObject: DataObject) {
        if (dataObject.fieldTypes) {
            const fieldTypes = generateFieldTypes(dataObject.fieldTypes)
            this.fieldTypeFacadeService.setFieldTypes(fieldTypes)
        } else {
            console.log(new Error('no fieldTypes object in response'))
        }
    }

    private saveSolution(dataObject: DataObject) {
        if (dataObject.solution) {
            const solution = dataObject.solution
            this.commonFacadeService.setSolution(solution)
        } else {
            console.log(new Error('no solution object in response'))
        }
    }

    private getDataObjectFromResponse(response: UpdateResponseModel): DataObject {
        const dataObject: DataObject = {
            tables: [],
            schemas: [],
        }

        response.data.forEach((item: DataItem) => {
            switch (item.type) {
                case ObjectType.SCHEMA:
                    dataObject.schemas.push(<ResponseSchema>item.object)
                    break
                case ObjectType.TABLE:
                    dataObject.tables.push(<TableModel>item.object)
                    break
                case ObjectType.USER:
                    dataObject.users = <ResponseUserModelEntities>item.object
                    break
                case ObjectType.FIELD_TYPE:
                    dataObject.fieldTypes = <ResponseFieldTypeEntities>item.object
                    break
                case ObjectType.SOLUTION:
                    dataObject.solution = <SolutionModel>item.object
                    break
                case ObjectType.ROLE:
                    dataObject.role = <RoleEntities>item.object
                    break
                case ObjectType.GROUP:
                    dataObject.group = <GroupEntities>item.object
                    break
                case ObjectType.SYSTEM_FIELDS:
                    dataObject.systemFields = <ResponseFieldEntities>item.object
                    break
                case ObjectType.FOLDER:
                    dataObject.folderNames = <FolderNameEntities>item.object
                    break
                case ObjectType.LINK:
                    dataObject.link = <LinkEntities>item.object
                    console.warn(new Error('save links to store'))
                    break
                case ObjectType.AUTOMATION:
                    dataObject.automations = <AutomationEntities>item.object
                    break
                case ObjectType.ACTION_TYPE:
                    dataObject.actionTypes = <ActionTypeModel>item.object
                    break
                case ObjectType.SOURCE_TYPE:
                    dataObject.sourceTypes = <SourceTypeModel>item.object
                    break
                default:
                    console.error(`Incorrect type of DataItem "${item.type}"`)
            }
        })

        return dataObject
    }

    private updateTable(table: TableModel) {
        this.schemaFacadeService
            .selectSchemaByGuid$(table.guid)
            .pipe(
                untilDestroyed(this),
                take(1),
                map((schema: Schema | undefined) => {
                    return schema!
                }),
            )
            .subscribe((schema: Schema) => {
                if (schema.is_system) {
                    this.systemRecordService.updateSystemRecord(table, schema)
                } else if (schema.system_object_type_code === SystemTypeCode.SUBTASK) {
                    this.cudSubtask(table, schema)
                } else {
                    this.cudRecord(table, schema)
                }
            })
    }

    private cudRecord(table: TableModel, schema: Schema) {
        if (table.deleted) {
            this.linkReferenceService.removeRecords(table.deleted, schema)
            this.recordFacadeService.deleteRecordsFromResponse(table.deleted)
        }
        if (table.added) {
            const records = generateRecords(schema, table.added) as BusinessRecords[]
            this.linkReferenceService.addRecords(records, schema)
            this.recordFacadeService.addRecordsFromResponse(records)
        }
        if (table.updated) {
            const records = Object.keys(table.updated!)
                .map((recordGuid: string) => {
                    return generateRecord(recordGuid, schema, table.updated![recordGuid])
                }, [])
                .filter(isNonNull) as AppRecord[]

            const updatedRecords = records.map((record) => ({ id: record.guid, changes: record }))

            this.linkReferenceService.updateRecords(records, schema)
            this.recordFacadeService.updateRecordsFromResponse(updatedRecords)
        }
    }

    private cudSubtask(table: TableModel, schema: Schema) {
        if (table.deleted) {
            this.subtaskFacadeService.deleteSubtasksFromResponse(table.deleted)
        }
        if (table.added) {
            const subtasks = generateRecords(schema, table.added) as SubtaskRecord[]
            this.subtaskFacadeService.addSubtasksFromResponse(subtasks)
        }
        if (table.updated) {
            const subtasks = Object.keys(table.updated)
                .map((recordGuid: string) => {
                    return generateRecord(recordGuid, schema, table.updated![recordGuid])
                }, [])
                .filter(isNonNull) as SubtaskRecord[]

            const updatedSubtasks = subtasks.map((subtask) => ({
                id: subtask.guid,
                changes: subtask,
            }))

            this.subtaskFacadeService.updateSubtasksFromResponse(updatedSubtasks)
        }
    }

    private saveSystemFields({ systemFields }: DataObject) {
        if (systemFields) {
            this.commonFacadeService.initSystemFields(systemFields)
        } else {
            console.log(new Error('no systemFields in response'))
        }
    }

    private saveAutomations({ automations }: DataObject) {
        if (automations) {
            const automationArray: Automation[] = Object.keys(automations).reduce(
                (acc, recordGuid) => {
                    return [
                        ...acc,
                        ...this.convertResponseAutomationToArray(
                            recordGuid,
                            automations[recordGuid].record!,
                        ),
                    ]
                },
                [] as Automation[],
            )

            this.automationFacadeService.setAutomation(automationArray)
        }
    }

    private saveActionTypes({ actionTypes }: DataObject) {
        if (!actionTypes) {
            console.error(`No actionTypes object!`)
            return
        }

        this.automationFacadeService.setActionTypes(actionTypes)
    }

    private saveSourceTypes({ sourceTypes }: DataObject) {
        if (!sourceTypes) {
            console.error(`No sourceTypes object!`)
            return
        }

        this.automationFacadeService.setSourceTypes(sourceTypes)
    }

    private saveGroups({ group }: DataObject) {
        if (!group) {
            console.error(`No groups!`)
            return
        }

        const groupArray: Group[] = Object.keys(group).map((guid) => {
            return {
                guid,
                name: group[guid].name,
            }
        })

        this.groupFacadeService.setGroups(groupArray)
    }

    private saveUpdatedAutomations(automationEntities: AutomationEntities) {
        Object.keys(automationEntities).forEach((recordGuid) => {
            const automationModel = automationEntities[recordGuid]

            if (automationModel.updated) {
                const automationUpdateRecords = automationModel.updated
                const automationGuid = Object.keys(automationModel.updated)[0]
                this.automationFacadeService.updateAutomationFromResponse({
                    id: automationGuid,
                    changes: this.composeAutomation(
                        automationUpdateRecords[automationGuid],
                        automationGuid,
                        recordGuid,
                    ),
                })
                return
            }

            if (automationModel.added) {
                const automationAddedRecords = automationModel.added
                const automationGuid = Object.keys(automationModel.added)[0]
                this.automationFacadeService.addAutomationsFromResponse(
                    this.composeAutomation(
                        automationAddedRecords[automationGuid],
                        automationGuid,
                        recordGuid,
                    ),
                )
                return
            }

            if (automationModel.deleted) {
                this.automationFacadeService.deleteAutomationFromResponse(automationModel.deleted)
                return
            }
        })
    }

    private convertResponseAutomationToArray(
        recordGuid: string,
        responseAutomations: Record<string, ResponseAutomation>,
    ) {
        return Object.keys(responseAutomations).reduce((acc, automationGuid) => {
            const responseAutomation = responseAutomations[automationGuid]
            acc.push(this.composeAutomation(responseAutomation, automationGuid, recordGuid))
            return acc
        }, [] as Automation[])
    }

    private composeAutomation(
        responseAutomation: ResponseAutomation,
        automationGuid: string,
        recordGuid: string,
    ): Automation {
        return {
            name: responseAutomation.name,
            guid: automationGuid,
            sotGuid: recordGuid,
            conditions: responseAutomation.conditions,
            actions: responseAutomation.actions,
            lastTimestamp: responseAutomation.last_timestamp,
            lastUserGuid: responseAutomation.last_user_guid,
            automationType: responseAutomation.automation_type,
            isActive: responseAutomation.is_active ?? 0,
            userGuids: responseAutomation.user_guids,
            systemNote: responseAutomation.system_note,
            failCount: responseAutomation.fail_count,
            isGlobal: responseAutomation.is_global,
        }
    }

    private resetRecords() {
        this.recordFacadeService.resetRecords()
    }

    private resetSubtasks() {
        this.subtaskFacadeService.resetSubtasks()
    }
}
