<ng-container *transloco="let tHeader; read: 'nav_menu'">
    @if (selectedSchema$ | async; as schema) {
    <div class="text-primaryDark headline sm:display-1 primaryColorBG">
        {{ tHeader(schema.name) }}
    </div>
    }
    <app-tb-menu xPosition="before" [template]="menu" [replaceable]="true">
        <app-tb-button trigger appearance="icon" icon="custom_settings"></app-tb-button>
    </app-tb-menu>
    <app-tb-button (click)="newRecord()" [appearance]="'text'" [icon]="'add_circle_outline'">
        <span class="body-2">{{ tHeader('add_new') }}</span>
    </app-tb-button>
</ng-container>
<ng-template #menu let-layout let-manager="manager">
    <app-tb-menu-list
        [containerLayout]="layout"
        *transloco="let tManageFields; read: 'manage_fields'"
    >
        <app-tb-menu-list-item (click)="manageFields(manager)" icon="custom_settings">
            {{ tManageFields('manage_fields') }}
        </app-tb-menu-list-item>
    </app-tb-menu-list>
</ng-template>
