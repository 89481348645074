import { Routes } from '@angular/router'

export const APP_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/main/main.routes'),
    },
    {
        path: '',
        loadChildren: () => import('./pages/errors/error.routes'),
    },
    {
        path: '**',
        redirectTo: 'view',
    },
]
