<div class="relative flex items-center max-sm:pl-4 max-sm:flex-wrap max-sm:mt-4 sm:mb-4">
    <div
        class="hidden max-sm:block absolute top-0 left-0 h-[calc(100%-16px)] w-[4px] bg-primaryLight rounded"
    ></div>
    <div class="flex items-center justify-between w-full">
        <div class="flex flex-1 gap-2 mr-2">
            <app-tb-select
                class="flex-1 w-[252px] max-sm:mb-4 noHintError"
                *transloco="let tFilter; read: 'filter'"
                [placeholder]="tFilter('select_filter')"
                [focusInitial]="true"
                (selectionChange)="setFilterItem.emit($event)"
            >
                @for (fieldGuid of fieldGuids; track fieldGuid) {
                    <app-tb-option [value]="fieldGuid">
                        <app-field-name
                            [field]="fields[fieldGuid]"
                            [folder]="selectedFolder"
                            [showIcon]="false"
                        ></app-field-name>
                    </app-tb-option>
                }
            </app-tb-select>
            <app-tb-select
                [disabled]="true"
                class="max-sm:mb-4 w-[160px] noHintError"
            ></app-tb-select>
        </div>
        <div class="w-full mr-2">
            <app-tb-input class="pointer-events-none"></app-tb-input>
        </div>
        @if (delete) {
            <app-tb-icon
                icon="custom_trash"
                class="icon-d p-0.5 text-secondaryMainLight hover:text-secondaryMain cursor-pointer ml-2"
                (click)="deleteFilterItem.emit()"
            ></app-tb-icon>
        }
    </div>
</div>
