@if (!isDefault) {
    <div
        [class.edit]="isFocused"
        #optionItem
        cdkDrag
        cdkDragBoundary=".status-container"
        [cdkDragData]="option.guid"
    >
        <ng-container *ngTemplateOutlet="optionItemTemplate"></ng-container>
    </div>
} @else {
    <div [class.edit]="isFocused" #optionItem>
        <ng-container *ngTemplateOutlet="optionItemTemplate"></ng-container>
    </div>
}
<ng-template #optionItemTemplate>
    <div
        [formGroup]="formGroup"
        class="flex flex-row justify-start items-center cursor-pointer mb-2.5"
    >
        @if (!isClosed && !isDefault) {
            <mat-icon class="text-newNeutral4">drag_indicator</mat-icon>
        }
        <div class="flex flex-row justify-start items-center status-item">
            <app-color-picker
                #colorPicker
                [set]="SetType.STATUS"
                [color]="formGroup.value['color']"
                (colorChange)="updateSelectedColor($event)"
            ></app-color-picker>
            <app-icon-picker
                #iconPicker
                class="mr-3"
                [set]="SetType.STATUS"
                [icon]="option.icon"
                (iconChange)="updateSelectedIcon($event)"
            ></app-icon-picker>
            <input #optionLabelInput="matInput" formControlName="label" matInput placeholder="" />
            @if (isSelected) {
                <mat-icon class="active-check-label">check</mat-icon>
            }
            @if (isDefault) {
                <mat-icon class="active-check-label">ac_unit </mat-icon>
            }
            <div class="status-action flex flex-row justify-start items-center">
                <!-- change to app-tb-button after new design will be implemented -->
                <button class="done-label" mat-button>
                    <mat-icon class="">done</mat-icon>
                </button>
                <!-- change to app-tb-button after new design will be implemented -->
                <button [matMenuTriggerFor]="statusAction" class="action-btn" mat-button>
                    <app-tb-icon class="text-newNeutral4" icon="more_vert"></app-tb-icon>
                </button>
                <mat-menu #statusAction="matMenu">
                    <ng-template
                        matMenuContent
                        *transloco="
                            let tCellTypesSelectOptionItem;
                            read: 'cell_types.select.option_item'
                        "
                    >
                        <!-- change to app-tb-button after new design will be implemented -->
                        <button (click)="optionLabelInput.focus()" mat-menu-item>
                            <app-tb-icon
                                class="text-newNeutral4 mr-2.5"
                                icon="mode_edit_outline"
                            ></app-tb-icon>
                            <span> {{ tCellTypesSelectOptionItem('rename') }} </span>
                        </button>
                        <!-- change to app-tb-button after new design will be implemented -->
                        @if ((!isClosed || isDropdown) && !isDefault) {
                            <button
                                (click)="setDefaultStatus()"
                                class="flex flex-row justify-start items-center"
                                mat-menu-item
                            >
                                <app-tb-icon
                                    class="text-newNeutral4 mr-2.5"
                                    icon="ac_unit"
                                ></app-tb-icon>
                                <span> {{ tCellTypesSelectOptionItem('default') }} </span>
                            </button>
                        }
                        <!-- change to app-tb-button after new design will be implemented -->
                        @if (!isClosed) {
                            <button (click)="openColorPicker()" mat-menu-item>
                                <app-tb-icon
                                    class="text-newNeutral4 mr-2.5"
                                    icon="colorize"
                                ></app-tb-icon>
                                <span> {{ tCellTypesSelectOptionItem('color') }} </span>
                            </button>
                        }
                        <!-- change to app-tb-button after new design will be implemented -->
                        <button
                            (click)="openIconDialog()"
                            class="flex flex-row justify-start items-center"
                            mat-menu-item
                        >
                            <app-tb-icon icon="add_circle_outline"></app-tb-icon>
                            <span> {{ tCellTypesSelectOptionItem('icon') }} </span>
                        </button>
                        <!-- change to app-tb-button after new design will be implemented -->
                        @if (!disableDelete && !isClosed && !isDefault) {
                            <button
                                (click)="deleteStatus()"
                                class="flex flex-row justify-start items-center"
                                mat-menu-item
                            >
                                <app-tb-icon
                                    class="text-newNeutral4 mr-2.5"
                                    icon="delete"
                                ></app-tb-icon>
                                <span> {{ tCellTypesSelectOptionItem('delete') }} </span>
                            </button>
                        }
                    </ng-template>
                </mat-menu>
            </div>
        </div>
    </div>
</ng-template>
