<div class="flex flex-col py-6 px-10 w-[31.25rem]" *transloco="let t">
    <div class="text-center">
        <div class="title">{{ t(data.translations.title) }}</div>
        <div class="body-1 text-newTextLight mt-2">{{ t(data.translations.message) }}</div>
    </div>
    <div class="mt-8 flex justify-center">
        <app-tb-button
            class="mr-2.5 w-1/2"
            [color]="cancelButtonColor"
            appearance="outlined"
            (click)="close()"
            >{{ t(data.translations.cancel || '') }}</app-tb-button
        >
        <app-tb-button [color]="confirmButtonColor" (click)="confirm()" class="ml-2.5 w-1/2">{{
            t(data.translations.confirm || '')
        }}</app-tb-button>
    </div>
</div>
