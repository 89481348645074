<ng-container *transloco="let tHeader; read: 'nav_menu'">
    @if (selectedSchema$ | async; as schema) {
        <div class="text-primaryDark headline me-1 primaryColorBG">
            {{ tHeader(schema.name) }}
        </div>
    }
    <app-tb-menu [replaceable]="true" [template]="menu" xPosition="before">
        <app-tb-button appearance="icon" icon="custom_settings" trigger></app-tb-button>
    </app-tb-menu>
    <app-tb-button (click)="newRecord()" [appearance]="'text'" [icon]="'add_circle'" class="ml-3">
        <span class="body-2">{{ tHeader('add_new') }}</span>
    </app-tb-button>
</ng-container>
<ng-template #menu let-layout let-manager="manager">
    <app-tb-menu-list
        *transloco="let tManageFields; read: 'manage_fields'"
        [containerLayout]="layout"
    >
        <app-tb-menu-list-item (click)="manageFields(manager)" icon="custom_settings">
            {{ tManageFields('manage_fields') }}
        </app-tb-menu-list-item>
    </app-tb-menu-list>
</ng-template>
