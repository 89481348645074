import { UntilDestroy } from '@ngneat/until-destroy'
import { Component } from '@angular/core'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbToggleComponent } from '@components-library/tb-toggle/tb-toggle.component'
import { MatDialogClose } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { TranslocoModule } from '@ngneat/transloco'

@UntilDestroy()
@Component({
    selector: 'app-select-views-settings',
    templateUrl: './select-views-settings.component.html',
    standalone: true,
    imports: [TranslocoModule, MatIconModule, MatDialogClose, TbToggleComponent, TbButtonComponent],
})
export class SelectViewsSettingsComponent extends ModalContainerComponent {}
