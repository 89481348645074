<div mat-dialog-content>
    <app-cell-container
        (cellValueChanged)="updateRecord(record, cells[record.guid][field.guid], $event)"
        [fieldTypes]="fieldTypes"
        [field]="field"
        [guid]="field.guid"
        [record]="record"
        [value]="newValue"
        class="flex flex-row justify-center items-center"
    ></app-cell-container>
</div>
<div mat-dialog-actions>
    <div
        class="flex flex-row justify-end items-center w-full mt-6"
        *transloco="let tButtons; read: 'buttons'"
    >
        <app-tb-button (click)="closeEditField()" class="mr-5" [appearance]="'outlined'">
            {{ tButtons('cancel') }}
        </app-tb-button>
        <app-tb-button (click)="applyEditField()"> {{ tButtons('apply') }} </app-tb-button>
    </div>
</div>
