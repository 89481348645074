<button
    class="w-inherit h-full rounded-lg transition-colors"
    [class]="getClasses()"
    (click)="toggleButton()"
    data-testid="toggle-btn"
>
    <app-tb-tooltip-component class="w-full" [tooltip]="tooltip">
        <div class="flex justify-center items-center">
            <app-tb-icon [icon]="togglingIcon" [filled]="isActive"></app-tb-icon>
            @if (label) {
                <span class="body-2 ml-2"> {{ label }} </span>
            } @else {
                <span class="body-2 ml-2">
                    <ng-content></ng-content>
                </span>
            }
        </div>
    </app-tb-tooltip-component>
</button>
