import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Input,
    OnChanges,
    OnDestroy,
    Renderer2,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { AssignFilterComponent } from '@app/views/view-controls/view-filter/filter-types/assign-filter/assign-filter.component'
import { BooleanFilterComponent } from '@app/views/view-controls/view-filter/filter-types/boolean-filter/boolean-filter.component'
import { DropdownFilterComponent } from '@app/views/view-controls/view-filter/filter-types/dropdown-filter/dropdown-filter.component'
import { EmailFilterComponent } from '@app/views/view-controls/view-filter/filter-types/email-filter/email-filter.component'
import { MoneyFilterComponent } from '@app/views/view-controls/view-filter/filter-types/money-filter/money-filter.component'
import { NumberFilterComponent } from '@app/views/view-controls/view-filter/filter-types/number-filter/number-filter.component'
import { RatingFilterComponent } from '@app/views/view-controls/view-filter/filter-types/rating-filter/rating-filter.component'
import { StatusFilterComponent } from '@app/views/view-controls/view-filter/filter-types/status-filter/status-filter.component'
import { TextOneLineFilterComponent } from '@app/views/view-controls/view-filter/filter-types/text-one-line-filter/text-one-line-filter.component'
import { UrlFilterComponent } from '@app/views/view-controls/view-filter/filter-types/url-filter/url-filter.component'

import { Field, FieldTypes } from '@core/models'

export const filterFieldTypesComponents: { [key: string]: Type<any> } = {
    [FieldTypes.TEXT]: TextOneLineFilterComponent,
    [FieldTypes.NUMBER]: NumberFilterComponent,
    [FieldTypes.STATUS]: StatusFilterComponent,
    [FieldTypes.DROPDOWN]: DropdownFilterComponent,
    [FieldTypes.ASSIGNEE]: AssignFilterComponent,
    [FieldTypes.PEOPLE]: AssignFilterComponent,
    [FieldTypes.BOOL]: BooleanFilterComponent,
    [FieldTypes.RATING]: RatingFilterComponent,
    [FieldTypes.EMAIL]: EmailFilterComponent,
    [FieldTypes.MONEY]: MoneyFilterComponent,
    [FieldTypes.MULTILINE_TEXT]: TextOneLineFilterComponent,
    [FieldTypes.NAME]: TextOneLineFilterComponent,
    [FieldTypes.WEBSITE]: UrlFilterComponent,
    [FieldTypes.WATCH]: AssignFilterComponent,
}

@Component({
    selector: 'app-filter-types-container',
    templateUrl: './filter-types-container.component.html',
    standalone: true,
})
export class FilterTypesContainerComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input()
    field!: Field

    @Input()
    form!: FormGroup

    @ViewChild('filterArea', { read: ViewContainerRef }) filterArea!: ViewContainerRef
    cmpRef!: ComponentRef<any>
    private isViewInitialized: boolean = false

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private cdr: ChangeDetectorRef,
        private _renderer: Renderer2,
    ) {}

    ngOnChanges() {
        this.updateComponent()
    }

    updateComponent() {
        if (!this.isViewInitialized) {
            return
        }
        if (this.cmpRef) {
            this.cmpRef.destroy()
        }
        const componentName = filterFieldTypesComponents[this.field.field_type_code]
        if (componentName) {
            const componentFactory =
                this.componentFactoryResolver.resolveComponentFactory(componentName)
            this.cmpRef = this.filterArea.createComponent(componentFactory)

            this.cmpRef.instance.field = this.field
            this.cmpRef.instance.form = this.form
        } else {
            console.log(this.field.field_type_code)
        }
    }

    ngAfterViewInit() {
        this.isViewInitialized = true
        this.updateComponent()
        this.cdr.detectChanges()
    }

    ngOnDestroy() {
        if (this.cmpRef) {
            this.cmpRef.destroy()
        }
    }
}
