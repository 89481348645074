import { Routes } from '@angular/router'
import ERROR_ROUTES from '@app/pages/errors/error.routes'

export const APP_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/main/main.routes'),
    },
    {
        path: '',
        children: ERROR_ROUTES,
    },
    {
        path: '**',
        redirectTo: 'view',
    },
]
