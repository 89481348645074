import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core'
import { DateService } from '@app/shared/cell-types/date.service'
import { CellComponent } from '../cell.component'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
    DaterangepickerComponent,
    DaterangepickerDirective,
    NgxDaterangepickerMd,
} from 'ngx-daterangepicker-material'
import { TranslocoService } from '@ngneat/transloco'
import { MatIconModule } from '@angular/material/icon'

type DateRange = {
    start: string
    end: string
}

@UntilDestroy()
@Component({
    selector: 'app-range',
    templateUrl: './range.component.html',
    styleUrls: ['./range.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgxDaterangepickerMd, MatIconModule],
})
export class RangeComponent extends CellComponent implements OnInit {
    dateFormat = 'MM/DD/YYYY'
    parsedValue!: DateRange

    @ViewChild(DaterangepickerDirective, { static: true })
    pickerDirective!: DaterangepickerDirective
    picker!: DaterangepickerComponent
    dateLabel!: string
    isEdit: boolean = false

    constructor(
        private translation: TranslocoService,
        private dateService: DateService,
    ) {
        super()
    }

    ngOnInit(): void {
        this.picker = this.pickerDirective.picker
        this.setDate()
    }

    filterFrom() {
        const currentDate = {
            start: this.picker.startDate.format(this.dateFormat),
            end: this.picker.endDate.format(this.dateFormat),
        }

        if (!this.value || this.currentValueNotEqualToNewValue(currentDate)) {
            this.cellValueChanged.emit(JSON.stringify(currentDate))
            return
        }
    }

    clearDate() {
        this.cellValueChanged.emit('')
        this.isEdit = true
    }

    private setDate() {
        this.translation
            .selectTranslate('cell_types.date.empty_date_label')
            .pipe(untilDestroyed(this))
            .subscribe((dateLabel) => {
                this.parsedValue = this.value ? JSON.parse(this.value) : ''
                this.picker.startDate = this.dateService.createDate(this.parsedValue.start)
                this.picker.endDate = this.dateService.createNextDayDate(this.parsedValue.end)

                this.dateLabel = this.dateService.rangeDataFormat(
                    this.dateFormat,
                    dateLabel,
                    this.parsedValue.start,
                    this.parsedValue.end,
                )
            })
    }

    private currentValueNotEqualToNewValue(currentDate: DateRange) {
        return (
            this.parsedValue.start &&
            this.parsedValue.end &&
            this.startOrEndDateChanged(currentDate)
        )
    }

    private startOrEndDateChanged(currentDate: DateRange) {
        return (
            this.parsedValue.start !== currentDate.start || this.parsedValue.end !== currentDate.end
        )
    }
}
