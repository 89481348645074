<div
    *transloco="let tCardSide; read 'card_side_content'"
    class="flex flex-col justify-center items-center h-full"
>
    <div>{{tCardSide('await_feature')}}</div>
    <div>{{tCardSide('touches')}}</div>
    <img
        alt="rocket"
        [ngSrc]="'../../../assets/img/rocket_placeholder.svg'"
        width="310"
        height="280"
    />
    <div>{{tCardSide('watch_for_updates')}}</div>
</div>
