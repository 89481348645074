import { NgIf } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbIconToggleButtonComponent } from '@components-library/tb-icon-toggle-button/tb-icon-toggle-button.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbToggleComponent } from '@components-library/tb-toggle/tb-toggle.component'
import { Field, Folder } from '@core/models'
import { FieldNameComponent } from '@shared/field-name/field-name.component'

@Component({
    selector: 'app-customize-view-item',
    templateUrl: './customize-view-item.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        FieldNameComponent,
        NgIf,
        TbIconComponent,
        TbToggleComponent,
        TbButtonComponent,
        TbIconToggleButtonComponent,
    ],
})
export class CustomizeViewItemComponent {
    @Input()
    field!: Field

    @Input()
    selectedFolder!: Folder

    @Input()
    pinned = false

    @Input({ required: true })
    toggleValue!: boolean

    @Output() toggle = new EventEmitter<boolean | null>()

    @Output() pin = new EventEmitter<void>()
}
