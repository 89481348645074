<div
    cdkDropListGroup
    class="status-container"
    *transloco="let tCellTypesStatusContent; read: 'cell_types.status_content'"
>
    <div class="status-section">
        <h4 class="mb-2.5">{{ tCellTypesStatusContent('active') }}</h4>
        <div
            (cdkDropListDropped)="drop($event, true)"
            [cdkDropListData]="statusesByTags.active"
            cdkDropList
            class="status-list"
            id="active"
        >
            @for (item of statusesByTags.active; track item) {
                <app-option-item
                    (changeIcon)="changeIcon($event, item.guid)"
                    (deleteOption)="deleteStatus($event)"
                    (optionChanged)="optionChanged($event, item.guid)"
                    (setDefault)="setDefaultOption(item.guid)"
                    [isSelected]="item.guid === activeStatus"
                    [option]="item"
                ></app-option-item>
            }
        </div>
        <!-- change to app-tb-button after new design will be implemented -->
        <button (click)="addOption()" color="primary" mat-button>
            <span class="flex flex-row justify-start items-center">
                <app-tb-icon icon="add"></app-tb-icon>
                <span> {{ tCellTypesStatusContent('add_status') }} </span>
            </span>
        </button>
    </div>
    <div class="status-section mt-5">
        <h4>{{ tCellTypesStatusContent('done') }}</h4>
        <div
            (cdkDropListDropped)="drop($event)"
            [cdkDropListData]="statusesByTags.done"
            cdkDropList
            class="status-list"
            id="done"
        >
            @for (item of statusesByTags.done; track item) {
                <app-option-item
                    (changeIcon)="changeIcon($event, item.guid)"
                    (deleteOption)="deleteStatus($event)"
                    (optionChanged)="optionChanged($event, item.guid)"
                    (setDefault)="setDefaultOption(item.guid)"
                    [isSelected]="item.guid === activeStatus"
                    [option]="item"
                ></app-option-item>
            }
        </div>
    </div>
    <div class="status-section mt-5">
        <h4>{{ tCellTypesStatusContent('closed') }}</h4>
        <div [cdkDropListDisabled]="true" cdkDropList class="status-list" id="closed">
            @for (item of statusesByTags.close; track item) {
                <app-option-item
                    (changeIcon)="changeIcon($event, item.guid)"
                    (deleteOption)="deleteStatus($event)"
                    (optionChanged)="optionChanged($event, item.guid)"
                    (setDefault)="setDefaultOption(item.guid)"
                    [isSelected]="item.guid === activeStatus"
                    [option]="item"
                >
                </app-option-item>
            }
        </div>
    </div>
</div>
