@switch (appearance) { @case ('primary') {
<button
    class="flex w-100 py-3 px-5 rounded-lg justify-center items-center sm:py-2 text-white transition-colors"
    [class]="[bgColor, 'hover:' + getDark(bgColor) ]"
>
    @if (icon) {
    <app-tb-icon [icon]="icon" color="white" class="mr-2"></app-tb-icon>
    }
    <div class="body-2 w-full">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
</button>
} @case ('iconPrimary') {
<button
    class="flex p-2 rounded-lg justify-center items-center text-white transition-colors"
    [class]="[bgColor, 'hover:' + getDark(bgColor) ]"
>
    @if (icon) {
    <app-tb-icon [icon]="icon"></app-tb-icon>
    } @else {
    <ng-content></ng-content>
    }
</button>
} @case ('outlined') {
<button
    class="flex w-100 py-3 px-5 rounded-lg justify-center items-center sm:py-2 transition-colors border border-solid"
    [class]="getOutlinedClasses()"
>
    @if (icon) {
    <app-tb-icon [icon]="icon" class="mr-2"></app-tb-icon>
    }
    <div class="body-2 w-full">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
</button>
} @case ('text') {
<button
    class="flex w-100 py-1 px-2 rounded-lg justify-center items-center transition-colors"
    [class]="getTextClasses()"
>
    @if (icon) {
    <app-tb-icon [icon]="icon" class="mr-2"></app-tb-icon>
    }
    <div class="body-2 w-full">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
</button>
} @case ('link') {
<button
    [class]="[disabled ? textColor : getLight(textColor), 'hover:' + textColor]"
    class="underline inline-flex"
>
    <div class="body-2 w-full">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
</button>
} @case ('icon') {
<button
    class="flex justify-center items-center p-0.5 rounded-lg cursor-pointer w-full h-full"
    [class]="getIconClasses()"
>
    @if (icon) {
    <app-tb-icon [color]="iconColor" [icon]="icon"></app-tb-icon>
    } @else {
    <ng-content></ng-content>
    }
</button>
} @case ('fab') {
<div class="relative p-1">
    <button
        class="flex rounded-full cursor-pointer transition-colors"
        [class]="[bgColor, 'hover:' + getDark(bgColor)]"
    >
        <app-tb-icon color="white" class="icon-lg" [icon]="icon"></app-tb-icon>
    </button>
</div>
} }
<ng-template #content>
    <ng-content></ng-content>
</ng-template>
