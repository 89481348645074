import { Component, ElementRef, OnInit } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { BaseCellComponent } from '@app/feature/input-cells/base-container-content'
import { getBoolean } from '@core/global-util'
import { TranslocoService } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import dayjs from 'dayjs'

export enum DateFormat {
    DATE = 'MMM DD',
    DATE_TIME = 'MMM DD, hh:mm A',
    TIME = 'hh:mm A',
}

export enum DateType {
    SINGLE = 'single',
    RANGE = 'range',
}

type DateRange = {
    start: string
    end: string
}

@UntilDestroy()
@Component({
    template: '',
})
export abstract class BaseDateComponent extends BaseCellComponent implements OnInit {
    protected dateFormat!: DateFormat

    protected dateType!: DateType

    abstract get inputElement(): ElementRef

    compareValue: string | null = null

    control = new FormControl<string | null>(null)

    dateLabel!: string

    get errorState() {
        return this.control.invalid && this.touched
    }

    get loadingState() {
        return this.loading
    }

    constructor(dateFormat: DateFormat, dateType: DateType) {
        super()
        this.dateFormat = dateFormat
        this.dateType = dateType
    }

    ngOnInit(): void {
        this.translation
            .selectTranslate('cell_types.date.empty_date_label')
            .pipe(untilDestroyed(this))
            .subscribe((label) => {
                this.dateLabel = label
                this.control.setValue(this.value ? this.formatByType() : this.dateLabel)

                this.mergeValidators(this.control)

                this.compareValue = this.control.value

                if (this.disabled) {
                    this.control.disable()
                }

                if (getBoolean(this.field.is_readonly)) {
                    this.readonly = true
                    this.inputContainer.readonly = true
                }

                this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
                    this.value = value || ''
                })
            })
    }

    emitValue(): void {
        const value = this.control.value
        if (this.compareValue === value) return

        this.compareValue = value
        this.valueChange.emit(value || '')
        this.onChange(value)
    }

    onContainerClick(): void {
        if (!this.readonly) {
            this.inputElement.nativeElement.focus()
        }
    }

    onOutsideClick(): void {}

    removeFocus() {
        this.makeTouchedAndUnfocused()
        this.inputElement.nativeElement.blur()
    }

    resetValue() {
        this.control.setValue(this.compareValue)
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled

        isDisabled ? this.control.disable() : this.control.enable()
    }

    onFocusIn() {
        if (!this.focused && !this.readonly) {
            this.focused = true
            this.inputContainer.makeFocused()
        }
    }

    onFocusOut(event: FocusEvent) {
        const contains = this.inputContainer.elementRef.nativeElement.contains(
            event.relatedTarget as Element,
        )

        if (this.inputContainer.editControls && !contains) {
            return
        }

        if (!contains) {
            !this.control.invalid && this.emitValue()
            this.makeTouchedAndUnfocused()
        }
    }

    writeValue(value: any): void {
        this.value = value
        this.control.setValue(value || null)
        this.compareValue = this.control.value
    }

    private formatByType() {
        if (this.dateType === DateType.SINGLE) {
            return this.localizeAndFormat(this.value, this.dateFormat)
        }

        return this.rangeDataFormat(this.dateFormat, this.value ? JSON.parse(this.value) : null)
    }

    private rangeDataFormat(formatString: string, rangeDate?: DateRange) {
        if (!rangeDate) return this.dateLabel

        return `${this.createDate(rangeDate.start).format(formatString)} - ${this.createDate(
            rangeDate.end,
        ).format(formatString)}`
    }

    private localizeAndFormat(date: string, formatString: string) {
        return this.createDate(date).format(formatString)
    }

    private createDate(date?: string) {
        const dateUtc = date ? dayjs.utc(date) : dayjs.utc()
        return dateUtc.local()
    }

    onFocusClick(): void {
        if (this.disabled || this.readonly || this.loadingState) return

        this.makeFocused()
    }
}
