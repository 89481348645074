import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ValidatorFn } from '@angular/forms'
import { AppRecord, Field, FieldType, ValueJson } from '@core/models'

@Component({
    template: '',
})
export abstract class CellComponent {
    @Input()
    value!: string

    @Input()
    valueJson!: ValueJson

    @Input()
    validatorOrOpts?: ValidatorFn[]

    @Input()
    field!: Field

    @Input()
    fieldType?: FieldType

    @Input()
    record!: AppRecord | undefined

    @Input()
    disabled!: boolean

    @Input()
    isCard?: boolean

    @Output()
    cellValueChanged: EventEmitter<any> = new EventEmitter<any>()
}
