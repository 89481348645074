import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { BaseCellComponent } from '@app/feature/input-cells/base-container-content'
import { BaseInputCellComponent } from '@app/feature/input-cells/base-input-cell/base-input-cell.component'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-number-cell',
    standalone: true,
    imports: [ReactiveFormsModule],
    templateUrl: './number-cell.component.html',
    styleUrl: './number-cell.component.sass',
})
export class NumberCellComponent extends BaseInputCellComponent<number> implements OnInit {
    @ViewChild('input')
    _inputElement!: ElementRef<HTMLElement>

    transformedValue(): number {
        return Number(this.value)
    }

    get lessThanZero() {
        return this.control.value && this.control.value < 0
    }

    get inputElement() {
        return this._inputElement
    }
}
