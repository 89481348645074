import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { CellComponent } from '../../cell.component'
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms'
import { Field } from '@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TranslocoModule } from '@ngneat/transloco'

@UntilDestroy()
@Component({
    selector: 'app-text-one-line-settings',
    templateUrl: './text-one-line-settings.component.html',
    styleUrls: ['./text-one-line-settings.component.sass'],
    standalone: true,
    imports: [TranslocoModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule],
})
export class TextOneLineSettingsComponent extends CellComponent implements OnInit {
    @Output()
    fieldChanged: EventEmitter<Field> = new EventEmitter<Field>()

    @Output()
    valid: EventEmitter<boolean> = new EventEmitter<boolean>()

    formGroup!: FormGroup<{
        minLength: FormControl<number | null>
        maxLength: FormControl<number | null>
    }>

    ngOnInit(): void {
        this.setFormControlValueAndValidation()

        this.setMinLengthValidation()

        this.detectGroupValueChange()
    }

    private setFormControlValueAndValidation() {
        const maxLength = this.parseValue('maxLength')
        this.formGroup = new FormGroup({
            minLength: new FormControl<number | null>(
                this.parseValue('minLength'),
                this.initialMinValidators(maxLength),
            ),
            maxLength: new FormControl<number | null>(maxLength, Validators.min(1)),
        })
    }

    private parseValue(key: string) {
        return this.field.configure_json ? Number(JSON.parse(this.field.configure_json)[key]) : null
    }

    private initialMinValidators(maxLength: number | null) {
        return maxLength ? [Validators.min(0), Validators.max(maxLength)] : Validators.min(0)
    }

    private setMinLengthValidation() {
        this.formGroup.controls.maxLength.valueChanges.subscribe((value) => {
            this.formGroup.controls.minLength.clearValidators()
            this.formGroup.controls.minLength.addValidators([
                Validators.min(0),
                Validators.max(value ?? 0),
            ])
            this.formGroup.controls.minLength.updateValueAndValidity()
        })
    }

    private detectGroupValueChange() {
        this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.fieldChanged.emit({
                ...this.field,
                configure_json: JSON.stringify({
                    minLength: value.minLength !== null ? value.minLength : '',
                    maxLength: value.maxLength !== null ? value.maxLength : '',
                }),
            })

            this.valid.emit(this.formGroup.valid)
        })
    }
}
