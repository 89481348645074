<ng-container *transloco="let tSort; read: 'sort'">
    <app-modal-layout
        [containerLayout]="containerLayout"
        [showLoader]="showLoader"
        [errors]="errors"
        (keydown.tab)="$event.stopPropagation()"
        (closeModal)="forceWithValidation()"
    >
        <ng-container title>
            <span class="text-primaryDark body-2">{{ tSort('sort_by') }}</span>
        </ng-container>
        <div
            (click)="$event.stopPropagation()"
            [cdkDropListData]="sortObjectsArray"
            (cdkDropListDropped)="drop($event)"
            cdkDropList
            class="sort-container max-sm:mt-4 md:w-[480px] filter-area body-1 leading-4"
        >
            <div class="flex justify-end items-center mb-4">
                @if (isSortDefault()) {
                    <div
                        (click)="onClear()"
                        class="flex items-center cursor-pointer text-secondaryMainLight hover:text-secondaryMain body-2"
                    >
                        <span>{{ tSort('clear_all').toUpperCase() }}</span>
                    </div>
                }
            </div>
            @if (sortObjectsArray.length) {
                <form [formGroup]="sortMenuForm">
                    @if (selectedFolder$ | async; as selectedFolder) {
                        @for (object of sortObjectsArray; track object; let index = $index) {
                            <app-sort-item
                                [fields]="fieldsForSortItem(object)"
                                [selectedFolder]="selectedFolder"
                                [sortObject]="object"
                                [sortGroup]="getSortItemGroup(object.fieldGuid)"
                                (changed)="updateSortObjects($event, index)"
                                (removed)="removeSortObject($event, index)"
                            ></app-sort-item>
                        }
                    } @else {
                        <span>some error message</span>
                    }
                </form>
            }
            <app-tb-button
                (click)="addSort()"
                [appearance]="'text'"
                [icon]="'add_circle_outline'"
                class="p-1 mb-4"
            >
                <span>{{ tSort('add_sort') }}</span>
            </app-tb-button>
        </div>
        <ng-container buttons>
            <div *transloco="let tButtons; read: 'buttons'" class="flex justify-end items-center">
                <app-tb-button
                    appearance="outlined"
                    (click)="forceWithValidation()"
                    class="mr-10 whitespace-nowrap"
                >
                    <span class="body-2">{{ tButtons('cancel') }}</span>
                </app-tb-button>
                <div class="flex gap-1">
                    <app-tb-button appearance="primary" (click)="preview()">
                        {{ tButtons('preview') }}
                    </app-tb-button>
                    <app-tb-menu [template]="saveMenu" #tbMenu>
                        <app-tb-button
                            trigger
                            appearance="iconPrimary"
                            [icon]="tbMenu.menuOpen ? 'expand_less' : 'expand_more'"
                        ></app-tb-button>
                    </app-tb-menu>
                </div>
                <ng-template #saveMenu>
                    <app-tb-menu-list>
                        <app-tb-menu-list-item (click)="resetToSave()">{{
                            tButtons('reset_to_saved')
                        }}</app-tb-menu-list-item>
                        <app-tb-menu-list-item (click)="resetToDefault()">{{
                            tButtons('reset_to_default')
                        }}</app-tb-menu-list-item>
                        <app-tb-divider></app-tb-divider>
                        <app-tb-menu-list-item (click)="save()">{{
                            tButtons('save')
                        }}</app-tb-menu-list-item>
                    </app-tb-menu-list>
                </ng-template>
            </div>
        </ng-container>
    </app-modal-layout>
</ng-container>
<!-- TODO: default error message, correct will be added later -->
<ng-template #error>
    <span>some error message</span>
</ng-template>
