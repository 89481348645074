import { AccessModel } from './access.model'
import { FieldGuid, RequestCreateCell, RequestUpdateCell } from './common.model'

export interface RequestViewCreateModel {
    parent_sot_guid: string
    folder_guid: string
    configuration: Record<FieldGuid, RequestCreateCell>
    access?: AccessModel
}

export interface RequestViewDeleteModel {
    record_guid: string
    record_revision: number
}

export interface RequestViewUpdateModel {
    record_guid: string
    record_revision: number
    configuration?: Record<FieldGuid, RequestUpdateCell>
    access?: AccessModel
    folder_guid: string
}
