import {
    AfterViewInit,
    ApplicationRef,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    createComponent,
    Input,
    OnChanges,
    OnDestroy,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { InputCellContainerComponent } from '@app/feature/input-cells/input-cell-container/input-cell-container.component'
import { BooleanFilterComponent } from '@app/views/view-controls/view-filter/filter-types/boolean-filter/boolean-filter.component'
import { DropdownFilterComponent } from '@app/views/view-controls/view-filter/filter-types/dropdown-filter/dropdown-filter.component'
import { EmailFilterComponent } from '@app/views/view-controls/view-filter/filter-types/email-filter/email-filter.component'
import { MoneyFilterComponent } from '@app/views/view-controls/view-filter/filter-types/money-filter/money-filter.component'
import { RatingFilterComponent } from '@app/views/view-controls/view-filter/filter-types/rating-filter/rating-filter.component'
import { StatusFilterComponent } from '@app/views/view-controls/view-filter/filter-types/status-filter/status-filter.component'
import { UrlFilterComponent } from '@app/views/view-controls/view-filter/filter-types/url-filter/url-filter.component'

import { Field, FieldTypes } from '@core/models'
import { InputContainerAppearance } from '@models/input'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

export const filterFieldTypesComponents: { [key: string]: Type<any> } = {
    [FieldTypes.STATUS]: StatusFilterComponent,
    [FieldTypes.DROPDOWN]: DropdownFilterComponent,
    [FieldTypes.BOOL]: BooleanFilterComponent,
    [FieldTypes.RATING]: RatingFilterComponent,
    [FieldTypes.EMAIL]: EmailFilterComponent,
    [FieldTypes.MONEY]: MoneyFilterComponent,
    [FieldTypes.WEBSITE]: UrlFilterComponent,
}

const NEW_FILTER_COMPONENTS: string[] = [
    FieldTypes.NUMBER,
    FieldTypes.ASSIGNEE,
    FieldTypes.PEOPLE,
    FieldTypes.WATCH,
    FieldTypes.TEXT,
    FieldTypes.MULTILINE_TEXT,
    FieldTypes.RICH_TEXT,
    FieldTypes.NAME,
    FieldTypes.LINK_REFERENCE,
]

@UntilDestroy()
@Component({
    selector: 'app-filter-types-container',
    templateUrl: './filter-types-container.component.html',
    standalone: true,
})
export class FilterTypesContainerComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input()
    field!: Field

    @Input()
    form!: FormGroup

    @ViewChild('filterArea', { read: ViewContainerRef }) filterArea!: ViewContainerRef
    cmpRef!: ComponentRef<any>
    private isViewInitialized: boolean = false

    constructor(
        private cdr: ChangeDetectorRef,
        private appRef: ApplicationRef,
    ) {}

    ngOnChanges() {
        this.updateComponent()
    }

    updateComponent() {
        if (!this.isViewInitialized) {
            return
        }

        if (NEW_FILTER_COMPONENTS.includes(this.field.field_type_code)) {
            this.handleUpdatedComponent()
            return
        }

        this.handleOldComponent()
    }

    ngAfterViewInit() {
        this.isViewInitialized = true
        this.updateComponent()
        this.cdr.detectChanges()
    }

    ngOnDestroy() {
        this.destroy()
    }

    private handleUpdatedComponent() {
        if (!this.cmpRef) {
            this.cmpRef = createComponent(InputCellContainerComponent, {
                environmentInjector: this.appRef.injector,
            })

            this.cmpRef.instance.valueChange
                .pipe(untilDestroyed(this))
                .subscribe((value: string) => {
                    this.form.controls['value'].setValue(value)
                })

            this.filterArea.insert(this.cmpRef.hostView)
        }

        this.cmpRef.setInput('value', this.form.controls['value'].value)
        this.cmpRef.setInput('field', this.field)
        this.cmpRef.setInput('appearance', InputContainerAppearance.filter)
        this.cdr.detectChanges()
    }

    private handleOldComponent() {
        this.destroy()

        const componentType = filterFieldTypesComponents[this.field.field_type_code]

        if (!componentType) {
            console.log(new Error(`unknown field type ${this.field.field_type_code}`))
            return
        }

        this.cmpRef = this.filterArea.createComponent(componentType)

        this.cmpRef.instance.field = this.field
        this.cmpRef.instance.form = this.form
    }

    private destroy() {
        if (this.cmpRef) {
            this.cmpRef.destroy()
        }
    }
}
