import { Component, Input } from '@angular/core'
import { Field } from '@core/models'
import { FormGroup } from '@angular/forms'

@Component({
    template: '',
})
export abstract class FilterTypesComponent {
    @Input()
    field!: Field

    @Input()
    form!: FormGroup

    value!: string
}
