import { Component, Input } from '@angular/core'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { UserStatus } from '@core/models'

// TODO: add @Input [user] instead of passing every property separately
@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.sass'],
    standalone: true,
    imports: [TbIconComponent],
})
export class UserAvatarComponent {
    @Input()
    userUrl!: string | undefined

    @Input()
    userName!: string

    @Input()
    status?: keyof typeof UserStatus | string

    @Input()
    unknown = false

    @Input()
    small: boolean = false

    @Input()
    border: boolean = false

    isImgUnavailable = false

    imgUnavailable() {
        this.isImgUnavailable = true
    }

    get avatarClass(): string {
        const borderStyle = this.border ? 'border border-newNeutral1' : ''
        const online = this.status === UserStatus.Online ? 'online' : ''
        const sizeClass = this.small ? 'h-5 w-5 ' : 'h-6 w-6'

        return `${sizeClass} ${borderStyle} ${online}`
    }

    get iconBgClass() {
        const bgClass = []
        if (this.unknown) {
            bgClass.push('bg-newTextLight')
        } else {
            bgClass.push('bg-newPrimaryLight')
        }

        if (this.small) {
            bgClass.push('w-5')
            bgClass.push('h-5')
        } else {
            bgClass.push('w-6')
            bgClass.push('h-6')
        }

        return bgClass
    }
}
