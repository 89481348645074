import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-filter-combination-toggle',
    templateUrl: './filter-combination-toggle.component.html',
    styleUrls: ['./filter-combination-toggle.component.sass'],
    standalone: true,
    imports: [TranslocoModule, MatButtonToggleModule],
})
export class FilterCombinationToggleComponent implements OnInit {
    @Input()
    combinationTypes: Record<string, string> = {}

    @Input()
    translateKey: string = 'filter.combination_types'

    @Input()
    value?: string

    @Input()
    disabled: boolean = false

    @Output() valueChange = new EventEmitter<string>()

    get combinationTypesKeys() {
        return Object.keys(this.combinationTypes)
    }

    ngOnInit() {
        if (!this.value) {
            this.value = this.combinationTypes[this.combinationTypesKeys[0]]
        }
    }

    toggle() {
        if (this.disabled) {
            return
        }
        this.value = Object.values(this.combinationTypes).find((type) => this.value !== type)
        if (this.value) {
            this.valueChange.emit(this.value)
        }
    }
}
