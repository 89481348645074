import { UntilDestroy } from '@ngneat/until-destroy'
import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

@UntilDestroy()
@Component({
    selector: 'app-field-configuration-selector',
    templateUrl: './field-configuration-selector.component.html',
    standalone: true,
    imports: [MatIconModule],
})
export class FieldConfigurationSelectorComponent {
    @Input() icon!: string
    @Input() customIcon!: string
    @Input() isSelected!: boolean
    @Input() name!: string
}
