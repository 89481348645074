import { Injectable } from '@angular/core'
import { compare } from '@core/global-util'
import {
    BusinessRecords,
    CellEntities,
    Field,
    FieldEntities,
    FieldTypes,
    UserEntities,
    View,
} from '@core/models'
import { SortDirection, SortObject, SortObjectEntities } from '@core/models/ui/sort.model'
import { SortStorageService } from '@core/services/local-storage/sort-storage.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SchemaFacadeService, UserFacadeService } from '@services/store-facade'
import { isProd } from '@test/dev-utils'
import { cloneDeep } from 'lodash-es'
import { BehaviorSubject } from 'rxjs'

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class ViewSortService {
    private readonly sortGuidFromColumn$$ = new BehaviorSubject<string | null>(null)
    getSortGuidFromColumn$ = this.sortGuidFromColumn$$.asObservable()

    fieldEntities!: FieldEntities
    userEntities!: UserEntities

    constructor(
        private sortStorageService: SortStorageService,
        private userFacadeService: UserFacadeService,
        private schemaFacadeService: SchemaFacadeService,
    ) {
        this.schemaFacadeService.selectSelectedTableSchemaFieldEntities$
            .pipe(untilDestroyed(this))
            .subscribe((fieldEntities) => (this.fieldEntities = fieldEntities ?? {}))

        this.userFacadeService.userEntities$
            .pipe(untilDestroyed(this))
            .subscribe((userEntities) => (this.userEntities = userEntities))
    }

    applySortToArray(
        records: BusinessRecords[],
        cells: { [recordGuid: string]: CellEntities },
        sortObjects: SortObject[],
    ) {
        return cloneDeep(records).sort((a, b) => {
            return this.compareBySortObject(cells[a.guid], cells[b.guid], sortObjects)
        })
    }

    getSortByView(view: View): SortObjectEntities {
        const sortValue = this.sortStorageService.get(view.guid)
        if (sortValue) return sortValue

        return view.sort.value.length ? JSON.parse(view.sort.value) : {}
    }

    setSortToStorage(viewGuid: string, sortEntity: SortObjectEntities) {
        this.sortStorageService.set(viewGuid, sortEntity)
    }

    updateSortMenuFromColumnData(guid: string) {
        this.sortGuidFromColumn$$.next(guid)
    }

    clearSortGuid() {
        this.sortGuidFromColumn$$.next(null)
    }

    isSortValueString(field: Field) {
        const fieldsWithStringSort = [
            FieldTypes.ASSIGNEE,
            FieldTypes.PEOPLE,
            FieldTypes.DROPDOWN,
            FieldTypes.STATUS,
            FieldTypes.EMAIL,
            FieldTypes.MULTILINE_TEXT,
            FieldTypes.TEXT,
            FieldTypes.NAME,
            FieldTypes.WEBSITE,
        ]

        return fieldsWithStringSort.includes(field.field_type_code as FieldTypes)
    }

    filterAllowedSortFieldsByType(fields: FieldEntities) {
        return Object.values(fields).filter((field) => {
            return this.isFieldAvailableForSorting(field)
        })
    }

    private isFieldAvailableForSorting(field: Field) {
        if (isProd()) {
            const prodAvailableFieldTypes = [
                FieldTypes.ASSIGNEE,
                FieldTypes.PEOPLE,
                FieldTypes.NAME,
                FieldTypes.NUMBER,
                FieldTypes.TEXT,
                FieldTypes.MULTILINE_TEXT,
                FieldTypes.STATUS,
            ]

            return prodAvailableFieldTypes.includes(field.field_type_code as FieldTypes)
        }

        const disabledForSortTypes = [FieldTypes.WATCH, FieldTypes.LINK, FieldTypes.RICH_TEXT]

        return !disabledForSortTypes.includes(field.field_type_code as FieldTypes)
    }

    private compareBySortObject(a: CellEntities, b: CellEntities, sortObjects: SortObject[]) {
        let compareResult = 0
        sortObjects.forEach((sortObject) => {
            if (!compareResult) {
                const isAsc = sortObject.sortDirection === SortDirection.ASC
                const compared = compare(
                    this.getValueForTypeSpecificSort(
                        sortObject.fieldGuid,
                        a[sortObject.fieldGuid].value,
                    ),
                    this.getValueForTypeSpecificSort(
                        sortObject.fieldGuid,
                        b[sortObject.fieldGuid].value,
                    ),
                    isAsc,
                )
                if (compared !== 0) {
                    compareResult = compared
                }
            }
        })
        return compareResult
    }

    private getValueForTypeSpecificSort(guid: string, value: string) {
        const field = this.fieldEntities[guid]

        if (!field) return value

        switch (this.fieldEntities[guid].field_type_code) {
            case FieldTypes.PEOPLE:
            case FieldTypes.ASSIGNEE:
                return this.getValueForPeopleSort(value)
            case FieldTypes.STATUS:
                return this.getValueForSelectSort(field, value)
            default:
                return value
        }
    }

    private getValueForSelectSort(field: Field, optionGuid: string) {
        return field.select_object_field![optionGuid].label
    }

    private getValueForPeopleSort(usersGuids: string) {
        if (!usersGuids.length) return usersGuids

        const usersGuidsArray = usersGuids.split(',')
        return usersGuidsArray.map((userGuid) => this.userEntities[userGuid]?.fullName).join(',')
    }
}
