import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MatListModule } from '@angular/material/list'
import { SearchResultsComponent } from '../search-results/search-results.component'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TranslocoModule } from '@ngneat/transloco'

@UntilDestroy()
@Component({
    selector: 'app-search-dialog',
    templateUrl: './search-dialog.component.html',
    styleUrls: ['./search-dialog.component.sass'],
    standalone: true,
    imports: [
        TranslocoModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonToggleModule,
        SearchResultsComponent,
        MatListModule,
    ],
})
export class SearchDialogComponent implements OnInit {
    searchValue?: string | null
    searchFilterValue?: string | null

    formGroup!: FormGroup<{
        searchInput: FormControl<string | null | undefined>
        searchFilter: FormControl<string | null | undefined>
    }>

    ngOnInit() {
        this.formGroup = new FormGroup({
            searchInput: new FormControl<string | null | undefined>(''),
            searchFilter: new FormControl<string | null | undefined>(''),
        })
        this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((event) => {
            this.searchValue = event.searchInput
            this.searchFilterValue = event.searchFilter
        })
    }
}
