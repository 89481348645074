import { Injectable } from '@angular/core'
import {
    AppState,
    createAutomation,
    deleteAutomation,
    executeAutomation,
    updateAutomation,
} from '@core/@ngrx'
import {
    AutomationCreateModel,
    AutomationDeleteModel,
    AutomationExecuteModel,
    AutomationUpdateModel,
} from '@models/ui/automation.model'
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { UpdateService } from '@services/update.service'
import { of } from 'rxjs'
import { catchError, mergeMap, switchMap, take, tap } from 'rxjs/operators'
import { BaseResponseModel } from '../../models'
import { onMessage, setError, setLoading } from '../common'

@Injectable()
export class AutomationEffects implements OnInitEffects {
    executeAutomation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(executeAutomation),
            tap(() => {
                console.log('executeAutomation$ Effect')
                this.store.dispatch(setLoading({ loading: true }))
            }),
            switchMap((action: { automations: AutomationExecuteModel }) => {
                console.log('executeAutomation$ Effect switchMap')
                return this.updateService.executeAutomation(action.automations).pipe(
                    take(1),
                    mergeMap((data: BaseResponseModel) => {
                        return [onMessage({ response: data }), setLoading({ loading: false })]
                    }),
                )
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    createAutomation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createAutomation),
            tap(() => {
                console.log('createAutomation$ Effect')
                this.store.dispatch(setLoading({ loading: true }))
            }),
            switchMap((action: { automation: AutomationCreateModel }) => {
                console.log('createAutomation$ Effect switchMap')
                return this.updateService.createAutomation(action.automation).pipe(
                    take(1),
                    mergeMap((data: BaseResponseModel) => {
                        return [onMessage({ response: data }), setLoading({ loading: false })]
                    }),
                )
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    updateAutomation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateAutomation),
            tap(() => {
                console.log('updateAutomation$ Effect')
                this.store.dispatch(setLoading({ loading: true }))
            }),
            switchMap((action: { automation: AutomationUpdateModel }) => {
                console.log('updateAutomation$ Effect switchMap')
                return this.updateService.updateAutomation(action.automation).pipe(
                    take(1),
                    mergeMap((data: BaseResponseModel) => {
                        return [onMessage({ response: data }), setLoading({ loading: false })]
                    }),
                )
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    deleteAutomation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteAutomation),
            tap(() => {
                console.log('deleteAutomation$ Effect')
                this.store.dispatch(setLoading({ loading: true }))
            }),
            switchMap((action: { automation: AutomationDeleteModel }) => {
                console.log('deleteAutomation$ Effect switchMap')
                return this.updateService.deleteAutomation(action.automation).pipe(
                    take(1),
                    mergeMap((data: BaseResponseModel) => {
                        return [onMessage({ response: data }), setLoading({ loading: false })]
                    }),
                )
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private updateService: UpdateService,
    ) {}

    ngrxOnInitEffects(): Action {
        return { type: '[AutomationEffects]: Init' }
    }
}
