import { Component, Input } from '@angular/core'
import { Field, FieldType } from '@core/models'
import { BooleanComponent } from '../cell-types/boolean/boolean.component'
import { DropdownComponent } from '../cell-types/select/dropdown/dropdown.component'
import { RatingComponent } from '../cell-types/rating/rating.component'
import { AssignComponent } from '../cell-types/people/assign/assign.component'
import { StatusComponent } from '../cell-types/select/status/status.component'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-grouping-values',
    templateUrl: './grouping-values.component.html',
    styleUrls: ['./grouping-values.component.sass'],
    standalone: true,
    imports: [
        TranslocoModule,
        StatusComponent,
        AssignComponent,
        RatingComponent,
        DropdownComponent,
        BooleanComponent,
    ],
})
export class GroupingValuesComponent {
    @Input() fieldType?: FieldType
    @Input() field!: Field
    @Input() value!: string
    @Input() isCollapsed!: boolean
    constructor() {}

    isAssignee() {
        return this.field?.field_type_code === 'field_type_assignee'
    }

    isNoGrouped() {
        return this.value === 'NO_GROUPED_RECORDS_KEY'
    }
}
