import { Component, Inject, Input } from '@angular/core'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import {
    ModalContainer,
    ModalManagerService,
} from '@components-library/tb-modal-manager/modal-manager.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { finalize, Observable } from 'rxjs'
import { take, tap } from 'rxjs/operators'
import { ConfirmationDialogService } from '../../services/confirmation-dialog.service'
import { CommonDialogResultStatus } from '../../tb-confirmation-popup/tb-confirmation-dialog.component'

/**
 * ModalContainerComponent is a base component for all Modal Containers. It contains base methods for closing and opening Confirmation Dialog.
 *
 * This component is extended by other components that will be opened in a Dialog or Menu.
 **/
@UntilDestroy()
@Component({
    template: '',
})
export abstract class ModalContainerComponent<ContainerData = unknown, Result = unknown> {
    /**
     * containerLayout tells what layout is actual at the moment. It is changed during Transformation.
     **/
    @Input()
    containerLayout!: ModalContainer

    ModalContainer = ModalContainer

    showLoader: boolean = false
    errors?: string[]

    constructor(
        @Inject(ModalContainerDataToken) protected data: ContainerData,
        protected modalManagerService: ModalManagerService,
        protected confirmationDialogService: ConfirmationDialogService,
    ) {}

    /**
     * It closes current Modal Container and go back to the previous if it in the stack.
     **/
    close(data?: Result) {
        this.modalManagerService.close(data)
    }

    /**
     * It closes Modal Flow. Should be called when we want to close current Modal Flow.
     * It is useful when we pick "Close" flow on the Confirmation Dialog.
     **/
    forceClose() {
        this.modalManagerService.closeFlow()
    }

    /**
     * It opens Confirmation Dialog if Modal Flow has changes. If it doesn't have changes we close the Modal Flow.
     * It is called when we click on "Cross" icon-button in dialog.
     **/
    forceWithValidation() {
        if (this.modalManagerService.shouldOpenConfirmation()) {
            this.openConfirmationDialog()
        } else {
            this.forceClose()
        }
    }

    /**
     * This function is called when we need to go to the previous Modal Container. It verifies whether the component has some changes and if it does it opens a Confirmation Popup.
     **/
    goBack(data?: Result) {
        if (this.hasChanged()) {
            this.openBackConfirmationDialog()
        } else {
            this.close(data)
        }
    }

    /**
     * If it is "true" current container is opened by Navigation.
     *
     * Useful when we should show some logic based on this condition,
     * for example show "Back" button instead of "Cancel"
     **/
    get isFlowChild() {
        return this.modalManagerService.isCurrentContainerIsAChild()
    }

    /**
     * This function indicates that container has some changes and if it has we show Confirmation Dialog.
     * By default, it is "false", so you should override this function in a Container.
     **/
    hasChanged() {
        return false
    }

    /**
     * This function opens Confirmation Dialog. Default implementation has some common translations to use,
     * so if you need some specific Confirmation Dialog, you should override the method.
     **/
    openConfirmationDialog() {
        this.confirmationDialogService
            .openCommon({
                translations: {
                    title: 'confirmation_dialog.discard_changes',
                    message: 'confirmation_dialog.unsaved_data_message',
                    cancel: 'confirmation_dialog.close_anyway',
                    confirm: 'confirmation_dialog.keep_editing',
                },
            })
            .subscribe((result) => {
                if (result === CommonDialogResultStatus.CANCEL) {
                    this.forceClose()
                }
            })
    }

    /**
     * This function opens Confirmation Dialog. It is called when we call goBack() function. Default implementation has some common translations to use,
     * so if you need some specific Confirmation Dialog, you should override the method.
     **/
    openBackConfirmationDialog(data?: Result) {
        this.confirmationDialogService
            .openCommon({
                translations: {
                    title: 'confirmation_dialog.discard_changes',
                    message: 'confirmation_dialog.back_unsaved_data_message',
                    cancel: 'confirmation_dialog.back_anyway',
                    confirm: 'confirmation_dialog.keep_editing',
                },
            })
            .subscribe((result) => {
                if (result === CommonDialogResultStatus.CANCEL) {
                    this.close(data)
                }
            })
    }

    /**
     * This function is used to process observable of generic type received on response from backend.
     * It should trigger (show/hide) loader and process error responses
     **/

    wrapResponseObservable<T>(observable: Observable<T>) {
        this.showLoader = true
        this.errors = []

        return observable.pipe(
            take(1),
            untilDestroyed(this),
            tap({
                error: (error: string | string[]) => {
                    this.errors = typeof error === 'string' ? [error] : error
                },
            }),
            finalize(() => (this.showLoader = false)),
        )
    }
}
