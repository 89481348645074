<ng-container *transloco="let tCardSubtasks; read: 'card_subtasks'">
    @if (!createMode) {
        <div>
            <app-tb-button [icon]="'add'" (click)="onCreateClick()" appearance="text">{{
                tCardSubtasks('create_subtask_title')
            }}</app-tb-button>
        </div>
    } @else {
        <div class="flex flex-row items-center justify-between create-row" [formGroup]="form">
            <mat-form-field>
                <input
                    [appAutoFocus]="true"
                    formControlName="name"
                    placeholder="name"
                    matInput
                    required
                />
            </mat-form-field>
            <div class="flex flex-row gap-2">
                <app-tb-button (click)="onSubmit()" appearance="text" [disabled]="form.invalid">{{
                    tCardSubtasks('create_subtask_save')
                }}</app-tb-button>
                <app-tb-button
                    (click)="onDismiss()"
                    appearance="text"
                    icon="cancel"
                ></app-tb-button>
            </div>
        </div>
    }
</ng-container>
