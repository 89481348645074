import { Component, Input } from '@angular/core'
import { TbIconComponent } from '../tb-icon/tb-icon.component'

@Component({
    selector: 'app-tb-menu-list-item',
    templateUrl: './tb-menu-list-item.component.html',
    styleUrls: ['./tb-menu-list-item.component.sass'],
    standalone: true,
    imports: [TbIconComponent],
})
export class TbMenuListItemComponent {
    @Input()
    icon?: string

    @Input()
    color?: string

    @Input()
    size?: string

    get textColor() {
        return `text-${this.color ? this.color : 'inherit'}`
    }
    get textSize() {
        return this.size ? this.size : 'body-1'
    }
}
