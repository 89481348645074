import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'
import { MatInput, MatInputModule } from '@angular/material/input'
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms'
import { ColorPickerComponent } from '@app/shared/color-picker/color-picker.component'
import { IconPickerComponent } from '@app/shared/icon-picker/icon-picker.component'
import { SetType } from '@app/shared/models/picker'
import { SelectOption } from '@core/models/response/select-object-options'
import { MatDialog } from '@angular/material/dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslocoModule } from '@ngneat/transloco'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { MatMenuModule } from '@angular/material/menu'
import { MatButtonModule } from '@angular/material/button'
import { IconPickerComponent as IconPickerComponent_1 } from '../../icon-picker/icon-picker.component'
import { ColorPickerComponent as ColorPickerComponent_1 } from '../../color-picker/color-picker.component'
import { MatIconModule } from '@angular/material/icon'
import { CdkDrag } from '@angular/cdk/drag-drop'
import { NgTemplateOutlet } from '@angular/common'

@UntilDestroy()
@Component({
    selector: 'app-option-item',
    templateUrl: './option-item.component.html',
    styleUrls: ['./option-item.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CdkDrag,
        NgTemplateOutlet,
        ReactiveFormsModule,
        MatIconModule,
        ColorPickerComponent_1,
        IconPickerComponent_1,
        MatInputModule,
        MatButtonModule,
        MatMenuModule,
        TbIconComponent,
        TranslocoModule,
    ],
})
export class OptionItemComponent implements OnInit, AfterViewInit {
    protected readonly SetType = SetType

    @Input()
    option!: SelectOption

    @Input()
    isSelected!: boolean

    @Input()
    isDropdown: boolean = false

    @Input()
    disableDelete = false

    formGroup!: FormGroup
    @Output() optionChanged = new EventEmitter<any>()
    @Output() deleteOption: EventEmitter<any> = new EventEmitter()
    @Output() changeIcon = new EventEmitter<any>()
    @Output() setDefault = new EventEmitter<any>()
    @ViewChild('optionLabelInput') optionLabelInput!: MatInput
    @ViewChild('optionItem') optionItem!: ElementRef
    @ViewChild('colorPicker') colorPicker!: ColorPickerComponent
    @ViewChild('iconPicker') iconPicker!: IconPickerComponent

    isFocused: boolean = false
    isActive: boolean = false
    isDefault: boolean = false
    isClosed: boolean = false

    constructor(public dialog: MatDialog) {}

    ngOnInit(): void {
        this.formGroup = new FormGroup({
            label: new FormControl<string>(this.option.label, {
                validators: [Validators.required],
                updateOn: 'blur',
            }),
            color: new FormControl<string>(this.option.color),
        })
        this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value: SelectOption) => {
            this.optionChanged.next(value)
        })

        this.isActive = this.option.tags === 'active'
        this.isClosed = this.option.tags === 'close'
        this.isDefault = this.option.is_default === 1
    }

    ngAfterViewInit() {
        this.optionLabelInput.stateChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.isFocused = this.optionLabelInput.focused
        })
    }

    deleteStatus() {
        this.optionItem.nativeElement.remove()
        this.deleteOption.emit(this.option.guid)
    }

    setDefaultStatus() {
        this.setDefault.emit(this.option.guid)
    }

    openIconDialog() {
        this.iconPicker.open()
    }

    openColorPicker() {
        this.colorPicker.open()
    }

    updateSelectedIcon(icon: string | null) {
        this.changeIcon.emit(icon)
    }

    updateSelectedColor(color: string) {
        this.formGroup.controls['color'].setValue(color)
    }
}
