<div class="sm:h-7 h-8 sm:bg-transparent bg-white rounded ml-4 group/sort">
    <app-tb-button
        (click)="openDialog()"
        [color]="getButtonColor(hasSortErrors)"
        class="hover:bg-newNeutral3 rounded-lg"
        [disabled]="!!(isDisabled$ | async)"
        appearance="text"
        icon="custom_sort"
    >
        <ng-container [ngTemplateOutlet]="buttonInside"></ng-container>
    </app-tb-button>

    <ng-template #buttonInside>
        <div class="flex flex-row justify-start items-center">
            <div *transloco="let tButtons; read: 'buttons'" class="flex items-center mr-2">
                <span class="body-2">{{ tButtons('sort_by') }}</span>
                @if (hasSortErrors) {
                    <span
                        class="flex ml-2 caption-2 rounded-full items-center justify-center py-0 px-1 h-4 min-w-[1rem] text-white"
                    >
                        <app-tb-icon
                            icon="error"
                            [filled]="true"
                            class="text-newErrorLight group-hover/sort:text-newError"
                        ></app-tb-icon>
                    </span>
                } @else if (!isSortEntitiesEmpty && filterCountText) {
                    @if (filterCountText === 1) {
                        <span class="mr-1">:&nbsp;</span>
                        @if (firstSortEntity) {
                            <app-field-name
                                [field]="(fieldEntities$ | async)![firstSortEntity.fieldGuid]"
                                [folder]="(selectedFolder$ | async)!"
                                [showIcon]="false"
                                class="body-2 max-w-[160px]"
                            ></app-field-name>
                        }
                    } @else {
                        <span
                            class="flex ml-2 caption-2 rounded-full items-center justify-center py-0 px-1 h-4 min-w-[1rem] bg-newSecondaryLight group-hover/sort:bg-newSecondary text-white"
                        >
                            {{ filterCountText }}
                        </span>
                    }
                }
            </div>
        </div>
    </ng-template>
</div>
