import { UntilDestroy } from '@ngneat/until-destroy'
import { Component } from '@angular/core'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { StatusElementComponent } from './status-element/status-element.component'
import { TemplateSelectorComponent } from './template-selector/template-selector.component'
import { FieldConfigurationSelectorComponent } from '../field-configuration/field-configuration-selector/field-configuration-selector.component'
import { MatDialogClose } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { TranslocoModule } from '@ngneat/transloco'

@UntilDestroy()
@Component({
    selector: 'app-status-configuration',
    templateUrl: './status-configuration.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        MatIconModule,
        MatDialogClose,
        FieldConfigurationSelectorComponent,
        TemplateSelectorComponent,
        StatusElementComponent,
        TbButtonComponent,
    ],
})
export class StatusConfigurationComponent extends ModalContainerComponent {}
