import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatBadgeModule } from '@angular/material/badge'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatTableModule } from '@angular/material/table'
import { ActionsPanelModule } from '@app/feature/actions-panel/actions-panel.module'
import { SharedModule } from '@app/shared/shared.module'
import { TbBoardModule } from '@components-library/tb-board/tb-board.module'
import { TranslocoModule } from '@ngneat/transloco'
import { BoardCardFieldsComponent } from '@app/views/board/board-card-fields/board-card-fields.component'
import { BoardViewComponent } from 'src/app/views/board/board-view.component'

@NgModule({
    imports: [
        CommonModule,
        DragDropModule,
        MatBadgeModule,
        MatMenuModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        MatIconModule,
        MatTableModule,
        ReactiveFormsModule,
        MatExpansionModule,
        TranslocoModule,
        SharedModule,
        MatCheckboxModule,
        ActionsPanelModule,
        TbBoardModule,
        BoardViewComponent,
        BoardCardFieldsComponent,
    ],
    exports: [BoardViewComponent],
})
export class BoardViewModule {}
