import { devStorageInstance, EnvironmentMode } from '@services/local-storage/dev-storage.service'

export function getEnvModeFromFile() {
    return devStorageInstance.getEnvModeFromFile()
}

export function getEnvMode(): EnvironmentMode {
    return devStorageInstance.getEnvironmentMode()
}

export function setEnvMode(mode: EnvironmentMode) {
    devStorageInstance.setEnvironmentMode(mode)
}

export function isProd(): boolean {
    return checkEnvironmentMode('prod')
}

export function isDev(): boolean {
    return checkEnvironmentMode('dev')
}

export function isMock(): boolean {
    return checkEnvironmentMode('mock')
}

function checkEnvironmentMode(mode: EnvironmentMode): boolean {
    return getEnvMode() === mode
}
