import { Component, Input, OnInit } from '@angular/core'
import { ActionPanelService } from '@app/feature/actions-panel/action-panel.service'
import { AddFieldComponent } from '@app/feature/add-field/add-field.component'
import { ManageFieldsComponent } from '@app/feature/manage-fields/manage-fields.component'
import { ManageFieldsService } from '@app/feature/manage-fields/manage-fields.service'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { getBoolean } from '@core/global-util'
import { AddFieldData, Field, RecordGroup } from '@models/ui'
import { TranslocoModule } from '@ngneat/transloco'
import { SchemaFacadeService } from '@services/store-facade'
import { take } from 'rxjs/operators'

@Component({
    selector: 'app-board-column-header-menu',
    standalone: true,
    imports: [
        TbDividerComponent,
        TbIconComponent,
        TbMenuListComponent,
        TbMenuListItemComponent,
        TranslocoModule,
    ],
    templateUrl: './board-column-header-menu.component.html',
})
export class BoardColumnHeaderMenuComponent implements OnInit {
    @Input({ required: true }) data!: any

    group!: RecordGroup

    collapsed = false

    isDeleteAvailable = false

    constructor(
        private actionPanelService: ActionPanelService,
        private modalFlowManagerService: ModalFlowManagerService,
        private schemaFacadeService: SchemaFacadeService,
        private manageFieldsService: ManageFieldsService,
    ) {}

    ngOnInit() {
        this.group = this.data.columnItem
        this.collapsed = this.data.collapsed
        this.isDeleteAvailable = getBoolean(this.group.field?.operationCode?.delete)
    }

    isAllGroupSelected(group: RecordGroup) {
        return this.actionPanelService.areSelected(group.data)
    }

    toggleGroup(selected: boolean, group: RecordGroup) {
        if (selected) {
            this.actionPanelService.selectRecords(group.data)
        } else {
            this.actionPanelService.deselectRecords(group.data)
        }
    }

    edit(field: Field) {
        this.modalFlowManagerService
            .openDialog<AddFieldComponent, AddFieldData, Field | undefined>({
                component: AddFieldComponent,
                data: {
                    ...field,
                },
            })
            .subscribe((res: Field | undefined) => {
                if (res) {
                    this.schemaFacadeService.updateField(field)
                }
            })
    }

    delete(guid: string) {
        this.manageFieldsService.deleteField(guid, true).pipe(take(1)).subscribe()
    }

    manageFields() {
        this.modalFlowManagerService.openDialog({ component: ManageFieldsComponent })
    }
}
