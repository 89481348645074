import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { TbMenuComponent } from '@components-library/tb-menu'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { CurrentUser, User } from '@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Observable } from 'rxjs'
import {
    ExpandMods,
    SideNavigationService,
} from '@components-library/tb-side-navigation-layout/side-navigation.service'
import { UserFacadeService } from '@services/store-facade'
import { UserSettingsContentComponent } from 'src/app/feature/user-settings/user-settings-content/user-settings-content.component'
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component'
import { MatMenuModule } from '@angular/material/menu'
import { NgTemplateOutlet, AsyncPipe } from '@angular/common'

@UntilDestroy()
@Component({
    selector: 'app-user-settings',
    templateUrl: './user-setting.component.html',
    styleUrls: ['./user-settings.component.sass'],
    standalone: true,
    imports: [
        MatMenuModule,
        NgTemplateOutlet,
        UserAvatarComponent,
        UserSettingsContentComponent,
        AsyncPipe,
        TbMenuComponent,
        ModalLayoutComponent,
    ],
})
export class UserSettingsComponent implements OnInit {
    userInfo$: Observable<User | null> = this.userFacadeService.currentUserEntity$
    currentUser$: Observable<CurrentUser | null> = this.userFacadeService.currentUser$
    hideName = false

    @ViewChild('templateBottomSheet') TemplateBottomSheet!: TemplateRef<any>

    constructor(
        private sideNavigationService: SideNavigationService,
        private userFacadeService: UserFacadeService,
    ) {}

    ngOnInit(): void {
        this.sideNavigationService.state$.pipe(untilDestroyed(this)).subscribe((value) => {
            this.hideName = value.expand === ExpandMods.COLLAPSED
        })
    }
}
