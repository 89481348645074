@if (!disabled) {
<div
    [matMenuTriggerFor]="statusMenu"
    [ngStyle]="{'background':getTitleColor(value),'color': getTitleColor(value)|getContrastColor}"
    class="c-pointer status-title_wrap"
>
    @if (getIcon(value); as icon) {
    <mat-icon class="status-icon"> {{icon}} </mat-icon>
    }
    <span class="status-title"> {{getTitle(value) | async}} </span>
</div>
} @else {
<div
    [ngStyle]="{'background':getTitleColor(value),'color': getTitleColor(value)|getContrastColor}"
    class="c-pointer status-title_wrap"
>
    @if (getIcon(value); as icon) {
    <mat-icon class="status-icon"> {{icon}} </mat-icon>
    }
    <span class="status-title"> {{getTitle(value) | async }} </span>
</div>
}
<mat-menu #statusMenu="matMenu" class="status-menu">
    <ng-template matMenuContent>
        <div (click)="$event.stopPropagation()">
            <!-- change to app-tb-button after new design will be implemented -->
            @for (key of optionKeys(); track key) {
            <button (click)="checkActive(key)" mat-menu-item>
                <span
                    [ngStyle]="{'background': statusData[key].color}"
                    class="status-color_label mr-sm"
                ></span>
                <span class="status-label"> {{statusData[key].label}} </span>
                <app-tb-icon
                    class="status-icon ml-sm text-muted"
                    [icon]="statusData[key].icon || ''"
                ></app-tb-icon>
                @if (key === value) {
                <app-tb-icon class="active-check-label" icon="check"></app-tb-icon>
                } @if (key === default) {
                <app-tb-icon icon="ac_unit"></app-tb-icon>
                }
            </button>
            }
        </div>
    </ng-template>
</mat-menu>
