<div
    #container
    [matTooltip]="tooltip"
    [matTooltipPosition]="tooltipPosition"
    [matTooltipDisabled]="tooltipDisabled"
    [matTooltipHideDelay]="tooltipHideDelay"
    [matTooltipShowDelay]="tooltipShowDelay"
    [matTooltipClass]="['bg-newText', 'rounded']"
    class="w-full overflow-ellipsis"
>
    <ng-content></ng-content>
</div>
