<button
    class="w-inherit rounded-lg transition-colors px-2 py-1"
    [class]="getClasses()"
    (click)="toggleButton()"
>
    <app-tb-tooltip-component [tooltip]="tooltip">
        <div class="flex justify-center items-center">
            <app-tb-icon
                [icon]="icon"
                [filled]="isActive"
                [class]="label ? 'mr-2' : '' "
            ></app-tb-icon>
            @if (label) {
            <span class="body-2 mr-2"> {{label}} </span>
            } @else {
            <span class="body-2">
                <ng-content></ng-content>
            </span>
            }
        </div>
    </app-tb-tooltip-component>
</button>
