<div *transloco="let t; read: 'buttons'" class="flex flex-row justify-start items-start">
    <div class="dropdown-holder">
        <div>
            <span> Notifications here</span>
            <!-- as i understand there should be a list of notifications, so there won't be any prewritten text here(no need for transloco)-->
        </div>

        <div (click)="navigateNotifications()" class="cursor-pointer">
            <!--Todo: // [desing-ref] icon-->
            <mat-icon class="text-primarySecondary mr-2">more_horiz</mat-icon>
            <span class="text-primaryDark"> {{ t('show_more') }}</span>
        </div>
    </div>
</div>
