import { Injectable } from '@angular/core'
import { GroupObject } from '@app/views/view-controls'
import { throwAndLogError } from '@core/global-util'
import {
    Field,
    FieldEntities,
    FieldTypes,
    SystemSotConfigurationModelItem,
    View,
} from '@core/models'
import { SortDirection } from '@models/ui/sort.model'
import { GroupStorageService } from '@services/local-storage/group-storage.service'
import { LogService } from '@services/log.service'
import { isProd } from '@test/dev-utils'
import { Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class ViewGroupService {
    constructor(
        private groupStorageService: GroupStorageService,
        private logService: LogService,
    ) {}

    filterAllowedGroupFieldsByType(fields: FieldEntities) {
        return Object.values(fields)
            .filter((field) => {
                return this.isFieldAvailableForGrouping(field)
            })
            .filter((field) => !field.operationCode?.system)
    }

    getGroupByView(view: View): string {
        const groupValue = this.groupStorageService.get(view.guid)

        if (groupValue) return groupValue.guid

        return this.safeParseViewGroup(view.group.value).guid
    }

    getGroupDirectionByView(view: View): SortDirection {
        const groupValue = this.groupStorageService.get(view.guid)

        if (groupValue) return groupValue.sortDirection ?? SortDirection.DESC

        return this.safeParseViewGroup(view.group.value).sortDirection ?? SortDirection.DESC
    }

    saveGroupData(view?: View, guid?: string): SystemSotConfigurationModelItem | Observable<any> {
        if (!view) {
            // TODO: add translation for errors
            return throwAndLogError(
                this.logService,
                'View not set or same value of view-group are picked!',
            )
        }

        return {
            cell: view.group,
            newValue: guid ?? '',
        }
    }

    isFieldAvailableForGrouping(field: Field) {
        if (isProd()) {
            return field.field_type_code === FieldTypes.STATUS
        }

        const devEnvFields = [
            FieldTypes.DROPDOWN,
            FieldTypes.ASSIGNEE,
            FieldTypes.STATUS,
            FieldTypes.BOOL,
            FieldTypes.PEOPLE,
            FieldTypes.RATING,
        ]

        return devEnvFields.includes(field.field_type_code as FieldTypes)
    }

    //TODO: [check-before-prod] should be removed in task #1872
    private safeParseViewGroup(value: string): GroupObject {
        try {
            return JSON.parse(value)
        } catch (e) {
            return { guid: value }
        }
    }
}
