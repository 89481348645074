import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { ApplicationRef, enableProdMode, importProvidersFrom, isDevMode } from '@angular/core'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { MatDialogModule } from '@angular/material/dialog'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { bootstrapApplication, BrowserModule, enableDebugTools } from '@angular/platform-browser'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideRouter } from '@angular/router'
import { APP_ROUTES } from '@app/app.routes'
import { HeaderModule } from '@app/feature/header/header.module'
import { PinPanelModule } from '@app/feature/pin-panel/pin-panel.module'
import { UserSettingsModule } from '@app/feature/user-settings/user-settings.module'
import { SharedModule } from '@app/shared/shared.module'
import { ViewsModule } from '@app/views/views.module'
import { RootStoreModule } from '@core/@ngrx/root-store.module'
import { devStorageInstance, DevStorageService } from '@services/local-storage/dev-storage.service'
import { WsService } from '@services/ws.service'
import { isProd } from '@test/dev-utils'
import { AppComponent } from './app/app.component'
import { TranslocoRootModule } from './app/transloco-root.module'

if (isProd()) {
    enableProdMode()
}

bootstrapApplication(AppComponent, {
    providers: [
        provideRouter(APP_ROUTES),
        importProvidersFrom(
            BrowserModule,
            ViewsModule,
            MatProgressSpinnerModule,
            RootStoreModule,
            PinPanelModule,
            TranslocoRootModule,
            HeaderModule,
            UserSettingsModule,
            SharedModule,

            // material
            MatBottomSheetModule,
            MatDialogModule,
        ),
        WsService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: DevStorageService,
            useValue: devStorageInstance,
        },
    ],
})
    .then((moduleRef) => {
        if (isDevMode()) {
            const applicationRef = moduleRef.injector.get(ApplicationRef)
            const componentRef = applicationRef.components[0]
            enableDebugTools(componentRef)
        }
    })
    .catch((err) => console.error(err))
