import { AsyncPipe } from '@angular/common'
import { Component } from '@angular/core'
import { MatToolbarModule } from '@angular/material/toolbar'
import {
    CustomizeViewComponent,
    ViewFilterComponent,
    ViewGroupComponent,
    ViewSortComponent,
} from '@app/views/view-controls'

@Component({
    selector: 'app-view-controls-bar',
    templateUrl: './view-controls-bar.component.html',
    styleUrl: './view-controls-bar.component.sass',
    standalone: true,
    imports: [
        AsyncPipe,
        MatToolbarModule,
        ViewFilterComponent,
        ViewSortComponent,
        ViewGroupComponent,
        CustomizeViewComponent,
    ],
})
export class ViewControlsBarComponent {
    constructor() {}
}
