<div class="sm:h-7 h-8 sm:bg-transparent bg-white rounded ml-4">
    <app-tb-button
        (click)="openDialog()"
        [color]="!(isSortEntitiesEmpty$ | async) ? 'secondary' : 'primary'"
        [disabled]="!!(hasNoSortErrors$ | async)"
        appearance="text"
        icon="custom_sort"
    >
        <div class="flex flex-row justify-start items-center">
            <div *transloco="let tButtons; read: 'buttons'" class="flex items-center">
                <span class="body-2 mr-2">{{ tButtons('sort_by') }}</span>
                @if (!(isSortEntitiesEmpty$ | async)) {
                    <span class="caption-1 leading-3 rounded-full">
                        {{ filterCountText$ | async }}
                    </span>
                }
            </div>
        </div>
    </app-tb-button>
</div>
