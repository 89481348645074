import { Component, EventEmitter, Inject, Output } from '@angular/core'
import { ManageFieldsComponent } from '@app/feature/manage-fields/manage-fields.component'
import { CardComponent } from '@app/feature/record-card/card.component'
import { ShareRecordComponent } from '@app/feature/share-record/share-record.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { ObjectResponseModel } from '@core/models'
import { AppRecord } from '@models/ui'
import { TranslocoModule } from '@ngneat/transloco'
import { RecordsService } from '@services/records.service'
import { Observable } from 'rxjs'
import { take, tap } from 'rxjs/operators'

@Component({
    selector: 'app-header-options-menu',
    templateUrl: './header-options-menu.component.html',
    styleUrl: './header-options-menu.component.sass',
    standalone: true,
    imports: [TbMenuListComponent, TbMenuListItemComponent, TbIconComponent, TranslocoModule],
})
export class HeaderOptionsMenuComponent {
    constructor(
        private recordService: RecordsService,
        private modalFlowManagerService: ModalFlowManagerService,
        @Inject(CardComponent) private card: CardComponent,
    ) {}

    @Output() favorite = new EventEmitter<void>()

    duplicate() {
        this.recordService.duplicateRecord(this.card.record)
    }

    delete() {
        this.wrapResponse(
            this.recordService.deleteRecord(this.card.record).pipe(
                take(1),
                tap((data) => {
                    if (data.status === 'success') {
                        this.card.close()
                    }
                }),
            ),
        )
    }

    share() {
        this.modalFlowManagerService
            .openDialog<ShareRecordComponent, AppRecord, AppRecord>({
                component: ShareRecordComponent,
                data: this.card.record,
            })
            .subscribe((record) => {
                if (record) {
                    this.wrapResponse(this.recordService.updateRecord({ record }))
                }
            })
    }

    manage() {
        this.modalFlowManagerService.openDialog({
            component: ManageFieldsComponent,
        })
    }

    private wrapResponse(observable: Observable<ObjectResponseModel>) {
        this.card.wrapResponseObservable<ObjectResponseModel>(observable).subscribe()
    }
}
