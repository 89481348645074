import { Directive, Input, TemplateRef } from '@angular/core'

@Directive({
    selector: 'ng-template[appTypedTemplate]',
    standalone: true,
})
export class TypedTemplateDirective<TypeToken> {
    @Input('appTypedTemplate') typeToken!: TypeToken

    constructor(private contentTemplate: TemplateRef<TypeToken>) {}

    static ngTemplateContextGuard<TypeToken>(
        dir: TypedTemplateDirective<TypeToken>,
        ctx: unknown,
    ): ctx is TypeToken {
        return true
    }
}
