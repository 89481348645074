import { values } from 'lodash-es'
import {
    AccessModel,
    FieldGuid,
    FolderCreateField,
    FolderCreateSotType,
    FolderCreateViews,
    RequestCreateCell,
    RequestUpdateCell,
} from '../request'
import { Cell } from './cell.model'

export interface SystemSotConfigurationModelItem {
    cell: Cell
    newValue: string
}

export interface FolderConfiguration {
    color: SystemSotConfigurationModelItem
    icon: SystemSotConfigurationModelItem
    name: SystemSotConfigurationModelItem
}

export interface UpdateFolderChanges {
    access?: AccessModel
    configuration: Partial<FolderConfiguration>
}

export interface CreateFolderData {
    access?: AccessModel
    configuration: FolderConfiguration
    views: Record<FolderCreateSotType, FolderCreateViews>
    fields: Record<FolderCreateSotType, FolderCreateField[]>
}

export interface NewFolderTemplateData {
    access?: AccessModel
    views: Record<FolderCreateSotType, FolderCreateViews>
    fields: Record<FolderCreateSotType, FolderCreateField[]>
}

export interface CreateViewConfiguration {
    name: {
        fieldGuid: string
        newValue: string
    }
    typeCode: {
        fieldGuid: string
        newValue: string
    }
}

export interface CreateViewSuccessResult {
    recordGuid: string
}

export interface UpdateViewConfiguration {
    name: SystemSotConfigurationModelItem
    type_code: SystemSotConfigurationModelItem

    columns_order?: SystemSotConfigurationModelItem
    columns_pinned?: SystemSotConfigurationModelItem
    columns_hide?: SystemSotConfigurationModelItem
    columns_width?: SystemSotConfigurationModelItem

    sort?: SystemSotConfigurationModelItem
    group?: SystemSotConfigurationModelItem
    filter?: SystemSotConfigurationModelItem
}

type SotUpdateConfigurations = FolderConfiguration | UpdateViewConfiguration

export function getSotUpdateConfiguration<
    T extends SotUpdateConfigurations | Partial<SotUpdateConfigurations>,
>(configuration: T): Record<FieldGuid, RequestUpdateCell> {
    return Object.keys(configuration).reduce(
        (res: Record<FieldGuid, RequestUpdateCell>, key: string) => {
            const configKey = <keyof SotUpdateConfigurations>key
            const configurationItem = configuration[configKey]

            if (!configurationItem) return res

            res[configurationItem.cell.fieldGuid] = {
                value: configurationItem.newValue,
                revision: configurationItem.cell.revision,
            }
            return res
        },
        {},
    )
}

export function getViewCreateConfiguration(
    configuration: CreateViewConfiguration,
): Record<string, RequestCreateCell> {
    return values(configuration).reduce((prev, curr) => {
        return {
            ...prev,
            [curr.fieldGuid]: {
                value: curr.newValue,
            },
        }
    }, {})
}
