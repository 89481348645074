<div class="flex items-center edit-mode-form">
    <div [class.active]="!disabled" class="edit-mode-area">
        <input
            ngxDaterangepickerMd
            class="data-input"
            [locale]="{ format: dateLabelFormat }"
            [singleDatePicker]="true"
            [timePicker]="true"
            [placeholder]="valueLocale"
            [timePicker24Hour]="true"
            [customRangeDirection]="false"
            [showDropdowns]="true"
            [showCancel]="true"
            (change)="filterFrom()"
            autocomplete="off"
        />
        @if (value) {
        <mat-icon (click)="clearDate()" class="text-muted"> close </mat-icon>
        }
    </div>
    @if (disabled) {
    <div>
        <label> {{valueLocale}} </label>
    </div>
    }
</div>
