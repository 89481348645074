import { CdkDrag } from '@angular/cdk/drag-drop'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { ViewSortService } from '@app/views/view-controls'
import { FieldExistsInSchemaValidator } from '@app/views/view-controls/validators'
import { SortItemFormGroup } from '@app/views/view-controls/view-sort/sort-modal/sort-modal.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbOptionComponent } from '@components-library/tb-select/components/tb-option/tb-option.component'

import { TbSelectComponent } from '@components-library/tb-select/components/tb-select/tb-select.component'
import { Field, Folder } from '@core/models'
import { SortDirection, SortObject } from '@models/ui/sort.model'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { FieldNameComponent } from '@shared/field-name/field-name.component'

@UntilDestroy()
@Component({
    selector: 'app-sort-item',
    templateUrl: './sort-item.component.html',
    styleUrls: ['./sort-item.component.sass'],
    standalone: true,
    imports: [
        CdkDrag,
        TbIconComponent,
        TbSelectComponent,
        ReactiveFormsModule,
        TbOptionComponent,
        FieldNameComponent,
        MatIconModule,
        TranslocoModule,
    ],
})
export class SortItemComponent implements OnInit {
    @Input() selectedFolder!: Folder
    @Input() fields!: Field[]
    @Input() sortObject!: SortObject
    @Input() sortGroup!: FormGroup<SortItemFormGroup>
    @Input() isDefault!: boolean

    @Output() removed: EventEmitter<string> = new EventEmitter<string>()
    @Output() changed: EventEmitter<SortObject> = new EventEmitter<SortObject>()
    @Output() selected = new EventEmitter<boolean>()

    sortSelectionControl!: FormControl<string | null>
    sortDirectionControl!: FormControl<SortDirection | null>

    isSelected = false

    get isInputError() {
        return this.sortSelectionControl.errors?.['invalidField']
    }

    get closeIconColor() {
        return this.isDefault || (!this.isSelected && !this.isInputError)
            ? 'text-newNeutral4'
            : 'text-newText'
    }

    get selectPlaceholder() {
        if (this.isInputError) {
            return 'unknown'
        }
        return 'select_sort'
    }

    get ascButtonClass() {
        return this.sortButtonClass(SortDirection.ASC)
    }

    get descButtonClass() {
        return this.sortButtonClass(SortDirection.DESC)
    }

    constructor(
        private readonly fieldExistsValidator: FieldExistsInSchemaValidator,
        private readonly sortService: ViewSortService,
    ) {}

    ngOnInit() {
        if (!this.sortObject || !this.sortGroup) {
            this.sortGroup = new FormGroup({
                select: new FormControl('', {
                    asyncValidators: [this.fieldExistsValidator.validate],
                }),
                direction: new FormControl(),
            })
        }

        this.setControls()
    }

    removeItem() {
        if (this.isDefault || (!this.isSelected && !this.isInputError)) return

        this.removed.emit(this.sortObject.fieldGuid)
    }

    changeDirection() {
        if (this.isDefault || !this.isSelected) return

        if (this.sortDirectionControl.value === SortDirection.ASC) {
            this.sortDirectionControl.setValue(SortDirection.DESC)
            return
        }

        this.sortDirectionControl.setValue(SortDirection.ASC)
    }

    private setControls() {
        this.isSelected = !!this.fields.find((field) => this.sortObject.fieldGuid === field.guid)
        this.selected.emit(!this.isSelected)

        this.sortSelectionControl = this.sortGroup.controls.select
        this.sortDirectionControl = this.sortGroup.controls.direction

        this.sortSelectionControl.valueChanges.pipe(untilDestroyed(this)).subscribe((guid) => {
            this.isSelected = !guid
            this.selected.emit(this.isSelected)

            if (!this.sortDirectionControl.value) {
                const defaultDirection = this.getDefaultDirection(guid)
                this.sortDirectionControl.setValue(defaultDirection)
                this.sortObject.sortDirection = defaultDirection
            }

            this.changed.emit({
                ...this.sortObject,
                fieldGuid: guid ?? '',
            })
        })

        this.sortDirectionControl.valueChanges.pipe(untilDestroyed(this)).subscribe((direction) => {
            this.changed.emit({
                ...this.sortObject,
                sortDirection:
                    direction ?? this.getDefaultDirection(this.sortSelectionControl.value),
            })
        })
    }

    private sortButtonClass(direction: SortDirection) {
        if (this.isDefault || !this.isSelected) {
            return 'text-newNeutral4'
        }

        return this.sortDirectionControl.value === direction ? 'text-newText' : 'text-newNeutral4'
    }

    private getDefaultDirection(guid: string | null) {
        if (!guid) return SortDirection.DESC

        const isStringSort = this.sortService.isSortValueString(
            this.fields.find((field) => field.guid === guid)!,
        )
        return isStringSort ? SortDirection.ASC : SortDirection.DESC
    }
}
