<div class="sm:h-7 h-8 sm:bg-transparent bg-white rounded ml-4">
    <app-tb-button
        *transloco="let tShow; read: 'show'"
        appearance="text"
        icon="custom_sliders_horizontal"
        [color]="isViewShowSet ? 'secondary' : 'text'"
        (click)="openShowModal()"
        [disabled]="!!(hasNoCustomizeViewErrors$ | async)"
        class="hover:bg-newNeutral3 rounded-lg"
    >
        <div class="flex flex-row justify-start items-center">
            <span class="body-2">{{ tShow('show_title') }}</span>

            @if (isPreview) {
                <span
                    class="flex ml-2 caption-2 rounded-full items-center justify-center py-0 px-1 h-4 min-w-[1rem] bg-newSecondaryLight group-hover/sort:bg-newSecondary text-white"
                >
                    {{ tShow('preview') }}
                </span>
            }
        </div>
    </app-tb-button>
</div>
