import { Component } from '@angular/core'
import { EnvDirective } from '@shared/env.directive'
import { NotificationsMenuComponent } from './notifications-menu/notifications-menu.component'
import { SearchComponent } from './search/search.component'
import { FolderControlsComponent } from './folder-controls/folder-controls.component'
import { HeaderNameComponent } from './header-name/header-name.component'

@Component({
    selector: 'app-header-top',
    templateUrl: './header.component.html',
    standalone: true,
    imports: [
        HeaderNameComponent,
        FolderControlsComponent,
        SearchComponent,
        NotificationsMenuComponent,
        EnvDirective,
    ],
})
export class HeaderComponent {}
