import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core'
import { TbIconComponent } from '../../../tb-icon/tb-icon.component'

@Component({
    selector: 'app-tb-tag',
    templateUrl: './tb-tag.component.html',
    styleUrl: './tb-tag.component.sass',
    standalone: true,
    imports: [TbIconComponent],
})
export class TbTagComponent {
    @Input()
    rounded: boolean = false

    @Input()
    icon?: string

    @Input()
    large: boolean = false

    @Input()
    iconColor?: string

    @HostBinding('class') get classes() {
        return [
            this.rounded ? 'rounded-lg' : 'rounded',
            this.large ? 'large' : 'py-0.5',
            this.removable ? 'justify-between' : 'justify-center',
        ]
    }

    @Input()
    textColor?: string

    @Input()
    closeColor?: string

    @Input()
    removable: boolean = false

    @Output()
    remove = new EventEmitter<void>()
}
