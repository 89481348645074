<div class="flex flex-row items-center">
    @if (icon) {
    <app-tb-icon
        [class]="{ 'pl-1': large }"
        [icon]="icon"
        [style.color]="iconColor ?? ''"
    ></app-tb-icon>
    }
    <span class="px-1 body-2 tb-tag-value"><ng-content></ng-content></span>
</div>
@if (removable) {
<app-tb-icon
    class="tb-tag-close icon-sm"
    [class]="closeColor"
    icon="close"
    (click)="$event.stopPropagation(); remove.emit()"
></app-tb-icon>
}
