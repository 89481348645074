import { Component, OnInit } from '@angular/core'
import { Folder, Schema } from '@core/models'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { of } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { CommonDialogResultStatus } from '@components-library/tb-confirmation-popup/tb-confirmation-dialog.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { AccessSelectorComponent } from '@shared/access-selector/access-selector.component'
import { EnvDirective } from '@shared/env.directive'
import { TbOptionComponent } from '@components-library/tb-select/components/tb-option/tb-option.component'

import { TbSelectComponent } from '@components-library/tb-select/components/tb-select/tb-select.component'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { TranslocoModule } from '@ngneat/transloco'

export interface RecordSettingsData {
    schemas: Schema[]
    folders: Folder[]
    selectedFolderGuid: string
    selectedSchemaGuid: string
}

interface RecordSettingsFormGroup {
    folder: FormControl<string | null>
    schema: FormControl<string | null>
    template: FormControl<string | null>
    access: FormControl<string | null>
}

@UntilDestroy()
@Component({
    selector: 'app-record-settings-content',
    templateUrl: './record-settings-content.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        ModalLayoutComponent,
        ReactiveFormsModule,
        TbSelectComponent,
        TbOptionComponent,
        EnvDirective,
        AccessSelectorComponent,
        TbButtonComponent,
    ],
})
export class RecordSettingsContentComponent
    extends ModalContainerComponent<RecordSettingsData>
    implements OnInit
{
    settingsFormGroup!: FormGroup<RecordSettingsFormGroup>
    initialFolderGuid!: string
    initialSchemaGuid!: string

    isDirty = false

    ngOnInit() {
        this.initialFolderGuid = this.data.selectedFolderGuid
        this.initialSchemaGuid = this.data.selectedSchemaGuid

        this.settingsFormGroup = new FormGroup({
            folder: new FormControl(this.initialFolderGuid),
            schema: new FormControl(this.initialSchemaGuid),
            template: new FormControl(''),
            access: new FormControl(''),
        })

        dirtyCheck(this.settingsFormGroup, of(this.settingsFormGroup.value))
            .pipe(untilDestroyed(this))
            .subscribe((isDirty) => (this.isDirty = isDirty))
    }

    cancelSettingsChange() {
        if (!this.hasChanged()) {
            this.modalManagerService.close()
            return
        }

        this.openConfirmationDialog()
    }

    openConfirmationDialog() {
        this.confirmationDialogService
            .openCommon({
                translations: {
                    title: 'confirmation_dialog.discard_changes',
                    message: 'confirmation_dialog.unsaved_data_message',
                    cancel: 'confirmation_dialog.close_anyway',
                    confirm: 'confirmation_dialog.keep_editing',
                },
            })
            .subscribe((result) => {
                if (result === CommonDialogResultStatus.CANCEL) {
                    this.modalManagerService.close()
                }
            })
    }

    applySettingsChange() {
        this.modalManagerService.close({
            folderGuid: this.settingsFormGroup.value.folder,
            schemaGuid: this.settingsFormGroup.value.schema,
        })
    }

    hasChanged(): boolean {
        return this.isDirty
    }
}
