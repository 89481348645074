<ng-container *transloco="let tAddRecordContent; read: 'add_record_content'">
    <div class="gap-2 my-4" [class]="folderLayoutClass(folderCount)">
        @if (!this.availableFolders || this.availableFolders.length > 1) {
            @for (folder of this.availableFolders; track folder) {
                @if (fieldsByFolder[folder.guid].length) {
                    <ng-container
                        [ngTemplateOutlet]="tagTemplate"
                        [ngTemplateOutletContext]="{
                            guid: folder.guid,
                            color: folder.color.value,
                            content: folder.name.value,
                        }"
                    >
                    </ng-container>
                }
            }
        }
        @if (!isOnlyGlobalInList && sharedFields.length) {
            <!--TODO: change when full coloring will be added-->
            <ng-container
                [ngTemplateOutlet]="tagTemplate"
                [ngTemplateOutletContext]="{
                    guid: 'shared',
                    color: '#F9A825',
                    content: tAddRecordContent('shared'),
                }"
            >
            </ng-container>
        }
        <ng-template #tagTemplate let-guid="guid" let-color="color" let-content="content">
            <app-tb-tag
                [rounded]="true"
                [style.background]="color"
                (click)="scrollToExpansionPanel(guid)"
                class="text-center py-1.5 text-newNeutral1 body-1"
                [class]="tagClass(folderCount)"
            >
                <span class="body-1">{{ content }}</span>
            </app-tb-tag>
        </ng-template>
    </div>
    <ng-container
        [ngTemplateOutlet]="accordionContent"
        [ngTemplateOutletContext]="getAccordionContentContext('required', requiredOrTopFields)"
    ></ng-container>
    @for (folder of this.availableFolders; track folder) {
        @if (fieldsByFolder[folder.guid]?.length) {
            <ng-container
                [ngTemplateOutlet]="accordion"
                [ngTemplateOutletContext]="{
                    id: folder.guid,
                    name: folder.name.value,
                    accordionContentContext: getAccordionContentContext(
                        folder.guid,
                        fieldsByFolder[folder.guid]
                    ),
                }"
            >
            </ng-container>
        }
    }
    @if (!isOnlyGlobalInList && sharedFields.length) {
        <ng-container
            [ngTemplateOutlet]="accordion"
            [ngTemplateOutletContext]="{
                id: 'shared',
                name: tAddRecordContent('shared'),
                accordionContentContext: getAccordionContentContext('shared', sharedFields),
            }"
        >
        </ng-container>
    }

    <ng-template
        #accordion
        let-id="id"
        let-name="name"
        let-accordionContentContext="accordionContentContext"
    >
        <app-tb-accordion [expanded]="true" [title]="name" [id]="id" class="mt-3 block">
            <ng-container
                [ngTemplateOutlet]="accordionContent"
                [ngTemplateOutletContext]="accordionContentContext"
            >
            </ng-container>
        </app-tb-accordion>
    </ng-template>
</ng-container>
