import { Injectable } from '@angular/core'
import { AddRecordContentComponent } from '@app/feature/add-record/add-record-content.component'
import { PinDraftRecord } from '@app/feature/pin-panel/pin-record.service'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { LogService } from '@services/log.service'
import {
    CommonFacadeService,
    FolderFacadeService,
    SchemaFacadeService,
} from '@services/store-facade'
import { EMPTY, combineLatest, finalize } from 'rxjs'
import { switchMap, take, tap } from 'rxjs/operators'
import {
    AppRecord,
    BusinessRecord,
    CreateRecordModel,
    Folder,
    getRecordCells,
    RecordUpdate,
    UpdateLinkModel,
} from '../models'
import { UpdateService } from './update.service'

@Injectable({
    providedIn: 'root',
})
export class RecordsService {
    constructor(
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private updateService: UpdateService,
        private modalFlowManagerService: ModalFlowManagerService,
        private logService: LogService,
        private commonFacadeService: CommonFacadeService,
    ) {}

    updateLink(data: RecordUpdate) {
        this.schemaFacadeService.selectSelectedTableSchema$
            .pipe(
                take(1),
                switchMap((schema) => {
                    if (!schema) {
                        this.logService.error(new Error('no schema in updateLink'))
                        return EMPTY
                    }
                    const linkData: UpdateLinkModel = {
                        record_guid: data.record.guid!,
                        record_revision: data.record.revision!,
                        object_field_guid: data.cell!.fieldGuid,
                        revision: data.cell!.revision === 0 ? 1 : data.cell!.revision,
                        add_guids: data.value?.add_guids ?? [],
                        delete_guids: data.value?.delete_guids ?? [],
                    }
                    return this.updateService.updateLink([linkData], schema)
                }),
            )
            .subscribe()
    }

    duplicateRecord(record: BusinessRecord) {
        combineLatest([
            this.schemaFacadeService.selectSchemaByGuid$(record.schemaGuid),
            this.folderFacadeService.selectSelectedFolder$,
        ])
            .pipe(take(1))
            .subscribe(([schema, folder]) => {
                if (!schema) return

                const cells = getRecordCells(record)
                const fieldValues = Object.keys(cells).reduce((acc, guid) => {
                    acc[guid] = cells[guid].value
                    return acc
                }, {} as Record<string, string>)

                const data: PinDraftRecord = {
                    name: record.name.value,
                    data: {
                        schema,
                        folder,
                        name: record.name.value,
                        fieldValues,
                    },
                    guid: record.guid,
                }

                this.modalFlowManagerService
                    .openDialog<AddRecordContentComponent, PinDraftRecord>({
                        component: AddRecordContentComponent,
                        data,
                    })
                    .pipe(take(1))
            })
    }

    updateRecord(data: RecordUpdate) {
        return this.updateRecords([data])
    }

    updateRecords(data: RecordUpdate[]) {
        return this.folderFacadeService.selectSelectedFolder$.pipe(
            take(1),
            switchMap((folder: Folder) => {
                return this.updateService.updateRecords(data, folder)
            }),
        )
    }

    createRecord(record: CreateRecordModel) {
        return this.updateService.createRecord(record)
    }

    deleteRecord(data: AppRecord | Array<AppRecord>, isShowCommonFacadeLoader = false) {
        return this.updateService.deleteRecord(data).pipe(
            tap(() => {
                if (isShowCommonFacadeLoader) {
                    this.commonFacadeService.setLoading(true)
                }
            }),
            finalize(() => {
                if (isShowCommonFacadeLoader) {
                    this.commonFacadeService.setLoading(false)
                }
            }),
        )
    }
}
