<div
    class="flex items-center cursor-pointer pr-2 py-1 pl-1 rounded hover:bg-newNeutral3"
    [class]="color ? 'text-' + color : ''"
>
    <div class="flex mr-1 item-icon empty:hidden" #iconRef>
        <ng-content select="[item-icon]"></ng-content>
    </div>
    @if (icon && !iconRef.children.length) {
        <app-tb-icon color="primarySecondary" [icon]="icon" class="item-icon mr-1"></app-tb-icon>
    }
    <div class="inline empty:hidden pl-1" [class]="textSize" #titleRef>
        <ng-content select="[item-title]"></ng-content>
    </div>
    @if (!titleRef.children.length) {
        <span class="pl-1" [class]="textSize">
            <ng-content></ng-content>
        </span>
    }
</div>
