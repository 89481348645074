import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatBadgeModule } from '@angular/material/badge'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatSelectModule } from '@angular/material/select'
import { MatSliderModule } from '@angular/material/slider'
import { AccessSelectorComponent } from '@app/shared/access-selector/access-selector.component'
import { DateTimeComponent } from '@app/shared/cell-types/date-time/date-time.component'
import { DateComponent } from '@app/shared/cell-types/date/date.component'
import { AutoFocusDirective } from '@app/shared/cell-types/focus.directive'
import { LinkSettingsComponent } from '@app/shared/cell-types/link/link-settings/link-settings.component'
import { LinkComponent } from '@app/shared/cell-types/link/link.component'
import { MoneySettingsComponent } from '@app/shared/cell-types/money/money-settings/money-settings.component'
import { PeopleMenuComponent } from '@app/shared/cell-types/people/people-menu.component'
import { RangeComponent } from '@app/shared/cell-types/range/range.component'
import { RatingSettingsComponent } from '@app/shared/cell-types/rating/rating-settings/rating-settings.component'
import { DropdownSettingsComponent } from '@app/shared/cell-types/select/dropdown'
import { OptionItemComponent } from '@app/shared/cell-types/select/option-item.component'
import { StatusSettingsComponent } from '@app/shared/cell-types/select/status'
import { UrlComponent } from '@app/shared/cell-types/url/url.component'
import { ColorPickerComponent } from '@app/shared/color-picker/color-picker.component'
import { EnvDirective } from '@app/shared/env.directive'
import { FieldTypeSelectorComponent } from '@app/shared/field-type-selector/field-type-selector.component'
import { FieldsCollectionComponent } from '@app/shared/fields-collection/fields-collection.component'
import { GroupingValuesComponent } from '@app/shared/grouping-values/grouping-values.component'
import { IconPickerComponent } from '@app/shared/icon-picker/icon-picker.component'
import { ShowIfOperationCodeMatchExpectedOperationDirective } from '@app/shared/show-if-operation-code-match-expected-operation.directive'
import { TypedTemplateDirective } from '@app/shared/typed-template.directive'
import { ModalManagerModule } from '@components-library/tb-modal-manager/modal-manager.module'
import { TbTagsModule } from '@components-library/tb-tags'
import { PipesModule } from '@core/pipes/pipes.module'
import { TranslocoModule } from '@ngneat/transloco'
import { NgxColorsModule } from 'ngx-colors'
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material'
import { FieldNameComponent } from './field-name/field-name.component'
import { UserAvatarComponent } from './user-avatar/user-avatar.component'
import { RecordFieldsComponent } from 'src/app/shared/record-fields/record-fields.component'
import { RecordFieldsInputComponent } from '@app/shared/record-fields/record-fields-input/record-fields-input.component'

const forExport = [
    FieldNameComponent,
    UserAvatarComponent,
    EnvDirective,
    ShowIfOperationCodeMatchExpectedOperationDirective,
    AccessSelectorComponent,
    FieldsCollectionComponent,
    GroupingValuesComponent,
    TypedTemplateDirective,
    FieldTypeSelectorComponent,
    ColorPickerComponent,
    IconPickerComponent,
    AutoFocusDirective,
    RecordFieldsComponent,
]

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        TranslocoModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        TbTagsModule,
        MatExpansionModule,
        DragDropModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatCheckboxModule,
        MatSliderModule,
        MatSelectModule,
        MatBadgeModule,
        NgxDaterangepickerMd.forRoot(),
        NgxColorsModule,
        ReactiveFormsModule,
        TranslocoModule,
        PipesModule,
        ModalManagerModule,
        ...forExport,
        DateComponent,
        DateTimeComponent,
        UrlComponent,
        MoneySettingsComponent,
        PeopleMenuComponent,
        OptionItemComponent,
        DropdownSettingsComponent,
        StatusSettingsComponent,
        RatingSettingsComponent,
        RangeComponent,
        LinkComponent,
        LinkSettingsComponent,
    ],
    exports: forExport,
})
export class SharedModule {}
