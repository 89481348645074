import {
    CdkDragDrop,
    CdkDropList,
    CdkDropListGroup,
    moveItemInArray,
    transferArrayItem,
} from '@angular/cdk/drag-drop'
import { NgTemplateOutlet } from '@angular/common'
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core'
import { SelectOptionComponent } from '@app/feature/add-field/add-field-content/status-select-cell-settings/select-option/select-option.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbTooltipComponent } from '@components-library/tb-tooltip/tb-tooltip-component/tb-tooltip.component'
import { generateUuid } from '@core/global-util'
import { SelectObjectOptions, SelectOption } from '@models/response/select-object-options'
import { Field } from '@models/ui'
import { TranslocoModule } from '@ngneat/transloco'
import { OptionItemComponent } from '@shared/cell-types/select/option-item.component'
import { groupBy, map } from 'lodash-es'

interface OptionsByTags {
    active: SelectOption[]
    done: SelectOption[]
    close: SelectOption[]
}

@Component({
    selector: 'app-status-select-cell-settings',
    standalone: true,
    imports: [
        TbIconComponent,
        TbTooltipComponent,
        OptionItemComponent,
        SelectOptionComponent,
        TbButtonComponent,
        TranslocoModule,
        NgTemplateOutlet,
        CdkDropList,
        CdkDropListGroup,
    ],
    templateUrl: './status-select-cell-settings.component.html',
    styleUrl: './status-select-cell-settings.component.sass',
})
export class StatusSelectCellSettingsComponent implements OnInit, OnChanges {
    @Input() field!: Field

    @Input() statusOptions!: SelectObjectOptions

    @Output() fieldChanged: EventEmitter<Field> = new EventEmitter<Field>()
    @Output() valid = new EventEmitter<boolean>()

    optionsByTags!: OptionsByTags
    optionsSorted!: SelectOption[]
    defaultOption!: SelectOption

    ngOnInit() {
        if (!this.statusOptions) {
            this.preFillForEmptyStatus()
            return
        }

        this.optionsByTags = this.setOptionsByTags()

        this.setDefaultOnInit()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.field) {
            this.setDefaultOnInit()
        }
    }

    add(tag: keyof OptionsByTags, color = '#cccccc', label = '') {
        const newGuid = `new-${generateUuid()}`
        const newOption: SelectOption = {
            label,
            color,
            tags: tag,
            icon: '',
        }

        this.optionsByTags[tag].push({ guid: newGuid, ...newOption })
        this.statusOptions = {
            ...this.statusOptions,
            [newGuid]: newOption,
        }
        this.valid.emit(false)
    }

    delete(guid: string, tag: string) {
        if (this.optionsSorted) {
            const listOfSpecificTagOptions = this.optionsByTags[tag as keyof OptionsByTags]
            const index = listOfSpecificTagOptions.findIndex((option) => {
                return option.guid === guid
            })
            listOfSpecificTagOptions.splice(index, 1)
            this.sendSortedData()
            return
        }

        delete this.statusOptions[guid]
        this.sendData()
    }

    drop(event: CdkDragDrop<SelectOption[]>, tags: keyof OptionsByTags) {
        this.moveItemsInGroups(event, tags)
        this.sendSortedData()
    }

    optionChange(option: SelectOption) {
        if (!option.label.length) {
            this.valid.emit(false)
            return
        }

        this.valid.emit(true)

        if (this.optionsSorted) {
            const index = this.optionsSorted.findIndex((option) => {
                return option.guid === guid
            })
            this.optionsSorted.splice(index, 1, option)
        }

        const guid = option.guid!
        this.statusOptions[guid] = { ...option }
        delete this.statusOptions[guid].guid

        this.fieldChanged.emit({
            ...this.field,
            select_object_field: this.statusOptions,
        })
    }

    private moveItemsInGroups(event: CdkDragDrop<SelectOption[]>, tags: keyof OptionsByTags) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
            return
        }

        if (event.previousContainer.data.length === 1) return

        transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex,
        )

        this.optionsByTags[tags].find((option) => option.guid === event.item.data)!.tags = tags
    }

    private sendSortedData() {
        this.optionsSorted = [
            ...this.optionsByTags.active,
            ...this.optionsByTags.done,
            ...this.optionsByTags.close,
        ]

        this.fieldChanged.emit({
            ...this.field,
            select_object_field_sorted: this.optionsSorted,
        })
    }

    private sendData() {
        this.optionsByTags = this.setOptionsByTags()
        this.fieldChanged.emit({
            ...this.field,
            select_object_field: this.statusOptions,
        })
    }

    private setDefaultOnInit() {
        const defaultGuid = this.getDefault()
        this.defaultOption = {
            guid: defaultGuid,
            ...this.statusOptions[defaultGuid],
        }
    }

    private getDefault() {
        const defaultValue = this.field.default_value
        if (!defaultValue) {
            return this.optionsByTags.active[0].guid ?? ''
        }

        return defaultValue
    }

    private setOptionsByTags(): OptionsByTags {
        const selectOptions = map(this.statusOptions, (value, guid) => ({ guid, ...value }))
        return groupBy(selectOptions, 'tags') as Record<'active' | 'done' | 'close', SelectOption[]>
    }

    private preFillForEmptyStatus() {
        // TODO: remove or update after status default options on folder creation will be implemented
        this.optionsByTags = {
            active: [],
            done: [],
            close: [],
        }

        this.add('active', '#C5E1A5', 'New')
        this.add('done', '#FFE0B2', 'Under review')
        this.add('close', '#FF9E80', 'Closed')
        this.setDefaultOnInit()
        this.fieldChanged.emit({
            ...this.field,
            default_value: this.defaultOption.guid,
            select_object_field: this.statusOptions,
        })
        this.valid.emit(true)
    }
}
