<div
    class="mb-2 p-2 sm:p-1 rounded cursor-pointer max-sm:bg-newNeutral3 hover:bg-newNeutral3 w-full"
    (click)="selectFolder.emit(folder)"
>
    <div class="flex justify-between relative pr-5">
        <div class="flex">
            <app-tb-icon
                class="mr-2 icon-m"
                [style.color]="folder.color.value"
                [icon]="folder.icon.value"
            >
            </app-tb-icon>
            <span class="max-w-[12.5rem] text-ellipsis overflow-hidden">
                {{ folder.name.value }}
            </span>
            @if (showSelectedIcon && isSelected) {
                <ng-container *ngTemplateOutlet="selectedIcon"></ng-container>
            }
            @if (isSelected) {
                <app-tb-icon
                    class="flex ms-2 text-newPrimary icon-d mt-[0.125rem]"
                    icon="check"
                ></app-tb-icon>
            }
        </div>
        <div class="absolute right-0 top-0.5">
            @if (showSettings) {
                <app-tb-icon
                    (click)="edit.emit(); $event.stopPropagation()"
                    icon="custom_settings"
                    class="flex icon-d text-newText"
                ></app-tb-icon>
            }
        </div>
        <ng-template #selectedIcon>
            <app-tb-icon class="flex text-newPrimary icon-sm" icon="check"></app-tb-icon>
        </ng-template>
    </div>
</div>
