import { CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
} from '@angular/core'
import { TbMenuState } from '@components-library/tb-menu'
import { ModalContainer } from '@components-library/tb-modal-manager/modal-manager.service'
import { CdkFixedSizeVirtualScrollDirective } from '@components-library/virtual-scrolling/directives/fixed-size-virtual-scroll'
import { VisibleRangeDirective } from '@components-library/virtual-scrolling/directives/visible-range.directive'
import { chunk } from 'lodash-es'
import { TbButtonComponent } from '../tb-button/tb-button.component'
import { NgTemplateOutlet } from '@angular/common'
import { TbMenuComponent } from '../tb-menu/tb-menu.component'

@Component({
    selector: 'app-tb-picker',
    templateUrl: './tb-picker.component.html',
    styleUrl: './tb-picker.component.sass',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TbMenuComponent,
        NgTemplateOutlet,
        TbButtonComponent,
        CdkVirtualScrollViewport,
        CdkVirtualForOf,
        CdkFixedSizeVirtualScrollDirective,
        VisibleRangeDirective,
    ],
})
export class TbPickerComponent<T> implements OnInit {
    protected readonly ModalContainer = ModalContainer

    @Input() itemTemplate!: TemplateRef<any>

    @Input() items!: T[]

    @Input() showClear = false

    @Input() selectedItem!: T | null

    @Input() name?: string

    @Input() large = false

    @Output() selectItem = new EventEmitter<T | null>()

    opened = false

    innerItems!: T[][]

    menuSize = ''

    ngOnInit() {
        this.innerItems = chunk(this.updatedItemsArray(), 8)
        this.menuSize = this.getMenuSize()
    }

    isMenuOpened(status: TbMenuState) {
        this.opened = status === TbMenuState.Open
    }

    selectPickerItem(rowIndex: number, itemIndex: number, item: T) {
        if (this.showClear && rowIndex === 0 && itemIndex === 0) {
            this.selectItem.emit(null)
            return
        }

        this.selectItem.emit(item)
    }

    private updatedItemsArray() {
        if (this.showClear) {
            return ['clear' as T, ...this.items]
        }
        return [...this.items]
    }

    private getMenuSize() {
        return this.large ? 'w-[25.5rem] h-[15.625rem]' : 'w-[25.5rem] h-40'
    }
}
