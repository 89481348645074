import { NgTemplateOutlet } from '@angular/common'
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { BaseCellComponent } from '@app/feature/input-cells/base-container-content'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbSpinnerComponent } from '@components-library/tb-spinner/tb-spinner.component'
import { TbTooltipComponent } from '@components-library/tb-tooltip/tb-tooltip-component/tb-tooltip.component'
import { Clipboard } from '@angular/cdk/clipboard'
import { generateUuid } from '@core/global-util'
import { UntilDestroy } from '@ngneat/until-destroy'
import { CellEventHandlerService } from '@services/cell-event-handler.service'
import { MouseEvent } from 'react'

export type OutlineContainerStyleConfig = {
    bg_and_border_by_default?: boolean
    disable_hover_and_mouse_events?: boolean
    show_extra_info?: boolean
    placeholder_on_mouseover?: boolean
}

@UntilDestroy()
@Component({
    selector: 'app-tb-outline-container',
    standalone: true,
    imports: [
        MatFormFieldModule,
        TbIconComponent,
        MatProgressSpinnerModule,
        TbButtonComponent,
        NgTemplateOutlet,
        TbTooltipComponent,
        TbSpinnerComponent,
    ],
    templateUrl: './tb-outline-container.component.html',
    styleUrl: './tb-outline-container.component.sass',
})
export class TbOutlineContainerComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild('content', { read: ViewContainerRef })
    contentContainer!: ViewContainerRef

    @HostListener('mouseleave')
    onMouseOver() {
        this.contentComponent.instance.mouseOver = false
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        this.contentComponent.instance.mouseOver = true
    }

    @Input()
    contentComponent!: ComponentRef<BaseCellComponent>

    @Input()
    readonly = false

    @Input()
    disabled = false

    @Input()
    locked = false

    @Input()
    loading = false

    @Input()
    activeState = false

    @Input()
    editControls = false

    @Input()
    hoverIcons?: TemplateRef<any>

    @Input()
    styleConfig: OutlineContainerStyleConfig = {
        bg_and_border_by_default: true,
        disable_hover_and_mouse_events: false,
        show_extra_info: false,
        placeholder_on_mouseover: false,
    }

    errorMessage = ''

    active = false
    focused = false
    disabledForActive = false
    keepHoverIcons = false

    containerClickTimeout: any

    onFocusBound = this.onFocus.bind(this)
    toggleIconsBound = this.toggleIconsForHover.bind(this)
    isFocusDisabledBound = this.isFocusDisabled.bind(this)
    onCopy = this.copyValue.bind(this)
    onClear = this.clearValue.bind(this)

    readonly onClick: ((event: MouseEvent) => void) | null = null
    readonly onDblClick: ((event: MouseEvent) => void) | null = null

    private readonly ICON_CONTAINER_CLASS = '.icon-container'

    private cellGuid = generateUuid()

    get containerClasses() {
        return {
            'hover:border-newNeutral4':
                !this.styleConfig.disable_hover_and_mouse_events &&
                !this.focused &&
                !this.loading &&
                !this.invalid,
            'border-newNeutral4': this.styleConfig.bg_and_border_by_default,
            'border-transparent': !this.styleConfig.bg_and_border_by_default,
            'focused': this.focused || this.loading,
            'background': this.background,
            'error': this.invalid && !this.readonly,
            'text-newTextLight': this.disabled,
            'hover-background':
                (this.disabled || this.locked || this.readonly) &&
                !this.styleConfig.disable_hover_and_mouse_events,
            'active': this.active,
            [this.cssContainerClass]: true,
        }
    }

    get cssContainerClass(): string {
        if (this.styleConfig.bg_and_border_by_default) {
            return 'bordered'
        } else if (!this.styleConfig.disable_hover_and_mouse_events) {
            return 'mixed'
        }
        return ''
    }

    get invalid() {
        return this.contentComponent.instance.errorState
    }

    get background() {
        return (
            this.styleConfig.bg_and_border_by_default &&
            (this.locked || this.disabled || this.readonly) &&
            !this.invalid
        )
    }

    constructor(
        public elementRef: ElementRef,
        private cdr: ChangeDetectorRef,
        private clipboard: Clipboard,
        private cellEventHandlerService: CellEventHandlerService,
    ) {
        this.onClick = (event: MouseEvent) => {
            if ((event.target as HTMLElement).closest(this.ICON_CONTAINER_CLASS)) return
            this.onContainerClick()
        }

        elementRef.nativeElement.addEventListener('click', this.onClick, true)

        this.onDblClick = (event: MouseEvent) => {
            if ((event.target as HTMLElement).closest(this.ICON_CONTAINER_CLASS)) return
            this.onContainerDoubleClick()
        }

        elementRef.nativeElement.addEventListener('dblclick', this.onDblClick, true)

        cellEventHandlerService.registerCell(this.cellGuid, this)
    }

    ngOnInit(): void {
        this.disabledForActive = this.activeState
    }

    ngAfterViewInit(): void {
        this.contentContainer?.insert(this.contentComponent.hostView)
        this.cdr.detectChanges()
    }

    ngOnDestroy(): void {
        this.elementRef.nativeElement.removeEventListener('click', this.onClick)
        this.elementRef.nativeElement.removeEventListener('click', this.onDblClick)

        this.cellEventHandlerService.remove(this.cellGuid)
    }

    handleClickOutside(event: Event) {
        if (!this.focused && !this.active) return
        if (
            event.target &&
            !this.elementRef.nativeElement.contains(event.target) &&
            !this.contentComponent.instance.eventIsInsideCell(event)
        ) {
            if (this.active && !this.focused) {
                this.cellEventHandlerService.deselectCell()
            }
            this.deselectContainer()
        }
    }

    deselectContainer() {
        if (this.focused && !this.editControls) {
            this.contentComponent.instance.onOutsideClick()
        }

        if (this.active && !this.focused) {
            this.resetActive()
        }

        this.cdr.detectChanges()
    }

    onContainerClick() {
        if (
            this.activeState &&
            !this.focused &&
            !this.invalid &&
            !this.disabled &&
            !this.locked &&
            !this.readonly &&
            !this.active
        ) {
            this.active = true
            this.cellEventHandlerService.selectCell(this.cellGuid)
            this.runClickTimeout()
            this.cdr.detectChanges()
            return
        }

        if (this.active) {
            this.cellEventHandlerService.deselectCell()
            return
        }

        if (this.disabled || this.readonly) {
            this.runClickTimeout()
            return
        }

        this.onContainerDoubleClick()
    }

    onContainerDoubleClick() {
        if (this.containerClickTimeout) {
            clearTimeout(this.containerClickTimeout)
            this.containerClickTimeout = null
        }

        if (!this.disabled && !this.readonly && !this.locked) {
            this.disableActive()

            this.contentComponent.instance.onFocusClick()

            if (!this.editControls) {
                this.cellEventHandlerService.selectCell(this.cellGuid)
            }

            this.cdr.detectChanges()
        }
    }

    toggleIconsForHover() {
        this.keepHoverIcons = !this.keepHoverIcons
    }

    setErrorMessage(message: string) {
        this.errorMessage = message
    }

    removeErrorMessage() {
        this.errorMessage = ''
    }

    applyValue() {
        this.contentComponent.instance.removeFocus()
        this.contentComponent.instance.emitValue()
        this.resetActive()
        this.makeUnfocused()
    }

    resetValue() {
        this.contentComponent.instance.removeFocus()
        this.contentComponent.instance.resetValue()
        this.resetActive()
        this.makeUnfocused()
    }

    resetActive() {
        this.active = false

        if (this.activeState) {
            this.disabledForActive = true
        }
    }

    makeFocused() {
        this.focused = true
    }

    makeUnfocused() {
        this.focused = false
        this.cellEventHandlerService.deselectCell()
    }

    private isFocusDisabled() {
        return this.disabled || this.readonly || this.locked
    }

    private onFocus() {
        this.focused = true
        this.contentComponent.instance.onFocusClick()
        this.disableActive()
    }

    private copyValue() {
        this.clipboard.copy(this.contentComponent.instance.value)
    }

    private clearValue() {
        this.contentComponent.instance.valueChange.emit('')
    }

    private disableActive() {
        this.active = false
        this.disabledForActive = false
    }

    private runClickTimeout() {
        if (this.containerClickTimeout) {
            return
        }

        this.containerClickTimeout = setTimeout(() => {
            this.contentComponent.instance.onContainerClick()
            this.containerClickTimeout = null
        }, 200)
    }
}
