export enum AggregateFunctionsModel {
    'SUM',
    'AVG',
    'MIN',
    'MAX',
    'Count',
    'Median',
}

export enum FieldTypes {
    NAME = 'field_type_name',
    VIEW_TYPE = 'field_type_view_type',
    TEXT = 'field_type_text',
    MONEY = 'field_type_money',
    EMAIL = 'field_type_email',
    WEBSITE = 'field_type_website',
    NUMBER = 'field_type_number',
    STATUS = 'field_type_status',
    DROPDOWN = 'field_type_dropdown',
    ASSIGNEE = 'field_type_assignee',
    PEOPLE = 'field_type_people',
    BOOL = 'field_type_bool',
    RATING = 'field_type_rating',
    DATE_TIME = 'field_type_datetime',
    DATE_RANGE = 'field_type_date_range',
    DATE = 'field_type_date',
    WATCH = 'field_type_watch',
    MULTILINE_TEXT = 'field_multi_line_text',
    LINK = 'field_type_link',
    RICH_TEXT = 'field_rich_text',
    LINK_REFERENCE = 'field_link_reference',
}

export enum FieldTypeStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export interface ResponseFieldTypeEntities {
    [guid: string]: ResponseFieldType
}

export interface ResponseFieldType {
    name: string
    is_select?: number
    is_agg_function?: number
    agg_function?: Array<AggregateFunctionsModel | string>
    frontend_validations?: string[]
    type_data_validations?: string[]
    operation_code: string
    filter_conditions?: string[]
    status?: FieldTypeStatus | string
}
