import { Component } from '@angular/core'
import { TbTooltipComponent } from '@components-library/tb-tooltip/tb-tooltip-component/tb-tooltip.component'
import { FolderFacadeService } from '@core/services/store-facade'
import { FoldersListComponent } from './folders-list/folders-list.component'
import { MatIconModule } from '@angular/material/icon'
import { TbMenuComponent } from '@components-library/tb-menu/tb-menu.component'
import { AsyncPipe } from '@angular/common'

@Component({
    selector: 'app-folder-controls',
    templateUrl: './folder-controls.component.html',
    standalone: true,
    imports: [TbMenuComponent, MatIconModule, AsyncPipe, TbTooltipComponent],
})
export class FolderControlsComponent {
    constructor(private folderFacadeService: FolderFacadeService) {}

    menuComponent = FoldersListComponent

    selectedFolder$ = this.folderFacadeService.selectSelectedFolder$
}
