<div [formGroup]="linkDefinition">
    <div class="w-full flex-col" *transloco="let tLink; read: 'cell_types.link_content'">
        <!--TODO: Replace with TbSelect -->
        <mat-form-field class="w-full">
            <mat-label>{{ tLink('system_object_type') }}</mat-label>
            <mat-select formControlName="schema">
                @for (schema of allSchema; track schema) {
                    <mat-option [value]="schema.guid"> {{ schema.object_type_code }} </mat-option>
                }
            </mat-select>
        </mat-form-field>
        <!--TODO: Replace with TbSelect. Implement multiple view as a list in addition to tags. Implement TbSelectTrigger -->
        <mat-form-field class="w-full">
            <mat-label>{{ tLink('fields') }}</mat-label>
            <mat-select formControlName="fields" (openedChange)="updateValue($event)" multiple>
                <mat-select-trigger>
                    @for (fieldGuid of fieldsValue; track fieldGuid; let last = $last) {
                        <span>
                            <app-field-name
                                class="inline-block max-w-full"
                                [field]="getField(fieldGuid)"
                                [folder]="folder"
                            ></app-field-name>
                            @if (!last && fieldsValue.length > 1) {
                                ,
                            }
                        </span>
                    }
                </mat-select-trigger>
                @for (field of selectedSchemaFields; track field) {
                    <mat-option [value]="field.guid">
                        <app-field-name
                            class="inline-block max-w-full"
                            [field]="field"
                            [folder]="folder"
                            [showIcon]="true"
                        ></app-field-name>
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
</div>
