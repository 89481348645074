@if(label) {
<label class="text-size-xs leading-5" [innerText]="label"></label>
}
<mat-form-field appearance="outline">
    @if(selectedTbOptionComponent?.icon) {
    <app-tb-icon
        [icon]="selectedTbOptionComponent?.icon || ''"
        [style.color]="selectedTbOptionComponent?.iconColor ?? ''"
        [color]="selectedTbOptionComponent?.iconColor ? '' : 'newTextLight'"
        matIconPrefix
    ></app-tb-icon>
    } @else if(icon) {
    <app-tb-icon [icon]="icon" color="newTextLight" matIconPrefix></app-tb-icon>
    }
    <mat-select
        class="body-1"
        #matSelectComponent
        [formControl]="formControl"
        [placeholder]="placeholder"
        [multiple]="multiple"
        [compareWith]="compareFn"
        [sortComparator]="multipleSortComparatorByLastChecked"
        (focusin)="onFocusIn()"
        (focusout)="onFocusOut()"
        (opened)="onOpened()"
        (closed)="onClosed()"
        (selectionChange)="onSelectionChange($event)"
    >
        <mat-select-trigger>
            @if (!multiple && selectedTbOptionComponent; as selected) {
            <div>
                <!-- TODO: unable to render tbOption content. Rendering label instead. See https://github.com/angular/angular/issues/37995 -->
                <span [innerText]="selected.label"></span>
            </div>
            } @if (multiple) {
            <div class="flex flex-row flex-wrap gap-2">
                @for (val of multiSelectedTbOptionComponents; track val) {
                <app-tb-tag
                    [class]="[tagBackgroundColor, tagTextColor]"
                    [icon]="val.icon"
                    [iconColor]="val.iconColor"
                    [removable]="true"
                    (remove)="onRemoveValue(val.value)"
                >
                    {{ val.label }}
                </app-tb-tag>
                }
            </div>
            }
        </mat-select-trigger>
        @for (tbOption of tbOptionComponents; track tbOption) {
        <mat-option
            [disabled]="tbOption.disabled"
            [value]="tbOption.value"
            (onSelectionChange)="onOptionSelectionChange($event)"
            ><div class="flex flex-row items-center w-full">
                <div class="w-full">
                    <span class="" *ngTemplateOutlet="tbOption.optionTemplate"></span>
                </div>
                @if (multiple) {
                <app-tb-checkbox [value]="multipleHasValue(tbOption.value)"></app-tb-checkbox>
                }
            </div></mat-option
        >
        }
    </mat-select>
    <app-tb-icon
        color="newTextLight"
        [icon]="isDropdownExpanded ? 'expand_less' : 'expand_more'"
        matIconSuffix
    ></app-tb-icon>
    @if (errorMessage) {
    <mat-error> {{ errorMessage }} </mat-error>
    } @if (hint) {
    <mat-hint class="caption-1">{{ hint }}</mat-hint>
    } @if (formControl.touched && formControl.invalid) {
    <app-tb-icon icon="error_outline" color="red-200" matIconSuffix></app-tb-icon>
    }
</mat-form-field>
