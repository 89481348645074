import { Component, ComponentRef, Inject, ViewContainerRef, Optional, OnInit } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Subject } from 'rxjs'
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

type ComponentData = { renderComponent$: Subject<ComponentRef<unknown>> }

/**
 * ModalRenderComponent is a "proxy" component between Material Modal (Dialog/Bottom sheet) and our Modal Container instance.
 *
 * When we use matDialog.open(Component) or matBottomSheet.open(Component) we cant switch opened Component to another to have Content Page Navigation.
 * So to make it possible we pass ModalRenderComponent as a shell component and pass Content Page with properties.
 * Thus, when we need to change Modal Container content we shouldn’t close and open mat* modals, we just pass another Container into ModalRenderComponent and render it.
 *
 * This component is passed to the Dialog and Bottom Sheet.
 **/
@UntilDestroy()
@Component({
    selector: 'app-modal-render-container',
    template: '',
    standalone: true,
})
export class ModalRenderComponent implements OnInit {
    renderedComponent: ComponentRef<unknown> | null = null

    constructor(
        @Optional()
        @Inject(MAT_BOTTOM_SHEET_DATA)
        public bottomSheetData: ComponentData,

        @Optional()
        @Inject(MAT_DIALOG_DATA)
        public dialogData: ComponentData,

        private viewContainerRef: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        /**
         * At this point we do not know what Mat Modal is parent.
         **/
        const renderComponent$ =
            this.bottomSheetData?.renderComponent$ || this.dialogData?.renderComponent$

        if (!renderComponent$) return

        /**
         * Wwe subscribe on renderComponent$ stream to rerender Content during Navigation.
         **/
        renderComponent$.pipe(untilDestroyed(this)).subscribe((componentRef) => {
            if (this.renderedComponent) {
                this.viewContainerRef.detach()
            }
            this.renderedComponent = componentRef
            this.viewContainerRef.insert(componentRef.hostView)
        })
    }
}
