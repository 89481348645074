import { UntilDestroy } from '@ngneat/until-destroy'
import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

@UntilDestroy()
@Component({
    selector: 'app-status-element',
    templateUrl: './status-element.component.html',
    standalone: true,
    imports: [MatIconModule],
})
export class StatusElementComponent {
    @Input() color!: string
    @Input() name!: string
}
