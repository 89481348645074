@for (message of messages; track message) {
    <div
        [class]="messageClass"
        class="h-9 flex items-center justify-between text-newText border-l-4 rounded border-r-0 border-y-0 pl-2 mb-2"
    >
        {{ message }}
        <app-tb-icon
            class="mr-2 hover:cursor-pointer"
            icon="close"
            color="newNeutral5"
            (click)="closeMessage($index)"
        ></app-tb-icon>
    </div>
}
