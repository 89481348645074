<div
    class="flex flex-col w-full"
    *transloco="let tCardHeader; read: 'card_header'"
    [formGroup]="uniqueFormGroup"
>
    <div class="flex flex-wrap mb-3">
        <app-cell-label [field]="nameField" class="w-full">
            <app-input-cell-container
                class="w-full"
                [field]="nameField"
                [formControl]="uniqueFormGroup.controls.name"
                appearance="record_card"
                [hoverIcons]="icons"
            ></app-input-cell-container>
        </app-cell-label>
    </div>
    @if (uniqueFormGroup.controls.description; as description) {
        <div class="flex flex-wrap mb-2">
            <app-cell-label [field]="richTextField ?? descriptionField" class="w-full">
                <app-input-cell-container
                    class="body-1 w-full"
                    [field]="richTextField ?? descriptionField"
                    [formControl]="description"
                    (keydown.enter)="updateUniqueOnEnter()"
                    appearance="record_card"
                ></app-input-cell-container>
            </app-cell-label>
        </div>
    }
</div>
<div *appEnv="'prod'; visible: false" class="flex justify-start w-full">
    <app-tb-tabs
        [tabs]="cardTabs"
        [activeTab]="activeTab"
        (tabOpened)="activeTab = $event"
    ></app-tb-tabs>
</div>
@if (activeTab.id === 'details') {
    <app-fields-collection
        [accordionContent]="accordionContent"
        [form]="formGroup"
        [selectedFolder]="folder"
        [recordFieldValues]="recordFieldValues"
        [excludedFieldTypes]="excludedFieldTypes"
        [excludedFieldsGuids]="excludedFieldGuids"
        [records]="(records$ | async) || {}"
        [folders]="folders$ | async"
        [selectedSchema]="schema"
        [fieldTypes]="(fieldTypes$ | async) || {}"
        [isSystemFieldsAvailable]="true"
    ></app-fields-collection>
    <ng-template
        #accordionContent
        [appTypedTemplate]="contextType"
        let-fieldTypes="fieldTypes"
        let-folders="folders"
        let-fields="fields"
        let-values="values"
        let-records="records"
        let-form="form"
        let-groupName="groupName"
    >
        <app-record-fields
            [fieldTypes]="fieldTypes"
            [foldersDictionary]="folders"
            [fields]="fields"
            [values]="values"
            [records]="records"
            [formDirectiveGroup]="form"
            [groupName]="groupName"
            [inputAppearance]="inputContainerAppearance.record_card"
            [showSystemFields]="true"
            [hoverIcons]="icons"
        ></app-record-fields>
    </ng-template>
}
@if (activeTab.id === 'subtasks') {
    <app-card-subtasks
        *appEnv="'prod'; visible: false"
        [schema]="schema"
        [record]="record"
        [fieldTypes]="(fieldTypes$ | async) || {}"
    ></app-card-subtasks>
}
<ng-template
    #icons
    let-onFocus="onFocus"
    let-toggleIcons="toggleIcons"
    let-isFocusDisabled="isFocusDisabled"
    let-onCopy="onCopy"
    let-onClear="onClear"
>
    <app-cell-edit-buttons
        [record]="record"
        [onFocus]="onFocus"
        [onClear]="onClear"
        [onCopy]="onCopy"
        [toggleIcons]="toggleIcons"
        [focusDisabled]="isFocusDisabled()"
        [currentRecordOpened]="true"
    ></app-cell-edit-buttons>
</ng-template>
