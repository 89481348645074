<form [formGroup]="formGroup">
    @for (field of fields; track field) {
    <div class="flex xsm:flex-row items-center mt-2 xsm:mb-0 mb-4">
        <div class="flex mr-auto xsm:min-h-[38px] xsm:mb-0 mb-2">
            <div class="flex w-36 mr-3 xsm:mt-[7.5px] p-1 rounded-lg">
                <app-field-name
                    [field]="field"
                    [folder]="folder"
                    [showIcon]="true"
                    [iconColor]="folder.color.value"
                >
                </app-field-name>
                @if (field.is_required) {
                <app-tb-icon icon="asterisk"></app-tb-icon>
                } @if (field.is_on_top) {
                <app-tb-icon icon="upgrade"></app-tb-icon>
                }
            </div>
        </div>
        <app-record-fields-input
            [fieldTypes]="fieldTypes"
            [field]="field"
            [records]="records"
            [fieldValueFormControl]="getFieldFormControl(field.guid)"
            class="relative flex items-center w-full max-h-[38px] xsm:max-w-[363px] xsm:ml-4"
        ></app-record-fields-input>
    </div>
    }
</form>
