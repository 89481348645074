<div class="inline-block h-8 w-8 rounded-full shrink-0 overflow-hidden">
    @if (userUrl) {
    <img
        class="flex-shrink-0 h-8 w-8"
        [src]="userUrl"
        alt=""
        onerror="this.src='../../../../assets/img/avatar.png'"
    />
    } @else {
    <img alt="userName" class="flex-shrink-0 h-8 w-8" src="../../../assets/img/avatar.png" />
    }
</div>
