import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TbIconComponent } from '../../../tb-icon/tb-icon.component'
import { TbSelectValue } from '../../models'

@Component({
    selector: 'app-tb-option',
    templateUrl: './tb-option.component.html',
    styleUrl: './tb-option.component.sass',
    standalone: true,
    imports: [TbIconComponent, MatFormFieldModule],
})
export class TbOptionComponent<T = TbSelectValue> {
    @Input() value!: T

    @Input() icon = ''

    @Input() iconColor?: string

    @Input() disabled: boolean = false

    @ViewChild('optionTemplate', { static: true }) optionTemplate!: TemplateRef<any>
    @ViewChild('optionContent') optionContent!: ElementRef<any>

    get label() {
        return this.optionContent?.nativeElement.innerText.replace(/\n/g, '')
    }

    constructor() {}
}
