<div
    (click)="openSearchItem()"
    class="d-flex flex-column w-100 p-2 b-light w-100 rounded-sm mb-md"
    *transloco="let tSearchResults; read: 'search.results'"
>
    <div class="flex-start-center">{{ name }}, {{ assignee }}</div>
    <div class="flex-start-center">
        @if (status) {
        <div class="flex-start-center">
            <span>{{ status.label }}</span>
            <div [style.background-color]="status.color" class="rounded circle-sm"></div>
        </div>
        } {{ tSearchResults('folder') }}, {{ tSearchResults('created') }}
    </div>
</div>
