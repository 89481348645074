<app-tb-menu
    [template]="menu"
    [yPosition]="'below'"
    class="max-w-full"
    [disabled]="!!styleConfig?.disable_hover_and_mouse_events || this.loading"
>
    <div class="w-full truncate" trigger>
        @if (textControl.value) {
            <span class="body-1 truncate max-w-full">{{ textControl.value }}</span>
        } @else if (!textControl.value && !focused && placeholder) {
            <span class="body-1 truncate max-w-full text-newTextLight">{{ placeholder }}</span>
        }
    </div>
</app-tb-menu>

<ng-template #menu let-containerLayout="containerLayout" let-closeFn="closeFn">
    <app-modal-layout [containerLayout]="containerLayout">
        <div (click)="$event.stopPropagation()" class="flex flex-col">
            <div class="flex-1 overflow-auto">
                <textarea
                    class="min-w-[20rem] min-h-[9rem] body-1 resize"
                    [formControl]="textControl"
                ></textarea>
            </div>
            @if (showButtons) {
                <div class="flex mt-2" *transloco="let tMultiline; read: 'cell_types.multiline'">
                    <app-tb-button icon="custom_copy" appearance="text" (click)="copy()">
                        {{ tMultiline('copy') }}
                    </app-tb-button>

                    @if (isEdit) {
                        <app-tb-button
                            icon="check"
                            appearance="text"
                            class="ml-auto"
                            (click)="closeFn(); save()"
                            >{{ tMultiline('save') }}
                        </app-tb-button>
                        <app-tb-button
                            icon="close"
                            appearance="text"
                            color="secondary"
                            (click)="closeFn(); cancel()"
                            >{{ tMultiline('cancel') }}
                        </app-tb-button>
                    }
                </div>
            }
        </div>
    </app-modal-layout>
</ng-template>
