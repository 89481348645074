<div class="sm:h-7 h-8 sm:bg-transparent bg-white rounded">
    <app-tb-button
        (click)="openDialog()"
        [color]="isFilterTextSessionOrNotApplied() ? 'secondary' : 'primary'"
        [disabled]="!!(hasNoFilterErrors$ | async)"
        appearance="text"
        icon="filter_list"
    >
        <ng-container *ngTemplateOutlet="buttonInside"></ng-container>
    </app-tb-button>
    <ng-template #buttonInside>
        <div *transloco="let tFilter; read: 'filter'" class="flex items-center">
            <span class="body-2 mr-2">{{ tFilter('filter') }}</span>
            @if (filterGroups && filterGroups.length) {
                <span
                    class="flex caption-1 leading-3 rounded-full items-center justify-center py-0 px-1.5 h-4 bg-accentContrastLight text-white"
                >
                    {{ filterCountText }}
                </span>
            }
        </div>
    </ng-template>
</div>
