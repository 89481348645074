@if (textFormControl) {
    <div class="edit-mode-form">
        <app-tb-input
            class="noHintError"
            [formControl]="textFormControl"
            [errors]="[{ code: 'required', message: '' }]"
        >
        </app-tb-input>
    </div>
}
