<div class="flex flex-row justify-start items-center">
    <app-tb-select
        class="w-full noHintError"
        [multiple]="true"
        [value]="activeOptions"
        [placeholder]="'select'"
    >
        @for (key of optionKeys(); track key) {
            <app-tb-option>
                <app-tb-button
                    (click)="checkActive(key); $event.stopPropagation()"
                    [appearance]="'text'"
                    [ngStyle]="{
                        background: dropdownData[key].color,
                        color: key | getContrastColor,
                    }"
                    class="text-center"
                >
                    {{ dropdownData[key].label }}
                    <mat-icon [ngStyle]="{ color: key | getContrastColor }" class="dropdown-icon">{{
                        dropdownData[key].icon
                    }}</mat-icon>
                    @if (this.activeOptions.includes(key)) {
                        <mat-icon
                            [ngStyle]="{ color: key | getContrastColor }"
                            class="active-check-label"
                            >check
                        </mat-icon>
                    }
                </app-tb-button>
            </app-tb-option>
        }
    </app-tb-select>
</div>
