<app-tb-menu [template]="dropdownMenu" xPosition="after">
    <!-- TODO: fix backdrop issue #1350 -->
    @if (activeOptions.length > 0) {
        <div trigger class="flex flex-row justify-start items-center status-dropdown">
            @for (option of activeOptions; track option) {
                <span [ngStyle]="{ background: getBgColor(option) }" class="option-item"></span>
            }
        </div>
    } @else {
        <div class="status-dropdown" *transloco="let tFilter; read: 'filter'">
            {{ tFilter('status_default_filter') }}
        </div>
    }
</app-tb-menu>
<ng-template #dropdownMenu>
    <div [formGroup]="formGroup">
        <cdk-accordion class="example-accordion" formArrayName="statusListChbx">
            @for (item of statusList; track item; let index = $index) {
                <cdk-accordion-item
                    #accordionItem="cdkAccordionItem"
                    [attr.aria-controls]="'accordion-body-' + index"
                    [attr.aria-expanded]="accordionItem.expanded"
                    [attr.id]="'accordion-header-' + index"
                    [formGroupName]="index"
                    class="example-accordion-item"
                    role="button"
                    tabindex="0"
                >
                    <div
                        (click)="$event.stopPropagation()"
                        class="example-accordion-item-header flex flex-row justify-start items-center justify-between ml-2.5"
                    >
                        <app-tb-checkbox
                            (valueChange)="this.setAll($event, item)"
                            [formControlName]="'label'"
                            [indeterminate]="someComplete(item)"
                            class="example-margin text-primary"
                        >
                            {{ item }}
                        </app-tb-checkbox>
                        <app-tb-icon
                            (click)="accordionItem.toggle(); $event.stopPropagation()"
                            class="example-accordion-item-description"
                            [icon]="accordionItem.expanded ? 'expand_less' : 'expand_more'"
                        ></app-tb-icon>
                    </div>
                    <div
                        [attr.aria-labelledby]="'accordion-header-' + index"
                        [attr.id]="'accordion-body-' + index"
                        [style.display]="accordionItem.expanded ? '' : 'none'"
                        class="example-accordion-item-body"
                        formArrayName="statusListInnerChbx"
                        role="region"
                    >
                        @for (option of optionValuesItem(item); track option; let index = $index) {
                            <div>
                                <div
                                    class="accordion-row flex flex-row justify-start items-center ml-5"
                                >
                                    <app-tb-checkbox
                                        [formControlName]="index"
                                        (click)="$event.stopPropagation()"
                                        (valueChange)="updateAllComplete($event, item, option)"
                                    >
                                        <span
                                            [style]="{ backgroundColor: option.color }"
                                            class="color-label mr-1"
                                        ></span>
                                        {{ option.label }}
                                    </app-tb-checkbox>
                                </div>
                            </div>
                        }
                    </div>
                </cdk-accordion-item>
            }
        </cdk-accordion>
    </div>
</ng-template>
