<ng-container *transloco="let tFilter; read: 'filter'">
    <app-modal-layout
        [containerLayout]="containerLayout"
        (closeModal)="forceWithValidation()"
        (keydown.tab)="$event.stopPropagation()"
        [showLoader]="showLoader"
        [errors]="errors"
    >
        <ng-container title>
            <div class="text-primaryDark body-2">{{ tFilter('active_filters') }}</div>
        </ng-container>
        <div (click)="$event.stopPropagation()" class="max-sm:mt-4 min-w-[540px] h-[672px]">
            <div class="flex justify-end mb-2 max-sm:pr-2">
                @if (isClearAll()) {
                    <span
                        (click)="clearAll()"
                        class="body-2 text-secondaryMainLight hover:text-secondaryMain cursor-pointer"
                    >
                        {{ tFilter('clear_all').toUpperCase() }}
                    </span>
                }
            </div>
            @if ((fields$ | async) || { fieldTypes: [], fields: {} }; as fieldData) {
                <div class="overflow-auto max-sm:pr-2 max-sm:mb-2">
                    @for (group of formGroups; track group; let groupIndex = $index) {
                        <app-filter-group
                            class="p-0"
                            (deleteGroup)="deleteGroup(groupIndex)"
                            (clearAll)="clearAll()"
                            [selectedFolder]="selectedFolder"
                            [fieldTypes]="fieldData.fieldTypes"
                            [fields]="fieldData.fields"
                            [groupIndex]="groupIndex"
                            [single]="formFilterService.getFilterFormArray.length === 1"
                            [isEmpty]="isFilterFormEmpty"
                        ></app-filter-group>
                    }
                    @if (!isFilterFormEmpty) {
                        <div>
                            <app-tb-button
                                *transloco="let tFilter; read: 'filter'"
                                [appearance]="'text'"
                                [icon]="'add_circle_outline'"
                                [disabled]="!canAddFilterGroup"
                                (click)="addGroupAction($event)"
                                class="flex items-center w-fit p-1 mt-6 sm:mb-0.5"
                            >
                                <span class="body-2">{{ tFilter('add_group') }}</span>
                            </app-tb-button>
                        </div>
                    }
                </div>
            }
        </div>
        <ng-container buttons>
            <div *transloco="let tButtons; read: 'buttons'" class="flex justify-end items-center">
                <app-tb-button
                    appearance="outlined"
                    (click)="forceWithValidation()"
                    class="mr-10 whitespace-nowrap"
                >
                    <span class="body-2">{{ tButtons('cancel') }}</span>
                </app-tb-button>
                <div class="flex gap-1">
                    <app-tb-button appearance="primary" (click)="preview()">
                        {{ tButtons('preview') }}
                    </app-tb-button>
                    <app-tb-menu [template]="saveMenu" #tbMenu>
                        <app-tb-button
                            trigger
                            appearance="iconPrimary"
                            [icon]="tbMenu.menuOpen ? 'expand_less' : 'expand_more'"
                        ></app-tb-button>
                    </app-tb-menu>
                </div>
                <ng-template #saveMenu>
                    <app-tb-menu-list>
                        <app-tb-menu-list-item (click)="resetToSave()">{{
                            tButtons('reset_to_saved')
                        }}</app-tb-menu-list-item>
                        <app-tb-menu-list-item (click)="resetToDefault()">{{
                            tButtons('reset_to_default')
                        }}</app-tb-menu-list-item>
                        <app-tb-divider></app-tb-divider>
                        <app-tb-menu-list-item (click)="save()">{{
                            tButtons('save')
                        }}</app-tb-menu-list-item>
                    </app-tb-menu-list>
                </ng-template>
            </div>
        </ng-container>
    </app-modal-layout>
</ng-container>
