<div class="w-full flex justify-between">
    <div class="flex">
        <app-tb-icon-toggle-button
            icon="push_pin"
            [isActive]="pinned"
            (toggle)="pin.emit()"
        ></app-tb-icon-toggle-button>
        <app-field-name
            [field]="field"
            [folder]="selectedFolder"
            [showIcon]="true"
        ></app-field-name>
    </div>
    <app-tb-toggle [checked]="toggleValue" (valueChange)="toggle.emit($event)"></app-tb-toggle>
</div>
