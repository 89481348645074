<span class="body-1 mb-1">{{ label }}</span>
<mat-form-field appearance="outline" [class]="{ noHintError: !hint }">
    @if (prefixIconTemplate) {
        <ng-container matIconPrefix>
            <ng-container [ngTemplateOutlet]="prefixIconTemplate"></ng-container>
        </ng-container>
    } @else if (icon) {
        <app-tb-icon [icon]="icon" color="newTextLight" matIconPrefix></app-tb-icon>
    }

    <input
        #inputElement
        class="body-1"
        matInput
        [minLength]="minValueLength"
        [maxLength]="maxValueLength"
        (focusout)="onTouched && onTouched()"
        [formControl]="formControl"
        [placeholder]="placeholder"
        [type]="type"
    />

    @if (hint) {
        <mat-hint class="caption-1">{{ hint }}</mat-hint>
    }
    @if (formControl.invalid && formControl.touched) {
        <app-tb-tooltip-component
            [tooltip]="errorMessage"
            [showOnlyOnOverflow]="false"
            [extraStyles]="['justify-end']"
            class="w-[220px] block"
            matIconSuffix
        >
            <app-tb-icon icon="error_outline" color="red-200"></app-tb-icon>
        </app-tb-tooltip-component>
    }

    @if (suffixIconTemplate) {
        <ng-container [ngTemplateOutlet]="suffixIconTemplate" matIconSuffix></ng-container>
    }
</mat-form-field>
