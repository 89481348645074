<!-- TODO: Implement component library for rating -->
<div class="flex flex-row justify-start items-center">
    @for (reit of countStarsInArray; track reit) {
        <mat-icon
            (click)="setRating(reit)"
            (mouseenter)="changeHoverStyles($event, reit)"
            (mouseleave)="backHoverStyles()"
            [class.hoverStar]="reit <= selectValue"
            class="star-item"
        >
            star
        </mat-icon>
    }
</div>
