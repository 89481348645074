<app-tb-menu-list *transloco="let tBoard; read: 'board.board_header'">
    @if (group.data.length) {
        <app-tb-menu-list-item
            icon="custom_select"
            (click)="toggleGroup(!isAllGroupSelected(group), group)"
            >{{
                isAllGroupSelected(group) ? tBoard('unselect_all') : tBoard('select_all')
            }}</app-tb-menu-list-item
        >
    }
    <app-tb-menu-list-item icon="keyboard_double_arrow_left" (click)="data.toggleCollapse()">{{
        data.collapsed ? tBoard('expand') : tBoard('collapse')
    }}</app-tb-menu-list-item>
    <app-tb-divider></app-tb-divider>
    @if (group.field; as field) {
        <app-tb-menu-list-item icon="custom_edit" (click)="edit(field)">{{
            tBoard('edit_field')
        }}</app-tb-menu-list-item>
    }
    <app-tb-menu-list-item icon="edit_note" (click)="manageFields()">{{
        tBoard('manage_fields')
    }}</app-tb-menu-list-item>
    @if (isDeleteAvailable && group.field; as field) {
        <app-tb-divider></app-tb-divider>
        <app-tb-menu-list-item (click)="delete(field.guid)">
            <app-tb-icon color="red-600" icon="custom_trash" item-icon></app-tb-icon>
            <span class="text-red-600" item-title>{{ tBoard('delete_field') }}</span>
        </app-tb-menu-list-item>
    }
</app-tb-menu-list>
