import { Injectable } from '@angular/core'
import { BusinessRecords } from '@models/ui'
import { forEach } from 'lodash-es'
import { BehaviorSubject } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class ActionPanelService {
    private selectedRecords: Set<BusinessRecords> = new Set()
    private readonly selectRecords$ = new BehaviorSubject(Array.from(this.selectedRecords))

    constructor() {}

    select(value: BusinessRecords) {
        this.selectedRecords.add(value)
        this.notifyUpdatedRecords()
    }

    selectRecords(records: BusinessRecords[]) {
        records.forEach((value) => {
            this.selectedRecords.add(value)
        })
        this.notifyUpdatedRecords()
    }

    toggle(value: BusinessRecords) {
        if (this.isSelected(value)) {
            this.deselect(value)
            return
        }

        this.select(value)
    }

    toggleRecords(values: BusinessRecords[]) {
        if (this.areSelected(values)) {
            this.deselectRecords(values)
            return
        }

        this.selectRecords(values)
    }

    isSelected(value: BusinessRecords) {
        return this.selectedRecords.has(value)
    }

    areSelected(values: BusinessRecords[]) {
        return values.every((value) => {
            return this.isSelected(value)
        })
    }

    deselect(value: BusinessRecords) {
        this.selectedRecords.delete(value)
        this.notifyUpdatedRecords()
    }

    deselectRecords(values: BusinessRecords[]) {
        forEach(values, (value) => {
            this.selectedRecords.delete(value)
        })
        this.notifyUpdatedRecords()
    }

    deselectAll() {
        this.selectedRecords.clear()
        this.notifyUpdatedRecords()
    }

    getSelectedRecords$() {
        return this.selectRecords$.asObservable()
    }

    private notifyUpdatedRecords() {
        this.selectRecords$.next(Array.from(this.selectedRecords))
    }
}
