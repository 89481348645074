import { Component } from '@angular/core'
import { NotificationsMenuContentComponent } from './notifications-menu-content/notifications-menu-content.component'
import { MatMenuModule } from '@angular/material/menu'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'

@Component({
    selector: 'app-notifications-menu',
    templateUrl: './notifications-menu.component.html',
    standalone: true,
    imports: [TbButtonComponent, MatMenuModule, NotificationsMenuContentComponent],
})
export class NotificationsMenuComponent {}
