import { Injectable } from '@angular/core'
import { View } from '@models/ui'
import { CustomizeViewStorageService } from '@services/local-storage/customize-view-storage.service'

export interface ShowObject {
    columns_hide: string
    pin?: string
}

@Injectable({
    providedIn: 'root',
})
export class CustomizeViewService {
    constructor(private customizeViewStorageService: CustomizeViewStorageService) {}

    getPinFromView(view: View): string {
        const showValue = this.customizeViewStorageService.get(view.guid)

        if (showValue && showValue.pin) return showValue.pin

        return view.columns_pinned.value
    }

    getHiddenColumnsFromView(view: View): string {
        const showValue = this.customizeViewStorageService.get(view.guid)

        if (showValue) return showValue.columns_hide

        return view.columns_hide.value
    }
}
