import { InjectionToken } from '@angular/core'
import { TbOutlineContainerComponent } from '@components-library/tb-outline-container/tb-outline-container.component'

export enum InputContainerAppearance {
    NO_BORDER = 'no-border',
    BORDERED = 'bordered',
    MIXED = 'mixed',
}

export const INPUT_CONTAINER = new InjectionToken<TbOutlineContainerComponent>('INPUT_CONTAINER')
