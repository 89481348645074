@if (numberFormControl) {
    <div class="edit-mode-form">
        <app-tb-input
            class="noHintError"
            type="number"
            [formControl]="numberFormControl"
            [errors]="[{ code: 'required', message: '' }]"
        ></app-tb-input>
    </div>
}
