<div [formGroup]="formGroup" class="mb-5" *transloco="let tFilter; read: 'filter'">
    @if (!single && !first) {
        <app-filter-combination-toggle
            class="flex w-fit mx-auto my-4 andOrToggle"
            [disabled]="isCombinationGroupToggleDisabled"
            [value]="formFilterService.getGroupsCombinationType"
            [combinationTypes]="filterGroupsCombinationTypes"
            (valueChange)="groupCombinationTypeChange($event)"
            translateKey="filter.groups_combination_types"
        ></app-filter-combination-toggle>
    }
    <div [class.notEmpty]="!isEmpty" [formGroup]="formGroup" class="p-2 rounded-lg bg-newNeutral2">
        <div [class.emptyTemplate]="isEmpty" class="flex flex-col">
            @if (!isEmpty) {
                <ng-container *ngTemplateOutlet="filterItem"></ng-container>
            } @else {
                <div class="flex flex-col w-full p-2" *transloco="let tFilter; read: 'filter'">
                    <div class="mb-4 subheading-1 text-newTextLight">
                        {{ tFilter('where_label') }}
                    </div>
                    <app-filter-empty-item
                        [fieldGuids]="fieldGuids"
                        [selectedFolder]="selectedFolder"
                        [fields]="fields"
                        (setFilterItem)="addFilterItem($event)"
                    ></app-filter-empty-item>
                </div>
            }
            @if (fieldGuids) {
                <div class="p-0 w-full">
                    <div
                        class="flex justify-between w-full"
                        *transloco="let tFilter; read: 'filter'"
                    >
                        <app-tb-button
                            [disabled]="formGroup.invalid || isEmpty"
                            (click)="addFilterItem('')"
                            [appearance]="'text'"
                            [icon]="'add_circle_outline'"
                        >
                            <span class="body-2">{{ tFilter('add_condition') }}</span>
                        </app-tb-button>
                        @if (!single) {
                            <app-tb-button
                                [appearance]="'text'"
                                [color]="'secondary'"
                                (click)="deleteGroup.emit()"
                            >
                                <span class="body-2 cursor-pointer">{{
                                    tFilter('delete_condition_group')
                                }}</span>
                            </app-tb-button>
                        }
                    </div>
                </div>
            }
        </div>
    </div>
    <ng-template #filterItem>
        <div class="rounded-lg bg-newNeutral2 p-2">
            <div class="flex items-center p-0 mb-4 w-full">
                <div class="subheading-1 text-newTextLight">{{ tFilter('where_label') }}</div>
                @if (isShowFilterCombinationToggle()) {
                    <div class="flex items-center">
                        <app-filter-combination-toggle
                            class="mx-4"
                            [value]="filterCombinationType"
                            [combinationTypes]="filterCombinationTypes"
                            (valueChange)="combinationTypeChange($event)"
                        ></app-filter-combination-toggle>
                        <div class="text-newTextLight">{{ tFilter('where_conditions_match') }}</div>
                    </div>
                }
            </div>
            @if (formGroup.value) {
                @for (formItem of formItems; track formItem; let itemIndex = $index) {
                    @if (formItem.value['fieldGuid']) {
                        <app-filter-item
                            (deleteFilter)="deleteFilter(itemIndex)"
                            (deleteGroup)="deleteGroup.emit()"
                            [itemIndex]="itemIndex"
                            [groupIndex]="groupIndex"
                            [fieldTypes]="fieldTypes"
                            [fieldGuids]="fieldGuids"
                            [totalItems]="
                                formFilterService.getFilterGroupValues(this.groupIndex).length
                            "
                            [fields]="fields"
                            [selectedFolder]="selectedFolder"
                        ></app-filter-item>
                    } @else {
                        <app-filter-empty-item
                            [fieldGuids]="fieldGuids"
                            [selectedFolder]="selectedFolder"
                            [fields]="fields"
                            [delete]="true"
                            (setFilterItem)="addFilterFromEmpty($event, itemIndex)"
                            (deleteFilterItem)="deleteFilter(itemIndex)"
                        ></app-filter-empty-item>
                    }
                }
            }
        </div>
    </ng-template>
</div>
