<div
    cdkDrag
    [cdkDragData]="item"
    [cdkDragPreviewClass]="'dragging-board-card'"
    (cdkDragEntered)="enter($event)"
    (cdkDragExited)="exit($event)"
    class="group hover:shadow-button px-2 bg-newNeutral1 rounded-lg"
    [class.bg-newNeutral3]="selected"
>
    <ng-container *cdkDragPlaceholder>
        <div
            [class.hidden]="collapsed"
            class="bg-newNeutral3 border-dashed border-newNeutral4 border-2 w-full min-h-[300px] rounded-lg"
        ></div>
    </ng-container>
    <div class="py-2 flex justify-between content-center items-center">
        <span class="body-2">{{ title }}</span>
        <div class="flex content-center">
            <app-tb-menu
                [template]="moreMenu"
                class="pr-2 group-hover:visible visible md:invisible"
            >
                <app-tb-button appearance="icon" icon="more_vert" trigger> </app-tb-button>
            </app-tb-menu>

            <div
                (click)="$event.stopPropagation()"
                class="group-hover:visible visible"
                [class]="selectedItems.length > 0 ? 'visible' : 'md:invisible'"
            >
                <app-tb-checkbox
                    (valueChange)="selectCard.emit($event)"
                    [value]="selected"
                ></app-tb-checkbox>
            </div>
        </div>
    </div>
    <div>
        <ng-content></ng-content>
    </div>
</div>
