import { Injectable } from '@angular/core'
import {
    BusinessRecords,
    FieldTypes,
    FilterCombinationTypes,
    FilterGroup,
    FilterGroupsCombinationTypes,
    FilterOptionTypes,
    FilterOptionTypesAction,
    FilterValue,
    FilterValuesObject,
    getRecordCells,
    View,
} from '@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { UserFacadeService } from '@core/services/store-facade'
import { FilterStorageService } from '@core/services/local-storage/filter-storage.service'
import { getBoolean } from '@core/global-util'

@Injectable({
    providedIn: 'root',
})
@UntilDestroy()
export class ViewFilterService {
    userGuid?: string

    constructor(
        private userFacadeService: UserFacadeService,
        private filterStorageService: FilterStorageService,
    ) {
        this.userFacadeService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((currentUser) => (this.userGuid = currentUser?.guid))
    }

    filterOptionFunctions: FilterOptionTypesAction = {
        [FilterOptionTypes.IS_ANY]: this.isAnyFilter,
        [FilterOptionTypes.IS_ALL]: this.isAllFilter,
        [FilterOptionTypes.IS_NOT]: this.isNotFilter.bind(this),
        [FilterOptionTypes.IS_SET]: this.isSetFilter,
        [FilterOptionTypes.IS_NOT_SET]: this.isNotSetFilter,
        [FilterOptionTypes.CONTAINS]: this.containsFilter,
        [FilterOptionTypes.CONTAINS_ME]: this.containsMeFilter.bind(this),
        [FilterOptionTypes.DOES_NOT_CONTAIN]: this.doesNotContainFilter.bind(this),
        [FilterOptionTypes.EQUALS]: this.equalsFilter,
        [FilterOptionTypes.NOT_EQUAL_TO]: this.notEqualToFilter.bind(this),
        [FilterOptionTypes.BEGINS_WITH]: this.beginsWithFilter,
        [FilterOptionTypes.ENDS_WITH]: this.endsWithFilter,
        [FilterOptionTypes.GREATER_THAN]: this.greaterThanFilter,
        [FilterOptionTypes.LESS_THAN]: this.lessThanFilter,
        [FilterOptionTypes.GREATER_THAN_OR_EQUAL_TO]: this.greaterThanOrEqualToFilter.bind(this),
        [FilterOptionTypes.LESS_THAN_OR_EQUAL_TO]: this.lessThanOrEqualToFilter.bind(this),
    }

    getFilterGroupByView(view: View): FilterGroup[] {
        const filterValue = this.filterStorageService.get(view.guid)

        if (filterValue) return filterValue

        return view.filter.value.length ? JSON.parse(view.filter.value) : []
    }

    applyFilter(filterGroups: FilterGroup[], records: BusinessRecords[]): BusinessRecords[] {
        return records.filter((record) => {
            if (!filterGroups.length) return true

            if (filterGroups[0].filterGroupsCombinationType === FilterGroupsCombinationTypes.AND) {
                return filterGroups.every((group) => {
                    return this.evaluateFilterGroup(group, record)
                })
            }
            if (filterGroups[0].filterGroupsCombinationType === FilterGroupsCombinationTypes.OR) {
                return filterGroups.some((group) => {
                    return this.evaluateFilterGroup(group, record)
                })
            }

            return false
        })
    }

    evaluateFilterGroup(group: FilterGroup, record: BusinessRecords): boolean {
        if (group.filterCombinationType === FilterCombinationTypes.ALL) {
            return this.evaluateFiltersByCombinationType(group.values, record, (arr) =>
                arr.every(Boolean),
            )
        } else if (group.filterCombinationType === FilterCombinationTypes.ANY) {
            return this.evaluateFiltersByCombinationType(group.values, record, (arr) =>
                arr.some(Boolean),
            )
        }
        return false
    }

    private evaluateFiltersByCombinationType(
        values: FilterValuesObject | undefined,
        record: BusinessRecords,
        combinationTypeFn: (arr: any[]) => boolean,
    ): boolean {
        if (!values) {
            return true
        }
        return combinationTypeFn(
            Object.values(values).map((filterValue) =>
                this.evaluateFilter(filterValue, filterValue.fieldGuid, record),
            ),
        )
    }

    private evaluateFilter(
        filterValue: FilterValue,
        fieldGuid: string,
        record: BusinessRecords,
    ): boolean {
        const option = filterValue.option
        if (!option) {
            return false
        }
        const filterOptionFunction = this.filterOptionFunctions[option]
        const cell = getRecordCells(record)[fieldGuid]

        if (cell?.fieldType === FieldTypes.BOOL) {
            return (
                filterOptionFunction?.(filterValue, cell?.value.length ? cell?.value : '0') ?? false
            )
        }
        return filterOptionFunction?.(filterValue, cell?.value) ?? false
    }

    private isAllFilter(filter: FilterValue, value: string): boolean {
        if (!value || !Array.isArray(filter.value)) {
            return false
        }

        const splitValue = value.split(',')
        if (filter.value.length !== splitValue.length) {
            return false
        }

        return filter.value.sort().toString() === splitValue.sort().toString()
    }

    private isAnyFilter(filter: FilterValue, value: string): boolean {
        if (!value || !Array.isArray(filter.value)) {
            return false
        }

        const values: string[] = value.split(',')

        return !!filter.value.find((filterValue) => values.includes(filterValue))
    }

    private isNotFilter(filter: FilterValue, value: string): boolean {
        return !this.isAnyFilter(filter, value)
    }

    private isSetFilter(filter: FilterValue, value: string): boolean {
        return getBoolean(value)
    }

    private isNotSetFilter(filter: FilterValue, value: string): boolean {
        return !getBoolean(value)
    }

    private containsFilter(filter: FilterValue, value: string): boolean {
        if (!value || !(typeof filter.value === 'string')) {
            return false
        }

        return value.toLowerCase().includes(filter.value.toLowerCase())
    }

    private containsMeFilter(filter: FilterValue, value: string): boolean {
        return !!this.userGuid && value.includes(this.userGuid)
    }

    private doesNotContainFilter(filter: FilterValue, value: string): boolean {
        return !this.containsFilter(filter, value)
    }

    private equalsFilter(filter: FilterValue, value: string): boolean {
        if (!value || filter.value === undefined) {
            return false
        }

        return filter.value.toString() === value.toString()
    }

    private notEqualToFilter(filter: FilterValue, value: string): boolean {
        return !this.equalsFilter(filter, value)
    }

    private beginsWithFilter(filter: FilterValue, value: string): boolean {
        if (!value || !(typeof filter.value === 'string')) {
            return false
        }

        return value.toLowerCase().startsWith(filter.value.toLowerCase())
    }

    private endsWithFilter(filter: FilterValue, value: string): boolean {
        if (!value || !(typeof filter.value === 'string')) {
            return false
        }

        return value.toLowerCase().endsWith(filter.value.toLowerCase())
    }

    private greaterThanFilter(filter: FilterValue, value: string): boolean {
        if (!value || filter.value === 'object') {
            return false
        }

        const filterValue = parseInt(filter.value as string)
        const recordValue = parseInt(value)
        if (!isNaN(recordValue) && !isNaN(filterValue)) {
            return recordValue > filterValue
        }

        return false
    }

    private lessThanFilter(filter: FilterValue, value: string): boolean {
        if (!value || filter.value === 'object') {
            return false
        }

        const filterValue = parseInt(filter.value as string)
        const recordValue = parseInt(value)
        if (!isNaN(recordValue) && !isNaN(filterValue)) {
            return recordValue < filterValue
        }
        return false
    }

    private greaterThanOrEqualToFilter(filter: FilterValue, value: string): boolean {
        return this.greaterThanFilter(filter, value) || this.equalsFilter(filter, value)
    }

    private lessThanOrEqualToFilter(filter: FilterValue, value: string): boolean {
        return this.lessThanFilter(filter, value) || this.equalsFilter(filter, value)
    }
}
