<ng-container *transloco="let tAddRecordContent; read: 'add_record_content'">
    <app-modal-layout
        [containerLayout]="containerLayout"
        [showLoader]="showLoader"
        [errors]="errors"
        (closeModal)="forceWithValidation()"
    >
        <ng-container title>
            <div>{{ tAddRecordContent('create_new_record') }}</div>
        </ng-container>
        <ng-container headerButtons>
            <app-tb-button appearance="icon" icon="remove" (click)="pinDraftRecord()">
                remove
            </app-tb-button>
            <app-tb-button appearance="icon" icon="close" (click)="close()"></app-tb-button>
        </ng-container>
        <app-add-record-settings-section
            [folders]="(folders$ | async)!"
            [schemas]="(schemas$ | async)!"
            [selectedFolder]="folder"
            [selectedSchema]="schema"
            (saveSettings)="saveSettings($event)"
        ></app-add-record-settings-section>
        <ng-container [formGroup]="uniqueGroupControl">
            <div class="flex flex-wrap mb-3">
                <app-input-cell-container
                    [field]="nameField"
                    appearance="create_record"
                    [formControl]="uniqueGroupControl.controls['name']"
                ></app-input-cell-container>
            </div>
            @if (richTextField) {
                <div class="flex flex-wrap mb-3">
                    <mat-form-field class="flex flex-wrap" appearance="outline">
                        <textarea
                            class="body-1 w-full"
                            matInput
                            [formControl]="uniqueGroupControl.controls['description']"
                            [placeholder]="tAddRecordContent('full_description')"
                        ></textarea>
                    </mat-form-field>
                </div>
            }
        </ng-container>
        <app-fields-collection
            [accordionContent]="accordionContent"
            [form]="formGroupDirective?.form"
            [selectedFolder]="folder"
            [recordFieldValues]="recordFieldValues"
            [records]="(records$ | async) || {}"
            [excludedFieldTypes]="uniqueFieldTypes"
            [folders]="folders$ | async"
            [selectedSchema]="schema"
            [fieldTypes]="(fieldTypes$ | async) || {}"
        >
        </app-fields-collection>
        <ng-container buttons>
            <div class="flex items-center gap-[17rem] sm:gap-44">
                <div class="flex justify-start mt-3 py-0 pr-5 max-sm:px-0">
                    <app-tb-icon-toggle-button icon="star" (toggle)="addToFavorite($event)">
                        {{ tAddRecordContent('add_favorite') }}
                    </app-tb-icon-toggle-button>
                </div>
                <div class="flex justify-end mt-3 py-0 pl-5 max-sm:px-0">
                    <app-tb-button (click)="createRecord()" class="mr-1">
                        {{ tAddRecordContent('create') }}
                    </app-tb-button>
                    <app-tb-button
                        #trigger="matMenuTrigger"
                        [matMenuTriggerFor]="addTaskMenu"
                        [appearance]="'iconPrimary'"
                        [icon]="trigger.menuOpen ? 'expand_less' : 'expand_more'"
                    >
                    </app-tb-button>
                </div>
                <mat-menu class="mb-2" #addTaskMenu="matMenu" yPosition="above" xPosition="before">
                    <div class="p-2 w-[222px] body-1 text-primaryDark">
                        <div
                            (click)="createAndOpen()"
                            class="py-1 px-2 rounded mb-2 hover:bg-primaryBackground hover:body-2 cursor-pointer"
                        >
                            {{ tAddRecordContent('create_and_open') }}
                        </div>
                        <div
                            (click)="createAndStartAnother()"
                            class="py-1 px-2 rounded mb-2 hover:bg-primaryBackground hover:body-2 cursor-pointer"
                        >
                            {{ tAddRecordContent('create_and_start_another') }}
                        </div>
                        <div
                            (click)="createAndDuplicate()"
                            class="py-1 px-2 rounded hover:bg-primaryBackground hover:body-2 cursor-pointer"
                        >
                            {{ tAddRecordContent('create_and_duplicate') }}
                        </div>
                    </div>
                </mat-menu>
            </div>
        </ng-container>
    </app-modal-layout>
</ng-container>
<ng-template
    #accordionContent
    [appTypedTemplate]="contextType"
    let-fieldTypes="fieldTypes"
    let-folder="folder"
    let-fields="fields"
    let-values="values"
    let-records="records"
    let-form="form"
    let-groupName="groupName"
>
    <app-record-fields
        [fieldTypes]="fieldTypes"
        [folder]="folder"
        [fields]="fields"
        [values]="values"
        [records]="records"
        [formDirectiveGroup]="form"
        [groupName]="groupName"
    ></app-record-fields>
</ng-template>
