@if (isNoGrouped()) {
    <ng-container *transloco="let tTable; read: 'table'">
        @if (isAssignee()) {
            <span class="sticky"> {{ tTable('unassigned') }} </span>
        }
        @if (!isAssignee()) {
            <span class="sticky"> {{ tTable('unknown') }} </span>
        }
    </ng-container>
}
@if (!isNoGrouped() && field) {
    @switch (field.field_type_code) {
        @case ('field_type_status') {
            <app-status [disabled]="true" [fieldType]="fieldType" [field]="field" [value]="value">
            </app-status>
        }
        @case ('field_type_assignee') {
            <app-assign
                [isGrouping]="true"
                [disabled]="true"
                [fieldType]="fieldType"
                [field]="field"
                [value]="value"
            >
            </app-assign>
        }
        @case ('field_type_people') {
            <app-assign
                [isGrouping]="true"
                [disabled]="true"
                [fieldType]="fieldType"
                [field]="field"
                [value]="value"
            >
            </app-assign>
        }
        @case ('field_type_rating') {
            <app-rating
                [disabled]="true"
                [fieldType]="fieldType"
                [field]="field"
                [value]="value"
            ></app-rating>
        }
        @case ('field_type_dropdown') {
            <app-dropdown [disabled]="true" [fieldType]="fieldType" [field]="field" [value]="value">
            </app-dropdown>
        }
        @case ('field_type_bool') {
            <app-boolean [disabled]="true" [fieldType]="fieldType" [field]="field" [value]="value">
            </app-boolean>
        }
    }
}
