import { NgModule } from '@angular/core'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { TbIconComponent } from './tb-icon.component'
import { svgIcons } from './tb-icon.model'
import { forEach } from 'lodash-es'
import { CommonModule } from '@angular/common'

@NgModule({
    imports: [MatIconModule, CommonModule, TbIconComponent],
    exports: [TbIconComponent],
})
export class TbIconModule {
    private path = '/assets/img/'
    private iconSet = svgIcons

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        forEach(this.iconSet, (file, name) => {
            matIconRegistry.addSvgIcon(name, this.getUrl(file))
        })

        this.matIconRegistry.setDefaultFontSetClass('material-symbols-rounded')
    }

    private getUrl(icon: string, path: string = this.path) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(`${path}${icon}`)
    }
}
