import { Injectable } from '@angular/core'
import { throwAndLogError } from '@core/global-util'
import {
    prepareSelectOptionModel,
    prepareSelectOptionModelSorted,
} from '@models/response/select-object-options'
import {
    AutomationCreateModel,
    AutomationDeleteModel,
    AutomationExecuteModel,
    AutomationUpdateModel,
} from '@models/ui/automation.model'
import { ConfigFields, ConfigUpdateModel } from '@models/ui/config.model'
import { SubtaskUpdate } from '@models/ui/subtask-record.model'
import { LogService } from '@services/log.service'
import { CommonFacadeService } from '@services/store-facade'
import { WsService } from '@services/ws.service'
import { cloneDeep } from 'lodash-es'
import { Observable } from 'rxjs'
import { take, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import {
    AppRecord,
    Cell,
    CreateFieldModel,
    CreateRecordModel,
    CreateSubtaskModel,
    DeleteConfigUpdateModel,
    DeleteFieldModel,
    DeleteRecordModel,
    Field,
    Folder,
    FolderCreateModel,
    FolderDeleteModel,
    FolderUpdateModel,
    RecordUpdate,
    Schema,
    UpdateConfigUpdateModel,
    UpdateFieldModel,
    UpdateLinkModel,
    UpdateLinks,
    UpdateRecordInnerModel,
    UpdateRecordModel,
    UpdateSubtaskModel,
    UpdateSubtaskRecordModel,
    RequestViewCreateModel,
    RequestViewDeleteModel,
    RequestViewUpdateModel,
    UpdateResponseModel,
    InitResponseModel,
} from '../models'

@Injectable({
    providedIn: 'root',
})
export class UpdateService {
    //TODO: delete all related effects and use wrapRequest for invoke calls
    constructor(
        private wsService: WsService,
        private commonFacadeService: CommonFacadeService,
        private logService: LogService,
    ) {}

    //TODO: add mock service logic for all methods
    createFolder(model: FolderCreateModel): Observable<InitResponseModel> {
        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.create_folder, model),
        )
    }

    updateFolder(model: FolderUpdateModel): Observable<InitResponseModel> {
        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.update_folder, model),
        )
    }

    deleteFolder(model: FolderDeleteModel): Observable<InitResponseModel> {
        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.delete_folder, model),
        )
    }

    createView(model: RequestViewCreateModel): Observable<UpdateResponseModel> {
        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.create_view, model),
        )
    }

    updateView(model: RequestViewUpdateModel): Observable<UpdateResponseModel> {
        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.update_view, model),
        )
    }

    deleteView(model: RequestViewDeleteModel): Observable<UpdateResponseModel> {
        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.delete_view, model),
        )
    }

    updateRecords(data: RecordUpdate[], folder: Folder): Observable<UpdateResponseModel> {
        const records = this.getUpdateRecordInnerModels(data, folder)

        if (!records.length) {
            return throwAndLogError(
                this.logService,
                'empty UpdateRecordInnerModel array in updateRecord',
            )
        }

        const solution_object_type_guid = data[0].record.schemaGuid

        if (!solution_object_type_guid) {
            return throwAndLogError(this.logService, 'no schema guid in updateRecord')
        }

        const body: UpdateRecordModel = {
            solution_object_type_guid,
            records,
        }

        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.update_record, body),
        )
    }

    updateLink(
        updateLinksData: UpdateLinkModel[],
        schema: Schema,
    ): Observable<UpdateResponseModel> {
        const body: UpdateLinks = {
            solution_object_type_guid: schema.guid,
            records: updateLinksData,
        }
        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.update_link, body),
        )
    }

    createRecord(record: CreateRecordModel): Observable<UpdateResponseModel> {
        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.create_record, record),
        )
    }

    deleteRecord(data: AppRecord | Array<AppRecord>): Observable<UpdateResponseModel> {
        if (!Array.isArray(data)) {
            data = [data]
        }

        if (!data.length) {
            return throwAndLogError(this.logService, 'Data not found, delete records lost')
        }

        const body: DeleteRecordModel = {
            solution_object_type_guid: data[0].schemaGuid,
            records: data.map((record) => {
                return {
                    record_guid: record.guid,
                    record_revision: record.revision.toString(),
                }
            }),
        }

        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.delete_record, body),
        )
    }

    updateSubtask(
        data: SubtaskUpdate | SubtaskUpdate[],
        folder: Folder,
    ): Observable<UpdateResponseModel> {
        if (!Array.isArray(data)) {
            data = [data]
        }

        const records = this.mapSubtaskUpdateToInnerModel(data, folder)

        if (!records.length) {
            return throwAndLogError(
                this.logService,
                'empty UpdateSubtaskInnerModel array in updateSubtask',
            )
        }

        const solution_object_type_guid = data[0].subtask.schemaGuid

        if (!solution_object_type_guid) {
            return throwAndLogError(this.logService, 'no schema guid in updateRecord')
        }

        const body: UpdateSubtaskModel = {
            solution_object_type_guid,
            records,
        }

        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.update_subtask, body),
        )
    }

    createSubtask(subtask: CreateSubtaskModel): Observable<UpdateResponseModel> {
        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.create_subtask, subtask),
        )
    }

    deleteSubtask(data: AppRecord) {
        const body: DeleteRecordModel = {
            solution_object_type_guid: data.schemaGuid,
            records: [
                {
                    record_guid: data.guid,
                    record_revision: data.revision.toString(),
                },
            ],
        }

        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.delete_subtask, body),
        )
    }

    updateField(field: Field, schema: Schema): Observable<UpdateResponseModel> {
        const body: UpdateFieldModel = {
            solution_object_type_guid: schema.guid,
            solution_object_type_revision: schema.revision,
            field_guid: field.guid,
            revision: field.revision,
            configure_json: field.configure_json,
            is_required: field.is_required,
            system_name: field.system_name,
            acl: null,
            name: field.name,
            frontend_validations: null,
            shared_with_folder: field.shared_with_folder,
            folder_guid: field.folder_guid,
            select_object_field: this.generateSelectObjectField(field),
            link_definition: field.link_definition,
            is_on_top: field.is_on_top,
            default_value: field.default_value,
        }

        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.update_field, body),
        )
    }

    createField(field: Partial<Field>, schema: Schema): Observable<UpdateResponseModel> {
        if (!field.field_type_code || !field.name) {
            return throwAndLogError(this.logService, 'invalid data in createField')
        }

        const body: CreateFieldModel = {
            solution_object_type_guid: schema.guid,
            solution_object_type_revision: schema.revision,
            field_type_code: field.field_type_code,
            configure_json: field.configure_json,
            is_required: field.is_required,
            acl: null,
            name: field.name,
            frontend_validations: null,
            folder_guid: field.folder_guid,
            select_object_field: this.generateSelectObjectField(field),
            link_definition: field.link_definition,
            is_on_top: field.is_on_top,
            default_value: field.default_value,
        }

        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.create_field, body),
        )
    }

    deleteField(guid: string, schema: Schema): Observable<UpdateResponseModel> {
        const body: DeleteFieldModel = {
            solution_object_type_guid: schema.guid,
            solution_object_type_revision: schema.revision,
            field_guid: guid,
            revision: schema.fieldEntities[guid].revision,
        }
        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.delete_field, body),
        )
    }

    resetSolutionToTemplate(): Observable<UpdateResponseModel> {
        return this.wrapRequest(this.wsService.invokeMessage(environment.ws.endpoints.reset))
    }

    createConfig(createConfig: Map<string, string>): Observable<UpdateResponseModel> {
        const body = {
            configuration: [...createConfig.keys()].reduce((config, key) => {
                config[key] = {
                    value: createConfig.get(key)!,
                    revision: 1,
                }
                return config
            }, {} as ConfigFields),
        }

        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.create_config, body),
        )
    }

    updateConfig(updateConfig: ConfigUpdateModel): Observable<UpdateResponseModel> {
        const body: UpdateConfigUpdateModel = {
            record_guid: updateConfig.guid,
            record_revision: updateConfig.revision,
            configuration: updateConfig.configuration,
        }

        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.update_config, body),
        )
    }

    deleteConfig(deleteConfig: ConfigUpdateModel): Observable<UpdateResponseModel> {
        const body: DeleteConfigUpdateModel = {
            record_guid: deleteConfig.guid,
            record_revision: deleteConfig.revision,
        }

        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.delete_config, body),
        )
    }

    executeAutomation(executeAutomation: AutomationExecuteModel) {
        const body = {
            solution_automation_guid: executeAutomation.guid,
            solution_object_type_guid: executeAutomation.objectTypeGuid,
            records: executeAutomation.records.map((record) => {
                return {
                    record_guid: record.guid,
                    record_revision: record.revision,
                }
            }),
        }

        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.execute_automation, body),
        )
    }

    createAutomation(automationRequest: AutomationCreateModel) {
        return this.wrapRequest(
            this.wsService.invokeMessage(
                environment.ws.endpoints.create_automation,
                automationRequest,
            ),
        )
    }

    updateAutomation(automationRequest: AutomationUpdateModel) {
        const body = {
            guid: automationRequest.automationGuid,
            ...automationRequest.automationModel,
        }

        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.update_automation, body),
        )
    }

    deleteAutomation(deleteAutomation: AutomationDeleteModel) {
        const body = {
            guid: deleteAutomation.guid,
            solution_object_type_guid: deleteAutomation.objectTypeGuid,
        }

        return this.wrapRequest(
            this.wsService.invokeMessage(environment.ws.endpoints.delete_automation, body),
        )
    }

    private wrapRequest(observable: Observable<UpdateResponseModel>) {
        return observable.pipe(
            take(1),
            tap((data) => {
                this.commonFacadeService.onMessage(data)
            }),
        )
    }

    private generateUpdateRequestCell(cell: Cell, value?: string) {
        return [
            {
                revision: cell.revision.toString(),
                field_guid: cell.fieldGuid,
                value: value ?? cell.value,
            },
        ]
    }

    private generateSelectObjectField({
        select_object_field,
        select_object_field_sorted,
    }: Partial<Field>) {
        const selectObjectField = select_object_field
            ? Object.values(prepareSelectOptionModel(cloneDeep(select_object_field)))
            : undefined

        return select_object_field_sorted
            ? prepareSelectOptionModelSorted(select_object_field_sorted)
            : selectObjectField
    }

    private mapSubtaskUpdateToInnerModel(
        data: SubtaskUpdate[],
        folder: Folder,
    ): UpdateSubtaskRecordModel[] {
        return data.reduce((acc: UpdateSubtaskRecordModel[], recordData: SubtaskUpdate) => {
            if (
                !recordData.subtask.guid ||
                !recordData.subtask.revision ||
                !recordData.subtask.folder_guids
            ) {
                this.logService.error(new Error('invalid data in updateSubTask'))
                return acc
            }

            acc.push({
                record_guid: recordData.subtask.guid,
                record_revision: recordData.subtask.revision.toString(),
                folders_guid:
                    'folder_guids' in recordData.subtask
                        ? recordData.subtask.folder_guids
                        : folder.guid,
                parent_guid: recordData.subtask.parentGuid as string,
                cells: recordData.cell
                    ? this.generateUpdateRequestCell(recordData.cell, recordData.value)
                    : [],
            })
            return acc
        }, [])
    }

    private getUpdateRecordInnerModels(data: RecordUpdate[], folder: Folder) {
        return data.reduce((acc: UpdateRecordInnerModel[], recordData: RecordUpdate) => {
            let parent_sot_guid: string | undefined
            if (recordData.record && 'parent_sot_guid' in recordData.record) {
                parent_sot_guid = recordData.record.parent_sot_guid
            }

            if (!recordData.record.guid || !recordData.record.revision) {
                this.logService.error(new Error('invalid data in updateRecord'))
                return acc
            }

            acc.push({
                record_guid: recordData.record.guid,
                record_revision: recordData.record.revision.toString(),
                parent_sot_guid: parent_sot_guid,
                folders_guid: recordData.record.folder_guids || '',
                cells: recordData.cell
                    ? this.generateUpdateRequestCell(recordData.cell, recordData.value)
                    : [],
            })
            return acc
        }, []) as UpdateRecordInnerModel[]
    }
}
