import { NgFor } from '@angular/common'
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatOptionModule } from '@angular/material/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { TbOptionComponent } from '@components-library/tb-select/components/tb-option/tb-option.component'
import { TbSelectComponent } from '@components-library/tb-select/components/tb-select/tb-select.component'
import { getBoolean } from '@core/global-util'
import { AppRecord, Folder } from '@core/models'
import { FolderFacadeService } from '@core/services/store-facade'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'
import { keys } from 'lodash-es'
import { of } from 'rxjs'

interface ListWorkspaceItem {
    guid: string
    name: string
    icon: string
    iconColor: string
}

@UntilDestroy()
@Component({
    selector: 'app-share-record',
    templateUrl: './share-record.component.html',
    styleUrls: ['./share-record.component.sass'],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatOptionModule,
        MatButtonModule,
        ModalLayoutComponent,
        TbButtonComponent,
        TbSelectComponent,
        TbOptionComponent,
        TbIconComponent,
        TranslocoModule,
    ],
})
export class ShareRecordComponent extends ModalContainerComponent implements OnInit, AfterViewInit {
    record!: AppRecord
    workspaces!: Dictionary<Folder>

    control = new FormControl<string[]>([])
    workspaceOptions: ListWorkspaceItem[] = []

    private isDirty = false

    constructor(
        private folderFacadeService: FolderFacadeService,
        protected modalManagerService: ModalManagerService,
        protected confirmationDialogService: ConfirmationDialogService,
        @Inject(ModalContainerDataToken) public data: AppRecord,
    ) {
        super(data, modalManagerService, confirmationDialogService)
        this.record = data
    }

    ngOnInit(): void {
        this.folderFacadeService.selectFolderEntities$
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.workspaces = data

                this.setLists()
            })
    }

    ngAfterViewInit(): void {
        if (!this.control) return

        dirtyCheck(this.control, of(this.control.value), {
            debounce: 0,
            useBeforeunloadEvent: false,
        })
            .pipe(untilDestroyed(this))
            .subscribe((isDirty) => {
                this.isDirty = isDirty
            })
    }

    hasChanged(): boolean {
        return this.isDirty
    }

    applyShare() {
        const selectedWorkspaceGuids = (this.control.value || []).filter(
            (guid) => !getBoolean(this.workspaces[guid]?.is_global.value),
        )

        this.record = {
            ...this.record,
            folder_guids: selectedWorkspaceGuids.join(','),
        }

        this.close(this.record)
    }

    cancelShare() {
        this.close()
    }

    private setLists() {
        this.workspaceOptions = keys(this.workspaces)
            .filter((workspaceGuid) => !getBoolean(this.workspaces[workspaceGuid]?.is_global.value))
            .map((workspaceGuid: string) => {
                return {
                    guid: workspaceGuid,
                    name: this.workspaces[workspaceGuid]?.name.value || '',
                    icon: this.workspaces[workspaceGuid]?.icon.value || '',
                    iconColor: this.workspaces[workspaceGuid]?.color.value || '',
                }
            })

        const selectedWorkspacesGuidsString = this.record.folder_guids
        const selectedWorkspacesGuids = selectedWorkspacesGuidsString
            ? selectedWorkspacesGuidsString.split(',')
            : []

        this.control.setValue(selectedWorkspacesGuids)
    }
}
