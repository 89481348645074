import { Route } from '@angular/router'
import { allowOnRedirect, clearErrorState } from '@app/pages/errors/check-data.guard'
import { ErrorPageComponent, ErrorPageData } from '@app/pages/errors/error/error.page'

export const ERROR_ROUTES: Route[] = [
    {
        path: '',
        canActivate: [allowOnRedirect('view')],
        canDeactivate: [clearErrorState()],
        children: [
            {
                path: 'something-wrong',
                component: ErrorPageComponent,

                data: {
                    title: 'error_pages.something_went_wrong',
                    message: 'error_pages.unexpected_occurred_message',
                    button: 'error_pages.try_again',
                } as ErrorPageData,
            },
            {
                path: 'access-denied',
                loadComponent: () =>
                    import('./access-denied/access-denied.page').then(
                        (m) => m.AccessDeniedPageComponent,
                    ),
            },
        ],
    },
]

export default ERROR_ROUTES
