import { UntilDestroy } from '@ngneat/until-destroy'
import { SelectViewsSettingsComponent } from '../select-views-settings/select-views-settings.component'
import { MatDialog } from '@angular/material/dialog'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { Component, Input, OnInit } from '@angular/core'
import { FieldConfigurationComponent } from '../field-configuration/field-configuration.component'
import { ComponentType } from '@angular/cdk/overlay'
import { StatusConfigurationComponent } from '../status-configuration/status-configuration.component'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { MatIconModule } from '@angular/material/icon'
import { MatExpansionModule } from '@angular/material/expansion'
import { TranslocoModule } from '@ngneat/transloco'

@UntilDestroy()
@Component({
    selector: 'app-advanced-options-section',
    templateUrl: './advanced-options-section.component.html',
    standalone: true,
    imports: [TranslocoModule, MatExpansionModule, MatIconModule],
})
export class AdvancedOptionsSectionComponent implements OnInit {
    @Input()
    sectionName!: string

    componentToOpen!: ComponentType<ModalContainerComponent>

    constructor(
        private modalManagerService: ModalManagerService,
        private bottomSheet: MatBottomSheet,
    ) {}

    ngOnInit(): void {
        switch (this.sectionName) {
            case 'views':
                this.componentToOpen = SelectViewsSettingsComponent
                break

            case 'fields':
                this.componentToOpen = FieldConfigurationComponent
                break

            case 'statuses':
                this.componentToOpen = StatusConfigurationComponent
                break
        }
    }

    openConfDialog(event: MouseEvent) {
        event.stopPropagation()
        if (!this.componentToOpen) return

        this.modalManagerService.openDialog({ component: this.componentToOpen })
    }

    openConfBottom() {
        if (!this.componentToOpen) return

        this.bottomSheet.open(this.componentToOpen, {
            panelClass: 'bottomSheetCustom',
        })
    }
}
