<div
    cdkDrag
    [cdkDragData]="item"
    [cdkDragPreviewClass]="'dragging-board-card'"
    (cdkDragEntered)="enter($event)"
    (cdkDragExited)="exit($event)"
    class="group/board-card hover:shadow-button px-2 bg-newNeutral1 rounded-lg"
    [class.bg-newNeutral3]="selected"
>
    <ng-container *cdkDragPlaceholder>
        <div
            [class.hidden]="collapsed"
            class="bg-newNeutral3 border-dashed border-newNeutral4 border-2 w-full min-h-[18.75rem] rounded-lg"
        ></div>
    </ng-container>
    <div class="p-2 pr-0 flex justify-between items-start">
        <span class="body-2">{{ title }}</span>
        <div class="flex items-center">
            <div
                class="group-hover/board-card:visible visible md:invisible"
                (click)="$event.stopPropagation()"
            >
                <ng-container *ngTemplateOutlet="favorite; context: { item }"></ng-container>
            </div>
            <app-tb-menu
                [template]="moreMenu"
                class="mr-2 group-hover/board-card:visible visible md:invisible"
            >
                <app-tb-button
                    appearance="icon"
                    icon="more_vert"
                    class="icon-sm"
                    color="newTextLight"
                    trigger
                >
                </app-tb-button>
            </app-tb-menu>

            <div
                (click)="$event.stopPropagation()"
                class="group-hover/board-card:visible visible"
                [class]="selectedItems.length > 0 ? 'visible' : 'md:invisible'"
            >
                <app-tb-checkbox
                    (valueChange)="selectCard.emit($event)"
                    [value]="selected"
                    size="small"
                ></app-tb-checkbox>
            </div>
        </div>
    </div>
    <div class="px-2">
        <ng-content></ng-content>
    </div>
</div>
