@if (currentUser$ | async; as currentUser) {
    @if (userInfo$ | async; as userInfo) {
        <app-tb-menu [template]="userSettings">
            <div
                trigger
                [class.closed]="hideName"
                class="group/user-settings flex text-white items-center justify-content-between w-full p-2 rounded-lg subheading-1 cursor-pointer bg-primaryDark hover:text-secondaryMain"
            >
                <div
                    class="invisible group-hover/user-settings:visible absolute left-[13px] w-6 h-6 box-content rounded-full border-[3px] border-solid border-secondaryMain avatarBorder"
                ></div>

                <div class="flex items-center p-0 cursor-pointer w-full hover:brightness-90">
                    <app-user-avatar
                        class="flex w-6 h-6"
                        [userName]="userInfo.first_name + ' ' + userInfo.last_name"
                        [userUrl]="userInfo.avatar_url"
                    >
                    </app-user-avatar>
                    @if (!hideName) {
                        <div
                            class="subheading-1 overflow-hidden max-w-[16ch] whitespace-nowrap text-ellipsis ml-4"
                        >
                            {{ userInfo.first_name }} {{ userInfo.last_name }}
                        </div>
                    }
                </div>
            </div>
        </app-tb-menu>

        <ng-template #userSettings let-containerLayout="containerLayout">
            <app-modal-layout [containerLayout]="containerLayout">
                <app-user-settings-content
                    [currentUserIsAdmin]="currentUser.is_admin"
                    [userInfo]="userInfo"
                ></app-user-settings-content>
            </app-modal-layout>
        </ng-template>
    }
}
