<div
    class="flex flex-col w-full"
    *transloco="let tCardHeader; read: 'card_header'"
    [formGroup]="uniqueFormGroup"
>
    <div class="flex flex-wrap mb-3">
        <app-tb-input
            class="w-full"
            label="Title"
            [formControl]="uniqueFormGroup.controls.name"
            [placeholder]="tCardHeader('name')"
            (keydown.enter)="updateUniqueOnEnter()"
        ></app-tb-input>
    </div>
    @if (uniqueFormGroup.controls.description; as description) {
    <div class="flex flex-wrap mb-3">
        <mat-form-field class="flex flex-wrap" appearance="outline">
            <textarea
                class="body-1 w-full"
                matInput
                [formControl]="description"
                (keydown.enter)="updateUniqueOnEnter()"
                [placeholder]="tCardHeader('description')"
            ></textarea>
        </mat-form-field>
    </div>
    }
</div>
<div *appEnv="'prod'; visible: false" class="flex justify-start w-full">
    <app-tb-tabs
        [tabs]="cardTabs"
        [activeTab]="activeTab"
        (tabOpened)="activeTab = $event"
    ></app-tb-tabs>
</div>
@if (activeTab.id === 'details') {
<app-fields-collection
    [accordionContent]="accordionContent"
    [form]="formGroup"
    [selectedFolder]="folder"
    [recordFieldValues]="recordFieldValues"
    [excludedFieldTypes]="uniqueFieldTypes"
    [records]="(records$ | async) || {}"
    [folders]="folders$ | async"
    [selectedSchema]="schema"
    [fieldTypes]="(fieldTypes$ | async) || {}"
></app-fields-collection>
<ng-template
    #accordionContent
    [appTypedTemplate]="contextType"
    let-fieldTypes="fieldTypes"
    let-folder="folder"
    let-fields="fields"
    let-values="values"
    let-records="records"
    let-form="form"
    let-groupName="groupName"
>
    <app-record-fields
        [fieldTypes]="fieldTypes"
        [folder]="folder"
        [fields]="fields"
        [values]="values"
        [records]="records"
        [formDirectiveGroup]="form"
        [groupName]="groupName"
    ></app-record-fields>
</ng-template>
} @if (activeTab.id === 'subtasks') {
<app-card-subtasks
    *appEnv="'prod'; visible: false"
    [schema]="schema"
    [record]="record"
    [fieldTypes]="(fieldTypes$ | async) || {}"
></app-card-subtasks>
}
