<div class="sm:h-7 h-8 sm:bg-transparent bg-white rounded ml-4">
    <app-tb-button
        *transloco="let tShow; read: 'show'"
        appearance="text"
        icon="custom_sliders_horizontal"
        [color]="(isPreview$ | async) ? 'secondary' : 'primary'"
        (click)="openShowModal()"
        [disabled]="!!(hasNoCustomizeViewErrors$ | async)"
    >
        <div class="body-2">{{ tShow('show_title') }}</div>
    </app-tb-button>
</div>
