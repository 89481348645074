@if (allowEdit) {
<!-- change to app-tb-button after new design will be implemented -->
@for (user of users; track user) {
<button (click)="toggleUser(user.guid)" mat-menu-item>
    <app-user-avatar
        [userName]="user.fullName"
        [userUrl]="user.avatar_url"
        [class.active]="activeUserGuids!.includes(user.guid)"
    ></app-user-avatar>
    {{user.fullName}}
</button>
} } @else {
<!-- change to app-tb-button after new design will be implemented -->
@for (user of users; track user) {
<button mat-menu-item>
    <app-user-avatar
        [userName]="user.fullName"
        [userUrl]="user.avatar_url"
        [class.active]="activeUserGuids && activeUserGuids.includes(user.guid)"
    ></app-user-avatar>
    {{user.fullName}}
</button>
} }
