import { AsyncPipe, NgTemplateOutlet } from '@angular/common'
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { AbstractControl, FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms'
import { MatMenuModule } from '@angular/material/menu'
import { BaseCellComponent } from '@app/feature/input-cells/base-container-content'
import { UsersListComponent } from '@app/feature/input-cells/common/users-list/users-list.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbCheckboxComponent } from '@components-library/tb-checkbox'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbIconToggleButtonComponent } from '@components-library/tb-icon-toggle-button/tb-icon-toggle-button.component'
import { TbInputComponent } from '@components-library/tb-input/tb-input.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { isNonNull } from '@core/global-util'
import { FieldTypes, RecordSystemFieldNames, User } from '@core/models'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'
import { UserFacadeService } from '@services/store-facade'
import { UserAvatarComponent } from '@shared/user-avatar/user-avatar.component'
import { without } from 'lodash-es'
import { filter, Observable, of, shareReplay, Subscription } from 'rxjs'
import { take } from 'rxjs/operators'

/**
 * Cell is used for People/Assignee/CreatedBy/UpdatedBy/Favorite
 */
@UntilDestroy()
@Component({
    selector: 'app-people-cell',
    standalone: true,
    imports: [
        TbMenuComponent,
        TbInputComponent,
        TbButtonComponent,
        MatMenuModule,
        UserAvatarComponent,
        TbCheckboxComponent,
        ModalLayoutComponent,
        AsyncPipe,
        TbDividerComponent,
        ReactiveFormsModule,
        NgTemplateOutlet,
        UsersListComponent,
        TranslocoModule,
        TbIconToggleButtonComponent,
    ],
    templateUrl: './people-cell.component.html',
    styleUrl: './people-cell.component.sass',
})
export class PeopleCellComponent extends BaseCellComponent implements OnInit, OnChanges {
    @ViewChild(UsersListComponent) usersListComponent!: UsersListComponent

    @Input() placeholder = 'cell_placeholder.people'

    isDirty = false
    isEdit = false
    isUserBy = false
    isFavorite = false

    userEntities!: Dictionary<User>

    dirtySubscription: Subscription | undefined

    currentUser!: User
    currentUser$: Observable<User> = this.userFacadeService.currentUserEntity$
        .pipe(untilDestroyed(this))
        .pipe(filter(isNonNull))

    unknownErrorMessage$$ = this.translation
        .selectTranslate('user_list.unknown_users_selected_error')
        .pipe(take(1), untilDestroyed(this), shareReplay(1))

    selectedUsersControl = new FormControl('', {
        nonNullable: true,
        validators: [this.hasUnknownUsersValidator()],
    })
    selectedUsers = new Set<string>()

    get errorState(): boolean {
        return this.selectedUsersControl.invalid && this.touched
    }

    get isCurrentUserSelected() {
        return this.currentUser && this.selectedUsers.has(this.currentUser.guid)
    }

    get isNoBorderedAppearance() {
        return !!this.styleConfig?.disable_hover_and_mouse_events
    }

    get moreThanOneSelected() {
        return this.isCurrentUserSelected
            ? this.selectedUsers.size > 1
            : this.selectedUsers.size > 0
    }

    get showCurrentUser() {
        return (
            this.isCurrentUserSelected &&
            ((this.isFavorite && this.isNoBorderedAppearance) || !this.isFavorite)
        )
    }

    constructor(private userFacadeService: UserFacadeService) {
        super()
    }

    ngOnInit(): void {
        this.userFacadeService.userEntities$
            .pipe(untilDestroyed(this))
            .subscribe((userEntities) => {
                this.userEntities = userEntities
                // Call updateValueAndValidity() to trigger validation on Unknown users
                this.selectedUsersControl.updateValueAndValidity()
            })

        this.isUserBy =
            (this.field.system_name === RecordSystemFieldNames.CREATE_BY ||
                this.field.system_name === RecordSystemFieldNames.UPDATE_BY) &&
            !this.extraData?.filter_input

        this.isFavorite =
            this.field.field_type_code === FieldTypes.WATCH && !this.extraData?.filter_input

        if (this.isUserBy) {
            this.readonly = true
            this.inputContainer.readonly = true
        }

        this.mergeValidators(this.selectedUsersControl)

        this.currentUser$.subscribe((user) => (this.currentUser = user))

        this.selectedUsersControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.updateSelectedUsers(value)
        })

        this.selectedUsersControl.statusChanges.subscribe((status) => {
            if (status === 'INVALID' && this.selectedUsersControl.errors?.['hasUnknownUsers']) {
                this.unknownErrorMessage$$.subscribe((message) => {
                    this.inputContainer.setErrorMessage(message)
                })
            }
            if (status === 'VALID') {
                this.inputContainer.removeErrorMessage()
            }
        })

        if (this.value) {
            this.selectedUsersControl.setValue(this.value)
        }

        this.setCheckOnDirty()
    }

    ngOnChanges(changes: SimpleChanges): void {
        const value = changes.value?.currentValue
        if (value && !changes.value.firstChange) {
            this.selectedUsersControl.setValue(value)
            this.updateSelectedUsers(value)

            this.setCheckOnDirty()
        }
    }

    writeValue(value?: string): void {
        if (value) {
            this.value = value
            this.selectedUsersControl.setValue(value)
            this.cd.detectChanges()
        }
    }

    onOutsideClick(): void {
        if (!this.usersListComponent.menu.menuOpen && this.focused) {
            this.makeTouchedAndUnfocused()
            this.inputContainer.resetActive()
            this.removeFocus()
            this.emitValue()
        }

        // to prevent menu layout shifting
        setTimeout(() => {
            this.isEdit = false
        }, 300)
    }

    isUserUnknown(user: User) {
        return !!this.userEntities?.[user.guid]
    }

    override eventIsInsideCell(event: MouseEvent) {
        return this.isTargetInsideMenu(this.usersListComponent.menu.menuTrigger, event)
    }

    onContainerClick() {
        const userListWithoutCurrent = this.isCurrentUserSelected
            ? this.selectedUsers.size > 1
            : this.selectedUsers.size >= 1

        const usersAreInList = this.isFavorite
            ? userListWithoutCurrent
            : this.selectedUsers.size > 1

        if (!usersAreInList || this.styleConfig?.disable_hover_and_mouse_events) return

        this.isEdit = false
        this.usersListComponent.openMenu()
    }

    onFocusClick(): void {
        if (this.isUserBy || this.readonly || this.loadingState) return

        this.isEdit = true
        this.makeFocused()

        if (!this.isFavorite) {
            this.usersListComponent.openMenu()
        }
    }

    removeFocus(): void {
        this.focused = false
    }

    toggleFavorite(active: boolean) {
        const userGuids = this.selectedUsersControl.value
        const guidList = userGuids ? userGuids.split(',') : []

        if (active) {
            guidList.push(this.currentUser.guid)
            this.selectedUsersControl.setValue(guidList.join())
            return
        }

        const value = without(guidList, this.currentUser.guid).join()
        this.selectedUsersControl.setValue(value)
    }

    emitValue(): void {
        this.touched = true
        if (!this.isDirty || this.errorState) return

        this.value = this.selectedUsersControl.value
        this.valueChange.emit(this.value)
        this.onChange(this.value)
        this.onTouched()
        this.setCheckOnDirty()
        this.touched = false
        this.focused = false
        this.selectedUsersControl.markAsUntouched()
        this.usersListComponent.resetSelectedUnknownUsers(this.value)
    }

    resetValue(): void {
        if (this.isDirty) {
            this.usersListComponent.resetSelectedUnknownUsers(this.value)
        }

        this.resetControl(this.selectedUsersControl, this.value)
    }

    private hasUnknownUsersValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value || !this.userEntities || this.isFavorite) return null

            const guids: string[] = (control.value || '').split(',')

            const hasInvalidGuid = guids.some((guid) => !this.userEntities[guid])

            return hasInvalidGuid ? { hasUnknownUsers: true } : null
        }
    }

    private setCheckOnDirty() {
        this.dirtySubscription?.unsubscribe()

        this.dirtySubscription = dirtyCheck(
            this.selectedUsersControl,
            of(this.selectedUsersControl.value),
            {
                debounce: 0,
                useBeforeunloadEvent: false,
            },
        )
            .pipe(untilDestroyed(this))
            .subscribe((isDirty) => {
                this.isDirty = isDirty
            })
    }

    private updateSelectedUsers(value: string | null) {
        if (value) {
            this.selectedUsers = new Set(value.split(','))
            return
        }

        this.selectedUsers.clear()
    }
}
