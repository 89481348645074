<div class="flex flex-row justify-start items-center rating-block">
    <div>
        @for (reit of countStarsInArray; track reit) {
            <mat-icon class="star-item"> star </mat-icon>
        }
    </div>
    <mat-slider max="5" min="1" step="1">
        <input matSliderThumb (change)="valueChanged()" [formControl]="sliderControl" />
    </mat-slider>
</div>
