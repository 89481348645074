import { AfterContentInit, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { PinRecordService } from '@app/feature/pin-panel/pin-record.service'
import { CardActivityComponent } from '@app/feature/record-card/card-activity/card-activity.component'
import { CardDetailComponent } from '@app/feature/record-card/card-detail/card-detail.component'
import { EnvDirective } from '@app/shared/env.directive'
import { FieldsCollectionComponent } from '@app/shared/fields-collection/fields-collection.component'
import { RecordFieldsComponent } from '@app/shared/record-fields/record-fields.component'
import { TypedTemplateDirective } from '@app/shared/typed-template.directive'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbSpinnerComponent } from '@components-library/tb-spinner/tb-spinner.component'
import {
    BusinessRecords,
    Cell,
    CellEntities,
    CurrentUser,
    Field,
    FieldEntities,
    FieldTypes,
    findImportantFieldByType,
    Folder,
    getRecordCells,
    isFolderGlobal,
    UpdateResponseModel,
    Schema,
    findFieldBySystemName,
} from '@core/models'
import {
    FolderFacadeService,
    RecordFacadeService,
    SchemaFacadeService,
    UserFacadeService,
} from '@core/services/store-facade'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { TranslocoModule } from '@ngneat/transloco'
import { CardSubtasksComponent } from './card-subtasks/card-subtasks.component'
import { TbTabsComponent } from '@components-library/tb-tabs/tb-tabs.component'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { CardHeaderComponent } from './header/card-header.component'
import { AsyncPipe, NgTemplateOutlet } from '@angular/common'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { RecordsService } from '@services/records.service'
import { combineLatest, Observable, of } from 'rxjs'
import { switchMap, take } from 'rxjs/operators'

export interface DialogCardInput {
    recordGuid: string
    isFolder: boolean
}

export interface UniqueFormGroup {
    name: FormControl<string | null>
    description?: FormControl<string | null>
}

@UntilDestroy()
@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.sass'],
    standalone: true,
    imports: [
        CardHeaderComponent,
        MatButtonModule,
        MatIconModule,
        TbTabsComponent,
        CardSubtasksComponent,
        AsyncPipe,
        CardDetailComponent,
        TbButtonComponent,
        MatProgressSpinnerModule,
        FieldsCollectionComponent,
        TypedTemplateDirective,
        RecordFieldsComponent,
        CardActivityComponent,
        EnvDirective,
        TranslocoModule,
        NgTemplateOutlet,
        TbSpinnerComponent,
    ],
})
export class CardComponent extends ModalContainerComponent implements OnInit, AfterContentInit {
    readonly folders$ = this.folderFacadeService.selectAllFolders$

    record!: BusinessRecords
    schema!: Schema
    fields!: FieldEntities

    isRecordPinned: boolean = false
    selectedFolder!: Folder
    user!: CurrentUser

    watchField!: Field

    isFavorite = false

    cells!: CellEntities

    formGroup!: FormGroup
    uniqueGroupControl!: FormGroup<UniqueFormGroup>
    recordFieldValues: { [fieldGuid: string]: string } = {}

    constructor(
        private recordFacadeService: RecordFacadeService,
        private recordService: RecordsService,
        private schemaFacadeService: SchemaFacadeService,
        private folderFacadeService: FolderFacadeService,
        private userFacadeService: UserFacadeService,
        protected modalManagerService: ModalManagerService,
        protected confirmationDialogService: ConfirmationDialogService,
        public pinRecordService: PinRecordService,
        @Inject(ModalContainerDataToken) public data: DialogCardInput,
    ) {
        super(data, modalManagerService, confirmationDialogService)
    }

    ngOnInit(): void {
        if (this.data.isFolder) {
            this.selectCardData().subscribe(([record, folder, fields, schema, user]) => {
                if (!record || !folder || !fields || !schema || !user) return

                this.selectedFolder = folder
                this.schema = schema

                this.user = user
                this.record = record
                this.fields = fields

                this.cells = getRecordCells(record)

                this.watchField = findImportantFieldByType(this.fields, FieldTypes.WATCH)
                this.isFavorite = this.cells[this.watchField.guid].value.includes(this.user.guid)

                this.convertCellsToFieldValuesObject()

                this.isRecordPinned = this.pinRecordService.isRecordPinned(this.record!.guid)
            })
        } else {
            this.selectCardDataForUndefinedFolder()
        }
    }

    ngAfterContentInit() {
        this.setFormGroup()
    }

    pinRecord() {
        const guid = this.record.guid

        if (!this.pinRecordService.isRecordPinned(guid)) {
            this.pinRecordService.pinRecord(guid, this.record.name.value)
        }

        this.close()
    }

    private setFormGroup() {
        this.formGroup = new FormGroup({}, { updateOn: 'blur' })
        this.setUniqueFormGroups()
        combineLatest([this.formGroup.valueChanges, this.folders$.pipe(take(1))]).subscribe(
            ([value, folders]) => {
                if (!value) return

                const availableFolders = this.getAvailableFolders(folders)

                const valueKeys = [
                    'required',
                    'shared',
                    ...availableFolders.map((folder) => folder.guid),
                ]

                valueKeys.forEach((valueKey) => {
                    const { cell, cellValue } = this.getUpdatedCellAndValue(value[valueKey]) ?? {}
                    if (cell && cellValue && this.formGroup.enabled) {
                        this.updateRecord(cell, cellValue)
                    }
                })
            },
        )
    }

    private getAvailableFolders(folders: Folder[]) {
        return isFolderGlobal(this.selectedFolder)
            ? folders
            : folders.filter(
                  (folder) => folder.guid === this.selectedFolder.guid || isFolderGlobal(folder),
              )
    }

    private getUpdatedCellAndValue(value?: { [guid: string]: string }) {
        if (!value) return

        const changedKey = Object.keys(value).find(
            (guid) => this.cells[guid]?.value !== value[guid],
        )

        if (!changedKey) return

        return { cell: this.cells[changedKey], cellValue: value[changedKey] }
    }

    private setUniqueFormGroups() {
        const nameField = findImportantFieldByType(this.fields, FieldTypes.NAME)
        const descriptionField =
            findImportantFieldByType(this.fields, FieldTypes.RICH_TEXT) ??
            findFieldBySystemName(this.fields, this.selectedFolder, 'description')

        this.uniqueGroupControl = new FormGroup<UniqueFormGroup>(
            {
                name: new FormControl(this.recordFieldValues[nameField.guid], Validators.required),
            },
            { updateOn: 'blur' },
        )

        this.uniqueGroupControl.controls.name.valueChanges.subscribe((value) => {
            this.updateRecord(this.cells[nameField.guid], value ?? '')
        })

        if (descriptionField) {
            this.uniqueGroupControl.addControl(
                'description',
                new FormControl(this.recordFieldValues[descriptionField.guid]),
            )

            this.uniqueGroupControl.controls.description?.valueChanges.subscribe((value) => {
                this.updateRecord(this.cells[descriptionField.guid], value ?? '')
            })
        }
    }

    private convertCellsToFieldValuesObject() {
        Object.keys(this.cells).reduce((acc, guid) => {
            acc[guid] = this.cells[guid].value
            return acc
        }, this.recordFieldValues)
    }

    private updateRecord(cell: Cell, value: string) {
        this.wrapResponse(
            this.recordService.updateRecord({
                record: this.record,
                cell,
                value,
            }),
        )
    }

    private selectCardData() {
        return combineLatest([
            this.recordFacadeService.selectRecordById$(this.data.recordGuid),
            this.folderFacadeService.selectSelectedFolder$,
        ]).pipe(
            switchMap(([record, folder]) => {
                if (!record) return of([])
                return combineLatest([
                    of(record),
                    of(folder),
                    this.schemaFacadeService.selectSchemaFieldsByFolder$(record.schemaGuid, folder),
                    this.schemaFacadeService.selectSchemaByGuid$(record.schemaGuid),
                    this.userFacadeService.currentUser$,
                ])
            }),
            untilDestroyed(this),
        )
    }

    private selectCardDataForUndefinedFolder() {
        this.recordFacadeService
            .selectRecordById$(this.data.recordGuid)
            .pipe(
                switchMap((record) => {
                    if (!record) return of([record])

                    return combineLatest([
                        of(record),
                        this.schemaFacadeService.selectSchemaByGuid$(record.schemaGuid),
                        this.userFacadeService.currentUser$,
                    ])
                }),
                untilDestroyed(this),
            )
            .subscribe(([record, schema, user]) => {
                if (!record || !schema || !user) return

                this.fields = schema.fieldEntities
                this.schema = schema
                this.record = record
                this.user = user
                this.cells = getRecordCells(record)
                this.convertCellsToFieldValuesObject()
                this.isRecordPinned = this.pinRecordService.isRecordPinned(this.record!.guid)
            })
    }

    private wrapResponse(observable: Observable<UpdateResponseModel>) {
        this.wrapResponseObservable<UpdateResponseModel>(observable).subscribe()
    }
}
