import { Component, OnInit } from '@angular/core'
import {
    FilterDialogData,
    FilterModalComponent,
} from '@app/views/view-controls/view-filter/filter-modal/filter-modal.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { openViewFilterDialog } from '@core/@ngrx/ui'
import { Field, FilterGroup, Folder, View } from '@core/models'
import {
    FolderFacadeService,
    SchemaFacadeService,
    ViewFacadeService,
} from '@core/services/store-facade'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Actions, ofType } from '@ngrx/effects'
import { FilterStorageService } from '@services/local-storage/filter-storage.service'
import { ViewErrorType, ViewValidatorService } from '@services/view-validator.service'
import { FieldNameComponent } from '@shared/field-name/field-name.component'
import { combineLatest } from 'rxjs'
import { take } from 'rxjs/operators'
import { ViewFilterService } from 'src/app/views/view-controls/view-filter/view-filter.service'
import { AsyncPipe, NgTemplateOutlet } from '@angular/common'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'

@UntilDestroy()
@Component({
    selector: 'app-view-filter',
    templateUrl: './view-filter.component.html',
    styleUrls: ['./view-filter.component.sass'],
    standalone: true,
    imports: [
        TbButtonComponent,
        NgTemplateOutlet,
        TranslocoModule,
        AsyncPipe,
        TbIconComponent,
        FieldNameComponent,
    ],
})
export class ViewFilterComponent implements OnInit {
    filterGroups!: FilterGroup[]
    selectedView!: View
    selectedFolder!: Folder
    filterField!: Field

    isPreview: boolean = false

    hasErrors = false
    hasFilterErrors = false

    hasErrors$ = this.viewValidatorService.errors$.pipe(untilDestroyed(this))

    hasFilterErrors$ = this.viewValidatorService
        .validateViewErrors({
            filter: [ViewErrorType.filterInvalidField],
        })
        .pipe(untilDestroyed(this))

    constructor(
        private viewFacadeService: ViewFacadeService,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private filterStorageService: FilterStorageService,
        private filterService: ViewFilterService,
        private modalFlowManagerService: ModalFlowManagerService,
        private actions$: Actions,
        private viewValidatorService: ViewValidatorService,
    ) {
        this.subscribeOnOpenAction()
    }

    get filterCount() {
        if (this.filterGroups && this.filterGroups.length === 1) {
            if (!this.filterGroups[0].values) return 1

            const keys = Object.keys(this.filterGroups[0].values)
            if (keys.length) {
                return keys.length
            }
        }
        return this.filterGroups ? this.filterGroups.length : ''
    }

    ngOnInit(): void {
        this.hasErrors$
            .pipe(untilDestroyed(this))
            .subscribe((hasErrors) => (this.hasErrors = !!hasErrors))
        this.hasFilterErrors$
            .pipe(untilDestroyed(this))
            .subscribe((hasFilterErrors) => (this.hasFilterErrors = !!hasFilterErrors))

        this.filterStorageService
            .isSet$()
            .pipe(untilDestroyed(this))
            .subscribe((state) => (this.isPreview = state))

        combineLatest([
            this.viewFacadeService.selectedView$,
            this.folderFacadeService.selectSelectedFolder$,
            this.schemaFacadeService.selectSelectedTableSchemaFieldEntities$,
            this.filterStorageService.getStore$(),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([view, selectedFolder, fieldEntities]) => {
                if (!view || !fieldEntities) return

                this.selectedView = view
                this.selectedFolder = selectedFolder

                this.filterGroups = this.filterService.getFilterGroupByView(view)
                this.filterStorageService.updateIsSetValue(view.guid)

                if (this.filterCount === 1 && this.filterGroups[0].values) {
                    const keys = Object.keys(this.filterGroups[0].values)
                    this.filterField = fieldEntities[keys[0]]
                }
            })
    }

    getButtonColor(hasErrors: boolean) {
        if (hasErrors) return 'error'

        return this.isFilterTextSessionOrNotApplied() ? 'secondary' : 'text'
    }

    // TODO: make it called when needed instead on every CD
    isFilterTextSessionOrNotApplied() {
        if (!this.isViewFilterValue()) {
            return false
        }

        return this.filterGroups && this.filterGroups.length !== 0
    }

    subscribeOnOpenAction() {
        this.actions$.pipe(ofType(openViewFilterDialog), untilDestroyed(this)).subscribe(() => {
            this.openDialog()
        })
    }

    openDialog() {
        this.modalFlowManagerService
            .openDialog<FilterModalComponent, FilterDialogData>({
                component: FilterModalComponent,
                data: {
                    filterGroups: this.filterGroups,
                    selectedView: this.selectedView,
                },
            })
            .pipe(take(1))
            .subscribe(() => {
                this.filterGroups = this.filterService.getFilterGroupByView(this.selectedView)
                this.filterStorageService.updateIsSetValue(this.selectedView.guid)
            })
    }

    private isViewFilterValue() {
        const filterValue = this.selectedView?.filter?.value
        if (!filterValue) return false

        return JSON.parse(filterValue).length
    }
}
