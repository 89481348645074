import { Injectable, OnDestroy } from '@angular/core'
import { throwAndLogError } from '@core/global-util'
import { WsBaseService } from '@core/services/ws-base.service'
import * as signalR from '@microsoft/signalr'
import { HubConnection, LogLevel } from '@microsoft/signalr'
import { ErrorNavigatorService } from '@services/error-navigator.service'
import { LogService } from '@services/log.service'
import { NotificationService } from '@services/notification.service'
import { CommonFacadeService } from '@services/store-facade'
import { EMPTY, from, Observable, of, Subject } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { AccessErrors, ObjectResponseModel } from '../models'
import { AuthResponse, AuthService } from './auth-service'

@Injectable()
export class WsService extends WsBaseService implements OnDestroy {
    private readonly RETRY_COUNT = 3
    private readonly RETRY_TIMEOUT = 1000
    private connection: HubConnection | undefined
    private onMessageSubject = new Subject<ObjectResponseModel>()
    private isErrorOnReconnect = false

    constructor(
        private authService: AuthService,
        private logService: LogService,
        private errorNavigatorService: ErrorNavigatorService,
        private commonFacadeService: CommonFacadeService,
        private notificationService: NotificationService,
    ) {
        super()
    }

    private static logError(error: any): Observable<never> {
        console.log(error)
        return EMPTY
    }

    disconnect() {
        return this.stopConnection().pipe(switchMap(() => this.authService.logout()))
    }

    connect(): Observable<void> {
        console.log('connect')
        return this.authService.auth().pipe(
            switchMap((resp: AuthResponse) => {
                if (resp.error) {
                    this.errorNavigatorService.goToSomethingWentWrongPage()
                    return throwAndLogError(this.logService, resp.error.message, resp.error.message)
                }

                return of(resp)
            }),
            switchMap((resp: AuthResponse) =>
                this.stopConnection().pipe(
                    switchMap(() => {
                        if (resp.data.ws && resp.data.token) {
                            this.connection = new signalR.HubConnectionBuilder()
                                .withUrl(resp.data.ws, {
                                    accessTokenFactory: (): string | Promise<string> =>
                                        resp.data.token!,
                                })
                                .configureLogging(LogLevel.Debug)
                                .withAutomaticReconnect({
                                    nextRetryDelayInMilliseconds: (retryContext) => {
                                        if (retryContext.previousRetryCount === this.RETRY_COUNT) {
                                            this.commonFacadeService.setLoading(false)
                                            this.isErrorOnReconnect = true
                                            return null
                                        }
                                        return this.RETRY_TIMEOUT
                                    },
                                })
                                .build()

                            this.connection.onclose((error) => {
                                if (error || this.isErrorOnReconnect) {
                                    this.errorNavigatorService.goToSomethingWentWrongPage()
                                    console.log('close with error')
                                    return
                                }

                                console.log('onclose')
                            })

                            this.connection.onreconnecting(() => {
                                this.commonFacadeService.setLoading(true)
                            })

                            this.connection.onreconnected(() => {
                                this.commonFacadeService.setLoading(false)
                                this.isErrorOnReconnect = false
                            })

                            return from(this.connection.start())
                        }
                        return EMPTY
                    }),
                ),
            ),
            catchError((e) => {
                console.error(e)
                this.commonFacadeService.setLoading(false)
                return EMPTY
            }),
        )
    }

    onMessage(): Observable<ObjectResponseModel> {
        return this.onMessageSubject.asObservable()
    }

    sendMessage(endpoint: string, body?: any): void {
        let then = () => {}

        if (body) {
            this.connection!.send(endpoint, body).then(then)
        } else {
            this.connection!.send(endpoint).then(then)
        }
    }

    invokeMessage(endpoint: string, body?: object): Observable<ObjectResponseModel> {
        this.logService.request(endpoint, JSON.stringify(body))
        const invocationMessage = this.generateInvocationMessage(body)
        const request: Promise<ObjectResponseModel> = this.connection!.invoke(
            endpoint,
            invocationMessage,
        )

        return this.combineResponseObservable(request)
    }

    stopConnection(): Observable<void> {
        if (this.connection) {
            return from(this.connection.stop()).pipe(catchError(WsService.logError))
        }

        return of(undefined)
    }

    ngOnDestroy(): void {
        this.stopConnection()
    }

    public subOnMessage(endpoint: string): void {
        if (this.connection && this.onMessageSubject) {
            this.connection.on(endpoint, (res) => {
                this.onMessageSubject.next(res)
            })
        }
    }

    private combineResponseObservable(request: Promise<ObjectResponseModel>) {
        return from(request).pipe(
            switchMap((data) => {
                if (data.status === 'error' && data.error) {
                    const errorStringArray = data.error.map((error) => error.message)

                    data.error.forEach((error) => {
                        if (error.message === AccessErrors.access0003) {
                            this.errorNavigatorService.goToSomethingWentWrongPage()
                            this.commonFacadeService.setError(JSON.stringify(data))
                            this.notificationService.openErrorNotification(data.error!)
                        }
                    })

                    return throwAndLogError(this.logService, errorStringArray, errorStringArray)
                }

                return of(data)
            }),
        )
    }
}
