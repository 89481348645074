import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { TbTagComponent } from './components/tb-tag/tb-tag.component'
import { TbIconModule } from '../tb-icon/tb-icon.module'

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, TbIconModule, TbTagComponent],
    exports: [TbTagComponent],
})
export class TbTagsModule {}
