import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { Folder } from '@core/models'
import { NavigatorService } from '@core/services/navigator.service'
import { FolderFacadeService } from '@core/services/store-facade'
import { SystemRecordsFacadeService } from '@core/services/system-records-facade.service'
import { UntilDestroy } from '@ngneat/until-destroy'
import { LogService } from '@services/log.service'
import { map, take, tap } from 'rxjs/operators'
import { combineLatest } from 'rxjs'
import {
    AddFolderComponent,
    FolderDialogData,
} from 'src/app/feature/header/folder-controls/add-folder-dialog-content/add-folder.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TranslocoModule } from '@ngneat/transloco'
import { MatDividerModule } from '@angular/material/divider'
import { FolderComponent } from '../folder/folder.component'
import { AsyncPipe } from '@angular/common'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'

@UntilDestroy()
@Component({
    selector: 'app-folder-list',
    templateUrl: './folders-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ModalLayoutComponent,
        FolderComponent,
        MatDividerModule,
        TranslocoModule,
        TbButtonComponent,
        AsyncPipe,
    ],
})
export class FoldersListComponent extends ModalContainerComponent {
    constructor(
        private systemRecordsFacadeService: SystemRecordsFacadeService,
        private folderFacadeService: FolderFacadeService,
        private navigatorService: NavigatorService,
        private logService: LogService,
        protected modalManagerService: ModalManagerService,
        protected confirmationDialogService: ConfirmationDialogService,
        @Inject(ModalContainerDataToken) protected data: unknown,
    ) {
        super(data, modalManagerService, confirmationDialogService)
    }

    folders$ = combineLatest([
        this.folderFacadeService.selectAllFolders$,
        this.folderFacadeService.selectGlobalFolder$,
    ]).pipe(
        map(([folders, global]) => {
            const nonGlobal = folders.filter((folder) => folder !== global)

            return { global, nonGlobal }
        }),
        tap((value) => {
            if (!value.global) {
                this.logService.error(new Error('no global folder'))
            }
        }),
    )
    selectedFolder$ = this.folderFacadeService.selectSelectedFolder$

    selectFolder(folder: Folder) {
        this.folderFacadeService.setSelectedFolderGuid(folder.guid)
        this.redirectToSelectedSystemObject()
    }

    redirectToSelectedSystemObject() {
        this.systemRecordsFacadeService
            .getSelectedSystemRecords()
            .pipe(take(1))
            .subscribe((selectedSystemRecords) => {
                const { tableSchema, folder } = selectedSystemRecords

                if (tableSchema && folder) {
                    this.navigatorService.goToSystemObject(tableSchema.guid, folder.guid)
                } else if (folder) {
                    this.navigatorService.goToFolder(folder.guid)
                }
            })
    }

    openFolderCreateDialog() {
        this.openFolderDialog()
    }

    openFolderEditDialog(folder: Folder) {
        this.openFolderDialog({
            updateData: {
                name: folder.name.value,
                icon: folder.icon.value,
                color: folder.color.value,
            },
            folder,
        })
    }

    private openFolderDialog(data?: FolderDialogData) {
        return this.modalManagerService
            .openDialog<AddFolderComponent, FolderDialogData>({
                component: AddFolderComponent,
                data: data,
            })
            .pipe(take(1))
    }
}
