import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop'
import { AsyncPipe } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { SortItemComponent } from '@app/views/view-controls/view-sort/sort-modal/sort-item/sort-item.component'
import { CommonDialogResultStatus } from '@components-library/tb-confirmation-popup/tb-confirmation-dialog.component'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { generateUuid } from '@core/global-util'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Field, Folder, UpdateResponseModel, View } from '@core/models'
import { FolderFacadeService, SchemaFacadeService, ViewFacadeService } from '@services/store-facade'
import { combineLatest, Observable, of } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'
import { SortDirection, SortObject, SortObjectEntities } from '@models/ui/sort.model'
import { SortStorageService } from '@services/local-storage/sort-storage.service'
import { cloneDeepWith, isEqual } from 'lodash-es'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { FieldExistsInSchemaValidator } from '@app/views/view-controls/validators'
import { ViewSortService } from '@app/views/view-controls/view-sort/view-sort.service'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'

export type SortItemFormGroup = {
    select: FormControl<string | null>
    direction: FormControl<SortDirection | null>
}

@UntilDestroy()
@Component({
    selector: 'app-sort-modal',
    templateUrl: './sort-modal.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        ModalLayoutComponent,
        CdkDropList,
        ReactiveFormsModule,
        SortItemComponent,
        TbButtonComponent,
        AsyncPipe,
        TbDividerComponent,
        TbMenuComponent,
        TbMenuListComponent,
        TbMenuListItemComponent,
    ],
})
export class SortModalComponent extends ModalContainerComponent implements OnInit {
    selectedFolder$ = this.folderFacadeService.selectSelectedFolder$

    private readonly combinedSortRelatedData$ = this.selectedFolder$.pipe(
        untilDestroyed(this),
        switchMap((folder) => {
            return this.combineViewAndFields(folder)
        }),
    )

    sortMenuForm!: FormGroup

    fields!: Field[]
    sortEntitiesArray!: SortObject[]
    sortObjectsArray!: SortObject[]
    selectedView!: View

    isSaveAvailable: boolean = false
    isSessionSaveAvailable: boolean = false
    isSessionValueSet: boolean = false

    isNotSelectedItem = false
    isDirty = false

    constructor(
        private schemaFacadeService: SchemaFacadeService,
        private sortStorageService: SortStorageService,
        private viewFacadeService: ViewFacadeService,
        private folderFacadeService: FolderFacadeService,
        private sortService: ViewSortService,
        private readonly fieldExistsValidator: FieldExistsInSchemaValidator,
        private translation: TranslocoService,
        modalManagerService: ModalManagerService,
        confirmationPopupService: ConfirmationDialogService,
        @Inject(ModalContainerDataToken) protected data: unknown,
    ) {
        super(data, modalManagerService, confirmationPopupService)
    }

    ngOnInit() {
        this.sortStorageService
            .isSet$()
            .pipe(untilDestroyed(this))
            .subscribe((isSessionSortSet) => (this.isSessionValueSet = isSessionSortSet))

        this.setInitSortData()
    }

    getSortItemGroup(guid: string) {
        return this.sortMenuForm.controls[guid] as FormGroup
    }

    updateSortObjects(object: SortObject, index: number) {
        const currentObject = this.sortObjectsArray[index]
        if (currentObject.fieldGuid !== object.fieldGuid) {
            this.sortMenuForm.controls[object.fieldGuid] =
                this.sortMenuForm.controls[currentObject.fieldGuid]
            delete this.sortMenuForm.controls[currentObject.fieldGuid]
        }
        this.sortObjectsArray[index] = object
    }

    removeSortObject(guid: string, index: number) {
        this.sortObjectsArray = this.sortObjectsArray.filter(
            (guid, guidIndex) => guidIndex !== index,
        )

        this.sortMenuForm.removeControl(guid)

        if (!this.sortObjectsArray.length) {
            this.onClear()
        }
    }

    addSort() {
        const newGuid = this.generateUuidWithNewPrefix()
        this.sortObjectsArray.push(this.setEmptySortArray(newGuid)[0])
        this.sortMenuForm.setControl(
            newGuid,
            new FormGroup({
                select: new FormControl('', {
                    asyncValidators: [this.fieldExistsValidator.validate],
                }),
                direction: new FormControl(),
            }),
        )
    }

    preview() {
        const filteredSortArray = this.filterEmptyOptions()

        this.saveSortToStorage(this.convertArrayToObject(filteredSortArray))
    }

    save() {
        const filteredSortArray = this.filterEmptyOptions()

        this.saveSortToView(this.convertArrayToObject(filteredSortArray))
    }

    onClear() {
        this.sortObjectsArray = this.setEmptySortArray(this.generateUuidWithNewPrefix())
        this.setSortMenuForm()
        this.refreshSaveAvailability()
    }

    isSortDefault() {
        return this.filterEmptyOptions().length
    }

    fieldsForSortItem(sortObject: SortObject) {
        const objectsArray = this.sortObjectsArray.filter(
            (object) => object.fieldGuid !== sortObject.fieldGuid,
        )

        return this.filterFieldsBySortObjects(objectsArray)
    }

    resetToSave() {
        this.setSortObjectsArray(null)
        this.reset()

        this.close()
    }

    resetToDefault() {
        this.confirmationDialogService
            .openCommon({
                translations: {
                    title: 'reset_view_control_confirmation.reset_title',
                    message: 'reset_view_control_confirmation.reset_sort_message',
                    cancel: 'reset_view_control_confirmation.reset_cancel',
                    confirm: 'reset_view_control_confirmation.reset_confirm',
                },
            })
            .subscribe((result) => {
                if (result === CommonDialogResultStatus.CANCEL) {
                    this.saveSortToView({})
                }
            })
    }

    drop(event: CdkDragDrop<SortObject[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)

        this.refreshSaveAvailability()
    }

    hasChanged(): boolean {
        return this.isDirty
    }

    private setInitSortData() {
        this.combinedSortRelatedData$.subscribe(([view, fields, columnGuid]) => {
            this.selectedView = view

            this.sortStorageService.updateIsSetValue(view.guid)
            this.sortEntitiesArray = Object.values(this.sortService.getSortByView(view))

            this.setSortObjectsArray(columnGuid)

            this.setSortMenuForm()

            this.fields = this.sortService.filterAllowedSortFieldsByType(fields)

            this.refreshSaveAvailability()
        })
    }

    private combineViewAndFields(folder: Folder) {
        return this.viewFacadeService.selectedView$.pipe(
            filter(Boolean),
            switchMap((view) =>
                combineLatest([
                    of(view),
                    this.schemaFacadeService
                        .selectSelectedTableSchemaFieldEntitiesFiltered$(folder)
                        .pipe(filter(Boolean)),
                    this.sortService.getSortGuidFromColumn$,
                    this.sortStorageService.getStore$(),
                ]).pipe(untilDestroyed(this)),
            ),
        )
    }

    private setSortMenuForm() {
        this.sortMenuForm = new FormGroup(
            this.sortObjectsArray.reduce(
                (sortControls, sortObject) => {
                    sortControls[sortObject.fieldGuid] = new FormGroup<SortItemFormGroup>({
                        select: new FormControl(sortObject.fieldGuid, {
                            asyncValidators: [this.fieldExistsValidator.validate],
                        }),
                        direction: new FormControl<SortDirection | null>(sortObject.sortDirection),
                    })
                    return sortControls
                },
                {} as { [sortGuid: string]: FormGroup<SortItemFormGroup> },
            ),
        )

        this.sortMenuForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.refreshSaveAvailability()
        })

        dirtyCheck(this.sortMenuForm, of(this.sortMenuForm.value), {
            debounce: 0,
            useBeforeunloadEvent: false,
        })
            .pipe(untilDestroyed(this))
            .subscribe((isDirty) => {
                this.isDirty = isDirty
            })

        if (this.sortMenuForm.invalid) {
            Object.keys(this.sortMenuForm.controls).forEach((key) => {
                if (
                    (this.sortMenuForm.controls[key] as FormGroup<SortItemFormGroup>).controls
                        .select.errors?.['invalidField']
                ) {
                    this.errors = [this.translation.translate('sort.field_no_exist_error_message')]
                }
            })
        }
    }

    private refreshSaveAvailability() {
        this.isSessionSaveAvailable = !isEqual(this.filterEmptyOptions(), this.sortEntitiesArray)
        this.isSaveAvailable = this.isSessionSaveAvailable || this.isSessionValueSet
    }

    private saveSortToView(sortObjects: SortObjectEntities) {
        const sortUpdateObservable = this.viewFacadeService.updateViewRequest(this.selectedView, {
            sort: {
                cell: this.selectedView.sort,
                newValue: JSON.stringify(sortObjects),
            },
        })

        this.updateSortValueInSelectedView(sortUpdateObservable)

        this.sortStorageService.remove(this.selectedView.guid)
    }

    private saveSortToStorage(sortObjects: SortObjectEntities) {
        this.sortService.setSortToStorage(this.selectedView.guid, sortObjects)
        this.sortService.clearSortGuid()
        this.onUpdatePreview(this.sortStorageService.isSet$())
    }

    private reset() {
        this.sortStorageService.remove(this.selectedView.guid)
        this.sortService.clearSortGuid()
        this.onUpdatePreview(this.sortStorageService.isSet$())
    }

    private setSortObjectsArray(columnGuid: string | null) {
        if (!columnGuid || !this.isColumnGuidAvailable(columnGuid)) {
            this.sortObjectsArray = this.sortEntitiesArray.length
                ? cloneDeepWith(this.sortEntitiesArray)
                : this.setEmptySortArray(this.generateUuidWithNewPrefix())
            return
        }

        this.setObjectsArrayWithColumnSort(columnGuid)
    }

    private isColumnGuidAvailable(columnGuid: string) {
        return !this.sortEntitiesArray.find((object) => object.fieldGuid === columnGuid)
    }

    private setObjectsArrayWithColumnSort(columnGuid: string) {
        const sortObjectFromColumn: SortObject = {
            fieldGuid: columnGuid,
            sortDirection: SortDirection.DESC,
        }

        this.sortObjectsArray = this.sortEntitiesArray.length
            ? [...cloneDeepWith(this.sortEntitiesArray), sortObjectFromColumn]
            : [sortObjectFromColumn]
    }

    private convertArrayToObject(sortObjectsArray: SortObject[]) {
        return sortObjectsArray.reduce((sortObjects, object) => {
            sortObjects[object.fieldGuid] = object
            return sortObjects
        }, {} as SortObjectEntities)
    }

    private filterEmptyOptions() {
        return this.sortObjectsArray.filter(
            (sortObject) => sortObject.fieldGuid.length && !!sortObject.sortDirection,
        )
    }

    private filterFieldsBySortObjects(objectsArray: SortObject[]) {
        return this.fields.filter(
            (field) => !objectsArray.find((sortObject) => sortObject.fieldGuid === field.guid),
        )
    }

    private setEmptySortArray(guid: string) {
        return [
            {
                fieldGuid: guid,
            } as SortObject,
        ]
    }

    private onUpdatePreview(updateObservable: Observable<boolean>) {
        this.wrapResponseObservable<boolean>(updateObservable).subscribe(() => {
            this.close()
        })
    }

    private updateSortValueInSelectedView(sortUpdateObservable: Observable<UpdateResponseModel>) {
        this.wrapResponseObservable<UpdateResponseModel>(sortUpdateObservable).subscribe((data) => {
            if (data.status === 'success') {
                this.isSessionValueSet = false
                this.sortStorageService.remove(this.selectedView.guid)
                this.close()
            }
        })
    }

    private generateUuidWithNewPrefix() {
        return `new-${generateUuid()}`
    }
}
