import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { TbToggleButtonGroupComponent } from '@components-library/tb-toggle-button-group/tb-toggle-button-group.component'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-filter-combination-toggle',
    templateUrl: './filter-combination-toggle.component.html',
    styleUrls: ['./filter-combination-toggle.component.sass'],
    standalone: true,
    imports: [TranslocoModule, MatButtonToggleModule, TbToggleButtonGroupComponent],
})
export class FilterCombinationToggleComponent implements OnInit {
    @Input()
    combinationTypes: Record<string, string> = {}

    @Input()
    translateKey: string = 'filter.combination_types'

    @Input()
    value!: string

    @Input()
    disabled: boolean = false

    @Output() valueChange = new EventEmitter<string>()

    ngOnInit() {
        if (!this.value) {
            this.value = this.combinationTypes[Object.keys(this.combinationTypes)[0]]
        }
    }
}
