<div class="w-full flex justify-between">
    <div class="flex items-center grow w-full">
        @if (selectedView.type_code.value !== ViewTypeCodes.BOARD) {
            <div class="flex w-[1.5rem] h-[1.5rem]">
                <app-tb-icon-toggle-button
                    #toggleButtonComponent
                    icon="custom_pin_outline"
                    toggledIcon="custom_pin_fill"
                    [isActive]="pinned"
                    (toggle)="onPin()"
                    class="mr-2 w-full h-full"
                ></app-tb-icon-toggle-button>
            </div>
        }
        <app-field-name
            [field]="field"
            [folder]="selectedFolder"
            [showIcon]="false"
        ></app-field-name>
    </div>
    <app-tb-toggle [checked]="toggleValue" (valueChange)="toggle.emit($event)"></app-tb-toggle>
</div>
