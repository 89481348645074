import { isNil, omitBy } from 'lodash-es'
import {
    AggregateFunctionsModel,
    FieldTypes,
    FieldTypeStatus,
    ResponseFieldType,
    ResponseFieldTypeEntities,
} from '../response'

export const fieldTypeCodesIcons: { [key: string]: string } = {
    '': 'block',
    [FieldTypes.NAME]: 'format_size',
    [FieldTypes.TEXT]: 'format_size',
    [FieldTypes.MONEY]: 'paid',
    [FieldTypes.EMAIL]: 'alternate_email',
    [FieldTypes.WEBSITE]: 'captive_portal',
    [FieldTypes.NUMBER]: 'numbers',
    [FieldTypes.STATUS]: 'task_alt',
    [FieldTypes.DROPDOWN]: 'top_panel_open',
    [FieldTypes.ASSIGNEE]: 'custom_people_outline',
    [FieldTypes.PEOPLE]: 'group',
    [FieldTypes.BOOL]: 'edit_attributes',
    [FieldTypes.RATING]: 'thumb_up',
    [FieldTypes.DATE_TIME]: 'calendar_today',
    [FieldTypes.DATE_RANGE]: 'calendar_month',
    [FieldTypes.DATE]: 'event',
    [FieldTypes.WATCH]: 'custom_star_outline',
    [FieldTypes.MULTILINE_TEXT]: 'insert_text',
    [FieldTypes.LINK]: 'add_link',
    [FieldTypes.RICH_TEXT]: 'description',
}

export interface OperationCode {
    // could be more than one field of current type per folder
    multiple?: boolean
    // field could be created
    create?: boolean
    // field could be updated
    update?: boolean
    // field could be deleted
    delete?: boolean
    //all operations are forbidden
    system?: boolean
    // field of current field type could be created only in global folder
    global?: boolean
    // field can't be moved to another folder
    freeze?: boolean
}

enum OperationCodeOptions {
    M = 'multiple',
    C = 'create',
    U = 'update',
    D = 'delete',
    S = 'system',
    G = 'global',
    F = 'freeze',
}

export interface FieldType {
    guid: string
    name: string
    icon: string
    isSelect: boolean
    isAggFunction: boolean
    aggFunction?: Array<AggregateFunctionsModel | string>
    frontendValidations?: string[]
    typeDataValidations?: string[]
    filterOptions: string[]
    operationCode: OperationCode
    status: boolean
}

export function getIconByFieldType(fieldTypeGuid: string): string {
    return fieldTypeCodesIcons[fieldTypeGuid]
}

function isValidOperationCodeOption(letter: string): letter is keyof typeof OperationCodeOptions {
    return letter in OperationCodeOptions
}

export function prepareOperationCode(
    operation_code: string | undefined,
): OperationCode | undefined {
    if (operation_code) {
        if (operation_code.includes('S') && operation_code.length !== 1) {
            console.log(new Error('incorrect operation code'))
        }
        return Array.from(operation_code).reduce((acc: OperationCode, letter: string) => {
            if (isValidOperationCodeOption(letter)) {
                const operationCodeOption = OperationCodeOptions[letter]
                acc[operationCodeOption] = true
            } else {
                console.log(new Error('not implemented operation code'))
            }
            return acc
        }, {} as OperationCode)
    }
    return
}

export function generateFieldTypes(
    responseFieldTypeEntities: ResponseFieldTypeEntities,
): FieldType[] {
    return Object.keys(responseFieldTypeEntities).map((fieldTypeGuid: string) => {
        const responseFieldType: ResponseFieldType = responseFieldTypeEntities[fieldTypeGuid]

        const result = {
            guid: fieldTypeGuid,
            aggFunction: responseFieldType.agg_function || undefined,
            frontendValidations: responseFieldType.frontend_validations || [],
            name: responseFieldType.name || undefined,
            isAggFunction: !!responseFieldType.is_agg_function,
            isSelect: !!responseFieldType.is_select,
            typeDataValidations: responseFieldType.type_data_validations || [],
            icon: getIconByFieldType(fieldTypeGuid),
            filterOptions: responseFieldType.filter_conditions || [],
            operationCode: prepareOperationCode(responseFieldType.operation_code)!,
            status: responseFieldType.status === FieldTypeStatus.ACTIVE,
        } as FieldType

        // Deletes null and undefined props from result object
        return omitBy(result, isNil) as FieldType
    })
}
