<div class="flex items-center w-full">
    <div
        *transloco="let t"
        class="empty:hidden flex"
        [class.w-full]="selectedUsers.size === 0 && !isFavorite"
    >
        @if (selectedUsers.size === 0 && !isFavorite && !focused) {
            @if (placeholderOnMouseover) {
                <span
                    class="invisible group-hover/container:visible body-1 w-full text-newTextLight"
                >
                    {{ t(placeholder) }}
                </span>
                <span
                    class="visible group-hover/container:invisible body-1 w-full text-newTextLight"
                ></span>
            } @else {
                <span class="body-1 w-full text-newTextLight">{{ t(placeholder) }}</span>
            }
        } @else {
            @if (showCurrentUser) {
                <div class="flex items-center">
                    <div
                        class="flex border border-newPrimaryLight rounded-full p-[0.1875rem] m-[-0.25rem] mr-0"
                    >
                        <app-user-avatar
                            class="flex relative rounded-full"
                            [userName]="currentUser.fullName"
                            [userUrl]="currentUser.avatar_url"
                            [unknown]="isUserUnknown(currentUser)"
                            [border]="false"
                            [small]="true"
                        ></app-user-avatar>
                    </div>
                    @if (selectedUsers.size === 1) {
                        <span class="body-1 ms-1" *transloco="let tUserList; read: 'user_list'">{{
                            tUserList('me', { name: currentUser.fullName })
                        }}</span>
                    }

                    @if (selectedUsers.size > 1) {
                        <div class="w-[1px] h-[1.5rem] bg-newNeutral4 mx-1"></div>
                    }
                </div>
            } @else if (isFavorite && !isNoBorderedAppearance) {
                <div class="flex items-center">
                    <app-tb-icon-toggle-button
                        icon="custom_star_outline"
                        toggledIcon="custom_star_full"
                        [class.pointer-events-none]="!focused"
                        [isActive]="isCurrentUserSelected"
                        (toggle)="toggleFavorite($event)"
                    ></app-tb-icon-toggle-button>

                    @if (!focused && moreThanOneSelected) {
                        <div class="w-[1px] h-full bg-newNeutral4 mx-1"></div>
                    }
                </div>
            }
        }
    </div>

    <app-users-list
        class="pointer-events-none"
        [formControl]="selectedUsersControl"
        [editMode]="isEdit"
        [class]="{ invisible: focused && isFavorite }"
        [fullNameForOneUser]="!isFavorite || (isFavorite && isNoBorderedAppearance)"
        [small]="true"
        [border]="true"
    >
    </app-users-list>
</div>
