import { Component, OnInit } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { FilterTypesComponent } from '@app/views/view-controls/view-filter/filter-types/filter-types.component'
import { User } from '@models/ui'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { UserFacadeService } from '@core/services/store-facade'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { UserAvatarComponent } from '@shared/user-avatar/user-avatar.component'

import { MatMenuModule } from '@angular/material/menu'

@UntilDestroy()
@Component({
    selector: 'app-assign-filter',
    templateUrl: './assign-filter.component.html',
    styleUrls: ['./assign-filter.component.sass'],
    standalone: true,
    imports: [
        MatMenuModule,
        UserAvatarComponent,
        TbIconComponent,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        TbButtonComponent,
    ],
})
export class AssignFilterComponent extends FilterTypesComponent implements OnInit {
    users!: User[]
    userGuids: string[] = []
    search!: FormControl<string | null>
    assignedUsers: User[] = []
    isShowAssignees!: boolean

    constructor(private userFacadeService: UserFacadeService) {
        super()
    }

    ngOnInit(): void {
        this.userFacadeService.users$.pipe(untilDestroyed(this)).subscribe((users) => {
            this.users = users
        })
        const control = this.form.controls['value']
        if (control?.value) {
            this.userGuids = control.value
            this.assignedUsers = this.users.filter((item) => this.userGuids.includes(item.guid))
        }
        this.search = new FormControl<string>('')
    }

    getAssignUsersLength(): number {
        if (this.assignedUsers) {
            return this.assignedUsers.length
        }
        return 0
    }

    addUser(guid: string) {
        this.addUniqueGuid(guid)

        this.assignedUsers = this.users.filter((item) => this.userGuids.includes(item.guid))

        this.form.controls['value'].setValue([...this.userGuids])
        this.form.controls['value'].markAsTouched()
    }

    addUniqueGuid(guid: string) {
        if (!this.userGuids.includes(guid)) {
            this.userGuids.push(guid)
        } else {
            this.userGuids.splice(this.userGuids.indexOf(guid), 1)
        }
    }
}
