import { Injectable } from '@angular/core'
import { CardComponent, DialogCardInput } from '@app/feature/record-card/card.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { BehaviorSubject, Subject } from 'rxjs'
import { generateUuid } from '@core/global-util'
import { Folder, Schema } from '@core/models'

export interface PinRecord {
    guid: string
    name: string
}

export interface PinDraftRecord {
    name: string
    data: DraftPinRecord
    guid: string
}

export interface DraftPinRecord {
    schema: Schema
    folders: Folder[]
    selectedFolder: Folder
    name: string | null
    fieldValues: { [fieldGuid: string]: string }
}

export interface PinObject {
    type: PinObjectType
    record: PinRecord | PinDraftRecord
}

export enum PinObjectType {
    CREATED,
    DRAFT,
}

@Injectable({
    providedIn: 'root',
})
export class PinRecordService {
    constructor(private modalFlowManagerService: ModalFlowManagerService) {}

    private readonly pinObjects: Map<string, PinObject> = new Map()
    public readonly pinObjects$: Subject<PinObject[]> = new BehaviorSubject<PinObject[]>([])

    isRecordPinned(guid: string) {
        return this.pinObjects.has(guid)
    }

    openPinItem(guid: string) {
        this.modalFlowManagerService.closeAllFlows()

        this.modalFlowManagerService.openDialog<CardComponent, DialogCardInput>({
            component: CardComponent,
            data: { recordGuid: guid, isFolder: true },
        })
    }

    pinDraftRecord(data: DraftPinRecord) {
        this.updateDraftRecord(generateUuid(), data)
    }

    updateDraftRecord(guid: string, data: DraftPinRecord) {
        const pinRecord = {
            name: data.name ?? 'Draft',
            data,
            guid,
        }

        this.pinObjects.set(guid, { type: PinObjectType.DRAFT, record: pinRecord })
        this.pinObjects$.next(Array.from(this.pinObjects.values()))
    }

    pinRecord(guid: string, name: string) {
        if (this.pinObjects.has(guid)) return
        const pinRecord = {
            guid,
            name,
        }

        this.pinObjects.set(guid, { type: PinObjectType.CREATED, record: pinRecord })
        this.pinObjects$.next(Array.from(this.pinObjects.values()))
    }

    removePinRecord(guid: string) {
        this.pinObjects.delete(guid)
        this.pinObjects$.next(Array.from(this.pinObjects.values()))
    }
}
