import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { svgIcons } from './tb-icon.model'
import { MatIconModule } from '@angular/material/icon'

@Component({
    selector: 'app-tb-icon',
    templateUrl: './tb-icon.component.html',
    styleUrls: ['tb-icon.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatIconModule],
})
export class TbIconComponent implements OnInit {
    @Input()
    color!: string

    @Input()
    icon!: string

    @Input() style: 'rounded' | 'svg' = 'rounded'

    @Input()
    filled = false

    get colorCss() {
        return this.color ? `text-${this.color}` : ''
    }

    ngOnInit() {
        if (!!svgIcons[this.icon as keyof typeof svgIcons]) this.style = 'svg'
    }
}
