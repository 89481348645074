import { Component, Input } from '@angular/core'
import { InputCellContainerComponent } from '@app/feature/input-cells/input-cell-container/input-cell-container.component'
import { CellEntities, FieldEntities, FieldType, Folder } from '@core/models'
import { Dictionary } from '@ngrx/entity'
import {
    CellContainerComponent,
    NEW_CONTAINER_CELLS,
} from '../../../shared/cell-types/cell-container.component'
import { FieldNameComponent } from '../../../shared/field-name/field-name.component'

@Component({
    selector: 'app-board-card-fields',
    templateUrl: './board-card-fields.component.html',
    styleUrls: ['./board-card-fields.component.sass'],
    standalone: true,
    imports: [FieldNameComponent, CellContainerComponent, InputCellContainerComponent],
})
export class BoardCardFieldsComponent {
    @Input()
    fields?: string[]

    @Input()
    fieldEntities!: FieldEntities

    @Input()
    fieldTypes!: Dictionary<FieldType>

    @Input()
    selectedFolder!: Folder | undefined

    @Input()
    cells!: CellEntities

    hasNewContainerSupport(type: string) {
        return NEW_CONTAINER_CELLS.includes(type)
    }
}
