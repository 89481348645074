<div class="edit-mode-form">
    <div [class.active]="!disabled" class="flex items-center edit-mode-area">
        <input
            ngxDaterangepickerMd
            class="data-input"
            [placeholder]="valueLocale"
            [singleDatePicker]="true"
            [autoApply]="true"
            [customRangeDirection]="false"
            [showDropdowns]="true"
            (change)="filterFrom()"
            [appAutoFocus]="isEdit"
            autocomplete="off"
        />
        @if (value) {
        <mat-icon (click)="clearDate()" class="text-muted"> close </mat-icon>
        }
    </div>
    @if (disabled) {
    <div>
        <label> {{valueLocale}} </label>
    </div>
    }
</div>
