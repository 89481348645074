import { Component } from '@angular/core'
import {
    FieldTypeSelectorComponent,
    FieldTypeSelectorData,
} from '@app/shared/field-type-selector/field-type-selector.component'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { Field } from '@models/ui'
import { UntilDestroy } from '@ngneat/until-destroy'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { FieldConfigurationTypeComponent } from './field-configuration-type/field-configuration-type.component'
import { FieldConfigurationSelectorComponent } from './field-configuration-selector/field-configuration-selector.component'
import { MatDialogClose } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { TranslocoModule } from '@ngneat/transloco'

@UntilDestroy()
@Component({
    selector: 'app-field-configuration',
    templateUrl: './field-configuration.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        MatIconModule,
        MatDialogClose,
        FieldConfigurationSelectorComponent,
        FieldConfigurationTypeComponent,
        TbButtonComponent,
    ],
})
export class FieldConfigurationComponent extends ModalContainerComponent {
    addField() {
        this.modalManagerService
            .openDialog<FieldTypeSelectorComponent, FieldTypeSelectorData, Field | undefined>({
                component: FieldTypeSelectorComponent,
                data: { newFolder: true },
            })
            .subscribe((field) => {
                //TODO: use field
                console.log(field)
            })
    }
}
