export const svgIcons: Record<string, string> = {
    custom_apps: 'CUSTOM_apps.svg',
    custom_board: 'CUSTOM_board.svg',
    custom_cards: 'CUSTOM_cards.svg',
    custom_chatbubble_ellipses: 'CUSTOM_chatbubble_ellipses.svg',
    custom_companies: 'CUSTOM_companies.svg',
    custom_contacts: 'CUSTOM_contacts.svg',
    custom_copy: 'CUSTOM_copy.svg',
    custom_deals: 'CUSTOM_deals.svg',
    custom_drag: 'CUSTOM_drag.svg',
    custom_edit: 'CUSTOM_edit.svg',
    custom_email: 'CUSTOM_email.svg',
    custom_group: 'CUSTOM_group_by.svg',
    custom_help_support: 'CUSTOM_help&support.svg',
    custom_home: 'CUSTOM_home.svg',
    custom_notifications: 'CUSTOM_notifications.svg',
    custom_notifications_new: 'CUSTOM_notifications_new.svg',
    custom_open: 'CUSTOM_open.svg',
    custom_pin: 'CUSTOM_pin.svg',
    custom_products: 'CUSTOM_products.svg',
    custom_search: 'CUSTOM_search.svg',
    custom_settings: 'CUSTOM_settings.svg',
    custom_sliders_horizontal: 'CUSTOM_sliders_horizontal.svg',
    custom_share: 'CUSTOM_share.svg',
    custom_show: 'CUSTOM_show.svg',
    custom_slider: 'CUSTOM_slider.svg',
    custom_sort: 'CUSTOM_sort.svg',
    custom_sort_down: 'CUSTOM_sort_down.svg',
    custom_sort_up: 'CUSTOM_sort_up.svg',
    custom_subtask: 'CUSTOM_subtask.svg',
    custom_arrow_up: 'CUSTOM_arrow_up.svg',
    custom_arrow_down: 'CUSTOM_arrow_down.svg',
    custom_arrow_back: 'CUSTOM_arrow_back.svg',
    custom_arrow_forward: 'CUSTOM_arrow_forward.svg',
    custom_table: 'CUSTOM_table.svg',
    custom_tasks: 'CUSTOM_tasks.svg',
    custom_trash: 'CUSTOM_trash.svg',
    filters_img: 'Filters.svg',
    rocket_img: 'rocket_placeholder.svg',
    table: 'Table.svg',
    kanban: 'Board.svg',
}
