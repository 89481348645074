<div class="flex xsm:h-full md:h-[90vh]">
    @if (showLoader) {
        <div class="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center z-50">
            <div class="w-full h-full bg-newNeutral1 opacity-50"></div>
            <mat-spinner class="absolute"></mat-spinner>
        </div>
    }
    <div class="absolute right-0 flex flex-row justify-end p-2">
        <app-tb-button appearance="icon" icon="remove" (click)="pinRecord()"></app-tb-button>
        <app-tb-button appearance="icon" icon="close" (click)="close()"></app-tb-button>
    </div>
    <div class="card-side w-[60%] h-full pt-4 pl-6 pr-2">
        @if (errors) {
            <ng-container *ngTemplateOutlet="error"></ng-container>
        }
        <app-card-header
            class="card-title"
            *transloco="let tNavMenu; read: 'nav_menu'"
            [schemaName]="tNavMenu(schema.name)"
            [isFavorite]="isFavorite"
        ></app-card-header>
        <app-card-detail
            class="w-full overflow-auto flex-1 relative p-2"
            [uniqueFormGroup]="uniqueGroupControl"
            [formGroup]="formGroup"
            [schema]="schema"
            [folder]="selectedFolder"
            [recordFieldValues]="recordFieldValues"
            [record]="record"
        >
        </app-card-detail>
    </div>
    <app-card-activity class="card-side w-[40%] bg-newNeutral2"></app-card-activity>
</div>
<ng-template #error>
    @if (errors) {
        <div class="text-red-600 pt-4">
            @for (error of errors; track error) {
                <div>{{ error }}</div>
            }
        </div>
    } @else {
        <!-- TODO: add correct default error -->
        <div
            *transloco="let tNotification; read: 'notification_messages'"
            class="text-red-600 pt-4"
        >
            {{ tNotification('default_error') }}
        </div>
    }
</ng-template>
