import { Component, Inject, Input } from '@angular/core'
import { AsyncPipe, DOCUMENT, NgTemplateOutlet } from '@angular/common'
import { CardSubtasksComponent } from '@app/feature/record-card/card-subtasks/card-subtasks.component'
import { UniqueFormGroup } from '@app/feature/record-card/card.component'
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { AccordionContentContext } from '@app/shared/fields-collection/fields-collection.component'
import { SharedModule } from '@app/shared/shared.module'
import { TbInputComponent } from '@components-library/tb-input/tb-input.component'
import { TbTab } from '@components-library/tb-tab/tb-tab.component'
import { TbTabsComponent } from '@components-library/tb-tabs/tb-tabs.component'
import { BusinessRecords, FieldTypes, Folder, Schema } from '@core/models'
import { FieldType } from '@models/ui'
import { TranslocoModule } from '@ngneat/transloco'
import { Dictionary } from '@ngrx/entity'
import {
    FieldTypeFacadeService,
    FolderFacadeService,
    RecordFacadeService,
} from '@services/store-facade'
import { Observable } from 'rxjs'

@Component({
    selector: 'app-card-detail',
    standalone: true,
    imports: [
        AsyncPipe,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgTemplateOutlet,
        SharedModule,
        TranslocoModule,
        ReactiveFormsModule,
        TbInputComponent,
        TbTabsComponent,
        CardSubtasksComponent,
    ],
    templateUrl: './card-detail.component.html',
    styleUrl: './card-detail.component.sass',
})
export class CardDetailComponent {
    readonly fieldTypes$: Observable<Dictionary<FieldType>> =
        this.fieldTypeFacadeService.selectFieldTypeEntities$
    readonly records$ = this.recordFacadeService.selectRecordEntities$
    readonly folders$ = this.folderFacadeService.selectAllFolders$

    readonly uniqueFieldTypes = [
        FieldTypes.WATCH,
        FieldTypes.NAME,
        FieldTypes.RICH_TEXT,
    ] as string[]

    @Input() schema!: Schema

    @Input() folder!: Folder

    @Input() record!: BusinessRecords

    @Input() uniqueFormGroup!: FormGroup<UniqueFormGroup>

    @Input() formGroup!: FormGroup

    @Input() recordFieldValues!: { [fieldGuid: string]: string }

    readonly cardTabs: TbTab[] = [
        {
            name: 'Details',
            id: 'details',
            icon: 'info',
        },
        {
            name: 'Subtasks',
            id: 'subtasks',
            icon: 'event_list',
        },
    ]

    activeTab: TbTab = this.cardTabs[0]

    contextType = {} as AccordionContentContext

    constructor(
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private recordFacadeService: RecordFacadeService,
        private folderFacadeService: FolderFacadeService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    updateUniqueOnEnter() {
        ;(this.document.activeElement as HTMLElement).blur()
    }
}
