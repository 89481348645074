<div class="edit-mode-form">
    @if (!isEdit) { @if (!disabled) { @if (value) {
    <span (click)="editMode();" class="input-label"> {{value}} </span>
    } @else {
    <app-tb-icon
        (click)="editMode()"
        class="text-muted icon input-label"
        icon="remove"
    ></app-tb-icon>
    } } @else { @if (value) {
    <span class="input-label"> {{value}} </span>
    } @else {
    <app-tb-icon class="text-muted icon input-label" icon="remove"></app-tb-icon>
    } } } @else {
    <div [class.active]="isEdit" class="edit-mode-area">
        <mat-form-field class="text-input">
            <div class="flex-start-center justify-content-between">
                <input
                    (keyup)="saveOnEnter($event)"
                    [appAutoFocus]="isEdit"
                    [formControl]="textFormControl"
                    autocomplete="off"
                    matInput
                    name="name"
                    type="text"
                />
                <app-tb-icon
                    (click)="saveValue()"
                    class="text-success"
                    icon="check_circle"
                ></app-tb-icon>
            </div>
        </mat-form-field>
    </div>
    <mat-error *transloco="let tCellTypesErrors; read: 'cell_types.errors'">
        {{tCellTypesErrors('value_is')}} @if (textFormControl.hasError('required')) {
        <strong> {{tCellTypesErrors('required')}} </strong>
        } @if (textFormControl.hasError('minlength')) {
        <strong> {{tCellTypesErrors('minlength')}} </strong>
        } @if (textFormControl.hasError('maxlength')) {
        <strong> {{tCellTypesErrors('maxlength')}} </strong>
        }
    </mat-error>
    }
</div>
