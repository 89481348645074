<div class="example-form">
    <label class="w-full flex flex-row justify-start items-center"> Choose a currency </label>
    <mat-form-field class="w-full flex flex-row justify-start items-center">
        <mat-select [formControl]="selectFormControl">
            @for (currency of currencyListKeys(); track currency) {
                <mat-option value="{{ currency }}">
                    {{ currency }} ({{ currencies[currency][4] }})
                </mat-option>
            }
        </mat-select>
    </mat-form-field>
</div>
