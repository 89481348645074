import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { CardComponent, DialogCardInput } from '@app/feature/record-card/card.component'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { BusinessRecords } from '@core/models'
import { SelectOption } from '@core/models/response/select-object-options'
import {
    FolderFacadeService,
    SchemaFacadeService,
    UserFacadeService,
} from '@core/services/store-facade'
import { TranslocoService, TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { combineLatest } from 'rxjs'

@UntilDestroy()
@Component({
    selector: 'app-search-results-item',
    templateUrl: './search-results-item.component.html',
    styleUrls: ['./search-results-item.component.sass'],
    standalone: true,
    imports: [TranslocoModule],
})
export class SearchResultsItemComponent implements OnInit {
    @Input()
    record!: BusinessRecords

    name!: string
    assignee!: string
    status!: SelectOption
    created!: string
    folder!: string
    assigned!: string

    constructor(
        public dialog: MatDialog,
        private translation: TranslocoService,
        private schemaFacadeService: SchemaFacadeService,
        private folderFacadeService: FolderFacadeService,
        private userFacadeService: UserFacadeService,
        private modalManagerService: ModalManagerService,
    ) {}

    ngOnInit() {
        console.log('record', this.record)
        combineLatest([
            this.schemaFacadeService.selectSchemaByGuid$(this.record.schemaGuid),
            this.userFacadeService.userEntities$,
            this.folderFacadeService.selectSelectedFolder$,
            this.translation.selectTranslateObject('search.results'),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([schema, users, folder, names]) => {
                if (schema) {
                    this.name = this.record.name.value

                    const folderGuid = folder.guid
                    const assignee = this.record.assignee[folderGuid].value

                    const user = users ? users[assignee] : null
                    this.assignee =
                        assignee && user
                            ? user.first_name + ' ' + user.last_name
                            : names['assigned']
                }
            })
    }

    openSearchItem() {
        this.modalManagerService.openDialog<CardComponent, DialogCardInput>({
            component: CardComponent,
            data: { recordGuid: this.record.guid, isFolder: true },
        })
    }
}
