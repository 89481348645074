import { Component, Input } from '@angular/core'
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms'
import { CreateSubtaskModel, GlobalFieldNames, Schema } from '@core/models'
import { FolderFacadeService } from '@core/services/store-facade'
import { take } from 'rxjs/operators'
import { SubtaskFacadeService } from '@core/services/store-facade/subtask-facade.service'
import { AutoFocusDirective } from '@shared/cell-types/focus.directive'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'

import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-card-create-subtask',
    templateUrl: './card-create-subtask.component.html',
    styleUrl: './card-create-subtask.component.sass',
    standalone: true,
    imports: [
        TranslocoModule,
        TbButtonComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        AutoFocusDirective,
    ],
})
export class CardCreateSubtaskComponent {
    @Input() subtaskSchema: Schema | null = null
    @Input() parentRecordGuid: string | null = null

    createMode = false

    form = new FormGroup({
        name: new FormControl('', Validators.required),
    })

    constructor(
        private readonly folderFacadeService: FolderFacadeService,
        private readonly subtaskFacadeService: SubtaskFacadeService,
    ) {}

    onCreateClick() {
        this.createMode = true
    }

    onSubmit() {
        this.folderFacadeService.selectSelectedFolderGuid$.pipe(take(1)).subscribe((folderGuid) => {
            if (!this.subtaskSchema) {
                return
            }

            const subtaskSchemaFieldEntities = Object.values(this.subtaskSchema.fieldEntities)
            const nameField = subtaskSchemaFieldEntities.find(
                (field) => field.system_name === GlobalFieldNames.NAME,
            )

            const request: CreateSubtaskModel = {
                solution_object_type_guid: this.subtaskSchema?.guid,
                parent_guid: this.parentRecordGuid || '',
                folders_guid: folderGuid,
                cells: [
                    {
                        field_guid: nameField?.guid || '',
                        value: this.form.value.name || '',
                    },
                ],
            }

            this.subtaskFacadeService.createSubtask(request)
            this.form.reset({})
        })

        this.createMode = false
    }

    onDismiss() {
        this.form.reset({})
        this.createMode = false
    }
}
