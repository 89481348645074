import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ManageFieldsModule } from '@app/feature/manage-fields/manage-fields.module'
import { SharedModule } from '@app/shared/shared.module'
import { ModalManagerModule } from '@components-library/tb-modal-manager/modal-manager.module'
import { TranslocoModule } from '@ngneat/transloco'
import { NgxColorsModule } from 'ngx-colors'
import { AddFolderComponent } from './folder-controls/add-folder-dialog-content/add-folder.component'
import { AdvancedOptionsSectionComponent } from './folder-controls/advanced-options/advanced-options-section/advanced-options-section.component'
import { AdvancedOptionsComponent } from './folder-controls/advanced-options/advanced-options.component'
import { FieldConfigurationSelectorComponent } from './folder-controls/advanced-options/field-configuration/field-configuration-selector/field-configuration-selector.component'
import { FieldConfigurationTypeComponent } from './folder-controls/advanced-options/field-configuration/field-configuration-type/field-configuration-type.component'
import { FieldConfigurationComponent } from './folder-controls/advanced-options/field-configuration/field-configuration.component'
import { SelectViewsSettingsComponent } from './folder-controls/advanced-options/select-views-settings/select-views-settings.component'
import { StatusConfigurationComponent } from './folder-controls/advanced-options/status-configuration/status-configuration.component'
import { StatusElementComponent } from './folder-controls/advanced-options/status-configuration/status-element/status-element.component'
import { TemplateSelectorComponent } from './folder-controls/advanced-options/status-configuration/template-selector/template-selector.component'
import { FolderControlsComponent } from './folder-controls/folder-controls.component'
import { FolderComponent } from './folder-controls/folder/folder.component'
import { FoldersListComponent } from './folder-controls/folders-list/folders-list.component'
import { HeaderNameComponent } from './header-name/header-name.component'
import { HeaderComponent } from './header.component'
import { NotificationsMenuContentComponent } from './notifications-menu/notifications-menu-content/notifications-menu-content.component'
import { NotificationsMenuComponent } from './notifications-menu/notifications-menu.component'
import { SearchModule } from './search/search.module'

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        SearchModule,
        MatIconModule,
        TranslocoModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatButtonModule,
        NgxColorsModule,
        MatDialogModule,
        MatInputModule,
        MatRadioModule,
        MatCheckboxModule,
        MatMenuModule,
        MatDividerModule,
        MatExpansionModule,
        MatSlideToggleModule,
        ModalManagerModule,
        MatTooltipModule,
        ManageFieldsModule,
        HeaderComponent,
        FolderControlsComponent,
        AddFolderComponent,
        AdvancedOptionsComponent,
        AdvancedOptionsSectionComponent,
        SelectViewsSettingsComponent,
        StatusConfigurationComponent,
        StatusElementComponent,
        TemplateSelectorComponent,
        FieldConfigurationComponent,
        FieldConfigurationSelectorComponent,
        FieldConfigurationTypeComponent,
        HeaderNameComponent,
        NotificationsMenuComponent,
        NotificationsMenuContentComponent,
        FolderComponent,
        FoldersListComponent,
    ],
    exports: [HeaderComponent],
})
export class HeaderModule {}
