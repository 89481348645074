@if (selectedRecords.length) {
    <div
        class="flex items-center w-initial h-10 mt-4 mx-6 px-4 rounded-lg bg-newDarkThemePrimaryDark"
    >
        <div class="flex items-center justify-between w-full">
            <div *transloco="let tTable; read: 'table'" class="flex items-center">
                <app-tb-checkbox
                    (valueChange)="clearAllSelected()"
                    [value]="true"
                    class="mr-2 h-5 flex"
                ></app-tb-checkbox>
                <span class="text-newInversed body-1"
                    >{{ tTable('actions_panel_count', { count: selectedRecords.length }) }}
                </span>
            </div>
            <div class="flex items-center">
                <div class="sm:inline-flex xsm:hidden">
                    <ng-container
                        *ngTemplateOutlet="actionButtons; context: { dark: true }"
                    ></ng-container>
                </div>
                <!-- incorrect bg color -->
                <app-tb-menu #xsmMenu [template]="actionButtons">
                    <app-tb-button
                        trigger
                        [appearance]="'icon'"
                        [dark]="true"
                        [icon]="'more_vert'"
                        class="sm:hidden h-6"
                    ></app-tb-button>
                </app-tb-menu>
                <app-tb-button
                    [appearance]="'icon'"
                    [dark]="true"
                    [icon]="'close'"
                    (click)="clearAllSelected()"
                    aria-label="Close"
                    class="h-5 ml-6"
                ></app-tb-button>
            </div>
            <mat-menu class="px-2 mt-2" #fieldsMenu="matMenu">
                <ng-template matMenuContent>
                    @for (field of fieldsList; track field) {
                        <app-tb-button
                            [appearance]="'text'"
                            (click)="editFieldValues(field)"
                            class="flex items-center justify-start w-full"
                        >
                            <span class="w-full flex">
                                <app-field-name
                                    [field]="field"
                                    [folder]="selectedFolder"
                                    [showIcon]="true"
                                ></app-field-name>
                            </span>
                        </app-tb-button>
                    }
                </ng-template>
            </mat-menu>
            <!-- No design, final solution will be implemented later, for testing purposes -->
            <ng-template #automationMenu>
                <div *transloco="let tButtons; read: 'buttons'" class="p-4">
                    <p class="body-1 px-2 py-1 mb-2">
                        <button (click)="createAutomation()">
                            {{ tButtons('create_automation') }}
                        </button>
                    </p>
                    <p class="body-1 px-2 py-1 mb-2">
                        <app-tb-menu [template]="automationUpdate" xPosition="after">
                            <button trigger>{{ tButtons('update_automation') }}</button>
                        </app-tb-menu>
                    </p>
                    <p class="body-1 px-2 py-1 mb-2">
                        <app-tb-menu [template]="automationDelete" xPosition="after">
                            <button trigger>{{ tButtons('delete_automation') }}</button>
                        </app-tb-menu>
                    </p>
                    <p class="body-1 px-2 py-1">
                        <app-tb-menu [template]="automationExecute" xPosition="after">
                            <button trigger>{{ tButtons('execute_automation') }}</button>
                        </app-tb-menu>
                    </p>
                </div>
            </ng-template>
            <ng-template #automationUpdate>
                @for (automation of automations$ | async; track automation) {
                    <div>
                        <button (click)="updateAutomation(automation)">
                            {{ automation.name }}
                        </button>
                    </div>
                }
            </ng-template>
            <ng-template #automationDelete>
                @for (automation of automations$ | async; track automation) {
                    <div>
                        <button (click)="deleteAutomation(automation.guid)">
                            {{ automation.name }}
                        </button>
                    </div>
                }
            </ng-template>
            <ng-template #automationExecute>
                @for (automation of executableAutomations$ | async; track automation) {
                    <div>
                        <button (click)="executeAutomation(automation.guid)">
                            {{ automation.name }}
                        </button>
                    </div>
                }
            </ng-template>
            <ng-template #automationButton let-dark="dark">
                <app-tb-button
                    *transloco="let tButtons; read: 'buttons'"
                    [dark]="dark"
                    appearance="text"
                    icon="build"
                >
                    <span class="xsm:block sm:hidden lg:inline-flex"
                        >{{ tButtons('automations') }}
                    </span>
                </app-tb-button>
            </ng-template>
            <ng-template #actionButtons let-dark="dark">
                <div
                    *transloco="let tButtons; read: 'buttons'"
                    class="flex sm:flex-row xsm:flex-col xsm:items-start sm:items-center gap-2 w-full xsm:body-1"
                >
                    <div
                        *transloco="let tTable; read: 'table'"
                        class="sm:hidden w-full px-4 flex justify-between"
                    >
                        <div class="w-full headline my-4">
                            {{ tTable('action_panel_bottom_sheet_title') }}
                        </div>
                        <app-tb-button
                            appearance="icon"
                            icon="close"
                            class="mt-6 h-5"
                        ></app-tb-button>
                    </div>
                    <app-tb-menu
                        #tbMenu
                        *appEnv="'prod'; visible: false"
                        [template]="automationMenu"
                        xPosition="before"
                        yPosition="above"
                        [class]="tbMenu.menuOpen ? 'bg-newNeutral4_20' : ''"
                        class="rounded-lg"
                    >
                        <ng-container
                            trigger
                            *ngTemplateOutlet="automationButton; context: { dark: dark }"
                        ></ng-container>
                    </app-tb-menu>
                    <app-tb-menu
                        #tbMenu
                        *appEnv="'prod'; visible: true"
                        [template]="automationExecute"
                        [class]="tbMenu.menuOpen ? 'bg-newNeutral4_20' : ''"
                        xPosition="before"
                    >
                        <ng-container
                            trigger
                            *ngTemplateOutlet="automationButton; context: { dark: dark }"
                        ></ng-container>
                    </app-tb-menu>
                    <!-- will be investigated further by designer, could be changed after investigation -->
                    <app-tb-button
                        *appEnv="'prod'; visible: false"
                        [dark]="dark"
                        icon="people_outline"
                        appearance="text"
                    >
                        <span class="xsm:block sm:hidden lg:inline-flex body-2">
                            {{ tButtons('set_assignee') }}
                        </span>
                    </app-tb-button>
                    <app-tb-button
                        *appEnv="'prod'; visible: false"
                        [dark]="dark"
                        icon="task_alt"
                        appearance="text"
                    >
                        <span class="xsm:block sm:hidden lg:inline-flex">
                            {{ tButtons('set_status') }}
                        </span>
                    </app-tb-button>
                    <app-tb-button
                        (click)="setFavorite()"
                        [dark]="dark"
                        icon="star_outline"
                        appearance="text"
                    >
                        <span class="xsm:block sm:hidden lg:inline-flex">
                            {{ tButtons('favorite') }}
                        </span>
                    </app-tb-button>
                    <app-tb-button
                        *appEnv="'prod'; visible: false"
                        [dark]="dark"
                        icon="share"
                        appearance="text"
                    >
                        <span class="xsm:block sm:hidden lg:inline-flex">
                            {{ tButtons('share') }}
                        </span>
                    </app-tb-button>
                    <app-tb-button
                        *appEnv="'prod'; visible: false"
                        [dark]="dark"
                        icon="custom_copy"
                        appearance="text"
                    >
                        <span class="xsm:block sm:hidden lg:inline-flex">
                            {{ tButtons('duplicate') }}
                        </span>
                    </app-tb-button>
                    <app-tb-button
                        *appEnv="'prod'; visible: false"
                        [class.bg-secondaryMain]="trigger.menuOpen"
                        #trigger="matMenuTrigger"
                        [mat-menu-trigger-for]="fieldsMenu"
                        [dark]="dark"
                        icon="cached"
                        appearance="text"
                    >
                        <span class="xsm:block sm:hidden lg:inline-flex">
                            {{ tButtons('edit') }}
                        </span>
                    </app-tb-button>
                    <app-tb-button
                        [dark]="dark"
                        icon="custom_trash"
                        appearance="text"
                        (click)="deleteAllRecords()"
                    >
                        <span class="xsm:block sm:hidden lg:inline-flex">
                            {{ tButtons('delete') }}
                        </span>
                    </app-tb-button>
                </div>
            </ng-template>
        </div>
    </div>
}
