import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { UserSettingsComponent } from 'src/app/feature/user-settings/user-settings.component'
import { UserSettingsContentComponent } from 'src/app/feature/user-settings/user-settings-content/user-settings-content.component'
import { VersionComponent } from 'src/app/feature/user-settings/version/version.component'

import { MatMenuModule } from '@angular/material/menu'
import { SharedModule } from '@app/shared/shared.module'
import { MatIconModule } from '@angular/material/icon'
import { MatDividerModule } from '@angular/material/divider'
import { AsyncPipe, NgTemplateOutlet } from '@angular/common'
import { TranslocoModule } from '@ngneat/transloco'

@NgModule({
    imports: [
        MatMenuModule,
        SharedModule,
        MatIconModule,
        MatDividerModule,
        AsyncPipe,
        TranslocoModule,
        NgTemplateOutlet,
        ReactiveFormsModule,
        UserSettingsComponent,
        UserSettingsContentComponent,
        VersionComponent,
    ],
    exports: [UserSettingsComponent, UserSettingsContentComponent, VersionComponent],
})
export class UserSettingsModule {}
