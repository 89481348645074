import { Component, Input } from '@angular/core'
import { ModalContainer } from '../tb-modal-manager/modal-manager.service'

@Component({
    selector: 'app-tb-menu-list',
    templateUrl: './tb-menu-list.component.html',
    styleUrls: ['./tb-menu-list.component.sass'],
    standalone: true,
})
export class TbMenuListComponent {
    @Input()
    containerLayout!: ModalContainer

    ModalContainer = ModalContainer
}
