<input
    #input
    type="text"
    class="body-1 w-full bg-transparent"
    [formControl]="control"
    (focusin)="onFocusIn()"
    (focusout)="onFocusOut($event)"
    [placeholder]="placeholder"
    [readOnly]="readonly || loadingState || styleConfig?.disable_hover_and_mouse_events"
/>
