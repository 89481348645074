@if (records && records.length) {
<div class="py-2">
    @for (record of records; track record) {
    <div class="flex-start-center w-100">
        <app-search-results-item [record]="record"></app-search-results-item>
    </div>
    }
</div>
} @else {
<div class="flex-start-center h-100 w-100" *transloco="let tSearchResults; read: 'search.results'">
    <mat-icon class="text-primary fs-lg-21"> search </mat-icon>
    <div class="text-primary fs-lg-21">{{tSearchResults('no_result')}}</div>
</div>
}
