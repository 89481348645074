<div
    class="flex py-1 px-2 gap-2 body-1 text-newTextLight rounded bg-newNeutral2 hover:cursor-pointer"
>
    <div>{{ schemaName }}</div>
    <div>/</div>
    @if (folder$ | async; as folder) {
        <div class="flex gap-1 w-fit">
            <app-tb-icon
                [style.color]="folder.color.value"
                [icon]="folder.icon.value"
            ></app-tb-icon>
            {{ folder.name.value }}
        </div>
    } @else {
        <!-- TODO: add correct UI error processing -->
        folderError
    }
</div>
<div class="flex">
    <app-tb-icon-toggle-button
        icon="star"
        [isActive]="isFavorite"
        (toggle)="toggleFavorite($event)"
        [disabled]="disableFavorite"
    ></app-tb-icon-toggle-button>
    <app-tb-menu [template]="automationMenu">
        <app-tb-icon
            trigger
            class="flex p-0.5 w-inherit rounded-lg justify-center items-center transition-colors h-full hover:cursor-pointer hover:bg-newNeutral3"
            color="newPrimary"
            icon="build"
        ></app-tb-icon>
    </app-tb-menu>
    <app-tb-menu [template]="optionsMenu">
        <app-tb-icon
            trigger
            class="flex p-0.5 w-inherit rounded-lg justify-center items-center transition-colors h-full hover:cursor-pointer hover:bg-newNeutral3"
            color="newPrimary"
            icon="more_vert"
        ></app-tb-icon>
    </app-tb-menu>
</div>
<ng-template #automationMenu>
    <app-tb-menu-list>
        @for (automation of automations$ | async; track automation) {
            <app-tb-menu-list-item (click)="executeAutomation(automation.guid)">
                <span item-title>{{ automation.name }}</span>
            </app-tb-menu-list-item>
        }
    </app-tb-menu-list>
</ng-template>
<ng-template #optionsMenu>
    <app-header-options-menu (favorite)="toggleFavorite()"></app-header-options-menu>
</ng-template>
<ng-template #folderError>
    <!-- TODO: add correct UI error processing -->
    folderError
</ng-template>
