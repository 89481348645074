import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { Colors, Palettes } from '../models/colors'
import { upperFirst } from 'lodash-es'
import { TbIconComponent } from '../tb-icon/tb-icon.component'
import { NgTemplateOutlet } from '@angular/common'

export enum TbButtonAppearance {
    primary = 'primary',
    outlined = 'outlined',
    text = 'text',
    icon = 'icon',
    iconPrimary = 'iconPrimary',
    fab = 'fab',
    link = 'link',
}

@Component({
    selector: 'app-tb-button',
    templateUrl: './tb-button.component.html',
    styleUrls: ['./tb-button.component.sass'],
    standalone: true,
    imports: [TbIconComponent, NgTemplateOutlet],
})
export class TbButtonComponent implements OnInit {
    @Input()
    appearance: keyof typeof TbButtonAppearance = TbButtonAppearance.primary

    @Input()
    color: string = Palettes.primary

    @HostBinding('class.pointer-events-none')
    @Input()
    disabled: boolean = false

    @Input()
    icon!: string

    @Input()
    dark: boolean = false

    @Input()
    filled = false

    DISABLED_COLOR = Colors.neutral4

    ngOnInit() {
        if (this.dark) {
            this.DISABLED_COLOR = Colors.darkThemeDisabled
        }
    }

    get bgColor() {
        return this.prepareClass('bg')
    }

    get borderColor() {
        return this.prepareClass('border')
    }

    get textColor() {
        return this.prepareClass('text')
    }

    get iconColor() {
        const color = this.dark ? 'white' : this.color
        return this.disabled ? this.DISABLED_COLOR : color
    }

    getOutlinedClasses() {
        if (this.dark) {
            return this.prepareDarkStyles(['text', 'border'])
        }

        return [
            this.borderColor,
            'hover:' + this.getDark(this.borderColor),
            this.textColor,
            'hover:' + this.getDark(this.textColor),
        ]
    }

    getTextClasses() {
        if (this.dark) {
            return this.prepareDarkStyles(['text'])
        }

        return [
            this.disabled ? this.textColor : this.getLight(this.textColor),
            'hover:' + this.textColor,
        ]
    }

    getIconClasses() {
        if (this.dark) {
            return this.prepareDarkStyles()
        }

        return [this.disabled ? this.textColor : '', 'hover:bg-newNeutral3']
    }

    getDark(color: string) {
        return `${color}Dark`
    }

    getLight(color: string) {
        return `${color}Light`
    }

    private prepareClass(prefix: string) {
        const color = this.disabled ? this.DISABLED_COLOR : this.color
        return color ? `${prefix}-new${upperFirst(color)}` : ''
    }

    private prepareDarkStyles(prefixes: string[] = []) {
        const classes = [`hover:bg-${Colors.darkThemePrimaryDark}`]
        const color = this.disabled ? this.DISABLED_COLOR : 'white'

        return [...classes, ...prefixes.map((prefix) => `${prefix}-${color}`)]
    }
}
