<input
    #input
    *transloco="let t"
    type="text"
    data-testid="input"
    class="body-1 w-full bg-transparent"
    [formControl]="control"
    (focusin)="onFocusIn()"
    (focusout)="onFocusOut($event)"
    [placeholder]="t(placeholderText)"
    [readOnly]="readonly || loadingState || styleConfig?.disable_hover_and_mouse_events"
/>
