<mat-expansion-panel
    hideToggle="true"
    [expanded]="expanded"
    class="flex flex-col rounded-lg"
    [class]="{ 'hover:shadow-light': background }"
    (opened)="expanded = true"
    (closed)="expanded = false"
>
    <mat-expansion-panel-header
        class="h-auto"
        [class]="[headerBackgroundDisplaying, headerPaddingDisplaying, headerRadiusDisplaying]"
    >
        <div class="flex w-full items-start p-2">
            <div class="flex flex-col w-full grow">
                <mat-panel-title class="justify-between text-newText body-2 mr-0">
                    <div class="flex items-center">
                        <app-tb-icon
                            [icon]="expanded ? 'custom_arrow_up' : 'custom_arrow_down'"
                        ></app-tb-icon>
                        <div class="ml-2">{{ title }}</div>
                    </div>
                </mat-panel-title>
                @if (description && background) {
                    <div class="flex text-newTextLight caption-1 ml-5 pl-2">
                        <span>{{ description }}</span>
                    </div>
                }
            </div>
            @if (settingsShow && background) {
                <div (click)="$event.stopPropagation()" #advanced>
                    <ng-content select="[advanced]"></ng-content>
                </div>
                @if (!advanced.childElementCount) {
                    <app-tb-button
                        appearance="icon"
                        icon="custom_settings"
                        color="text"
                        (click)="callAdvanced.emit(); $event.stopPropagation()"
                    ></app-tb-button>
                }
            }
        </div>
    </mat-expansion-panel-header>
    <div class="flex justify-center" [class]="[bodyBackgroundDisplay]">
        <div class="w-full flex justify-center" [class]="[contentBackgroundDisplay]">
            <ng-content></ng-content>
        </div>
    </div>
</mat-expansion-panel>
