@if (records && records.length) {
    <div class="py-2">
        @for (record of records; track record) {
            <div class="flex flex-row justify-start items-center w-full">
                <app-search-results-item [record]="record"></app-search-results-item>
            </div>
        }
    </div>
} @else {
    <div
        class="flex flex-row justify-start items-center h-full w-full"
        *transloco="let tSearchResults; read: 'search.results'"
    >
        <mat-icon class="text-newPrimary title"> search </mat-icon>
        <div class="text-newPrimary title">{{ tSearchResults('no_result') }}</div>
    </div>
}
