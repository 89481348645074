import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    standalone: true,
    imports: [],
})
export class UserAvatarComponent {
    @Input()
    userUrl!: string | undefined

    @Input()
    userName!: string
}
