import { NgForOf, NgIf } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatMenuModule } from '@angular/material/menu'
import { CustomizeViewItemComponent } from '@app/views/view-controls/customize-view/customize-view-item/customize-view-item.component'
import { ShowObject } from '@app/views/view-controls/customize-view/customize-view.service'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { CommonDialogResultStatus } from '@components-library/tb-confirmation-popup/tb-confirmation-dialog.component'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { UpdateResponseModel, ViewData } from '@core/models'
import { AppRecord, FieldEntities, Folder, UpdateViewConfiguration, View } from '@models/ui'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { CustomizeViewStorageService } from '@services/local-storage/customize-view-storage.service'
import { RecordFacadeService, ViewFacadeService } from '@services/store-facade'
import { FieldNameComponent } from '@shared/field-name/field-name.component'
import { isEqual } from 'lodash-es'
import { finalize, Observable, of, Subject } from 'rxjs'
import { take, takeUntil } from 'rxjs/operators'

@UntilDestroy()
@Component({
    selector: 'app-customize-view-modal',
    standalone: true,
    imports: [
        FieldNameComponent,
        MatMenuModule,
        NgForOf,
        NgIf,
        TbButtonComponent,
        ModalLayoutComponent,
        TranslocoModule,
        TbMenuListComponent,
        TbMenuListItemComponent,
        TbDividerComponent,
        TbMenuComponent,
        TbIconComponent,
        CustomizeViewItemComponent,
        ReactiveFormsModule,
    ],
    templateUrl: './customize-view-modal.component.html',
})
export class CustomizeViewModalComponent extends ModalContainerComponent implements OnInit {
    private readonly isToggleAllCalled$$ = new Subject<void>()
    private readonly isToggleAllCalled$ = this.isToggleAllCalled$$.asObservable()

    record!: AppRecord
    fields!: FieldEntities

    visibleFields: string[] = []
    hiddenFields: string[] = []

    viewFieldGuids: string[] = []
    commonToggleGuids: string[] = []

    selectedView!: View
    viewData!: ViewData

    showFormGroup!: FormGroup<{
        pin: FormControl<string | null>
        toggles: FormGroup<{ [guid: string]: FormControl<boolean | null> }>
    }>

    isDirty = false
    isDataSavedInPreview = false

    constructor(
        private viewFacadeService: ViewFacadeService,
        private recordsFacadeService: RecordFacadeService,
        private showStorageService: CustomizeViewStorageService,
        private translation: TranslocoService,
        @Inject(ModalContainerDataToken) public data: Folder,
        protected modalManagerService: ModalManagerService,
        protected confirmationDialogService: ConfirmationDialogService,
    ) {
        super(data, modalManagerService, confirmationDialogService)
    }

    get pinnedGuid() {
        return this.showFormGroup?.value.pin || ''
    }

    ngOnInit(): void {
        this.showStorageService
            .isSet$()
            .pipe(untilDestroyed(this))
            .subscribe((isSet) => (this.isDataSavedInPreview = isSet))

        this.recordsFacadeService
            .selectViewData$()
            .pipe(untilDestroyed(this))
            .subscribe((viewData) => {
                this.setProperties(viewData)
            })
    }

    hasChanged(): boolean {
        return this.isDirty
    }

    getToggleControl(guid: string) {
        return this.showFormGroup.controls.toggles.controls[guid]
    }

    toggleAll() {
        if (this.hiddenFields.length) {
            this.hiddenFields = []
            this.visibleFields = [...this.viewFieldGuids]
        } else {
            this.hiddenFields = [...this.viewFieldGuids]
            this.visibleFields = []
        }

        this.isToggleAllCalled$$.next()
        this.combineShowFormGroup(this.pinnedGuid, true)
    }

    toggle(guid: string, state: boolean | null): void {
        this.showFormGroup.controls.toggles.controls[guid].setValue(state)
        if (state) {
            this.hiddenFields.splice(this.hiddenFields.indexOf(guid), 1)
            this.visibleFields.push(guid)
            return
        }

        this.visibleFields.splice(this.visibleFields.indexOf(guid), 1)
        this.hiddenFields.push(guid)
    }

    pin(guid: string, isPinned = false) {
        if (isPinned) {
            this.showFormGroup.controls.pin.setValue('')
            return
        }

        if (this.pinnedGuid.length) {
            this.confirmationDialogService
                .openCommon({
                    translations: {
                        title: 'table.column_menu.pin_confirmation_title',
                        message: 'table.column_menu.pin_confirmation_message',
                        cancel: 'table.column_menu.pin_confirm',
                        confirm: 'table.column_menu.pin_cancel',
                    },
                })
                .subscribe((result) => {
                    if (result === CommonDialogResultStatus.CANCEL) {
                        this.showFormGroup.controls.pin.setValue(guid)
                    }
                })
            return
        }

        this.showFormGroup.controls.pin.setValue(guid)
    }

    save() {
        if (!this.isSaveAvailable() && !this.isDataSavedInPreview) {
            this.errors = [this.translation.translate('dialog_errors.no_changes_save')]
            return
        }

        this.wrapResponseObservable<UpdateResponseModel>(
            this.viewFacadeService.updateViewRequest(
                this.selectedView,
                this.formUpdateViewObject(),
            ),
        ).subscribe((data) => {
            if (data.status === 'success') {
                this.close()
            }
        })

        this.showStorageService.remove(this.selectedView.guid)
        this.showStorageService.updateIsSetValue(this.selectedView.guid)
    }

    preview() {
        if (!this.isSaveAvailable()) {
            this.errors = [this.translation.translate('dialog_errors.no_changes_preview')]
            return
        }

        this.showLoader = true

        const showObject: ShowObject = {
            columns_hide: this.hiddenFields.join(','),
        }

        const pinnedColumn = this.pinnedGuid
        if (pinnedColumn?.length) {
            showObject.pin = pinnedColumn
        }

        this.showStorageService.set(this.selectedView.guid, showObject)
        this.showStorageService.updateIsSetValue(this.selectedView.guid)
        this.close()
    }

    resetToSave() {
        if (!this.isDataSavedInPreview) {
            this.errors = [this.translation.translate('dialog_errors.no_preview')]
            return
        }

        this.showStorageService.remove(this.selectedView.guid)
        this.showStorageService.updateIsSetValue(this.selectedView.guid)

        this.close()
    }

    resetToDefault() {
        if (!this.hiddenFields.length && !this.pinnedGuid.length) {
            this.errors = [this.translation.translate('dialog_errors.no_overall_changes')]
            return
        }

        this.hiddenFields = []
        this.save()
    }

    private setProperties(viewData: ViewData) {
        if (!viewData.selectedView) return

        this.viewData = viewData
        this.selectedView = viewData.selectedView
        this.visibleFields = [...viewData.columns.columns]
        this.hiddenFields = [...(viewData.columns.hiddenColumns ?? [])]
        this.showStorageService.updateIsSetValue(this.selectedView.guid)
        this.fields = viewData.fields
        this.viewFieldGuids = Object.keys(this.fields)
        const pinnedGuid = viewData.columns.pinnedColumns?.join('') ?? ''
        this.commonToggleGuids = this.availableForToggle(pinnedGuid)
        this.combineShowFormGroup(pinnedGuid)
    }

    private availableForToggle(pinnedGuid: string) {
        return this.viewFieldGuids.filter((guid) => {
            return this.fields[guid].field_type_code !== 'field_type_name' && guid !== pinnedGuid
        })
    }

    private combineShowFormGroup(pinnedColumn: string, isToggleAll = false) {
        this.showFormGroup = new FormGroup({
            pin: new FormControl(pinnedColumn),
            toggles: new FormGroup(this.combineTogglesFormGroup()),
        })

        this.showFormGroup.controls.pin.valueChanges.subscribe((value) => {
            this.commonToggleGuids = this.availableForToggle(value ?? '')
        })

        if (isToggleAll) {
            this.showFormGroup.markAsDirty()
        }

        dirtyCheck(this.showFormGroup, of(this.showFormGroup.value), {
            debounce: 0,
            useBeforeunloadEvent: false,
        })
            .pipe(takeUntil(this.isToggleAllCalled$), untilDestroyed(this))
            .subscribe((isDirty) => {
                this.isDirty = isDirty
            })
    }

    private isSaveAvailable() {
        const isHiddenFieldsChanged = !isEqual(
            this.hiddenFields,
            this.viewData.columns.hiddenColumns,
        )
        const isPinChanged = this.pinnedGuid !== this.viewData.columns.pinnedColumns?.join('')

        return isHiddenFieldsChanged || isPinChanged
    }

    private combineTogglesFormGroup() {
        return this.viewFieldGuids.reduce(
            (acc, guid) => {
                acc[guid] = new FormControl(this.visibleFields.includes(guid))
                return acc
            },
            {} as { [guid: string]: FormControl<boolean | null> },
        )
    }

    private formUpdateViewObject() {
        const updateObject: Partial<UpdateViewConfiguration> = {
            columns_hide: {
                cell: this.selectedView.columns_hide,
                newValue: this.hiddenFields.join(','),
            },
        }

        if (this.pinnedGuid !== this.selectedView.columns_pinned.value) {
            updateObject.columns_pinned = {
                cell: this.selectedView.columns_pinned,
                newValue: this.pinnedGuid,
            }
        }

        return updateObject
    }
}
