import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import {
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup,
    FormRecord,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms'
import { MatMenuModule } from '@angular/material/menu'
import { TbCheckboxComponent } from '@components-library/tb-checkbox'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbInputComponent } from '@components-library/tb-input/tb-input.component'
import { TbMenuComponent, TbMenuState } from '@components-library/tb-menu'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { isNonNull } from '@core/global-util'
import { User, UserStatus } from '@core/models'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { UserFacadeService } from '@services/store-facade'
import { UserAvatarComponent } from '@shared/user-avatar/user-avatar.component'
import { NgArrayPipesModule } from 'ngx-pipes'
import { combineLatest, filter, ReplaySubject } from 'rxjs'
import { take } from 'rxjs/operators'

@UntilDestroy()
@Component({
    selector: 'app-users-list',
    standalone: true,
    imports: [
        ModalLayoutComponent,
        NgArrayPipesModule,
        ReactiveFormsModule,
        TbCheckboxComponent,
        TbDividerComponent,
        TbInputComponent,
        TbMenuComponent,
        UserAvatarComponent,
        NgArrayPipesModule,
        MatMenuModule,
        TranslocoModule,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UsersListComponent),
            multi: true,
        },
    ],
    templateUrl: './users-list.component.html',
})
export class UsersListComponent implements OnInit, OnChanges, ControlValueAccessor {
    @ViewChild(TbMenuComponent) menu!: TbMenuComponent

    @Input()
    value = ''

    @Input()
    editMode = false

    @Input()
    fullNameForOneUser = true

    @Output()
    valueChanged = new EventEmitter<string>()

    users!: User[]
    selectedUsersWithoutCurrent: User[] = []
    currentUser!: User
    selectedUsers: User[] = []
    renderedUsersList: User[] = []
    unknownUsers: User[] = []

    unknownName!: string

    formGroup!: FormGroup<{
        search: FormControl<string>
        users: FormRecord<FormControl<boolean>>
    }>

    formInitialized$ = new ReplaySubject<void>(1)

    onChange = (_: any) => {}

    constructor(
        private userFacadeService: UserFacadeService,
        private fb: FormBuilder,
        private translation: TranslocoService,
    ) {}

    get showUserInList() {
        return !this.editMode ? this.isCurrentUserSelected : !this.formGroup.controls.search.value
    }

    get isCurrentUserSelected() {
        return !!this.formGroup.controls.users.get(this.currentUser.guid)?.value
    }

    ngOnInit(): void {
        combineLatest([
            this.userFacadeService.users$,
            this.userFacadeService.currentUserEntity$.pipe(filter(isNonNull)),
            this.translation.selectTranslate('user_list.unknown'),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([users, currentUser, unknownName]) => {
                this.unknownName = unknownName

                this.users = users
                this.renderedUsersList = this.users
                this.currentUser = currentUser

                this.formGroup = this.fb.nonNullable.group({
                    search: '',
                    users: this.fb.nonNullable.record(this.prepareFormControls(users)),
                })

                if (this.value) {
                    this.setValue(this.value)
                }

                this.formInitialized$.next()

                this.formGroup.controls.users.valueChanges
                    .pipe(untilDestroyed(this))
                    .subscribe((users) => {
                        this.selectedUsers = this.setSelectedUsers(users)
                        this.sortUsers(this.selectedUsers)
                        this.selectedUsersWithoutCurrent = this.getSelectedUsersWithoutCurrent()
                        const userGuids = this.selectedUsers.map((u) => u.guid).join()
                        this.value = userGuids
                        this.onChange(userGuids)
                        this.valueChanged.emit(userGuids)
                    })
            })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value?.currentValue) {
            this.setValue(changes.value.currentValue)
        }

        if (changes.editMode) {
            this.renderedUsersList = this.getRenderedListByMode(changes.editMode.currentValue)
        }
    }

    writeValue(value: string): void {
        this.formInitialized$.pipe(untilDestroyed(this), take(1)).subscribe(() => {
            this.setValue(value)
        })
    }

    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    registerOnTouched(fn: any): void {}

    openMenu() {
        this.menu.openMenu()
    }

    toggle(guid: string) {
        if (!this.editMode) return

        const control = this.formGroup.controls.users.get(guid)!
        const value = control.value

        control.setValue(!value)
        control.markAsTouched()
    }

    resetSelectedUnknownUsers() {
        this.users = this.users.filter((user) => {
            if (user.status === UserStatus.Remove) {
                this.formGroup.controls.users.removeControl(user.guid, { emitEvent: false })
            }
            return user.status !== UserStatus.Remove
        })
        this.unknownUsers = []
        this.setValue(this.value)
    }

    canShowUserInList(user: User) {
        return this.formGroup.controls.search.value
            ? user.status !== UserStatus.Remove
            : user.guid !== this.currentUser.guid
    }

    menuStateIsChanged() {
        if (TbMenuState.Open) {
            this.sortUsers(this.users)
            this.sortUsers(this.selectedUsers)
        }
    }

    private setValue(value: string) {
        if (!value) return

        this.unknownUsers = this.prepareUnknownUsers(value)

        this.users = [...this.unknownUsers, ...this.users]

        const users = value.split(',')
        this.users.forEach((user) => {
            const control = this.formGroup.controls.users.get(`${user.guid}`)
            if (control) {
                control.setValue(users.includes(user.guid), { emitEvent: false })
            } else {
                this.formGroup.controls.users.addControl(
                    user.guid,
                    new FormControl(true, { nonNullable: true }),
                    { emitEvent: false },
                )
            }
        })

        this.selectedUsers = this.setSelectedUsers(this.formGroup.controls.users.value)
        this.selectedUsersWithoutCurrent = this.getSelectedUsersWithoutCurrent()

        this.sortUsers(this.users)
        this.sortUsers(this.selectedUsers)
        this.renderedUsersList = this.getRenderedListByMode(this.editMode)
    }

    private sortUsers(users: User[]) {
        users.sort((a, b) => {
            const getOrder = (user: User) => {
                const isSelected = this.selectedUsers.includes(user)
                const isUnknown = this.unknownUsers.includes(user)

                if (isSelected && !isUnknown) return 0
                if (isSelected && isUnknown) return 1
                if (!isSelected && isUnknown) return 2

                return 3
            }

            const indexA = getOrder(a)
            const indexB = getOrder(b)

            if (indexA !== indexB) {
                return indexA - indexB
            }

            return a.fullName.localeCompare(b.fullName)
        })
    }

    private prepareUnknownUsers(value: string): User[] {
        return value
            .split(',')
            .filter((guid) => {
                return !this.users.find((user) => user.guid === guid)
            })
            .map((guid) => {
                return {
                    guid,
                    fullName: this.unknownName,
                    first_name: '',
                    last_name: '',
                    email: '',
                    status: UserStatus.Remove,
                }
            })
    }

    private getSelectedUsersWithoutCurrent() {
        return this.selectedUsers.filter((user) => user.guid !== this.currentUser.guid)
    }

    private setSelectedUsers(users: Partial<{ [key: string]: boolean }>) {
        return Object.keys(users)
            .filter((guid) => users[guid])
            .map((guid) => this.users.find((user) => user.guid === guid)!)
    }

    private getRenderedListByMode(editMode: boolean) {
        return !editMode ? this.selectedUsers : this.users
    }

    private prepareFormControls(users: User[]) {
        return users.reduce(
            (acc, user) => {
                acc[user.guid] = false
                return acc
            },
            {} as Record<string, boolean>,
        )
    }
}
