<div
    [ngClass]="{
        'body-1': !isSelectedOrMenuOpened,
        'body-2': isSelectedOrMenuOpened,
        'text-secondaryMain': isMenuOpened,
    }"
    class="flex items-center cursor-pointer bg-inherit"
>
    <div class="relative pb-1">
        <div
            (click)="select()"
            class="flex items-center pl-1 py-1"
            [class]="tab.name ? 'pr-2' : 'pr-1'"
        >
            <div class="relative flex">
                @if (icon) {
                    <ng-container [ngTemplateOutlet]="icon" [ngTemplateOutletContext]="{ tab }">
                    </ng-container>
                } @else if (tab.icon) {
                    <app-tb-icon [icon]="tab.icon"></app-tb-icon>
                }
            </div>
            @if (tab.name) {
                <div
                    class="tab-name max-w-[17ch] whitespace-nowrap text-ellipsis overflow-hidden"
                    title="{{ tab.name }}"
                >
                    {{ tab.name }}
                </div>
            }
        </div>
        <div
            [class]="isSelected ? 'visible' : 'invisible'"
            class="w-full h-[0.25rem] rounded-sm bg-secondaryMain absolute bottom-[-0.25rem]]"
        ></div>
    </div>
    @if (moreMenu) {
        <div class="more-menu">
            <app-tb-menu
                [template]="moreMenu"
                (stateChanged)="isMenuOpened = $event === TbMenuStateEnum.Open"
            >
                <app-tb-button
                    [appearance]="'icon'"
                    [icon]="'more_vert'"
                    [class.visible]="isMenuOpened"
                    trigger
                >
                </app-tb-button>
            </app-tb-menu>
        </div>
    }
</div>
