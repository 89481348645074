import {
    CdkDragDrop,
    moveItemInArray,
    transferArrayItem,
    CdkDropListGroup,
    CdkDropList,
} from '@angular/cdk/drag-drop'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { generateUuid } from '@core/global-util'
import { Field } from '@core/models'
import { SelectObjectOptions, SelectOption } from '@models/response/select-object-options'
import { cloneDeep } from 'lodash-es'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { MatButtonModule } from '@angular/material/button'
import { OptionItemComponent } from '../../option-item.component'

import { TranslocoModule } from '@ngneat/transloco'

type StatusOptionsByTags = {
    active: SelectOption[]
    done: SelectOption[]
    close: SelectOption[]
}

@Component({
    selector: 'app-status-content',
    templateUrl: './status-settings.component.html',
    styleUrls: ['./status-settings.component.sass'],
    standalone: true,
    imports: [
        TranslocoModule,
        CdkDropListGroup,
        CdkDropList,
        OptionItemComponent,
        MatButtonModule,
        TbIconComponent,
    ],
})
export class StatusSettingsComponent implements OnInit {
    @Input()
    statusData!: Field

    @Input()
    activeStatus!: string | undefined

    @Input()
    defaultStatus!: string | undefined

    @Input()
    statusOptions!: SelectObjectOptions

    @Output()
    fieldChanged: EventEmitter<Field> = new EventEmitter<Field>()
    @Output()
    defaultChanged: EventEmitter<string> = new EventEmitter<string>()
    @Output() valid = new EventEmitter<boolean>()

    statusesByTags!: StatusOptionsByTags
    statusesTmp!: SelectOption[]

    constructor() {}

    ngOnInit(): void {
        if (this.statusData && !this.statusOptions) {
            this.statusOptions = cloneDeep(this.statusData.select_object_field!)
        }
        this.completeStatusesToArray()
    }

    completeStatusesToArray() {
        if (this.statusOptions) {
            const statusesByTagsTmp = {
                active: [],
                done: [],
                close: [],
            }

            this.statusesTmp = Object.keys(this.statusOptions).map((guid) => {
                return {
                    guid,
                    ...this.statusOptions[guid],
                }
            })

            this.statusesByTags = this.statusesTmp.reduce((acc, status) => {
                if (status.tags) {
                    return {
                        ...acc,
                        [status.tags]: [...acc[status.tags as keyof StatusOptionsByTags], status],
                    }
                }
                return acc
            }, statusesByTagsTmp)
        }

        const defaultOption = this.getDefaultOption()

        if (defaultOption) {
            this.processDefaultOption(defaultOption)
        }
    }

    addOption() {
        const newIndex = generateUuid()
        const newOption: SelectOption = {
            label: '',
            guid: newIndex,
            color: '#cccccc',
            tags: 'active',
            icon: '',
        }
        this.statusesByTags.active.push(newOption)
        this.statusOptions = {
            ...this.statusOptions,
            [newIndex]: {
                label: '',
                color: '#cccccc',
                tags: 'active',
                is_new: true,
            },
        }
        this.valid.emit(false)
    }

    drop(event: CdkDragDrop<SelectOption[]>, isActive = false) {
        const currentIndex = !isActive ? event.currentIndex : event.currentIndex + 1
        const previousIndex = !isActive ? event.previousIndex : event.previousIndex + 1
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, previousIndex, currentIndex)
            return
        }

        if (event.previousContainer.data.length === 1) return

        transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            previousIndex,
            currentIndex,
        )
        this.updateOptionsOnDrop(event)
        this.sendData()
    }

    deleteStatus(guid?: string) {
        if (!guid || this.statusOptions[guid].tags === 'close') return

        delete this.statusOptions[guid]
        this.sendData()
    }

    optionChanged(optionValue: SelectOption, guid?: string) {
        if (!guid) return

        this.statusOptions[guid] = {
            ...this.statusOptions[guid],
            color: optionValue.color,
            label: optionValue.label,
        }

        if (!optionValue.label.length) {
            this.valid.emit(false)
            return
        }

        this.valid.emit(true)
        this.sendData()
    }

    changeIcon(optionValue: string, guid?: string) {
        if (!guid) return

        const status = this.statusOptions[guid]

        if (!optionValue && status.icon) {
            delete status.icon
            this.sendData()
            return
        }

        this.statusOptions[guid] = {
            ...status,
            icon: optionValue,
        }
        this.sendData()
    }

    setDefaultOption(guid?: string) {
        if (!guid) return

        this.updateStatusDefault(guid)
        this.defaultChanged.emit(guid)
        this.sendData()
    }

    sendData() {
        this.completeStatusesToArray()
        this.fieldChanged.emit({ ...this.statusData, select_object_field: this.statusOptions })
    }

    private updateStatusDefault(guid: string) {
        if (!this.statusOptions || !this.defaultStatus) return

        this.defaultStatus = guid
    }

    private updateOptionsOnDrop(event: CdkDragDrop<SelectOption[]>) {
        if (!this.statusOptions) {
            return
        }
        const updatedTagName = event.container.id as keyof StatusOptionsByTags
        this.statusOptions[event.item.data].tags = updatedTagName

        this.statusesByTags[updatedTagName] = this.statusesByTags[updatedTagName].map((item) => {
            return item.tags !== updatedTagName ? { ...item, tags: updatedTagName } : item
        })
    }

    private processDefaultOption(defaultOption: SelectOption) {
        const defaultTag = defaultOption.tags as keyof StatusOptionsByTags

        if (defaultTag === 'close') return

        this.setDefaultGuid(defaultOption)

        const tagOptionsWithoutDefault = this.statusesByTags[defaultTag].filter(
            (option) => option.guid !== defaultOption.guid,
        )

        this.statusesByTags[defaultTag] = [defaultOption, ...tagOptionsWithoutDefault]
    }

    private getDefaultOption() {
        if (!this.defaultStatus) throw Error('No default option defined')
        return this.statusOptions[this.defaultStatus]
    }

    private setDefaultGuid(defaultOption: SelectOption) {
        if (!this.defaultStatus) {
            this.defaultStatus = defaultOption.guid
        }
    }
}
