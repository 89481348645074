@if (!disabled) {
    <div
        [matMenuTriggerFor]="assignMenu"
        (mouseenter)="isShowAssignees = true"
        (mouseleave)="isShowAssignees = false"
    >
        @if (assignUsersLength) {
            <div class="users-icons">
                @for (user of assignedUsers; track user) {
                    <app-user-avatar [userName]="user.fullName" [userUrl]="user.avatar_url">
                    </app-user-avatar>
                }
                @if (assignUsersLength && isShowAssignees) {
                    <div class="assignee-info">
                        @for (user of assignedUsers; track user) {
                            <i> {{ user.fullName }} </i>
                        }
                    </div>
                }
            </div>
        } @else {
            <app-tb-icon class="md-inactive" icon="person_add_alt"></app-tb-icon>
        }
    </div>
} @else {
    @if (!isGrouping) {
        <div (mouseenter)="isShowAssignees = true" (mouseleave)="isShowAssignees = false">
            @if (assignUsersLength) {
                <div class="users-icons">
                    @for (user of assignedUsers; track user) {
                        <span class="user-avatar">
                            @if (user.avatar_url) {
                                <img
                                    [src]="user.avatar_url"
                                    alt=""
                                    onerror="this.src='../../../../assets/img/avatar.png'"
                                />
                            } @else {
                                <img
                                    alt="{{ user.fullName }}"
                                    src="../../../../../assets/img/avatar.png"
                                    title="{{ user.fullName }}"
                                />
                            }
                        </span>
                    }
                    @if (isShowAssignees) {
                        <div class="assignee-info">
                            @for (user of assignedUsers; track user) {
                                <p>{{ user.fullName }}</p>
                            }
                        </div>
                    }
                </div>
            } @else {
                <ng-container *ngTemplateOutlet="iconAdd"></ng-container>
            }
        </div>
    } @else {
        @if (assignUsersLength) {
            <div class="users-icons">
                @for (user of assignedUsers; track user) {
                    <span class="user-avatar">
                        @if (user.avatar_url) {
                            <img
                                [src]="user.avatar_url"
                                alt=""
                                onerror="this.src='../../../../assets/img/avatar.png'"
                            />
                        } @else {
                            <img
                                alt="{{ user.fullName }}"
                                src="../../../../../assets/img/avatar.png"
                                title="{{ user.fullName }}"
                            />
                        }
                    </span>
                }
                <div class="assignee-info">
                    @for (user of assignedUsers; track user) {
                        <i> {{ user.fullName }} </i>
                    }
                </div>
            </div>
        } @else {
            <ng-container *ngTemplateOutlet="iconAdd"></ng-container>
        }
    }
    <ng-template #iconAdd>
        <ng-container *transloco="let tCellTypesAssign; read: 'cell_types.assign'">
            <app-tb-icon
                class="md-inactive"
                [title]="tCellTypesAssign('template_title')"
                icon="person_add_alt"
            >
            </app-tb-icon>
        </ng-container>
    </ng-template>
}

<mat-menu #assignMenu="matMenu">
    <ng-template matMenuContent>
        <div (click)="$event.stopPropagation()">
            <mat-form-field class="search">
                <span matPrefix>
                    <app-tb-icon class="md-inactive search-icon" icon="search"></app-tb-icon>
                </span>
                <input [formControl]="search" class="search-input" matInput type="text" />
            </mat-form-field>
            <ng-container *transloco="let tCellTypesErrors; read: 'cell_types.errors'">
                @if (isInvalidUser) {
                    <mat-error>
                        <span> {{ tCellTypesErrors('unknown_user') }} </span>
                    </mat-error>
                }
            </ng-container>
            <app-people-menu
                (changeUser)="toggleUser($event)"
                [activeUserGuids]="userGuids"
                [allowEdit]="!disabled"
                [users]="users"
            ></app-people-menu>
        </div>
    </ng-template>
</mat-menu>
