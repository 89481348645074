<app-modal-layout
    *transloco="let tManageFields; read: 'manage_fields'"
    [containerLayout]="containerLayout"
    [showLoader]="showLoader"
    [errors]="errors"
    (closeModal)="forceWithValidation()"
>
    <ng-container title>{{ tManageFields(editMode ? 'manage_field' : 'add_field') }}</ng-container>
    <ng-container subtitle>{{ tManageFields('add_field_subtitle') }}</ng-container>
    <div [formGroup]="formGroup">
        <div>
            <div class="flex w-full">
                @if (availableFieldTypes$ | async; as types) {
                    <app-tb-select
                        [value]="data.folder_guid"
                        class="flex-1"
                        formControlName="fieldType"
                    >
                        @for (type of types; track type) {
                            <app-tb-option [value]="type.guid"> {{ type.name }} </app-tb-option>
                        }
                    </app-tb-select>
                }
                @if (data.folder_guid) {
                    <app-tb-select
                        class="flex-1 text-nowrap overflow-ellipsis"
                        formControlName="folder"
                    >
                        @for (folder of foldersForMove; track folder) {
                            <app-tb-option
                                [icon]="folder.icon.value"
                                [iconColor]="folder.color.value"
                                [value]="folder.guid"
                            >
                                {{ folder.name.value }}
                            </app-tb-option>
                        }
                    </app-tb-select>
                }
            </div>
            <app-tb-input
                class="w-full"
                [label]="tManageFields('field_name')"
                [placeholder]="tManageFields('name_placeholder')"
                formControlName="columnName"
            ></app-tb-input>
        </div>
        <div class="w-full flex flex-row justify-start items-center">
            <app-add-field-content
                (fieldChanged)="changedValue($event)"
                (valid)="isColumnContentValid = $event"
                [fieldType]="field.field_type_code"
                [field]="field"
            ></app-add-field-content>
        </div>
        <div class="flex items-start">
            <app-tb-toggle formControlName="onTop"></app-tb-toggle>
            <div>
                <div>{{ tManageFields('on_top') }}</div>
                <span>{{ tManageFields('on_top_description') }}</span>
            </div>
        </div>
        <div class="flex items-start">
            <app-tb-toggle formControlName="required"></app-tb-toggle>
            <div>
                <div>{{ tManageFields('required_field') }}</div>
                <span>{{ tManageFields('required_field_description') }}</span>
                @if (isRequired) {
                    <div>
                        <span>{{ tManageFields('default_value') }}</span>
                        @if (supportNewContainer) {
                            <app-input-cell-container
                                [field]="field"
                                appearance="create_record"
                                formControlName="defaultValue"
                            ></app-input-cell-container>
                        } @else {
                            <!--TODO: convert to form control-->
                            <app-cell-container
                                [field]="field"
                                [guid]="field.guid"
                                [value]="field.default_value ?? ''"
                                [fieldTypes]="(allFieldTypesDictionary$ | async) || {}"
                                (cellValueChanged)="defaultValueChanged($event)"
                            ></app-cell-container>
                        }
                    </div>
                }
            </div>
        </div>
        @if (shareAvailable) {
            <div class="pt-4 flex flex-col">
                <div class="body-2">{{ tManageFields('share_field') }}</div>
                <span class="pb-2 caption-1">{{ tManageFields('share_field_description') }}</span>
                <app-tb-select
                    formControlName="sharedFolders"
                    [placeholder]="tManageFields('share_field_select_placeholder')"
                    [value]="data.shared_with_folder"
                    [multiple]="true"
                    [shaded]="true"
                >
                    @for (folder of foldersForShare; track folder) {
                        <app-tb-option
                            [iconColor]="folder.color.value"
                            [icon]="folder.icon.value"
                            [value]="folder.guid"
                        >
                            {{ folder.name.value }}
                        </app-tb-option>
                    }
                </app-tb-select>
            </div>
        }
        <!--TODO: add accordion-->
    </div>
    <ng-container buttons>
        <div *transloco="let tButtons; read: 'buttons'" class="flex justify-end">
            <div class="flex sm:gap-1 gap-2 flex-1 sm:flex-none">
                <app-tb-button appearance="text" icon="arrow_back" (click)="close()">
                    {{ tButtons('back') }}
                </app-tb-button>
                <app-tb-button
                    *appOperation="field.operationCode; target: 'delete'; condition: editMode"
                    appearance="text"
                    icon="close"
                    color="secondary"
                    (click)="delete()"
                >
                    {{ tButtons('delete') }}
                </app-tb-button>
                <app-tb-button (click)="creteColumn()">
                    {{ tButtons(editMode ? 'save' : 'add') }}
                </app-tb-button>
            </div>
        </div>
    </ng-container>
</app-modal-layout>
