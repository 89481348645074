import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FieldExistsInSchemaValidator } from '@app/views/view-controls/validators'
import { Field, Folder } from '@core/models'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { SortDirection, SortObject } from '@models/ui/sort.model'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SortItemFormGroup } from '@app/views/view-controls/view-sort/sort-modal/sort-modal.component'
import { MatIconModule } from '@angular/material/icon'
import { FieldNameComponent } from '@shared/field-name/field-name.component'
import { TbOptionComponent } from '@components-library/tb-select/components/tb-option/tb-option.component'

import { TbSelectComponent } from '@components-library/tb-select/components/tb-select/tb-select.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { CdkDrag } from '@angular/cdk/drag-drop'

@UntilDestroy()
@Component({
    selector: 'app-sort-item',
    templateUrl: './sort-item.component.html',
    styleUrls: ['./sort-item.component.sass'],
    standalone: true,
    imports: [
        CdkDrag,
        TbIconComponent,
        TbSelectComponent,
        ReactiveFormsModule,
        TbOptionComponent,
        FieldNameComponent,
        MatIconModule,
    ],
})
export class SortItemComponent implements OnInit {
    @Input() selectedFolder!: Folder
    @Input() fields!: Field[]
    @Input() sortObject!: SortObject
    @Input() sortGroup!: FormGroup<SortItemFormGroup>

    @Output() removed: EventEmitter<string> = new EventEmitter<string>()
    @Output() changed: EventEmitter<SortObject> = new EventEmitter<SortObject>()

    sortSelectionControl!: FormControl<string | null>
    sortDirectionControl!: FormControl<SortDirection | null>

    constructor(private readonly fieldExistsValidator: FieldExistsInSchemaValidator) {}

    ngOnInit() {
        if (!this.sortObject || !this.sortGroup) {
            // TODO: create a single service that creates/pushes sort form controls
            // TODO: show red input on failed validation
            this.sortGroup = new FormGroup({
                select: new FormControl('', {
                    asyncValidators: [this.fieldExistsValidator.validate],
                }),
                direction: new FormControl(),
            })
        }

        this.setControls()
    }

    removeItem() {
        this.removed.emit(this.sortObject.fieldGuid)
    }

    changeDirection(direction: string) {
        this.sortDirectionControl.setValue(direction as SortDirection)
    }

    private setControls() {
        this.sortSelectionControl = this.sortGroup.controls.select
        this.sortDirectionControl = this.sortGroup.controls.direction

        this.sortSelectionControl.valueChanges.pipe(untilDestroyed(this)).subscribe((guid) => {
            if (!this.sortDirectionControl.value) {
                this.sortDirectionControl.setValue(SortDirection.DESC)
                this.sortObject.sortDirection = SortDirection.DESC
            }

            this.changed.emit({
                ...this.sortObject,
                fieldGuid: guid ?? '',
            })
        })

        this.sortDirectionControl.valueChanges.pipe(untilDestroyed(this)).subscribe((direction) => {
            this.changed.emit({
                ...this.sortObject,
                sortDirection: direction ?? SortDirection.DESC,
            })
        })
    }
}
