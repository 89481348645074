import { CdkDragDrop, moveItemInArray, CdkDropList } from '@angular/cdk/drag-drop'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { generateUuid } from '@core/global-util'
import { Field } from '@core/models'
import { SelectObjectOptions, SelectOption } from '@models/response/select-object-options'
import { cloneDeep } from 'lodash-es'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TranslocoModule } from '@ngneat/transloco'
import { MatButtonModule } from '@angular/material/button'
import { OptionItemComponent } from '../../option-item.component'

@Component({
    selector: 'app-dropdown-settings',
    templateUrl: './dropdown-settings.component.html',
    styleUrls: ['./dropdown-settings.component.sass'],
    standalone: true,
    imports: [CdkDropList, OptionItemComponent, MatButtonModule, TranslocoModule, TbIconComponent],
})
export class DropdownSettingsComponent implements OnInit {
    @Input()
    field!: Field

    @Input()
    activeStatus!: string | undefined

    @Input()
    defaultStatus?: string

    dropdownOptions: SelectObjectOptions = {}

    dropdownOptionsInArray: SelectOption[] = []

    defaultColor!: string

    @Output()
    fieldChanged: EventEmitter<Field> = new EventEmitter<Field>()
    @Output()
    valid: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Output()
    defaultChanged: EventEmitter<string> = new EventEmitter<string>()

    constructor() {}

    ngOnInit(): void {
        this.defaultColor = '#ff00ee'

        if (this.field.select_object_field) {
            this.dropdownOptions = cloneDeep(this.field.select_object_field)
        }

        this.completeOptionsToArray()

        if (!this.defaultStatus) {
            this.defaultStatus = this.field.default_value
        }

        if (!this.dropdownOptionsInArray.length) {
            this.addOption()
        }
    }

    completeOptionsToArray() {
        if (this.dropdownOptions) {
            this.dropdownOptionsInArray = Object.keys(this.dropdownOptions).map((guid) => {
                return {
                    guid,
                    ...this.dropdownOptions[guid],
                }
            })
        }
    }

    drop(event: CdkDragDrop<SelectOption[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
        this.sendData(true)
    }

    deleteOption(guid: string) {
        delete this.dropdownOptions[guid]
        this.sendData()
    }

    addOption() {
        const newIndex = generateUuid()
        const newOption: SelectOption = {
            label: '',
            guid: newIndex,
            color: this.defaultColor,
            tags: '',
            icon: '',
            is_new: true,
        }
        this.dropdownOptionsInArray.push(newOption)
        if (this.dropdownOptionsInArray.length === 1) {
            this.setDefaultOption(newIndex)
        }
        this.dropdownOptions = {
            ...this.dropdownOptions,
            [newIndex]: {
                label: '',
                color: this.defaultColor,
                tags: '',
                is_new: true,
            },
        }
        this.sendData()
    }

    optionChanged(guid: string | undefined, optionValue: SelectOption) {
        if (!guid) return

        if (optionValue.color) {
            this.dropdownOptions[guid].color = optionValue.color
        }
        if (optionValue.label) {
            this.dropdownOptions[guid].label = optionValue.label
        }

        this.sendData()
    }

    changeIcon(guid: string | undefined, optionValue: string) {
        if (!guid) return
        this.dropdownOptions[guid].icon = optionValue
        this.sendData()
    }

    setDefaultOption(guid: string | undefined) {
        if (!guid || !this.dropdownOptions || !this.defaultStatus) return

        this.defaultChanged.emit(guid)

        this.sendData()
    }

    private sendData(isDragged: boolean = false) {
        if (isDragged) {
            this.fieldChanged.emit({
                ...this.field,
                select_object_field_sorted: this.dropdownOptionsInArray,
            })
            return
        }
        this.completeOptionsToArray()

        this.valid.emit(!this.isOptionsInvalid())
        this.fieldChanged.emit({ ...this.field, select_object_field: this.dropdownOptions })
    }

    private isOptionsInvalid() {
        return !!this.dropdownOptionsInArray.find((option) => !option.label.length)
    }
}
