import { Component, Inject, OnInit } from '@angular/core'
import { FormArray, FormGroup } from '@angular/forms'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { CommonDialogResultStatus } from '@components-library/tb-confirmation-popup/tb-confirmation-dialog.component'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import {
    FieldEntities,
    FieldType,
    FieldTypes,
    FilterCombinationTypes,
    FilterGroup,
    Folder,
    getFieldEntitiesByFieldTypes,
    UpdateResponseModel,
    View,
} from '@core/models'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { FilterStorageService } from '@services/local-storage/filter-storage.service'
import {
    FieldTypeFacadeService,
    FolderFacadeService,
    SchemaFacadeService,
    ViewFacadeService,
} from '@services/store-facade'
import { pickBy } from 'lodash-es'
import { combineLatest, Observable, of } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { FormFilterService } from '@app/views/view-controls/view-filter/form-filter.service'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { FilterGroupComponent } from '../filter-group/filter-group.component'
import { AsyncPipe } from '@angular/common'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'

export interface FilterDialogData {
    filterGroups: FilterGroup[]
    selectedView: View
}

@UntilDestroy()
@Component({
    selector: 'app-filter-modal',
    templateUrl: './filter-modal.component.html',
    styleUrls: ['./filter-modal.component.sass'],
    providers: [FormFilterService],
    standalone: true,
    imports: [
        TranslocoModule,
        ModalLayoutComponent,
        FilterGroupComponent,
        TbButtonComponent,
        AsyncPipe,
        TbDividerComponent,
        TbMenuComponent,
        TbMenuListComponent,
        TbMenuListItemComponent,
    ],
})
export class FilterModalComponent extends ModalContainerComponent implements OnInit {
    fieldTypesForFilter = [
        FieldTypes.WATCH,
        FieldTypes.PEOPLE,
        FieldTypes.ASSIGNEE,
        FieldTypes.STATUS,
        FieldTypes.NAME,
        FieldTypes.MULTILINE_TEXT,
        FieldTypes.TEXT,
        FieldTypes.NUMBER,
    ]
    filterGroups!: FilterGroup[]
    selectedView!: View
    fields$!: Observable<{ fields: FieldEntities; fieldTypes: FieldType[] } | undefined>

    selectedFolder!: Folder
    showResetDefault: boolean = false

    canAddFilterGroup = false

    isDirty = false
    isClearAll = false
    errors: string[] = []

    get isFilterFormEmpty() {
        return (
            this.formFilterService.getFilterFormArray?.length === 1 &&
            !this.formFilterService.getFilterGroupValues(0).length
        )
    }

    get anyGroupEmpty() {
        return !!this.formGroups.find((group) => !(group.get('values') as FormArray).length)
    }

    get formGroups(): FormGroup[] {
        return this.formFilterService.getFilterFormArray.controls as FormGroup[]
    }

    constructor(
        private schemaFacadeService: SchemaFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
        private filterStorageService: FilterStorageService,
        public formFilterService: FormFilterService,
        private viewFacadeService: ViewFacadeService,
        private translation: TranslocoService,
        protected modalManagerService: ModalManagerService,
        protected confirmationDialogService: ConfirmationDialogService,
        @Inject(ModalContainerDataToken) protected data: any,
    ) {
        super(data, modalManagerService, confirmationDialogService)
        this.filterGroups = data?.filterGroups || []
        this.selectedView = data?.selectedView || []
    }

    ngOnInit(): void {
        this.folderFacadeService.selectSelectedFolder$
            .pipe(untilDestroyed(this))
            .subscribe((selectedFolder) => (this.selectedFolder = selectedFolder))

        this.initializeFields()

        this.formFilterService.initForm(this.filterGroups)

        this.showResetDefault = !!this.filterStorageService.get(this.selectedView.guid)

        const filterFormArray = this.formFilterService.getFilterFormArray
        this.canAddFilterGroup = this.formFilterService.canAddFilterGroup
        this.isClearAll = this.isClearAvailable()

        filterFormArray.valueChanges
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(() => {
                this.canAddFilterGroup = this.formFilterService.canAddFilterGroup
                this.isClearAll = this.isClearAvailable()
            })

        dirtyCheck(filterFormArray, of(filterFormArray.value), {
            debounce: 0,
            useBeforeunloadEvent: false,
        })
            .pipe(untilDestroyed(this))
            .subscribe((isDirty) => {
                this.isDirty = isDirty
            })
    }

    isGroupEmpty(index: number) {
        return !this.formFilterService.getFilterGroupValues(index)?.length
    }

    addGroupAction(event: any) {
        if (this.formFilterService.getFilterFormArray.invalid) {
            return
        }
        event.stopPropagation()
        this.formFilterService.addFilterGroup(FilterCombinationTypes.ALL)
    }

    save() {
        if (this.formFilterService.getFilterFormArray.invalid || this.anyGroupEmpty) {
            this.errors = [this.translation.translate('dialog_errors.incorrect_input')]
            return
        }

        let rawFilter = this.formFilterService.prepareToSave()

        if (this.isFilterFormEmpty) {
            rawFilter = []
        }

        const newFilter = JSON.stringify(rawFilter)

        if (this.selectedView.filter.value === newFilter) {
            return
        }

        this.formFilterService.getFilterFormArray.markAsPristine()
        this.formFilterService.getFilterFormArray.markAsUntouched()

        this.updateFilterValueInSelectedView(newFilter)
    }

    preview() {
        if (this.formFilterService.getFilterFormArray.invalid || this.anyGroupEmpty) {
            this.errors = [this.translation.translate('dialog_errors.incorrect_input')]
            return
        }

        let rawFilter = this.formFilterService.prepareToSave()
        if (this.isFilterFormEmpty) {
            rawFilter = []
        }
        this.filterStorageService.set(this.selectedView.guid, rawFilter)
        this.formFilterService.getFilterFormArray.markAsPristine()
        this.formFilterService.getFilterFormArray.markAsUntouched()
        this.onUpdatePreview(this.filterStorageService.isSet$())
    }

    resetToSave() {
        this.filterStorageService.remove(this.selectedView.guid)
        this.formFilterService.getFilterFormArray.markAsPristine()
        this.formFilterService.getFilterFormArray.markAsUntouched()
        this.onUpdatePreview(this.filterStorageService.isSet$())
    }

    resetToDefault() {
        this.confirmationDialogService
            .openCommon({
                translations: {
                    title: 'reset_view_control_confirmation.reset_title',
                    message: 'reset_view_control_confirmation.reset_filter_message',
                    cancel: 'reset_view_control_confirmation.reset_cancel',
                    confirm: 'reset_view_control_confirmation.reset_confirm',
                },
            })
            .subscribe((result) => {
                if (result === CommonDialogResultStatus.CANCEL) {
                    this.updateFilterValueInSelectedView('[]')
                }
            })
    }

    deleteGroup(groupIndex: number) {
        if (groupIndex !== -1) {
            this.formFilterService.deleteFilterGroup(groupIndex)
        }

        if (!this.formFilterService.getFilterFormArray.length) {
            this.formFilterService.addDefaultFilterGroup()
        }
    }

    clearAll() {
        this.formFilterService.getFilterFormArray.clear()
        this.formFilterService.addDefaultFilterGroup()
    }

    hasChanged(): boolean {
        return this.isDirty
    }

    private isClearAvailable() {
        if (this.formFilterService.getFilterFormArray.length > 1) {
            return true
        }

        return this.formFilterService
            .prepareToSave()
            .some((group) => (group.values ? Object.values(group.values).length >= 1 : false))
    }

    private initializeFields(): void {
        this.fields$ = combineLatest([
            this.fieldTypeFacadeService.selectAllFieldTypes$,
            this.schemaFacadeService
                .selectSelectedTableSchemaFieldEntitiesFiltered$(this.selectedFolder)
                .pipe(
                    map((fieldEntities) => {
                        return pickBy(fieldEntities, (value) => {
                            return this.fieldTypesForFilter.includes(
                                value.field_type_code as FieldTypes,
                            )
                        })
                    }),
                ),
        ]).pipe(
            map(([fieldTypes, fieldEntities]) => {
                if (!fieldEntities || !fieldTypes) {
                    return { fields: {}, fieldTypes: [] }
                }

                const fields = getFieldEntitiesByFieldTypes(fieldEntities, fieldTypes)

                return {
                    fields,
                    fieldTypes,
                }
            }),
        )
    }

    private onUpdatePreview(updateObservable: Observable<boolean>) {
        this.wrapResponseObservable<boolean>(updateObservable).subscribe(() => {
            this.close()
        })
    }

    private updateFilterValueInSelectedView(filterValue: string) {
        const filterUpdateObservable = this.viewFacadeService.updateViewRequest(this.selectedView, {
            filter: {
                cell: this.selectedView.filter,
                newValue: filterValue,
            },
        })

        this.wrapResponseObservable<UpdateResponseModel>(filterUpdateObservable).subscribe(
            (data) => {
                if (data.status === 'success') {
                    this.showResetDefault = false
                    this.filterStorageService.remove(this.selectedView.guid)
                    this.close()
                }
            },
        )
    }
}
