import { Injectable } from '@angular/core'
import { throwAndLogError } from '@core/global-util'
import { FieldTypes, SystemTypeCode } from '@core/models'
import { Field, FieldEntities, Folder, isFolderGlobal, Schema } from '@models/ui'
import { Dictionary, Update } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import { LogService } from '@services/log.service'
import { values } from 'lodash-es'
import { of } from 'rxjs'
import { map, switchMap, take } from 'rxjs/operators'
import {
    AppState,
    initSchemas,
    selectAllSchemas,
    selectSchemaEntities,
    selectSelectedTableSchema,
    selectSelectedTableSchemaFieldEntities,
    selectSelectedTableSchemaGuid,
    selectTableSchemas,
    setSelectedTableSchemaGuid,
    updateSchemas,
} from '../../@ngrx'
import { UpdateService } from '../update.service'

@Injectable({
    providedIn: 'root',
})
export class SchemaFacadeService {
    selectSchemaEntities$ = this.store.select(selectSchemaEntities)

    selectAllSchemas$ = this.store.select(selectAllSchemas)

    selectTableSchemas$ = this.store.select(selectTableSchemas)

    selectSelectedTableSchemaGuid$ = this.store.select(selectSelectedTableSchemaGuid)

    selectSelectedTableSchema$ = this.store.select(selectSelectedTableSchema)

    selectSelectedTableSchemaFieldEntities$ = this.store.select(
        selectSelectedTableSchemaFieldEntities,
    )

    private selectDefinedSelectedSchema$ = this.selectSelectedTableSchema$.pipe(
        switchMap((schema) => {
            if (!schema) {
                return throwAndLogError(this.logService, 'no schema')
            }
            return of(schema!)
        }),
    )

    constructor(
        private store: Store<AppState>,
        private updateService: UpdateService,
        private logService: LogService,
    ) {}

    selectSchemaByGuid$ = (guid: string) => {
        return this.selectSchemaEntities$.pipe(
            map((schemaDictionary: Dictionary<Schema>) => schemaDictionary[guid]),
        )
    }

    selectSchemaFieldsByFolder$ = (schemaGuid: string, folder: Folder) => {
        return this.selectSchemaByGuid$(schemaGuid).pipe(
            map((schema) => {
                if (!schema) return

                return this.fieldsFilteredByFolder(schema, folder)
            }),
        )
    }

    selectSchemaBySystemObjectTypeCode$ = (type: SystemTypeCode) => {
        return this.selectAllSchemas$.pipe(
            map((schemas: Schema[]) =>
                schemas.find((schema) => schema.system_object_type_code === type),
            ),
        )
    }

    selectSchemaByParentSotGuid$ = (guid: string) => {
        return this.selectAllSchemas$.pipe(
            map((schemas) => schemas.find((schema) => schema.parentSotGuid === guid)),
        )
    }

    selectFieldByObjectTypeCode$(schemaSystemTypeCode: SystemTypeCode, fieldTypeCode: FieldTypes) {
        return this.selectSchemaBySystemObjectTypeCode$(schemaSystemTypeCode).pipe(
            map(
                (viewSchema) =>
                    (viewSchema &&
                        values(viewSchema.fieldEntities).find(
                            (field) => field.field_type_code === fieldTypeCode,
                        )) ||
                    null,
            ),
        )
    }

    selectSelectedTableSchemaFieldEntitiesFiltered$ = (folder?: Folder) => {
        return this.selectSelectedTableSchema$.pipe(
            map((schema: Schema | undefined) => {
                if (!schema || !folder) return undefined

                return this.fieldsFilteredByFolder(schema, folder)
            }),
        )
    }

    initSchemas(schemas: Schema[]) {
        this.store.dispatch(initSchemas({ schemas }))
    }

    updateSchemas(schemas: Update<Schema>[]) {
        this.store.dispatch(updateSchemas({ schemas }))
    }

    updateField(field: Field) {
        return this.selectDefinedSelectedSchema$.pipe(
            take(1),
            switchMap((schema: Schema) => {
                return this.updateService.updateField(field, schema)
            }),
        )
    }

    createField(field: Field) {
        return this.selectDefinedSelectedSchema$.pipe(take(1)).pipe(
            take(1),
            switchMap((schema) => {
                return this.updateService.createField(field, schema)
            }),
        )
    }

    deleteField(guid: string) {
        return this.selectDefinedSelectedSchema$.pipe(
            take(1),
            switchMap((schema) => {
                return this.updateService.deleteField(guid, schema)
            }),
        )
    }

    setSelectedTableSchemaGuid(selectedSchemaGuid: string | null) {
        this.store.dispatch(setSelectedTableSchemaGuid({ selectedSchemaGuid }))
    }

    private fieldsFilteredByFolder(schema: Schema, folder: Folder) {
        return Object.keys(schema.fieldEntities)
            .filter((guid) => {
                if (isFolderGlobal(folder)) return true
                return this.fieldBelongsToFolder(schema, folder, guid)
            })
            .reduce(
                (sortedFields, guid) => ({
                    ...sortedFields,
                    [guid]: schema.fieldEntities[guid],
                }),
                {} as FieldEntities,
            )
    }

    private fieldBelongsToFolder(schema: Schema, folder: Folder, guid: string) {
        return (
            schema.fieldEntities[guid].folder_guid === folder.guid ||
            schema.fieldEntities[guid].shared_with_folder?.includes(folder.guid) ||
            schema.fieldEntities[guid].folder_name?.is_global
        )
    }
}
