@if (loading$ | async) {
    <div class="overlay">
        <div class="flex flex-col items-center gap-6">
            <app-tb-spinner></app-tb-spinner>
            <div>
                <img
                    ngSrc="../../../assets/img/logo_loading.svg"
                    alt="logo"
                    height="67"
                    width="229"
                />
            </div>
        </div>
    </div>
}
