<div
    class="flex items-center cursor-pointer bg-white"
    [ngClass]="{ 'body-1': !isSelectedOrMenuOpened, 'body-2': isSelectedOrMenuOpened, 'text-secondaryMain': isMenuOpened}"
>
    <div class="relative">
        <div class="flex items-center pl-1 pr-2 py-1" (click)="select()">
            @if (icon) {
            <ng-container [ngTemplateOutlet]="icon" [ngTemplateOutletContext]="{ tab }">
            </ng-container>
            } @else if (tab.icon) {
            <app-tb-icon [icon]="tab.icon"></app-tb-icon>
            }
            <div
                title="{{ tab.name }}"
                class="tab-name max-w-[17ch] ml-2 whitespace-nowrap text-ellipsis overflow-hidden"
            >
                {{tab.name}}
            </div>
        </div>
        <div
            [class]="isSelected ? 'visible' : 'invisible'"
            class="w-100 h-[4px] rounded-sm bg-secondaryMain absolute bottom-[-4px]"
        ></div>
    </div>
    @if (moreMenu) {
    <div class="more-menu">
        <app-tb-menu
            [template]="moreMenu"
            (stateChanged)="isMenuOpened = $event === TbMenuStateEnum.Open"
        >
            <app-tb-button
                [appearance]="'icon'"
                [icon]="'more_vert'"
                [class.visible]="isMenuOpened"
                trigger
            >
            </app-tb-button>
        </app-tb-menu>
    </div>
    }
</div>
