import { Component, Input, OnInit } from '@angular/core'
import { InputCellContainerComponent } from '@app/feature/input-cells/input-cell-container/input-cell-container.component'
import { NO_GROUPED_RECORDS_KEY } from '@core/@ngrx'
import { Field, FieldType } from '@core/models'
import { NEW_CONTAINER_CELLS } from '@shared/cell-types/cell-container.component'
import { BooleanComponent } from '../cell-types/boolean/boolean.component'
import { RatingComponent } from '../cell-types/rating/rating.component'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-grouping-values',
    templateUrl: './grouping-values.component.html',
    styleUrls: ['./grouping-values.component.sass'],
    standalone: true,
    imports: [TranslocoModule, RatingComponent, BooleanComponent, InputCellContainerComponent],
})
export class GroupingValuesComponent implements OnInit {
    @Input() fieldType?: FieldType
    @Input() field!: Field
    @Input() value!: string
    @Input() isCollapsed!: boolean

    hasNewContainerSupport = false

    get isAssignee() {
        return this.field?.field_type_code === 'field_type_assignee'
    }

    get isStatus() {
        return this.field.field_type_code === 'field_type_status'
    }

    get isNoGrouped() {
        return this.value === NO_GROUPED_RECORDS_KEY
    }

    ngOnInit(): void {
        this.setHasNewContainerSupport()
    }

    private setHasNewContainerSupport() {
        this.hasNewContainerSupport = NEW_CONTAINER_CELLS.includes(this.field.field_type_code)
    }
}
