<div *transloco="let tUserSettings; read: 'user_settings'" class="content-start">
    <div
        class="flex items-center p-4 sm:py-2 sm:bg-newText text-newPrimaryDark sm:text-newInversed text-xl sm:body-1"
    >
        <div
            class="flex items-center justify-center w-5 h-5 rounded-full mr-2 body-1 text-newInversed bg-newPrimary"
        >
            {{ solutionIcon }}
        </div>
        <div>{{ solutionName }}</div>
    </div>
    <div class="dropdown-holder p-4">
        <div (click)="logout()">
            <app-tb-icon icon="logout"></app-tb-icon>
            <span> {{ tUserSettings('log_out') }}</span>
        </div>
        <app-tb-divider></app-tb-divider>
        <div class="my-2">
            <app-version class="w-full h-5"></app-version>
        </div>
        <div (click)="resetSolution()" class="mb-0">
            <app-tb-icon icon="refresh"></app-tb-icon>
            <span> {{ tUserSettings('reset_solution') }}</span>
        </div>
        @if (envIsMock()) {
            <app-tb-select
                [value]="devDefaultSolutionValue"
                (selectionChange)="devSolutionChanged($event)"
            >
                @for (type of devSolutionTypes; track type) {
                    <app-tb-option [value]="type"> {{ type }}</app-tb-option>
                }
            </app-tb-select>
        }
        @if (envIsMock() || envIsDev()) {
            <app-tb-select
                [value]="devCurrentEnvMode$ | async"
                [placeholder]="(devCurrentEnvMode$ | async) || ''"
                (selectionChange)="devSetEnvMode($event)"
            >
                @for (mode of devEnvModesOptions; track mode) {
                    <app-tb-option [value]="mode"> {{ mode }}</app-tb-option>
                }
            </app-tb-select>
        }
    </div>
</div>
