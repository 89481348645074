<ng-container *transloco="let tStatusConfiguration; read: 'status_configuration'">
    <!--    desktop screen-->
    <div class="sm:block hidden">
        <div
            class="flex flex-col gap-2 w-[173px] min-h-[212px] max-h-[212px] overflow-auto mb-4 p-2 rounded-2xl shadow-light"
        >
            <div class="mb-2 pl-3 hover:text-secondaryMain hover:body-2 cursor-pointer">
                &#x2022; {{ tStatusConfiguration('default') }}
            </div>
            <div class="text-primarySecondary">{{ tStatusConfiguration('templates') }}</div>

            <div class="pl-3 hover:text-secondaryMain hover:body-2 cursor-pointer">
                &#x2022; $Template
            </div>
        </div>
        <!--            if there are modifies templates-->
        <div
            class="flex flex-col gap-2 w-[173px] min-h-[210px] max-h-[212px] overflow-auto p-2 rounded-2xl shadow-light"
        >
            <div class="text-primarySecondary">{{ tStatusConfiguration('modified') }}</div>
            <!--                everything except the  before pl-3 should apply if template is selected-->
            <div
                class="w-fit py-1 pr-2 text-white bg-secondaryMain hover:text-white body-2 rounded-lg pl-3 hover:font-semibold hover:text-secondaryMain cursor-pointer"
            >
                &#x2022; $Template (2)
            </div>
        </div>
    </div>

    <!--    small screen-->
    <div class="sm:hidden mb-6 p-2 rounded-lg shadow-light">
        <mat-expansion-panel
            class="rounded-2xl body-1 shadow-light"
            #templatePan
            hideToggle="true"
            [expanded]="false"
        >
            <mat-expansion-panel-header class="pl-2">
                <mat-panel-title class="text-primaryDark body-1">
                    <mat-icon>{{ templatePan.expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
                    {{
                        tStatusConfiguration(
                            templatePan.expanded ? 'hide_template_panel' : 'show_template_panel'
                        )
                    }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex flex-col justify-start items-start gap-4 mt-2 xsm:mb-0 mb-4">
                <!--            all elements with text starting with $ should be dynamically inserted-->
                <div
                    class="flex items-center w-full h-12 px-3 py-2 rounded-lg bg-primaryBackground body-2 hover:text-secondaryMain cursor-pointer"
                >
                    {{ tStatusConfiguration('default') }}
                    <!--                icon should be visible if this template is selected-->
                    <mat-icon class="hidden h-4 w-4 ml-4 subheading-1 leading-4 text-secondaryMain"
                        >check</mat-icon
                    >
                </div>
                <div class="flex flex-col w-full gap-2">
                    <div class="text-primarySecondary">{{ tStatusConfiguration('templates') }}</div>
                    <!--                if template is selected, class text-secondaryMain should be applied-->
                    <div
                        class="flex items-center w-full h-12 px-3 py-2 rounded-lg bg-primaryBackground body-2 hover:text-secondaryMain cursor-pointer"
                    >
                        $Template
                        <!--                icon should be visible if this template is selected-->
                        <mat-icon
                            class="hidden h-4 w-4 ml-4 subheading-1 leading-4 text-secondaryMain"
                            >check</mat-icon
                        >
                    </div>
                </div>
                <div class="w-full h-[2px] rounded-sm bg-primaryLight"></div>
                <div class="w-full flex flex-col gap-2">
                    <div class="text-primarySecondary">{{ tStatusConfiguration('modified') }}</div>
                    <div
                        class="flex items-center w-full h-12 px-3 py-2 rounded-lg bg-primaryBackground body-2 hover:text-secondaryMain cursor-pointer"
                    >
                        $Template
                        <!--                icon should be visible if this template is selected-->
                        <mat-icon
                            class="hidden h-4 w-4 ml-4 subheading-1 leading-4 text-secondaryMain"
                            >check</mat-icon
                        >
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <!--        place for the selected template-->
        <div
            [class.hidden]="templatePan.expanded"
            class="flex items-center w-full h-12 px-3 py-2 rounded-lg bg-primaryBackground body-2 text-secondaryMain cursor-pointer"
        >
            $Template
            <mat-icon class="h-4 w-4 ml-4 subheading-1 leading-4 text-secondaryMain"
                >check</mat-icon
            >
            <!--            if the selected template is modified-->
            <div class="ml-auto body-1 text-primarySecondary">
                {{ tStatusConfiguration('modified') }}
            </div>
        </div>
    </div>
</ng-container>
