<div class="dropdown-section mt-md">
    <div
        (cdkDropListDropped)="drop($event)"
        [cdkDropListData]="dropdownOptionsInArray"
        cdkDropList
        class="dropdown-list"
    >
        @for (item of dropdownOptionsInArray; track item) {
        <app-option-item
            (changeIcon)="changeIcon(item.guid, $event)"
            (deleteOption)="deleteOption($event)"
            (optionChanged)="optionChanged(item.guid, $event)"
            (setDefault)="setDefaultOption(item.guid)"
            [isSelected]="activeStatus === item.guid"
            [option]="item"
            [disableDelete]="dropdownOptionsInArray.length === 1"
            [isDropdown]="true"
        ></app-option-item>
        }
    </div>
    <!-- change to app-tb-button after new design will be implemented -->
    <button (click)="addOption()" color="primary" mat-button>
        <span
            class="flex-start-center"
            *transloco="let tCellTypesDropdownDialog; read: 'cell_types.dropdown_dialog'"
        >
            <app-tb-icon icon="add"></app-tb-icon>
            <span> {{tCellTypesDropdownDialog('dropdown_component_content_option')}} </span>
        </span>
    </button>
</div>
