import { CdkDrag } from '@angular/cdk/drag-drop'
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbInputComponent } from '@components-library/tb-input/tb-input.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { SelectOption } from '@models/response/select-object-options'
import { TranslocoModule } from '@ngneat/transloco'
import { ColorPickerComponent } from '@shared/color-picker/color-picker.component'
import { IconPickerComponent } from '@shared/icon-picker/icon-picker.component'
import { SetType } from '@shared/models/picker'

interface OptionControl {
    label: FormControl<string>
    color: FormControl<string>
    icon: FormControl<string>
}

@Component({
    selector: 'app-select-option',
    standalone: true,
    imports: [
        CdkDrag,
        TbIconComponent,
        ColorPickerComponent,
        MatFormFieldModule,
        TbMenuComponent,
        TbMenuListComponent,
        TbMenuListItemComponent,
        TbDividerComponent,
        TranslocoModule,
        IconPickerComponent,
        TbInputComponent,
        ReactiveFormsModule,
    ],
    templateUrl: './select-option.component.html',
})
export class SelectOptionComponent implements OnInit {
    protected readonly SetType = SetType

    @Input({ required: true }) option!: SelectOption
    @Input({ required: true }) dragBoundaryClass!: string

    @Input() isDefault: boolean = false

    @Output() delete = new EventEmitter<void>()
    @Output() optionChanged = new EventEmitter<void>()

    @ViewChild('iconPicker') iconPicker!: IconPickerComponent

    formGroup!: FormGroup<OptionControl>

    isClosed = false

    ngOnInit(): void {
        this.isClosed = this.option.tags === 'close'

        const builder = new FormBuilder()

        this.formGroup = builder.nonNullable.group({
            label: [
                this.option.label ?? '',
                {
                    updateOn: 'blur',
                },
            ],
            icon: this.option.icon ?? '',
            color: this.option.color ?? '',
        })

        this.formGroup.valueChanges.subscribe((value) => {
            if (!this.isChanges(value)) return

            this.option.label = value.label ?? this.option.label
            if (value.icon !== 'add_reaction') this.option.icon = value.icon
            this.option.color = value.color ?? this.option.color
            this.optionChanged.emit()
        })
    }

    private isChanges(value: Partial<{ label: string; color: string; icon: string }>) {
        return (
            this.option.label !== value.label ||
            this.option.color !== value.color ||
            (this.option.icon !== value.icon && value.icon !== 'add_reaction')
        )
    }
}
