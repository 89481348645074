import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-tb-toggle-button-group',
    standalone: true,
    imports: [MatButtonToggleModule, TranslocoModule],
    templateUrl: './tb-toggle-button-group.component.html',
})
export class TbToggleButtonGroupComponent implements OnInit {
    @Input({ required: true }) translateKey!: string

    @Input()
    combinationTypes: Record<string, string> = {}

    @Input({ required: true })
    value!: string

    @Input()
    disabled: boolean = false

    @Output() valueChange = new EventEmitter<string>()

    combinationTypesKeys: string[] = []

    ngOnInit() {
        this.combinationTypesKeys = Object.keys(this.combinationTypes)
    }

    toggle(toggleValue: string) {
        if (this.disabled) {
            return
        }

        this.value = toggleValue
    }
}
