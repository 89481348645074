<div class="flex flex-col h-9 mr-6 w-fit justify-end hover:text-secondaryMain cursor-pointer">
    <div class="flex items-center mb-1 pl-1 pr-2">
        <mat-icon svgIcon="{{ customIcon }}" class="h-5 w-5 text-lg leading-5 mr-2 text-inherit">{{
            icon
        }}</mat-icon>
        <div
            [class.body-2]="isSelected"
            class="leading-4 body-1 h-4 max-w-[17ch] overflow-hidden whitespace-nowrap text-ellipsis"
        >
            {{ name }}
        </div>
    </div>
    <div
        [class]="isSelected ? 'visible' : 'invisible'"
        class="w-full h-[4px] min-h-[4px] rounded-sm bg-secondaryMain"
    ></div>
</div>
