import {
    AfterViewInit,
    Component,
    inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { TextOneLineCellComponent } from '@app/feature/input-cells/one-line-cell/text-one-line-cell.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { CardComponent, DialogCardInput } from '@app/feature/record-card/card.component'
import { AppState, selectSelectedFolder, selectSelectedFolderStatusField } from '@core/@ngrx'
import { isNonNull } from '@core/global-util'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Store } from '@ngrx/store'
import { combineLatest, filter } from 'rxjs'
import { take } from 'rxjs/operators'

@UntilDestroy()
@Component({
    selector: 'app-name-cell',
    standalone: true,
    imports: [ReactiveFormsModule, TranslocoModule],
    templateUrl: './name-cell.component.html',
    styleUrl: './name-cell.component.sass',
})
export class NameCellComponent extends TextOneLineCellComponent implements OnInit, OnChanges {
    @Input() placeholder = 'cell_placeholder.name'

    readonly UNKNOWN_STATUS_COLOR = '#aa2255'
    readonly modalFlowManagerService = inject(ModalFlowManagerService)
    statusColor?: string

    constructor(private store: Store<AppState>) {
        super()
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateColor()

        super.ngOnChanges(changes)
    }

    ngOnInit() {
        this.updateColor()

        super.ngOnInit()
    }

    private updateColor() {
        combineLatest([
            this.store.select(selectSelectedFolderStatusField).pipe(filter(isNonNull)),
            this.store.select(selectSelectedFolder).pipe(filter(isNonNull)),
        ])
            .pipe(take(1))
            .subscribe(([field, folder]) => {
                const statusValue = this.record?.status[folder.guid]?.value
                if (statusValue && field.select_object_field) {
                    this.statusColor =
                        field.select_object_field[statusValue]?.color || this.UNKNOWN_STATUS_COLOR
                }
            })
    }

    override onContainerClick() {
        if (!this.record) return

        import('../../../feature/record-card/card.component').then(({ CardComponent }) => {
            if (!this.record) return

            this.modalFlowManagerService.openDialog<CardComponent, DialogCardInput>({
                component: CardComponent,
                data: {
                    recordGuid: this.record.guid,
                    isFolder: true,
                },
            })
        })
    }
}
