import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms'
import { FieldNameComponent } from '@app/shared/field-name/field-name.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { emailValidationPattern, getBoolean, urlValidator } from '@core/global-util'
import { BusinessRecords, Field, FieldType, FieldTypes, Folder } from '@core/models'
import { InputContainerAppearance } from '@models/input'
import { Dictionary } from '@ngrx/entity'
import { isEqual } from 'lodash-es'
import { RecordFieldsInputComponent } from './record-fields-input/record-fields-input.component'

@Component({
    selector: 'app-record-fields',
    templateUrl: './record-fields.component.html',
    standalone: true,
    imports: [ReactiveFormsModule, RecordFieldsInputComponent, FieldNameComponent, TbIconComponent],
})
export class RecordFieldsComponent implements OnInit, OnChanges {
    @Input() folder!: Folder
    @Input() fields!: Field[]
    @Input() fieldTypes!: Dictionary<FieldType>
    @Input() values!: { [fieldGuid: string]: string }
    @Input() records!: Dictionary<BusinessRecords>
    @Input({ required: false }) formDirectiveGroup?: FormGroup
    @Input() inputAppearance = InputContainerAppearance.create_record
    @Input() groupName!: string

    formGroup!: FormGroup<{ [fieldGuid: string]: FormControl<string | null> }>

    ngOnInit() {
        this.setFormGroup()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fields) {
            if (!isEqual(changes.fields.currentValue, changes.fields.previousValue)) {
                this.setFormGroup()
            }
        }
    }

    getFieldFormControl(guid: string) {
        return this.formGroup.controls[guid]
    }

    private setFormGroup() {
        this.formGroup = new FormGroup(this.setFormControls())

        this.formDirectiveGroup?.setControl(this.groupName, this.formGroup)
    }

    private setFormControls() {
        return this.fields.reduce(
            (controls, field) => {
                const validators = this.fieldTypeBasedValidators(
                    field.field_type_code,
                    getBoolean(field.is_required),
                )
                controls[field.guid] = new FormControl(this.values[field.guid], validators)
                return controls
            },
            {} as { [fieldGuid: string]: FormControl<string | null> },
        )
    }

    // TODO: all methods below will be reworked/removed in task #1263

    private fieldTypeBasedValidators(fieldType: string, isRequired = false) {
        const validatorsArray = isRequired ? [Validators.required] : []

        if (!this.validatedTypes().includes(fieldType)) return validatorsArray

        if (fieldType === FieldTypes.EMAIL) {
            validatorsArray.push(Validators.pattern(emailValidationPattern))
        }

        if (fieldType === FieldTypes.WEBSITE) {
            validatorsArray.push(this.urlValidator())
        }

        if (fieldType === FieldTypes.MONEY) {
            validatorsArray.push(this.moneyValidator())
        }

        return validatorsArray
    }

    private validatedTypes() {
        return [FieldTypes.EMAIL, FieldTypes.MONEY, FieldTypes.WEBSITE] as string[]
    }

    private urlValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            if (urlValidator(control.value)) return null
            return { urlValid: false }
        }
    }

    private moneyValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value || Number(control.value)) return null
            return { moneyValid: false }
        }
    }
}
