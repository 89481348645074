<div
    class="flex flex-col justify-start gap-2 items-start xsm:flex-row mb-5"
    *transloco="let tAddRecordContent; read: 'add_record_content'"
>
    <div class="w-1/2 flex flex-col gap-2">
        @if (pickedFolders.length === 1) {
            <ng-container
                *ngTemplateOutlet="
                    settingsItem;
                    context: {
                        name: tAddRecordContent('workspace'),
                        icon: pickedFolders[0].icon.value,
                        selectedName: pickedFolders[0].name.value,
                        color: pickedFolders[0].color.value,
                    }
                "
            ></ng-container>
        } @else {
            <div
                (click)="openDialog()"
                class="bg-newNeutral2 rounded-lg px-2 py-1 cursor-pointer recordInfoBlock"
            >
                <div class="flex items-center justify-between w-full mb-1">
                    <div class="text-newTextLight caption-1">
                        {{ tAddRecordContent('workspace') }}
                    </div>
                    <app-tb-icon
                        icon="custom_edit"
                        class="text-newTextLight icon-d editIcon"
                    ></app-tb-icon>
                </div>
                <div class="w-full flex">
                    <div class="flex flex-row overflow-hidden max-w-[220px]">
                        <app-tb-icon
                            [icon]="pickedFolders[0].icon.value"
                            class="icon-d"
                            [style.color]="pickedFolders[0].color.value"
                        ></app-tb-icon>
                        <div class="body-1">
                            {{ hiddenElementsCounter() }}
                        </div>
                    </div>
                </div>
            </div>
        }

        <ng-container *appEnv="'prod'; visible: false">
            <ng-container
                *ngTemplateOutlet="
                    settingsItem;
                    context: {
                        name: tAddRecordContent('template'),
                        icon: 'person_outline',
                        selectedName: '$Test',
                    }
                "
            ></ng-container>
        </ng-container>
    </div>

    <div *transloco="let tSchemaReadableName; read: 'nav_menu'" class="w-1/2 flex flex-col gap-2">
        <ng-container
            *ngTemplateOutlet="
                settingsItem;
                context: {
                    name: tAddRecordContent('type'),
                    icon: schemaIconByName[selectedSchema.name],
                    selectedName: tSchemaReadableName(selectedSchema.name),
                    color: 'newPrimary',
                }
            "
        ></ng-container>

        <!-- will be changed after ACL implementation -->
        <ng-container *appEnv="'prod'; visible: false">
            <ng-container
                *ngTemplateOutlet="
                    settingsItem;
                    context: {
                        name: tAddRecordContent('access'),
                        icon: 'person_outline',
                        selectedName: '$Everyone',
                    }
                "
            ></ng-container>
        </ng-container>
    </div>
</div>

<ng-template
    #settingsItem
    let-icon="icon"
    let-name="name"
    let-color="color"
    let-selectedName="selectedName"
>
    <div
        (click)="openDialog()"
        class="bg-newNeutral2 rounded-lg px-2 py-1 cursor-pointer recordInfoBlock"
    >
        <div class="flex items-center justify-between w-full mb-1">
            <div class="text-newTextLight caption-1">{{ name }}</div>
            <app-tb-icon icon="custom_edit" class="text-newTextLight icon-d editIcon"></app-tb-icon>
        </div>
        <div class="flex">
            <app-tb-icon
                [icon]="icon"
                class="mr-2 icon-d"
                [color]="color"
                [style.color]="color"
            ></app-tb-icon>
            <div class="body-1">{{ selectedName }}</div>
        </div>
    </div>
</ng-template>
