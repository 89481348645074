import { AsyncPipe, NgOptimizedImage } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TranslocoModule } from '@ngneat/transloco'
import { WsProcessingService } from '@services/ws-processing.service'

export type ErrorPageData = {
    title: string
    message: string
    button: string
}

@Component({
    selector: 'app-errors',
    templateUrl: './error.page.html',
    standalone: true,
    imports: [TranslocoModule, AsyncPipe, TbButtonComponent, NgOptimizedImage],
})
export class ErrorPageComponent implements OnInit {
    data: ErrorPageData | null = null

    constructor(
        private route: ActivatedRoute,
        private wsProcessingService: WsProcessingService,
    ) {}

    ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.data = data as ErrorPageData
        })
    }

    tryAgain() {
        this.wsProcessingService.logout()
    }
}
