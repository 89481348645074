import { ComponentType } from '@angular/cdk/overlay'
import {
    AfterViewInit,
    ApplicationRef,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    createComponent,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { DateTimeCellComponent } from '@app/feature/input-cells/date-time-cell/date-time-cell.component'
import { MultiLineCellComponent } from '@app/feature/input-cells/multi-line-cell/multi-line-cell.component'
import { NameCellComponent } from '@app/feature/input-cells/name-cell/name-cell.component'
import { TextOneLineCellComponent } from '@app/feature/input-cells/one-line-cell/text-one-line-cell.component'
import { PeopleCellComponent } from '@app/feature/input-cells/people-cell/people-cell.component'
import { NumberCellComponent } from '@app/feature/input-cells/number-cell/number-cell.component'
import {
    OutlineContainerStyleConfig,
    TbOutlineContainerComponent,
} from '@components-library/tb-outline-container/tb-outline-container.component'
import { BaseCellComponent } from '@app/feature/input-cells/base-container-content'
import { BusinessRecord, Field, FieldTypes } from '@core/models'
import { InputContainerAppearance, INPUT_CONTAINER } from '@models/input'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Subject } from 'rxjs'
import { take } from 'rxjs/operators'

export const fieldTypeComponents: { [key: string]: ComponentType<any> } = {
    [FieldTypes.NUMBER]: NumberCellComponent,
    [FieldTypes.DATE_TIME]: DateTimeCellComponent,
    [FieldTypes.ASSIGNEE]: PeopleCellComponent,
    [FieldTypes.PEOPLE]: PeopleCellComponent,
    [FieldTypes.WATCH]: PeopleCellComponent,
    [FieldTypes.TEXT]: TextOneLineCellComponent,
    [FieldTypes.MULTILINE_TEXT]: MultiLineCellComponent,
    [FieldTypes.NAME]: NameCellComponent,
}

const stylesConfigsForAppearances: Record<InputContainerAppearance, OutlineContainerStyleConfig> = {
    [InputContainerAppearance.board]: {
        bg_and_border_by_default: false,
        disable_hover_and_mouse_events: true,
    },
    [InputContainerAppearance.create_record]: {
        bg_and_border_by_default: true,
        disable_hover_and_mouse_events: false,
    },
    [InputContainerAppearance.record_card]: {
        bg_and_border_by_default: true,
        disable_hover_and_mouse_events: false,
    },
    [InputContainerAppearance.table]: {
        bg_and_border_by_default: false,
        disable_hover_and_mouse_events: false,
        show_extra_info: true,
    },
}

export type ExtraData = {
    row_order?: number
}

@UntilDestroy()
@Component({
    selector: 'app-input-cell-container',
    standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputCellContainerComponent),
            multi: true,
        },
    ],
    imports: [MatInputModule, MatIconModule, TbOutlineContainerComponent],
    templateUrl: './input-cell-container.component.html',
    styleUrl: './input-cell-container.component.sass',
})
export class InputCellContainerComponent
    implements AfterViewInit, ControlValueAccessor, OnChanges, OnInit
{
    @Input({ required: true })
    field!: Field

    @Input()
    record?: BusinessRecord

    @Input()
    readonly = false

    @Input()
    disabled = false

    @Input()
    locked = false

    @Input()
    loading = false

    @Input()
    activeState = false

    @Input()
    editControls = false

    @Input()
    placeholder = ''

    @Input()
    extraData?: ExtraData

    @Input()
    value!: string

    @Input()
    hoverIcons: TemplateRef<any> | null = null

    @Input()
    appearance: keyof typeof InputContainerAppearance = InputContainerAppearance.create_record

    @Output()
    valueChange = new EventEmitter<any>()

    @ViewChild('component', { read: ViewContainerRef, static: true })
    componentContainer!: ViewContainerRef

    cellInstance!: ComponentRef<BaseCellComponent>
    containerInstance!: ComponentRef<TbOutlineContainerComponent>
    contentIsRendered$$ = new Subject<void>()

    constructor(
        private appRef: ApplicationRef,
        private injector: Injector,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        if (!this.field || !fieldTypeComponents[this.field.field_type_code]) {
            throw new Error('Type is incorrect')
        }
    }

    ngOnChanges(): void {
        if (this.containerInstance && this.cellInstance) {
            this.setDynamicContentProperties()
        }
    }

    ngAfterViewInit(): void {
        this.containerInstance = createComponent(TbOutlineContainerComponent, {
            environmentInjector: this.appRef.injector,
        })

        this.cellInstance = createComponent(fieldTypeComponents[this.field.field_type_code], {
            environmentInjector: this.appRef.injector,
            elementInjector: Injector.create({
                providers: [
                    {
                        provide: INPUT_CONTAINER,
                        useValue: this.containerInstance.instance,
                    },
                ],
                parent: this.injector,
            }),
        })

        this.cellInstance.instance.valueChange.pipe(untilDestroyed(this)).subscribe((value) => {
            this.valueChange.emit(value)
        })

        this.setDynamicContentProperties()

        this.containerInstance.instance.contentComponent = this.cellInstance

        this.componentContainer.insert(this.containerInstance.hostView)
        this.cdr.detectChanges()

        this.contentIsRendered$$.next()
    }

    writeValue(value: any): void {
        this.contentIsRendered$$.pipe(take(1), untilDestroyed(this)).subscribe(() => {
            this.cellInstance.instance.writeValue(value)
        })
    }

    registerOnChange(fn: any): void {
        this.contentIsRendered$$.pipe(take(1), untilDestroyed(this)).subscribe(() => {
            this.cellInstance.instance.registerOnChange(fn)
        })
    }

    registerOnTouched(fn: any): void {
        this.contentIsRendered$$.pipe(take(1), untilDestroyed(this)).subscribe(() => {
            this.cellInstance.instance.registerOnTouched(fn)
        })
    }

    setDisabledState?(isDisabled: boolean): void {
        this.contentIsRendered$$.pipe(take(1), untilDestroyed(this)).subscribe(() => {
            this.containerInstance.instance.disabled = isDisabled
            this.cellInstance.instance.setDisabledState(isDisabled)
        })
    }

    setDynamicContentProperties() {
        const styleConfig = stylesConfigsForAppearances[this.appearance]

        this.containerInstance.instance.readonly = this.readonly
        this.containerInstance.instance.disabled = this.disabled
        this.containerInstance.instance.locked = this.locked
        this.containerInstance.instance.loading = this.loading
        this.containerInstance.instance.activeState = this.activeState
        this.containerInstance.instance.styleConfig = styleConfig
        this.containerInstance.instance.editControls = this.editControls
        this.containerInstance.instance.hoverIcons = this.hoverIcons

        if (this.appearance === InputContainerAppearance.board) {
            this.containerInstance.instance.readonly = true
        }

        if (
            this.appearance === InputContainerAppearance.table ||
            this.appearance === InputContainerAppearance.record_card
        ) {
            this.containerInstance.instance.activeState = true
            this.containerInstance.instance.editControls = true
        }

        this.cellInstance.instance.readonly = this.readonly
        this.cellInstance.instance.placeholder = this.placeholder
        this.cellInstance.instance.value = this.value
        this.cellInstance.instance.loading = this.loading
        this.cellInstance.instance.disabled = this.disabled
        this.cellInstance.instance.field = this.field
        this.cellInstance.instance.styleConfig = styleConfig
        this.cellInstance.instance.extraData = this.extraData
        this.cellInstance.instance.record = this.record
    }
}
