<input
    #input
    class="body-1 w-full bg-transparent"
    type="number"
    [class.text-red-800]="lessThanZero"
    [formControl]="control"
    (focusin)="onFocusIn()"
    (focusout)="onFocusOut($event)"
    [placeholder]="placeholder"
    [readOnly]="readonly || loadingState"
/>
