@if (fieldTypes$ | async; as fieldTypes) {
    @if (selectedFolder$ | async; as selectedFolder) {
        @if (hasFieldErrors$ | async; as errors) {
            <app-view-invalid-message class="w-full" [errors]="errors"></app-view-invalid-message>
        } @else if (filterHasNoResults) {
            <app-filter-no-results
                class="w-full"
                (changeFilters)="openFiltersMenu()"
            ></app-filter-no-results>
        } @else {
            <app-tb-board class="pl-4" [columnTemplate]="columnTemplate"></app-tb-board>
        }

        <ng-template #columnTemplate>
            @for (group of groups; track group) {
                <app-tb-board-column
                    [items]="group.data || []"
                    [card]="card"
                    [columnItem]="group"
                    [headerMenu]="cardHeaderMenu"
                    [disableDragIntoList]="group.value === NO_GROUPED_RECORDS_KEY"
                    [selectedItems]="selectedRecords"
                    [isCollapsedSet]="!isCollapsedNotSet(group.value, group.field?.guid)"
                    [collapsed]="getCollapseState(group.value, group.field?.guid)"
                    (columnMoved)="drop(group, $event)"
                    (selectColumn)="toggleGroup($event, group)"
                    (collapseChange)="setCollapseState($event, group.value, group.field?.guid)"
                >
                    <div header class="">
                        @if (group.field && fieldTypes) {
                            <div class="flex items-center relative">
                                <app-grouping-values
                                    [fieldType]="fieldTypes[group.field.field_type_code]"
                                    [field]="group.field"
                                    [value]="group.value ?? ''"
                                ></app-grouping-values>
                            </div>
                        } @else {
                            <span *transloco="let tBoard; read: 'board'">
                                {{ tBoard('template') }}
                            </span>
                        }
                    </div>
                </app-tb-board-column>
            }
        </ng-template>
        <ng-template #businessCardContent let-fieldTypes="fieldTypes" let-record="record">
            @if (fields$ | async; as fields) {
                <app-board-card-fields
                    [fieldTypes]="fieldTypes"
                    [fields]="visibleFields"
                    [fieldEntities]="fields"
                    [selectedFolder]="selectedFolder"
                    [cells]="cells[record.guid]"
                ></app-board-card-fields>
            }
        </ng-template>
        <ng-template #card let-record="item">
            <app-tb-board-card
                [item]="record"
                [title]="record.name.value"
                [key]="boardItemsKey"
                [moreMenu]="cardMoreMenu"
                [favorite]="favorite"
                [selected]="isRecordSelected(record)"
                [selectedItems]="selectedRecords"
                (selectCard)="toggleSelection(record)"
                (click)="openCardDialog(record)"
            >
                <ng-container
                    *ngTemplateOutlet="
                        businessCardContent;
                        context: { fieldTypes: fieldTypes, record: record }
                    "
                ></ng-container>
                <ng-template #cardMoreMenu>
                    <app-board-card-menu [record]="record"></app-board-card-menu>
                </ng-template>
            </app-tb-board-card>
        </ng-template>
        <ng-template #favorite let-record="item">
            @if (isWatchVisible(record)) {
                <app-tb-icon-toggle-button
                    icon="custom_star_outline"
                    toggledIcon="custom_star_full"
                    [isActive]="watchByRecords[record.guid]"
                    (toggle)="updateWatch(record)"
                ></app-tb-icon-toggle-button>
            }
        </ng-template>
        <ng-template #cardHeaderMenu let-data="data">
            <app-board-column-header-menu [data]="data"></app-board-column-header-menu>
        </ng-template>
    }
}
