<ng-container *transloco="let tNewWorkspace; read: 'new_workspace'">
    <app-modal-layout
        [containerLayout]="containerLayout"
        [errors]="errors"
        [showLoader]="showLoader"
        (closeModal)="forceWithValidation()"
    >
        <ng-container title>{{ tNewWorkspace('create_new_workspace') }}</ng-container>

        <div>
            <div class="mb-6 min-w-[28rem]">
                <div class="mb-1 text-primaryDark">{{ tNewWorkspace('workspace_name') }}</div>
                <app-tb-input [formControl]="folderGroupControl.controls['name']"></app-tb-input>
            </div>
            <div class="flex justify-between gap-4 w-full mb-6">
                <app-color-picker
                    name="Color"
                    [set]="SetType.FOLDER"
                    class="w-full"
                    [color]="defaultColor"
                    (colorChange)="updateColor($event)"
                ></app-color-picker>
                <app-icon-picker
                    name="Icon"
                    [set]="SetType.FOLDER"
                    [icon]="defaultIcon"
                    [iconColor]="color"
                    (iconChange)="updateIcon($event)"
                    class="w-full"
                ></app-icon-picker>
            </div>
            <app-access-selector
                *appEnv="'prod'; visible: false"
                class="mb-10"
            ></app-access-selector>
            <app-advanced-options *appEnv="'prod'; visible: false"></app-advanced-options>
        </div>

        <ng-container buttons *transloco="let tButtons; read: 'buttons'">
            <div class="flex justify-between">
                <app-tb-button
                    [class.invisible]="!data || isGlobal"
                    appearance="text"
                    color="secondary"
                    icon="close"
                    (click)="delete()"
                    class="mr-10 whitespace-nowrap"
                >
                    <span class="body-2">{{ tButtons('delete_workspace') }}</span>
                </app-tb-button>

                <div class="flex justify-end">
                    <app-tb-button
                        (click)="close()"
                        class="flex-1 sm:flex-none mr-4"
                        appearance="outlined"
                    >
                        {{ tButtons('cancel') }}
                    </app-tb-button>
                    <div class="flex sm:gap-1 gap-2 flex-1 sm:flex-none">
                        <app-tb-button class="w-full" (click)="apply()">{{
                            tButtons('apply')
                        }}</app-tb-button>
                        <app-tb-button [appearance]="'iconPrimary'" [icon]="'expand_less'">
                        </app-tb-button>
                    </div>
                </div>
            </div>
        </ng-container>
    </app-modal-layout>
</ng-container>
