import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { BaseCellComponent } from '@app/feature/input-cells/base-container-content'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { of, Subscription } from 'rxjs'

@UntilDestroy()
@Component({
    selector: 'app-multi-line-cell',
    standalone: true,
    templateUrl: './multi-line-cell.component.html',
    styleUrl: './multi-line-cell.component.sass',
    imports: [
        TbMenuComponent,
        TbButtonComponent,
        ModalLayoutComponent,
        ReactiveFormsModule,
        TranslocoModule,
    ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class MultiLineCellComponent extends BaseCellComponent implements OnInit, OnChanges {
    @ViewChild(TbMenuComponent) menu!: TbMenuComponent

    @Input() placeholder = 'cell_placeholder.text'

    isEdit = false
    isDirty = false

    showButtons = false

    dirtySubscription: Subscription | undefined

    textControl = new FormControl('', { nonNullable: true })

    ngOnInit(): void {
        this.showButtons = this.inputContainer.editControls

        this.mergeValidators(this.textControl)

        this.textControl.setValue(this.value)
        this.setCheckOnDirty()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value && !changes.value.firstChange) {
            this.textControl.setValue(changes.value.currentValue)
            this.setCheckOnDirty()
        }
    }

    writeValue(value: string): void {
        this.value = value
        this.textControl.setValue(value)
        this.setCheckOnDirty()
        this.cd.detectChanges()
    }

    onFocusClick(): void {
        this.isEdit = true
        this.menu.openMenu()
        this.makeFocused()
    }

    onContainerClick(): void {
        if (!this.value.length || this.styleConfig?.disable_hover_and_mouse_events) return

        this.isEdit = false
        this.menu.openMenu()
    }

    onOutsideClick(): void {
        if (!this.menu.menuOpen && this.focused) {
            this.makeTouchedAndUnfocused()
            this.inputContainer.resetActive()
            this.removeFocus()
            this.emitValue()
        }

        // to prevent menu layout shifting
        setTimeout(() => {
            this.isEdit = false
        }, 300)
    }

    override eventIsInsideCell(event: MouseEvent) {
        return this.isTargetInsideMenu(this.menu.menuTrigger, event)
    }

    removeFocus(): void {
        this.makeTouchedAndUnfocused()
    }

    emitValue(): void {
        const value = this.textControl.value
        if (!this.isDirty || this.errorState) return

        this.value = value
        this.valueChange.emit(value)
        this.onChange(value)
        this.onTouched()
    }

    resetValue(): void {
        this.resetControl(this.textControl, this.value)
    }

    cancel() {
        this.resetValue()
        this.makeTouchedAndUnfocused()
    }

    save() {
        this.emitValue()
        this.makeUnfocused()
    }

    copy() {
        this.inputContainer.onCopy()
    }

    get errorState() {
        return this.textControl.invalid && this.touched
    }

    private setCheckOnDirty() {
        this.dirtySubscription?.unsubscribe()

        this.dirtySubscription = dirtyCheck(this.textControl, of(this.textControl.value), {
            debounce: 0,
            useBeforeunloadEvent: false,
        })
            .pipe(untilDestroyed(this))
            .subscribe((isDirty) => {
                this.isDirty = isDirty
            })
    }
}
