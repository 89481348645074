import { reduce } from 'lodash-es'
import { FieldTypes, ResponseFieldEntities } from '../response'
import { FolderName, FolderNameEntities } from '../response/folder-names'
import { SelectObjectOptions, SelectOption } from '../response/select-object-options'
import { BusinessRecords, isFolderGlobal } from '@core/models'
import { FieldType, OperationCode, prepareOperationCode } from '@core/models'
import { Folder } from '@core/models'

export interface Field {
    guid: string
    field_type_code: FieldTypes | string
    name?: string
    is_readonly?: number
    is_required?: number
    is_primary?: number
    is_lock?: number
    folder_name?: FolderName
    configure_json?: string
    revision?: number
    validation?: string
    shared_with_folder?: string
    folder_guid?: string
    system_name?: string
    select_object_field?: SelectObjectOptions
    select_object_field_sorted?: SelectOption[]
    operationCode?: OperationCode
    link_definition?: LinkDefinition
    virtual_link?: string
    is_on_top?: number
    default_value?: string
}

export interface LinkDefinition {
    target_solution_object_type_guid: string
    target_object_field_guids: string[]
}

export type FieldEntities = Record<string, Field>

export type AddFieldData = Partial<Field> & {
    field_type_code: FieldTypes | string
    folder_guid?: string
    new_folder?: boolean
    duplicate?: boolean
}

export function generateFieldEntities(
    responseFieldEntities: ResponseFieldEntities,
    folderNames: FolderNameEntities,
): FieldEntities {
    let global_name: FolderName = {
        name: folderNames[Object.keys(folderNames).find((key) => folderNames[key].is_global)!].name,
        is_global: 1,
    }
    return Object.keys(responseFieldEntities).reduce((res: FieldEntities, fieldGuid: string) => {
        const responseField = responseFieldEntities[fieldGuid]
        if (responseField.folder_guid && !folderNames[responseField.folder_guid]) {
            return res
        }

        res[fieldGuid] = {
            guid: fieldGuid,
            folder_name: responseField.folder_guid
                ? folderNames[responseField.folder_guid]
                : global_name,
            ...responseField,
            operationCode: prepareOperationCode(responseField.operation_code),
        }

        return res
    }, {})
}

/**
 * Find a field by the field type in a schema's field entities
 * @param {FieldEntities} fieldEntities - fields collection
 * @param {FieldTypes} type - field type
 * @param {Folder | undefined} folder - related folder. For search in specific folder
 * @returns {Field | undefined} First field of the type if any
 */
export function findFieldByType(
    fieldEntities: FieldEntities,
    type: FieldTypes,
    folder?: Folder,
): Field | undefined {
    return Object.values(fieldEntities).find((field) => {
        if (!folder) return field.field_type_code === type

        const areGuidsEqual = field.folder_guid === folder.guid
        const globalValue = !!field.folder_name?.is_global && isFolderGlobal(folder)

        return field.field_type_code === type && (areGuidsEqual || globalValue)
    })
}
/**
 * Find an important field by the field type in a schema's field entities
 * Suggest that you always should have fields like name, watch
 * @param {FieldEntities} fieldEntities - fields collection
 * @param {FieldTypes.NAME | FieldTypes.WATCH | FieldTypes.RICH_TEXT} type - field type
 * @returns {Field | undefined} First field of the type
 */
export function findImportantFieldByType(
    fieldEntities: FieldEntities,
    type: FieldTypes.NAME | FieldTypes.WATCH | FieldTypes.RICH_TEXT,
): Field {
    return findFieldByType(fieldEntities, type)!
}

export function formAllFields(record: BusinessRecords, folder?: Folder | undefined) {
    const foldersCells = ['status', 'assignee', 'description'] as const

    const folderFieldGuids = foldersCells.reduce((array, fieldName) => {
        const cellEntities = record[fieldName]
        if (!cellEntities || typeof cellEntities !== 'object') return array

        const filteredCells = Object.keys(cellEntities)
            .filter((folderGuid) => {
                return folder?.guid !== folderGuid
            })
            .map((folderGuid) => cellEntities[folderGuid].fieldGuid)

        return [...array, ...filteredCells]
    }, [] as string[])

    const noNameFieldGuids = reduce(
        record.noNameCells,
        (array, value) => [...array, value.fieldGuid],
        [] as string[],
    )

    return [...noNameFieldGuids, ...folderFieldGuids]
}

export function getFieldEntitiesByFieldTypes(
    fieldEntities: FieldEntities,
    fieldTypes: FieldType[],
) {
    return Object.keys(fieldEntities).reduce((acc: FieldEntities, guid: string) => {
        const field = fieldEntities[guid]
        const currentFieldType = fieldTypes.find(
            (fieldType) => fieldType.guid === field.field_type_code,
        )
        if (currentFieldType) {
            acc[guid] = field
        }
        return acc
    }, {})
}
