import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    QueryList,
    TemplateRef,
    ViewChildren,
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MatExpansionPanel, MatExpansionModule } from '@angular/material/expansion'
import { compare } from '@core/global-util'
import { BusinessRecords, Field, FieldType, Folder, isFolderGlobal, Schema } from '@core/models'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'
import { cloneDeep } from 'lodash-es'
import { TbIconComponent } from '../../@components-library/tb-icon/tb-icon.component'
import { TbTagComponent } from '../../@components-library/tb-tags/components/tb-tag/tb-tag.component'
import { NgTemplateOutlet } from '@angular/common'
import { TranslocoModule } from '@ngneat/transloco'

export interface AccordionContentContext {
    fieldTypes: Dictionary<FieldType>
    folder: Folder
    fields: Field[]
    //next fields are needed for record creation
    values: { [fieldGuid: string]: string }
    records: Dictionary<BusinessRecords>
    form?: FormGroup
    groupName: string
}

type FieldKeys = keyof Field

@UntilDestroy()
@Component({
    selector: 'app-fields-collection',
    templateUrl: './fields-collection.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        NgTemplateOutlet,
        TbTagComponent,
        MatExpansionModule,
        TbIconComponent,
    ],
})
export class FieldsCollectionComponent implements OnChanges {
    private readonly sortingFieldKeys: FieldKeys[] = ['is_on_top', 'is_required']
    protected readonly isFolderGlobal = isFolderGlobal

    @Input() accordionContent!: TemplateRef<AccordionContentContext>
    @Input({ required: false }) form?: FormGroup
    // could be not equal to store selected folder during record creation
    @Input() selectedFolder!: Folder
    @Input() recordFieldValues: { [fieldGuid: string]: string } = {}
    // for link fields
    @Input() records!: Dictionary<BusinessRecords>
    @Input() excludedFieldTypes!: string[]
    @Input() folders!: Folder[] | null
    @Input() selectedSchema?: Schema | null
    @Input() fieldTypes!: Dictionary<FieldType>

    @ViewChildren(MatExpansionPanel) expansionPanels!: QueryList<MatExpansionPanel>

    availableFolders!: Folder[]
    fieldsByFolder: { [guid: string]: Field[] } = {}
    sharedFields: Field[] = []
    requiredOrTopFields: Field[] = []

    constructor(private elementRef: ElementRef) {}

    ngOnChanges(): void {
        this.setState()
    }

    getAccordionContentContext(
        groupName: string,
        fields: Field[],
        folder: Folder,
    ): AccordionContentContext {
        return {
            fieldTypes: this.fieldTypes,
            folder,
            fields,
            values: this.recordFieldValues,
            records: this.records,
            form: this.form,
            groupName,
        }
    }

    scrollToExpansionPanel(guid: string) {
        const htmlPanel = (this.elementRef.nativeElement as HTMLElement).querySelector(`#${guid}`)

        if (htmlPanel) {
            htmlPanel.scrollIntoView({ behavior: 'smooth' })
        }
    }

    // TODO: will be moved to the accordion component later
    expansionPanelIsExpanded(guid: string) {
        if (!this.expansionPanels) return false

        const expansionPanel = this.expansionPanels.find((panel) => panel.id === guid)

        return !!expansionPanel?.expanded
    }

    private setState() {
        this.setFields()
        this.setAvailableFolders()
    }

    private setFields() {
        if (!this.selectedSchema || !this.folders) return

        const fields = cloneDeep(Object.values(this.selectedSchema.fieldEntities)).sort((a, b) =>
            compare(a.name, b.name),
        )

        this.sharedFields = this.getSharedFields(fields)
        this.requiredOrTopFields = this.getRequiredOrTopFields(fields)

        this.folders.reduce((fieldsByFolder, folder) => {
            const guid = folder.guid
            fieldsByFolder[guid] = this.getFieldsByFolder(fields, guid)
            return fieldsByFolder
        }, this.fieldsByFolder)
    }

    private setAvailableFolders() {
        if (!this.selectedSchema || !this.folders) return

        if (isFolderGlobal(this.selectedFolder)) {
            this.availableFolders = this.folders

            return
        }

        this.availableFolders = this.folders.filter((folder) => {
            if (isFolderGlobal(this.selectedFolder)) {
                return true
            }

            if (!this.fieldsByFolder[folder.guid].length) {
                return false
            }

            return folder.guid === this.selectedFolder.guid || isFolderGlobal(folder)
        })
    }

    private getRequiredOrTopFields(fields: Field[]) {
        return fields
            .filter(
                (field) =>
                    (!!field.is_required || !!field.is_on_top) &&
                    !this.excludedFieldTypes.includes(field.field_type_code),
            )
            .sort((a, b) => this.sortFields(a, b, this.sortingFieldKeys))
    }

    private sortFields(a: Field, b: Field, sortingFieldKeys: FieldKeys[]) {
        let compareResult = 0
        sortingFieldKeys.forEach((fieldKey) => {
            const isAsc = false
            if (!compareResult) {
                const compared = compare(
                    a[fieldKey] as number | undefined,
                    b[fieldKey] as number | undefined,
                    isAsc,
                )
                if (compared !== 0) {
                    compareResult = compared
                }
            }
        })
        return compareResult
    }

    private getFieldsByFolder(fields: Field[], folderGuid: string) {
        return fields.filter(
            (field) =>
                !field.is_required &&
                !field.is_on_top &&
                !this.excludedFieldTypes.includes(field.field_type_code) &&
                field.folder_guid === folderGuid,
        )
    }

    private getSharedFields(fields: Field[]) {
        return fields.filter(
            (field) =>
                !field.is_required &&
                !this.excludedFieldTypes.includes(field.field_type_code) &&
                !!field.shared_with_folder?.includes(this.selectedFolder.guid),
        )
    }
}
