<!-- TODO: Implement component library for searchable select -->
<div [matMenuTriggerFor]="assignMenu">
    @if (getAssignUsersLength() !== 0) {
        <div
            (mouseenter)="isShowAssignees = true"
            (mouseleave)="isShowAssignees = false"
            class="users-icons"
        >
            @for (user of assignedUsers; track user) {
                <span class="user-avatar">
                    <app-user-avatar
                        [userName]="user.fullName"
                        [userUrl]="user.avatar_url"
                        [class.active]="this.userGuids.includes(user.guid)"
                    >
                    </app-user-avatar>
                </span>
            }
            @if (getAssignUsersLength() && isShowAssignees) {
                <div class="assignee-info">
                    @for (user of assignedUsers; track user) {
                        <i> {{ user.fullName }}</i>
                    }
                </div>
            }
        </div>
    } @else {
        <app-tb-icon class="md-inactive" icon="person_add_alt"></app-tb-icon>
    }
</div>
<mat-menu #assignMenu="matMenu">
    <ng-template matMenuContent>
        <mat-form-field (click)="$event.stopPropagation()" class="noHintError" appearance="outline">
            <span matPrefix>
                <app-tb-icon class="md-inactive search-icon" icon="search"></app-tb-icon>
            </span>
            <input [formControl]="search" class="search-input" matInput type="text" />
        </mat-form-field>
        @for (user of users; track user) {
            <app-tb-button
                [appearance]="'text'"
                (click)="addUser(user.guid); $event.stopPropagation()"
            >
                <span class="user-avatar">
                    <app-user-avatar
                        [userName]="user.fullName"
                        [userUrl]="user.avatar_url"
                        [class.active]="this.userGuids.includes(user.guid)"
                    >
                    </app-user-avatar>
                </span>
                {{ user.fullName }}
            </app-tb-button>
        }
    </ng-template>
</mat-menu>
