import { createFeatureSelector, createSelector } from '@ngrx/store'
import { folderAdapter, folderFeature, FolderState } from './folder.reducer'
import { Dictionary } from '@ngrx/entity'
import { Field, FieldEntities, FieldTypes, findFieldByType, Folder } from '../../models'
import { AppState } from '../app.state'
import { FolderNameEntities } from '../../models/response/folder-names'
import { selectSelectedTableSchemaFieldEntities } from '../schema'
import { StoreFeatureNames } from '../store-feature-names'
import { getBoolean, Nullable } from '@core/global-util'

const selectFolderState = createFeatureSelector<FolderState>(StoreFeatureNames.Folder)

const { selectIds, selectEntities, selectAll, selectTotal } =
    folderAdapter.getSelectors(selectFolderState)

export const selectFolderIds = selectIds
export const selectFolderEntities = selectEntities
export const selectAllFolders = selectAll
export const selectFolderTotal = selectTotal

export const selectFoldersAreLoaded = folderFeature.selectAreLoaded
export const selectFoldersIsError = folderFeature.selectIsError
export const selectFolderTemplate = folderFeature.selectFolderTemplate

export const selectGlobalFolder = createSelector<AppState, [Folder[]], Folder>(
    selectAllFolders,
    (folders: Folder[]) => {
        return folders.find((folder) => getBoolean(folder.is_global.value))!
    },
)

export const selectSelectedFolderGuid = createSelector<
    AppState,
    [Nullable<string>, Folder],
    string
>(
    folderFeature.selectSelectedFolderGuid,
    selectGlobalFolder,
    (selectedFolderGuid: Nullable<string>, globalFolder: Folder) => {
        return selectedFolderGuid ?? globalFolder?.guid
    },
)

export const selectSelectedFolder = createSelector<
    AppState,
    [Dictionary<Folder>, string, Folder],
    Folder
>(
    selectFolderEntities,
    selectSelectedFolderGuid,
    selectGlobalFolder,
    (folderDictionary: Dictionary<Folder>, selectedFolderGuid: string, globalFolder) => {
        return folderDictionary[selectedFolderGuid] ?? globalFolder
    },
)

export const selectGlobalFolderStatusField = createSelector<
    AppState,
    [Folder, Nullable<FieldEntities>],
    Nullable<Field>
>(
    selectGlobalFolder,
    selectSelectedTableSchemaFieldEntities,
    (selectedFolder: Folder, fields: Nullable<FieldEntities>) => {
        if (!fields) return
        return findFieldByType(fields, FieldTypes.STATUS, selectedFolder)
    },
)

export const selectSelectedFolderStatusField = createSelector<
    AppState,
    [Folder, Nullable<FieldEntities>],
    Nullable<Field>
>(
    selectSelectedFolder,
    selectSelectedTableSchemaFieldEntities,
    (selectedFolder: Folder, fields: Nullable<FieldEntities>) => {
        if (!fields) return
        return findFieldByType(fields, FieldTypes.STATUS, selectedFolder)
    },
)

export const selectFolderNames = createSelector<AppState, FolderNameEntities, FolderNameEntities>(
    folderFeature.selectFolderNames,
    (folderNames: FolderNameEntities) => {
        return folderNames
    },
)
