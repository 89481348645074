import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { take } from 'rxjs/operators'
import { BusinessRecords } from '@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { RecordFacadeService } from '@core/services/store-facade/record-facade.service'
import { MatIconModule } from '@angular/material/icon'
import { TranslocoModule } from '@ngneat/transloco'
import { SearchResultsItemComponent } from './search-results-item/search-results-item.component'

@UntilDestroy()
@Component({
    selector: 'app-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.sass'],
    standalone: true,
    imports: [SearchResultsItemComponent, TranslocoModule, MatIconModule],
})
export class SearchResultsComponent implements OnInit, OnChanges {
    @Input()
    searchValue?: string | null

    @Input()
    searchFilterValue?: string | null

    records!: BusinessRecords[] | null

    constructor(private recordFacadeService: RecordFacadeService) {}

    ngOnInit() {
        this.getRecords(this.searchValue)
    }

    getRecords(searchVal?: string | null) {
        this.recordFacadeService.selectAllRecords$
            .pipe(untilDestroyed(this), take(10))
            .subscribe((records) => {
                if (records) {
                    this.records = records.filter((record) => {
                        return (
                            searchVal &&
                            record.name.value.toUpperCase().includes(searchVal.toUpperCase())
                        )
                    })
                }
            })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.searchValue) return
        const searchValue = changes?.searchValue?.currentValue

        if (searchValue) {
            this.getRecords(searchValue)
        } else {
            this.records = null
        }
    }
}
