@for (fieldGuid of fields; track fieldGuid) { @if (cells[fieldGuid].value &&
fieldEntities[fieldGuid]; as field) {
<div class="flex-start-center">
    <div class="pr-2">
        @if (selectedFolder) {
        <app-field-name
            [field]="field"
            [folder]="selectedFolder"
            [showIcon]="false"
        ></app-field-name>
        }
    </div>
    @if (hasNewContainerSupport(field.field_type_code)) {
    <app-input-cell-container
        [type]="field.field_type_code"
        [value]="cells[fieldGuid].value"
        [appearance]="inputContainerAppearance.NO_BORDER"
    ></app-input-cell-container>
    } @else {
    <app-cell-container
        [disabled]="true"
        [fieldTypes]="fieldTypes"
        [field]="field"
        [guid]="fieldGuid"
        [value]="cells[fieldGuid].value"
        class="flex-start-center text-center"
    ></app-cell-container>
    }
</div>
} }
