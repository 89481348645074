import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatDialogClose } from '@angular/material/dialog'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatSelectModule } from '@angular/material/select'
import { SharedModule } from '@app/shared/shared.module'
import { ModalManagerModule } from '@components-library/tb-modal-manager/modal-manager.module'
import { TbTagsModule } from '@components-library/tb-tags'
import { TranslocoModule } from '@ngneat/transloco'
import { AddRecordContentComponent } from './add-record-content.component'
import { AddRecordSettingsSectionComponent } from './add-record-settings-section/add-record-settings-section.component'
import { RecordSettingsContentComponent } from './add-record-settings-section/record-settings-content/record-settings-content.component'

@NgModule({
    imports: [
        CommonModule,
        TranslocoModule,
        ModalManagerModule,
        MatDialogClose,
        ReactiveFormsModule,
        MatInputModule,
        TbTagsModule,
        MatMenuModule,
        SharedModule,
        MatSelectModule,
        AddRecordContentComponent,
        AddRecordSettingsSectionComponent,
        RecordSettingsContentComponent,
    ],
    exports: [AddRecordContentComponent],
})
export class AddRecordModule {}
