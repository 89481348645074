import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FieldExistsInSchemaValidator } from '@app/views/view-controls/validators'
import { ViewGroupService } from '@app/views/view-controls/view-group/view-group.service'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { Field, Folder, UpdateResponseModel, View, ViewTypeCodes } from '@core/models'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { ViewFacadeService } from '@services/store-facade'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { GroupStorageService } from '@services/local-storage/group-storage.service'
import { Observable, of } from 'rxjs'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { FieldNameComponent } from '@shared/field-name/field-name.component'

import { TbOptionComponent } from '@components-library/tb-select/components/tb-option/tb-option.component'
import { TbSelectComponent } from '@components-library/tb-select/components/tb-select/tb-select.component'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'

export interface GroupDialogData {
    selectedFolder: Folder
    selectedView: View
    fields?: Field[]
    groupValue?: string
}

@UntilDestroy()
@Component({
    selector: 'app-group-modal',
    templateUrl: './group-modal.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        ModalLayoutComponent,
        TbSelectComponent,
        ReactiveFormsModule,
        TbOptionComponent,
        FieldNameComponent,
        TbButtonComponent,
        TbDividerComponent,
        TbMenuComponent,
        TbMenuListComponent,
        TbMenuListItemComponent,
    ],
})
export class GroupModalComponent extends ModalContainerComponent implements OnInit, OnChanges {
    selectedFolder!: Folder
    selectedView!: View
    applyForSession: boolean = false
    selectControl!: FormControl

    groupValue?: string
    fields?: Field[]
    isSessionValueSet: boolean = false

    isDirty = false

    constructor(
        private groupStorageService: GroupStorageService,
        private groupService: ViewGroupService,
        private viewFacadeService: ViewFacadeService,
        private translation: TranslocoService,
        protected modalManagerService: ModalManagerService,
        protected confirmationDialogService: ConfirmationDialogService,
        private readonly fieldExistsValidator: FieldExistsInSchemaValidator,
        @Inject(ModalContainerDataToken) public groupMenuData: GroupDialogData,
    ) {
        super(groupMenuData, modalManagerService, confirmationDialogService)
    }

    ngOnInit() {
        this.groupValue = this.groupMenuData.groupValue
        this.fields = this.groupMenuData.fields || []
        this.selectedView = this.groupMenuData.selectedView
        this.selectedFolder = this.groupMenuData.selectedFolder

        if (this.groupStorageService.get(this.selectedView.guid)) {
            this.applyForSession = true
        }
        this.selectControl = new FormControl(this.groupValue, {
            asyncValidators: [this.fieldExistsValidator.validate],
        })

        dirtyCheck(this.selectControl, of(this.selectControl.value), {
            debounce: 0,
            useBeforeunloadEvent: false,
        })
            .pipe(untilDestroyed(this))
            .subscribe((isDirty) => {
                this.isDirty = isDirty
            })

        this.groupStorageService
            .isSet$()
            .pipe(untilDestroyed(this))
            .subscribe((state) => (this.isSessionValueSet = state))
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.selectControl && changes.groupValue) {
            this.selectControl.setValue(this.groupValue)
        }
    }

    preview() {
        if (!this.isSelectControlChanged()) {
            this.errors = [this.translation.translate('dialog_errors.no_changes_preview')]
            return
        }

        this.applyForSession = true
        this.groupStorageService.set(this.selectedView.guid, this.selectControl.value)
        this.onUpdatePreview(this.groupStorageService.isSet$())
    }

    save() {
        if (!this.isSaveButtonActive()) {
            this.errors = [this.translation.translate('dialog_errors.no_changes_save')]
            return
        }

        const saveData = this.groupService.saveGroupData(
            this.selectedView,
            this.selectControl.value,
        )

        this.updateGroupValueInSelectedView(
            'cell' in saveData
                ? this.viewFacadeService.updateViewRequest(this.selectedView, { group: saveData })
                : saveData,
        )
    }

    resetToSave() {
        if (!this.isValueSession()) {
            this.errors = [this.translation.translate('dialog_errors.no_preview')]
            return
        }

        this.applyForSession = false
        this.groupStorageService.remove(this.selectedView.guid)
        this.selectControl.setValue(this.groupValue)
        this.onUpdatePreview(this.groupStorageService.isSet$())
    }

    resetToDefault() {
        if (!this.groupValue?.length) {
            this.errors = [this.translation.translate('dialog_errors.no_overall_changes')]
            return
        }

        this.selectControl.setValue('')
        this.selectControl.markAsDirty()
        this.save()
    }

    isSelectControlChanged() {
        return this.selectControl.dirty && this.selectControl.value !== this.groupValue
    }

    isSaveButtonActive() {
        return this.isSelectControlChanged() || this.isValueSession()
    }

    hasChanged(): boolean {
        return this.isDirty
    }

    private isValueSession() {
        return this.selectedView.group.value !== this.groupValue && this.isSessionValueSet
    }

    private onUpdatePreview(updateObservable: Observable<boolean>) {
        this.wrapResponseObservable<boolean>(updateObservable).subscribe(() => {
            this.close()
        })
    }

    private updateGroupValueInSelectedView(groupObservable: Observable<UpdateResponseModel>) {
        this.wrapResponseObservable<UpdateResponseModel>(groupObservable).subscribe((data) => {
            if (data.status === 'success') {
                this.applyForSession = false
                this.groupStorageService.remove(this.selectedView.guid)
                this.close()
            }
        })
    }

    protected readonly ViewTypeCodes = ViewTypeCodes
}
