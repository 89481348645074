<app-tb-menu class="w-full" [template]="pickerTemplate" (stateChanged)="isMenuOpened($event)">
    <div class="rounded-lg inline-flex w-full" trigger>
        <div
            [class]="{
                'trigger-item': name && !opened,
                'opened-menu-trigger-item': name && opened,
            }"
        >
            @if (name) {
                <div class="name-container">
                    <div>{{ name }}</div>
                    <div class="name-item-container">
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                </div>
            } @else {
                <ng-container [ngTemplateOutlet]="content"></ng-container>
            }
            <ng-template #content>
                <ng-content select="[trigger]"></ng-content>
            </ng-template>
        </div>
    </div>
</app-tb-menu>
<ng-template #pickerTemplate let-containerLayout="containerLayout" let-container="container">
    <div [class]="containerLayout === ModalContainer.BottomSheet ? '' : menuSize">
        <cdk-virtual-scroll-viewport
            appItemSize="32"
            maxBufferPx="64"
            minBufferPx="64"
            class="w-full h-full"
        >
            <div
                class="picker-row-template"
                *cdkVirtualFor="
                    let itemsRow of innerItems;
                    templateCacheSize: 0;
                    let rowIndex = index
                "
            >
                @for (item of itemsRow; track item; let index = $index) {
                    <div (click)="selectPickerItem(rowIndex, index, item)">
                        @if (showClear && rowIndex === 0 && index === 0) {
                            <app-tb-button
                                appearance="icon"
                                icon="close"
                                class="clear-picker-component"
                                [class]="
                                    selectedItem === null
                                        ? 'border-newText hover:border-newText'
                                        : 'border-transparent'
                                "
                            ></app-tb-button>
                        } @else {
                            <ng-container
                                *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"
                            ></ng-container>
                        }
                    </div>
                }
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</ng-template>
