<div
    class="flex items-center w-full"
    *transloco="let tSettings; read: 'status_configuration'"
    cdkDrag
    [cdkDragBoundary]="dragBoundaryClass"
    [cdkDragData]="option.guid"
    [cdkDragDisabled]="isClosed"
>
    <app-tb-icon
        icon="drag_indicator"
        class="cursor-pointer"
        [class.pointer-events-none]="isClosed"
        [color]="isClosed ? 'newNeutral4' : 'newTextLight'"
    ></app-tb-icon>
    <app-color-picker
        #colorPicker
        class="mt-2"
        [set]="SetType.STATUS"
        [color]="formGroup.value.color"
        (colorChange)="formGroup.controls.color.setValue($event)"
    ></app-color-picker>
    <app-tb-input
        class="w-full"
        [formControl]="formGroup.controls.label"
        [prefixIconTemplate]="iconTemplate"
        [suffixIconTemplate]="defaultIcon"
    ></app-tb-input>
    <app-tb-menu [template]="menu" [disabled]="isClosed">
        <app-tb-icon
            trigger
            icon="more_vert"
            class="cursor-pointer icon-m"
            [class]="isClosed ? 'text-newNeutral4 pointer-events-none' : 'newTextLight'"
        ></app-tb-icon>
    </app-tb-menu>
    <ng-template #menu>
        <app-tb-menu-list>
            <app-tb-menu-list-item icon="colors" (click)="colorPicker.open()"
                >{{ tSettings('set_color') }}
            </app-tb-menu-list-item>
            <app-tb-menu-list-item icon="add_reaction" (click)="iconPicker.open()"
                >{{ tSettings('set_icon') }}
            </app-tb-menu-list-item>

            @if (!isDefault) {
                <app-tb-divider></app-tb-divider>

                <app-tb-menu-list-item (click)="delete.emit()">
                    <app-tb-icon color="red-600" icon="custom_trash" item-icon></app-tb-icon>
                    <span class="text-red-600" item-title>{{ tSettings('delete') }}</span>
                </app-tb-menu-list-item>
            }
        </app-tb-menu-list>
    </ng-template>

    <ng-template #iconTemplate>
        <app-icon-picker
            #iconPicker
            [set]="SetType.STATUS"
            [icon]="formGroup.value.icon"
            (iconChange)="formGroup.controls.icon.setValue($event ?? '')"
        ></app-icon-picker>
    </ng-template>
    <ng-template #defaultIcon>
        @if (isDefault) {
            <app-tb-icon [filled]="true" color="newPrimary" icon="in_home_mode"></app-tb-icon>
        }
    </ng-template>
</div>
