import { Component, ElementRef, ViewChild } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import {
    BaseDateComponent,
    DateFormat,
    DateType,
} from '@app/feature/input-cells/base-date/base-date.component'
import { TranslocoService } from '@ngneat/transloco'

@Component({
    selector: 'app-date-time-cell',
    standalone: true,
    imports: [ReactiveFormsModule],
    templateUrl: './date-time-cell.component.html',
})
export class DateTimeCellComponent extends BaseDateComponent {
    @ViewChild('dateInput')
    dateTimeInput!: ElementRef<HTMLElement>

    get inputElement() {
        return this.dateTimeInput
    }

    constructor() {
        super(DateFormat.DATE_TIME, DateType.SINGLE)
    }
}
