<app-modal-layout
    *transloco="let t"
    [containerLayout]="containerLayout"
    (closeModal)="forceWithValidation()"
>
    <ng-container title>{{ t('add_fields') }}</ng-container>
    <div class="overflow-hidden w-[49rem]">
        @if (fieldTypes$ | async; as fieldTypes) {
            <div>
                @if (fieldTypes.onePerFolder.length) {
                    <ng-container
                        [ngTemplateOutlet]="items"
                        [ngTemplateOutletContext]="{
                            fieldTypes: fieldTypes.onePerFolder,
                            onePerFolder: true,
                        }"
                    ></ng-container>
                    <app-tb-divider></app-tb-divider>
                }
                <ng-container
                    [ngTemplateOutlet]="items"
                    [ngTemplateOutletContext]="{
                        fieldTypes: fieldTypes.multiple,
                        onePerFolder: false,
                    }"
                ></ng-container>
            </div>
        }
    </div>
    <ng-container buttons>
        <div class="flex justify-start">
            <div class="flex sm:gap-1 gap-2 flex-1 sm:flex-none">
                <app-tb-button (click)="close()" appearance="text" icon="custom_arrow_back">
                    {{ t('buttons.back') }}</app-tb-button
                >
            </div>
        </div>
    </ng-container>
</app-modal-layout>
<ng-template
    #items
    [appTypedTemplate]="contextType"
    let-fieldTypes="fieldTypes"
    let-onePerFolder="onePerFolder"
>
    <div class="flex flex-wrap gap-4 mb-2 mt-5 px-2">
        @for (type of fieldTypes; track type) {
            <div
                (click)="addField(type)"
                class="flex flex-col items-center h-40 w-44 gap-2 py-5 px-4 rounded-2xl shadow-md cursor-pointer relative"
            >
                @if (!type.status) {
                    <span class="text-red-600 absolute top-0">DEV</span>
                }
                <app-tb-icon
                    [icon]="type.icon"
                    class="icon-xlg text-newPrimaryLight"
                    [class]="onePerFolder ? 'text-newPrimaryLight' : 'text-newTextLight'"
                ></app-tb-icon>
                <div *transloco="let tFieldTypeName; read: 'field_type.name'" class="subheading-2">
                    {{ tFieldTypeName(type.guid) }}
                </div>
                <div
                    *transloco="let tFieldTypeDescription; read: 'field_type.description'"
                    class="text-center caption-1 text-newTextLight"
                >
                    {{ tFieldTypeDescription(type.guid) }}
                </div>
            </div>
        }
    </div>
</ng-template>
