@if (selectedFolder$ | async; as selectedFolder) {
<app-tb-menu [container]="menuComponent" [replaceable]="true">
    <div trigger>
        <div
            class="flex items-center content-center cursor-pointer hover:bg-white rounded-lg py-1 pr-2 pl-4"
        >
            <div class="d-flex items-center">
                <mat-icon class="mr-2">{{selectedFolder.icon.value}}</mat-icon>
                <app-tb-tooltip-component
                    class="flex-1"
                    [tooltip]="selectedFolder.name.value"
                    tooltipPosition="below"
                >
                    <div class="max-w-[160px] overflow-hidden whitespace-nowrap text-ellipsis">
                        {{selectedFolder.name.value}}
                    </div>
                </app-tb-tooltip-component>
                <mat-icon class="ml-2">expand_less</mat-icon>
            </div>
        </div>
    </div>
</app-tb-menu>
}
