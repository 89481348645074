import { Component, Input, TemplateRef } from '@angular/core'
import { ModalContainerComponent } from '../modal-container-component/modal-container.component'
import { NgTemplateOutlet } from '@angular/common'

/**
 * TemplateContainerComponent is a component that TbMenu use as ModalContainerComponent.
 * It happens when use TbMenu with [template] instead of [container].
 *
 * This component is used to wrap <ng-template>, to keep it's state during Content Navigation and Transformation.
 **/
@Component({
    selector: 'app-template-container',
    templateUrl: './template-container.component.html',
    standalone: true,
    imports: [NgTemplateOutlet],
})
export class TemplateContainerComponent extends ModalContainerComponent {
    /**
     * Template that we should render inside this component.
     **/
    @Input()
    template!: TemplateRef<any>

    /**
     * This function is passed to TemplateOutlet to be able to call Close from a template.
     **/
    boundCloseFn = this.close.bind(this)
}
