import { AbstractControl } from '@angular/forms'
import { ActivatedRouteSnapshot, Params } from '@angular/router'
import { ResponseRecordEntities, TableModel, UpdateResponseModel } from '@core/models'
import { LogService } from '@services/log.service'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { trim } from 'lodash-es'
import { distinctUntilChanged } from 'rxjs/operators'

export function getBoolean(val: any): boolean {
    if (!val) return false

    const num = +val
    return !isNaN(num)
        ? !!num
        : !!String(val)
              .toLowerCase()
              .replace(!!0 + '', '')
}

export function collectParams(root: ActivatedRouteSnapshot): Params {
    let params: Params = {}
    ;(function mergeParamsFromSnapshot(snapshot: ActivatedRouteSnapshot) {
        Object.assign(params, snapshot.params)
        snapshot.children.forEach(mergeParamsFromSnapshot)
    })(root)
    return params
}

export function isNonNull<T>(value: T): value is NonNullable<T> {
    return value !== null && value !== undefined
}

export function generateUuid() {
    return crypto.randomUUID()
}

export const emailValidationPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const numericValidationPattern = /[+-]?([0-9]*[.])?[0-9]+/

export function urlValidator(urlString: string) {
    if (!urlString) return true

    try {
        const url = new URL(urlString)

        if (!checkProtocol(url.protocol)) return false
    } catch (_) {
        return false
    }

    return true
}

/**
 * This function returns control's touched value as observable
 * @param control
 */
export function formListenToTouch(control: AbstractControl): Observable<boolean> {
    const result$ = new BehaviorSubject(false)
    const markFn = control.markAsTouched
    const unmarkFn = control.markAsUntouched

    control.markAsTouched = (opts) => {
        markFn.call(control, opts)
        result$.next(true)
    }

    control.markAsUntouched = (opts) => {
        unmarkFn.call(control, opts)
        result$.next(false)
    }

    return result$.asObservable().pipe(distinctUntilChanged())
}

/**
 * This util type declares that T may be null or undefined. Opposite of typescript's NonNullable<T>
 */
export type Nullable<T> = T | null | undefined

function checkProtocol(protocol: string) {
    return protocol === 'http:' || protocol === 'https:'
}

export function throwAndLogError(
    logService: LogService,
    message: string | string[],
    publicMessage: string | string[] = 'error',
) {
    const errorString = typeof message === 'string' ? message : message.join(',')
    const error = new Error(errorString)
    logService.error(error)
    return throwError(() => publicMessage)
}

export function compare(
    a: number | string | boolean | undefined,
    b: number | string | boolean | undefined,
    isAsc: boolean = true,
) {
    const direction = isAsc ? 1 : -1
    if (!a || !b) {
        if (!a && b) return -1 * direction
        if (a && !b) return 1 * direction
        return 0
    }

    if (typeof a === 'string') a = a.toLowerCase()
    if (typeof b === 'string') b = b.toLowerCase()

    if (a < b) return -1 * direction
    if (a > b) return 1 * direction
    return 0
}

export function addPostfix(source: string, postfix: string) {
    return `${trim(source)} ${postfix}`
}

export function getAddedFromResponse(response: UpdateResponseModel): ResponseRecordEntities | null {
    const added = (response.data[0].object as TableModel).added

    return added || null
}
