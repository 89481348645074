import { Component, Input } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { MatOptionModule } from '@angular/material/core'
import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { TranslocoModule } from '@ngneat/transloco'

@UntilDestroy()
@Component({
    selector: 'app-access-selector',
    templateUrl: './access-selector.component.html',
    styleUrls: ['./access-selector.component.sass'],
    standalone: true,
    imports: [TranslocoModule, MatIconModule, MatFormFieldModule, MatSelectModule, MatOptionModule],
})
export class AccessSelectorComponent {
    @Input()
    isActive!: boolean
}
