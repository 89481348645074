<div class="flex">
    <app-tb-button
        [disabled]="focusDisabled"
        appearance="icon"
        icon="custom_edit"
        color="newTextLight"
        (click)="onFocus()"
        class="icon-d"
    ></app-tb-button>
    <app-tb-menu [template]="moreMenu" (stateChanged)="toggleIcons()">
        <app-tb-button
            color="newTextLight"
            appearance="icon"
            icon="more_vert"
            class="ml-1 icon-d"
            trigger
        ></app-tb-button>
    </app-tb-menu>
</div>

<ng-template #moreMenu>
    <app-cell-options-menu
        [record]="record"
        [onFocus]="onFocus"
        [onClear]="onClear"
        [onCopy]="onCopy"
        [focusDisabled]="focusDisabled"
        [currentRecordOpened]="currentRecordOpened"
    ></app-cell-options-menu>
</ng-template>
