<ng-container *transloco="let tVersion; read: 'version'">
    <app-tb-menu class="w-full" [template]="menuContent">
        <div trigger class="flex flex-row items-center w-full">
            <app-tb-icon
                icon="info"
                class="sm:w-5 sm:h-5 sm:text-xl sm:leading-5 mr-4 sm:mr-2 text-primarySecondary"
            ></app-tb-icon>
            <div class="flex-1">Version</div>
        </div>
    </app-tb-menu>
    <ng-template #menuContent>
        {{tVersion('branch')}}{{version.branch}}
        <br />
        {{tVersion('commit')}}{{version.commit}}
        <br />
        {{tVersion('date')}}{{version.date}}
        <br />
        {{tVersion('mode')}}{{ mode$ | async }}
    </ng-template>
</ng-container>
