import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core'
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { CellComponent } from '@app/shared/cell-types/cell.component'
import { DateService } from '@app/shared/cell-types/date.service'
import {
    DaterangepickerComponent,
    DaterangepickerDirective,
    NgxDaterangepickerMd,
} from 'ngx-daterangepicker-material'
import { TranslocoService } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MatIconModule } from '@angular/material/icon'

@UntilDestroy()
@Component({
    selector: 'app-date-time',
    templateUrl: './date-time.component.html',
    styleUrls: ['./date-time.component.sass'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgxDaterangepickerMd, MatIconModule],
})
export class DateTimeComponent extends CellComponent implements OnInit {
    dateLabelFormat = 'MM/DD/YYYY HH:mm'

    @ViewChild(DaterangepickerDirective, { static: true })
    pickerDirective!: DaterangepickerDirective
    picker!: DaterangepickerComponent
    valueLocale!: string
    isEdit: boolean = false

    constructor(private translation: TranslocoService, private dateService: DateService) {
        super()
    }

    ngOnInit(): void {
        this.translation
            .selectTranslate('cell_types.date.empty_date_label')
            .pipe(untilDestroyed(this))
            .subscribe((dateLabel) => {
                this.picker = this.pickerDirective.picker
                const pickerDate = this.dateService.localizeAndFormat(
                    this.value,
                    this.dateLabelFormat,
                )
                this.valueLocale = this.value ? pickerDate : dateLabel
                this.picker.startDate = this.dateService.createDate(pickerDate)
                this.picker.endDate = this.dateService.createDate(pickerDate)
            })
    }

    editMode(e: Event) {
        this.isEdit = true
        this.pickerDirective?.open(e)
    }

    clearDate() {
        this.cellValueChanged.emit('')
        this.isEdit = true
    }

    filterFrom() {
        const utcDate = this.dateService.formatWithUTC(
            this.picker.chosenLabel,
            'YYYY-MM-DD HH:mm:ssZ',
        )

        if (!this.value || utcDate !== this.valueLocale) {
            this.cellValueChanged.emit(utcDate)
        }
    }
}
