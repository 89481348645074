import { AsyncPipe, NgTemplateOutlet } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { InputCellContainerComponent } from '@app/feature/input-cells/input-cell-container/input-cell-container.component'
import { ManageFieldsService } from '@app/feature/manage-fields/manage-fields.service'
import {
    CellContainerComponent,
    NEW_CONTAINER_CELLS,
} from '@app/shared/cell-types/cell-container.component'
import { ShowIfOperationCodeMatchExpectedOperationDirective } from '@app/shared/show-if-operation-code-match-expected-operation.directive'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbInputComponent } from '@components-library/tb-input/tb-input.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { TbOptionComponent } from '@components-library/tb-select/components/tb-option/tb-option.component'
import { TbSelectComponent } from '@components-library/tb-select/components/tb-select/tb-select.component'
import { TbToggleComponent } from '@components-library/tb-toggle/tb-toggle.component'
import {
    AddFieldData,
    Field,
    FieldEntities,
    FieldType,
    FieldTypes,
    Folder,
    isFolderGlobal,
    UpdateResponseModel,
} from '@core/models'
import {
    FieldTypeFacadeService,
    FolderFacadeService,
    SchemaFacadeService,
} from '@core/services/store-facade'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'
import { EnvDirective } from '@shared/env.directive'
import { combineLatest, Observable, of, switchMap } from 'rxjs'
import { take } from 'rxjs/operators'
import { AddFieldContentComponent } from './add-field-content/add-field-content.component'

@UntilDestroy()
@Component({
    selector: 'app-add-column',
    templateUrl: './add-field.component.html',
    styleUrls: ['./add-field.component.sass'],
    standalone: true,
    imports: [
        TranslocoModule,
        ModalLayoutComponent,
        ReactiveFormsModule,
        TbSelectComponent,
        TbOptionComponent,
        TbInputComponent,
        AddFieldContentComponent,
        TbToggleComponent,
        CellContainerComponent,
        TbButtonComponent,
        ShowIfOperationCodeMatchExpectedOperationDirective,
        AsyncPipe,
        InputCellContainerComponent,
        TbMenuComponent,
        TbMenuListComponent,
        TbMenuListItemComponent,
        EnvDirective,
        NgTemplateOutlet,
    ],
})
export class AddFieldComponent
    extends ModalContainerComponent<AddFieldData, Field | undefined>
    implements OnInit
{
    availableFieldTypes$: Observable<FieldType[]> =
        this.fieldTypeFacadeService.selectAvailableFieldTypeByFolder$
    allFieldTypesDictionary$: Observable<Dictionary<FieldType>> =
        this.fieldTypeFacadeService.selectFieldTypeEntities$

    folders$: Observable<Folder[]> = this.folderFacadeService.selectAllFolders$

    formGroup!: FormGroup<{
        columnName: FormControl<string | null>
        fieldType: FormControl<string | null>
        folder: FormControl<string | null>
        onTop: FormControl<boolean | null>
        required: FormControl<boolean | null>
        defaultValue: FormControl<any>
        sharedFolders: FormControl<string[] | null>
    }>

    field!: Field
    editMode = true
    isColumnContentValid = true
    isRequired: boolean = false
    foldersForMove!: Folder[]
    foldersForShare!: Folder[]

    shareAvailable = false

    supportNewContainer!: boolean

    get isContentValid() {
        return this.formGroup.invalid || !this.isColumnContentValid
    }

    constructor(
        @Inject(ModalContainerDataToken) public data: AddFieldData,
        modalManagerService: ModalManagerService,
        confirmationPopupService: ConfirmationDialogService,
        private manageFieldsService: ManageFieldsService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private folderFacadeService: FolderFacadeService,
        private translation: TranslocoService,
    ) {
        super(data, modalManagerService, confirmationPopupService)
    }

    ngOnInit() {
        if (!this.data.guid || this.data.duplicate) {
            this.editMode = false
        }

        this.supportNewContainer = this.hasNewContainerSupport(this.data.field_type_code)

        combineLatest(
            this.folders$,
            this.schemaFacadeService.selectSelectedTableSchemaFieldEntities$,
            this.allFieldTypesDictionary$,
        )
            .pipe(
                untilDestroyed(this),
                switchMap(([folders, fields, fieldTypes]) => {
                    return combineLatest([
                        of(this.getFoldersForMove(folders, fieldTypes, fields)),
                        this.folderFacadeService.selectSelectedFolderGuid$,
                    ])
                }),
            )
            .subscribe(([folders, selectedFolderGuid]) => {
                this.foldersForMove = folders
                this.foldersForShare = this.foldersForMove.filter(
                    (folder) => !isFolderGlobal(folder) && folder.guid !== this.data.folder_guid,
                )

                this.isRequired = !!this.data.is_required
                this.shareAvailable = this.isShareAvailable(
                    this.data.folder_guid ?? selectedFolderGuid,
                )

                this.initForm(selectedFolderGuid)
                this.setDefaultState()
            })
    }

    private initForm(selectedFolderGuid: string) {
        this.formGroup = new FormGroup({
            columnName: new FormControl(this.data.name ? this.data.name : '', [
                Validators.required,
            ]),
            fieldType: new FormControl<string>(
                {
                    value: this.data.field_type_code,
                    disabled: this.editMode,
                },
                Validators.required,
            ),
            folder: new FormControl({
                value: this.data.folder_guid ?? selectedFolderGuid,
                disabled: !this.isMoveAvailable(),
            }),
            onTop: new FormControl<boolean>(!!this.data.is_on_top),
            required: new FormControl<boolean>(
                !!this.data.is_required || this.data.field_type_code === FieldTypes.STATUS,
            ),
            defaultValue: new FormControl(this.data.default_value),
            sharedFolders: new FormControl<string[]>(
                this.data.shared_with_folder?.split(',') ?? [],
            ),
        })

        this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((changes) => {
            if (changes.required !== undefined) {
                this.isRequired = !!changes.required
            }
            const folder = changes.folder ?? this.formGroup.value.folder ?? selectedFolderGuid
            this.shareAvailable = this.isShareAvailable(folder)
            this.foldersForShare = this.foldersForMove.filter(
                (folder) => !isFolderGlobal(folder) && folder.guid !== changes.folder,
            )

            this.updateRequiredValidator(this.formGroup.controls.defaultValue)

            this.field = {
                ...this.field,
                folder_guid: folder,
                is_required: this.isRequired ? 1 : 0,
                name: changes.columnName!,
                is_on_top: changes.onTop ? 1 : 0,
                default_value: changes.defaultValue,
                shared_with_folder: changes.sharedFolders?.toString() ?? '',
            }

            if (changes.fieldType) {
                this.field.field_type_code = changes.fieldType
                this.supportNewContainer = this.hasNewContainerSupport(changes.fieldType)
            }
        })

        if (this.data.field_type_code === FieldTypes.STATUS) {
            this.formGroup.controls.required.disable()
            this.isRequired = true
        }
    }

    valid() {
        return this.formGroup.invalid
    }

    saveAndDuplicate() {
        const duplicateData = {
            ...this.field,
            name: `${this.field.name} copy`,
            duplicate: true,
        }

        this.saveWithAddFieldOpen(duplicateData)
    }

    saveAndCreateAnotherOne() {
        const createData = {
            field_type_code: this.data.field_type_code,
            folder_guid: this.data.folder_guid,
            new_folder: this.data.new_folder,
        }

        this.saveWithAddFieldOpen(createData)
    }

    saveColumn() {
        if (this.isContentValid) {
            this.setIncorrectInputError()
            return
        }

        if (this.data.new_folder) {
            this.close(this.field)
            return
        }

        this.wrapResponse(this.getUpdateObservable())
    }

    changedValue(field: Field) {
        // TODO: will be deleted during cell type rework or following issues
        // https://chat.anveo.com/git/AnveoTikibase_ui/issues/566
        if (this.field.default_value !== field.default_value) {
            this.defaultValueChanged(field.default_value ?? '')
        }
        if (!field.guid) {
            this.field = {
                ...this.field,
                select_object_field: field.select_object_field,
                configure_json: field.configure_json,
                link_definition: field.link_definition,
            }
            return
        }
        this.field = field
    }

    setDefaultState() {
        // TODO: will be deleted during cell type rework or following issues
        // https://chat.anveo.com/git/AnveoTikibase_ui/issues/566
        this.field = {
            ...this.data,
            field_type_code: this.formGroup.controls.fieldType.value || this.data.field_type_code,
            guid: this.data.guid ? this.data.guid : '',
            select_object_field: this.data.select_object_field,
            configure_json: this.data.configure_json,
            link_definition: this.data.link_definition,
        }
    }

    delete() {
        this.wrapResponse(this.manageFieldsService.deleteField(this.field.guid))
    }

    defaultValueChanged(value: string) {
        this.formGroup.controls.defaultValue.setValue(value)
    }

    hasNewContainerSupport(type: string) {
        return NEW_CONTAINER_CELLS.includes(type)
    }

    private getFoldersForMove(
        folders: Folder[],
        fieldTypes: Dictionary<FieldType>,
        fields?: FieldEntities,
    ) {
        if (
            this.data.operationCode?.multiple ||
            fieldTypes[this.data.field_type_code]?.operationCode.multiple ||
            !fields
        )
            return folders

        const typedFields = Object.keys(fields)
            .filter((guid) => fields[guid].field_type_code === this.data.field_type_code)
            .map((guid) => fields[guid])

        return folders.filter((folder) => {
            return !typedFields.find(
                (field) =>
                    field.folder_guid === folder.guid &&
                    field.folder_guid !== this.data.folder_guid,
            )
        })
    }

    private saveWithAddFieldOpen(data: AddFieldData) {
        if (this.isContentValid) {
            this.setIncorrectInputError()
            return
        }

        if (this.data.new_folder) {
            this.close(this.field)
            return
        }

        this.wrapResponseObservable(this.getUpdateObservable()).subscribe((updateData) => {
            this.openAddField(data, updateData)
        })
    }

    private openAddField(updateData: AddFieldData, data: UpdateResponseModel) {
        if (data.status === 'success') {
            this.close()
            this.modalManagerService
                .openDialog<AddFieldComponent, AddFieldData | null>({
                    component: AddFieldComponent,
                    data: updateData,
                })
                .pipe(take(1))
        }
    }

    private isMoveAvailable() {
        return this.shareAvailable && !this.data.operationCode?.freeze
    }

    private isShareAvailable(guid: string) {
        return (
            !this.data.operationCode?.global &&
            !isFolderGlobal(this.getFolderByGuid(guid)) &&
            !!this.foldersForShare.length
        )
    }

    private getFolderByGuid(folderGuid: string) {
        return this.foldersForMove.find((folder) => folder.guid === folderGuid)!
    }

    private getUpdateObservable() {
        if (this.editMode) return this.schemaFacadeService.updateField(this.field)

        return this.schemaFacadeService.createField(this.field)
    }

    private setIncorrectInputError() {
        this.errors = [this.translation.translate('dialog_errors.incorrect_input')]
    }

    private wrapResponse(observable: Observable<UpdateResponseModel>) {
        this.wrapResponseObservable<UpdateResponseModel>(observable).subscribe((data) => {
            if (data.status === 'success') {
                this.close()
            }
        })
    }

    private updateRequiredValidator(control: FormControl<any>) {
        if (this.isRequired && !control.hasValidator(Validators.required)) {
            control.addValidators(Validators.required)
        }

        if (!this.isRequired && control.hasValidator(Validators.required)) {
            control.removeValidators(Validators.required)
        }

        control.updateValueAndValidity()
    }

    protected readonly FieldTypes = FieldTypes
}
