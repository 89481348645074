import { NgTemplateOutlet } from '@angular/common'
import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChildren,
} from '@angular/core'
import { timer } from 'rxjs'
import { TbTab, TbTabComponent } from '../tb-tab/tb-tab.component'

@Component({
    selector: 'app-tb-tabs',
    templateUrl: './tb-tabs.component.html',
    styleUrls: ['./tb-tabs.component.sass'],
    standalone: true,
    imports: [TbTabComponent, NgTemplateOutlet],
})
export class TbTabsComponent implements OnChanges {
    @Input()
    tabs!: TbTab[]

    @Input()
    icon?: TemplateRef<any>

    @Input()
    activeTab: TbTab | null = null

    @Input()
    moreMenu: TemplateRef<any> | null = null

    @Input()
    hideOverflowingTabs = false

    @Output()
    tabOpened = new EventEmitter<TbTab>()

    @Output()
    activeHidden = new EventEmitter<boolean>()

    @ViewChildren(TbTabComponent) tabComponents!: QueryList<TbTabComponent>

    timeID!: any

    constructor(
        private readonly elRef: ElementRef,
        private readonly renderer2: Renderer2,
    ) {}

    ngOnChanges() {
        // timer is needed to wait until tabs are rendered
        timer(0).subscribe(() => this.setTabsOrder())
    }

    @HostListener('window:resize')
    onResize() {
        this.setAllFlex()
        clearTimeout(this.timeID)
        this.timeID = setTimeout(() => this.setTabsOrder(), 300)
    }

    private setTabsOrder() {
        if (!this.hideOverflowingTabs) {
            return
        }

        const allTabs = this.tabComponents.toArray()
        const activeTab = this.tabComponents.find((tab) => tab.isSelected)

        allTabs.forEach((tab, index) => {
            this.renderer2.setStyle(tab.nativeElement, `order`, index)
        })

        setTimeout(() => {
            this.displayNoneForInvisibleTabs(allTabs)
        })

        // Emit activeHidden if active tab is overflowing

        if (activeTab && this.isTabOverflowing(activeTab)) {
            this.activeHidden.emit(true)
            return
        }

        this.activeHidden.emit(false)
    }

    private displayNoneForInvisibleTabs(tabs: TbTabComponent[]) {
        tabs.forEach((tab) => {
            if (this.isTabOverflowing(tab)) {
                this.renderer2.setStyle(tab.nativeElement, `display`, 'none')
                return
            }
            this.renderer2.setStyle(tab.nativeElement, `display`, 'flex')
        })
    }

    private setAllFlex() {
        this.tabComponents.toArray().forEach((tab) => {
            this.renderer2.setStyle(tab.nativeElement, `display`, 'flex')
        })
    }

    private isTabOverflowing(tab: TbTabComponent) {
        const tabAbsoluteOffsetTop = tab?.nativeElement.getBoundingClientRect().top
        const hostElementAbsoluteOffsetTop = this.elRef.nativeElement.getBoundingClientRect().top

        return tabAbsoluteOffsetTop > hostElementAbsoluteOffsetTop
    }
}
