@if (selectedFolder$ | async; as selectedFolder) {
    @if (fieldTypes$ | async; as fieldTypes) {
        <ng-container *transloco="let tManageFields; read: 'manage_fields'">
            <app-modal-layout
                [containerLayout]="containerLayout"
                (closeModal)="forceWithValidation()"
                [showLoader]="showLoader"
                [errors]="errors"
            >
                <ng-container title>{{ tManageFields('manage_fields') }}</ng-container>
                <ng-container subtitle>{{ tManageFields('manage_fields_subtitle') }}</ng-container>
                <app-fields-collection
                    [accordionContent]="accordionContent"
                    [selectedFolder]="selectedFolder"
                    [excludedFieldTypes]="excludedFieldTypes"
                    [folders]="folders$ | async"
                    [selectedSchema]="selectedSchema$ | async"
                    [fieldTypes]="fieldTypes"
                    [showAllFolders]="true"
                >
                </app-fields-collection>
                <ng-container buttons>
                    <div class="flex justify-end">
                        <div class="flex sm:gap-1 gap-2 flex-1 sm:flex-none">
                            <app-tb-button
                                (click)="addField(selectedFolder)"
                                class="w-full"
                                icon="add_circle_outline"
                            >
                                {{ tManageFields('add_field') }}
                            </app-tb-button>
                        </div>
                    </div>
                </ng-container>
            </app-modal-layout>
            <ng-template
                #accordionContent
                [appTypedTemplate]="contextType"
                let-fieldTypes="fieldTypes"
                let-folders="folders"
                let-fields="fields"
            >
                <div class="flex flex-col w-full">
                    @for (field of fields; track field) {
                        @if (getFolderForField(field.folder_guid, folders); as folder) {
                            <app-manage-fields-item
                                [folder]="folder"
                                [field]="field"
                                [fieldType]="fieldTypes[field.field_type_code]"
                                (deleteField)="deleteField($event)"
                            ></app-manage-fields-item>
                        }
                    }
                </div>
            </ng-template>
        </ng-container>
    }
}
