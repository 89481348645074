@if (selectedFolder$ | async; as selectedFolder) { @if (fieldTypes$ | async; as fieldTypes) {
<ng-container *transloco="let tManageFields; read: 'manage_fields'">
    <app-modal-layout
        [containerLayout]="containerLayout"
        (closeModal)="forceWithValidation()"
        [showLoader]="showLoader"
        [errors]="errors"
    >
        <ng-container title>{{ tManageFields('manage_fields') }}</ng-container>
        <ng-container subtitle>{{ tManageFields('manage_fields_subtitle') }}</ng-container>
        <app-fields-collection
            [accordionContent]="accordionContent"
            [selectedFolder]="selectedFolder"
            [excludedFieldTypes]="excludedFieldTypes"
            [folders]="folders$ | async"
            [selectedSchema]="selectedSchema$ | async"
            [fieldTypes]="fieldTypes"
        >
        </app-fields-collection>
        <ng-container buttons>
            <div class="flex justify-end">
                <div class="flex sm:gap-1 gap-2 flex-1 sm:flex-none">
                    <app-tb-button (click)="addField(selectedFolder)" class="w-full">
                        {{ tManageFields('add_field') }}
                    </app-tb-button>
                </div>
            </div>
        </ng-container>
    </app-modal-layout>
    <ng-template
        #accordionContent
        [appTypedTemplate]="contextType"
        let-fieldTypes="fieldTypes"
        let-folder="folder"
        let-fields="fields"
    >
        @for (field of fields; track field) {
        <div class="flex xsm:flex-row items-center mt-2 xsm:mb-0 mb-4">
            <div [style]="{background: folder.color.value, width: '6px', height: '10px'}"></div>
            <div class="flex items-center my-2 mx-4 gap-x-4">
                <div class="w-[352px] flex">
                    {{ field.name }}
                    <app-tb-tooltip-component [tooltip]="tManageFields('required_tooltip')">
                        @if (field.is_required) {
                        <app-tb-icon icon="asterisk"></app-tb-icon>
                        }
                    </app-tb-tooltip-component>
                    <app-tb-tooltip-component [tooltip]="tManageFields('on_top_tooltip')">
                        @if (field.is_on_top) {
                        <app-tb-icon icon="upgrade"></app-tb-icon>
                        }
                    </app-tb-tooltip-component>
                </div>
                @if ((field.folder_name?.name ?? tManageFields('unknown_folder')); as folderName) {
                <!--TODO: move to tooltip-->
                <app-tb-tooltip-component [tooltip]="folderName" [showOnlyOnOverflow]="true">
                    <app-tb-tag
                        #nameBlock
                        class="w-[176px] text-ellipsis overflow-hidden whitespace-nowrap"
                    >
                        {{ folderName }}
                    </app-tb-tag>
                </app-tb-tooltip-component>
                }
                <div class="flex items-center w-[132px] h-fit">
                    @if (fieldTypes[field.field_type_code]?.icon; as icon) {
                    <app-tb-icon [icon]="icon"> </app-tb-icon>
                    } @if ((fieldTypes[field.field_type_code]?.name ??
                    tManageFields('unknown_type')); as type) {
                    <app-tb-tooltip-component [tooltip]="type" [showOnlyOnOverflow]="true">
                        <span
                            #typeBlock
                            class="text-ellipsis overflow-hidden whitespace-nowrap capitalize"
                        >
                            {{ type }}
                        </span>
                    </app-tb-tooltip-component>
                    }
                </div>
            </div>
            <app-tb-menu [template]="fieldOptions" xPosition="after">
                <app-tb-button trigger appearance="icon" icon="more_vert"></app-tb-button>
            </app-tb-menu>
            <ng-template class="mb-2" #fieldOptions>
                <app-manage-field-field-options
                    [field]="field"
                    [selectedFolder]="folder"
                    (deleteField)="deleteField(field)"
                ></app-manage-field-field-options>
            </ng-template>
        </div>
        }
    </ng-template>
</ng-container>
} }
