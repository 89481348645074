<ng-container *transloco="let tGroup; read: 'group'">
    <app-modal-layout
        [containerLayout]="containerLayout"
        [showLoader]="showLoader"
        [errors]="errors"
        (keydown.tab)="$event.stopPropagation()"
        (closeModal)="forceWithValidation()"
    >
        <ng-container title>
            <span class="text-newText">{{ tGroup('group_by') }}</span>
        </ng-container>
        <div
            (click)="$event.stopPropagation()"
            (keydown.tab)="$event.stopPropagation()"
            class="max-sm:mt-4 md:w-[480px] filter-area body-1 leading-4"
        >
            <div class="flex justify-end items-center mb-4">
                <app-tb-button appearance="text" [disabled]="!isValueChanged()" (click)="onClear()">
                    {{ tGroup('clear').toUpperCase() }}
                </app-tb-button>
            </div>

            <div class="flex items-center justify-content-between dense-1">
                <app-tb-select
                    class="w-full noHintError"
                    [placeholder]="tGroup(selectPlaceholder)"
                    [formControl]="selectControl"
                >
                    @if (selectedView.type_code.value !== ViewTypeCodes.BOARD) {
                        <app-tb-option [value]="''">{{ tGroup('none') }}</app-tb-option>
                    }
                    @for (field of fields; track field) {
                        <app-tb-option [value]="field.guid">
                            <app-field-name
                                [field]="field"
                                [folder]="selectedFolder"
                                [showIcon]="false"
                            ></app-field-name>
                        </app-tb-option>
                    }
                </app-tb-select>

                <div class="px-1.5 ml-2 flex flex-col justify-center items-start self-stretch">
                    <app-tb-icon
                        [class]="ascButtonClass"
                        (click)="sortControl.setValue(SortDirection.ASC)"
                        class="cursor-pointer icon-xs"
                        icon="custom_sort_up"
                    >
                    </app-tb-icon>
                    <app-tb-icon
                        [class]="descButtonClass"
                        (click)="sortControl.setValue(SortDirection.DESC)"
                        class="cursor-pointer icon-xs"
                        icon="custom_sort_down"
                    >
                    </app-tb-icon>
                </div>
            </div>
        </div>
        <div>
            @if (selectControl.errors?.['invalidField']) {
                <span class="text-red-500 text-xs">{{ 'group.field_not_exist' | transloco }}</span>
            }
        </div>
        <ng-container buttons>
            <div *transloco="let tButtons; read: 'buttons'" class="flex justify-end items-center">
                <app-tb-button
                    appearance="outlined"
                    (click)="forceWithValidation()"
                    class="mr-4 whitespace-nowrap min-w-[6.25rem]"
                >
                    <span class="body-2">{{ tButtons('cancel') }}</span>
                </app-tb-button>
                <div class="flex gap-1">
                    <app-tb-button appearance="primary" (click)="preview()" class="min-w-[6.25rem]">
                        {{ tButtons('preview') }}
                    </app-tb-button>
                    <app-tb-menu [template]="saveMenu" #tbMenu xPosition="before" yPosition="above">
                        <app-tb-button
                            trigger
                            appearance="iconPrimary"
                            [icon]="tbMenu.menuOpen ? 'expand_less' : 'expand_more'"
                        ></app-tb-button>
                    </app-tb-menu>
                </div>
                <ng-template #saveMenu>
                    <app-tb-menu-list>
                        <app-tb-menu-list-item (click)="resetToSave()"
                            >{{ tButtons('reset_to_saved') }}
                        </app-tb-menu-list-item>
                        <app-tb-menu-list-item (click)="resetToDefault()"
                            >{{ tButtons('reset_to_default') }}
                        </app-tb-menu-list-item>
                        <app-tb-divider></app-tb-divider>
                        <app-tb-menu-list-item (click)="save()"
                            >{{ tButtons('save') }}
                        </app-tb-menu-list-item>
                    </app-tb-menu-list>
                </ng-template>
            </div>
        </ng-container>
    </app-modal-layout>
</ng-container>
