import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { SearchDialogComponent } from './search-dialog/search-dialog.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    standalone: true,
    imports: [TranslocoModule, TbButtonComponent],
})
export class SearchComponent {
    constructor(public dialog: MatDialog) {}

    openSearch() {
        this.dialog.open<SearchDialogComponent>(SearchDialogComponent, {
            width: '100%',
        })
    }
}
