<div class="edit-mode-form">
    @if (!isEdit) {
        @if (!disabled) {
            @if (value) {
                <span (click)="editMode()" class="input-label">
                    <a (click)="$event.stopPropagation()" [href]="value"> {{ value }} </a>
                </span>
            } @else {
                <app-tb-input
                    (click)="editMode()"
                    class="text-newNeutral4 icon input-label"
                    icon="remove"
                ></app-tb-input>
            }
        } @else {
            @if (value) {
                <span class="input-label">
                    <a (click)="$event.stopPropagation()" [href]="value"> {{ value }} </a>
                </span>
            } @else {
                <app-tb-input
                    class="text-newNeutral4 icon input-label"
                    icon="remove"
                ></app-tb-input>
            }
        }
    } @else {
        <div [class.active]="isEdit" class="edit-mode-area">
            <mat-form-field class="text-input">
                <div class="flex flex-row items-center justify-between">
                    <input
                        (keyup)="saveOnEnter($event)"
                        [appAutoFocus]="isEdit"
                        [formControl]="textFormControl"
                        autocomplete="off"
                        matInput
                        name="name"
                        type="text"
                    />
                    <app-tb-icon (click)="saveValue()" class="" icon="check_circle"> </app-tb-icon>
                </div>
            </mat-form-field>
        </div>
        <mat-error *transloco="let tCellTypesErrors; read: 'cell_types.errors'">
            {{ tCellTypesErrors('value_is') }}
            <strong>{{ tCellTypesErrors('required') }}</strong>
        </mat-error>
    }
</div>
