import { Injectable } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { ErrorNavigatorService } from '@services/error-navigator.service'
import { WsService } from '@services/ws.service'
import { Subject } from 'rxjs'
import { switchMap, takeUntil, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import {
    AccessErrors,
    BaseResponseModel,
    FolderTemplateResponseModel,
    InitResponseModel,
    UpdateResponseModel,
} from '../models'
import { NavigatorService } from './navigator.service'
import { NotificationService } from './notification.service'
import { SaveDataService } from './save-data.service'
import { CommonFacadeService } from './store-facade'

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class WsProcessingService {
    private readonly userLoggedOut$ = new Subject<void>()

    constructor(
        private wsService: WsService,
        private saveDataService: SaveDataService,
        private commonFacadeService: CommonFacadeService,
        private notificationService: NotificationService,
        private navigationService: NavigatorService,
        private errorNavigatorService: ErrorNavigatorService,
    ) {}

    init() {
        console.log('init')
        this.connectAndInit()
            .pipe(takeUntil(this.userLoggedOut$))
            .subscribe((res) => {
                this.onMessage(res)
            })
    }

    logout() {
        console.log('logout')
        this.wsService
            .disconnect()
            .pipe(tap(() => this.commonFacadeService.dataReset()))
            .subscribe(() => {
                this.userLoggedOut$.next()
                this.commonFacadeService.reInitApp()
            })
    }

    reInit() {
        console.log('reInit')
        this.connectAndInit()
            .pipe(takeUntil(this.userLoggedOut$))
            .subscribe((res) => {
                this.onMessage(res)
                this.navigationService.reloadCurrentRoute()
            })
    }

    onMessage(response: BaseResponseModel) {
        console.log(response)
        switch (response.status) {
            case environment.ws.status.success:
                this.parseSuccessResponse(response)
                break
            case environment.ws.status.error:
                this.parseErrorResponse(response)
                break
            default:
                throw new Error(`Incorrect response status: ${response.status}`)
        }
    }

    private connectAndInit() {
        return this.wsService.connect().pipe(
            switchMap(() => {
                this.wsService.subOnMessage(environment.ws.endpoints.on_update)
                this.wsService.sendMessage(environment.ws.endpoints.init)
                return this.wsService.onMessage()
            }),
        )
    }

    private parseSuccessResponse(
        response: UpdateResponseModel | FolderTemplateResponseModel | InitResponseModel,
    ) {
        switch (response.code) {
            case environment.ws.response_types.init:
                this.parseInitResponse(response as InitResponseModel)
                break
            case environment.ws.response_types.solution_reset:
                this.logout()
                break
            case environment.ws.response_types.folder_create:
            case environment.ws.response_types.folder_update:
            case environment.ws.response_types.folder_delete:
                this.parseResetResponse(response as InitResponseModel)
                break
            // TODO: check if better implement different methods for each update type
            case environment.ws.response_types.field_update:
            case environment.ws.response_types.record_delete:
            case environment.ws.response_types.record_update:
            case environment.ws.response_types.record_create:
            case environment.ws.response_types.config_create:
            case environment.ws.response_types.config_delete:
            case environment.ws.response_types.config_update:
            case environment.ws.response_types.view_create:
            case environment.ws.response_types.view_update:
            case environment.ws.response_types.view_delete:
            case environment.ws.response_types.create_automation:
            case environment.ws.response_types.update_automation:
            case environment.ws.response_types.delete_automation:
            case environment.ws.response_types.subtask_update:
            case environment.ws.response_types.subtask_create:
            case environment.ws.response_types.subtask_delete:
            case environment.ws.response_types.user_update:
                this.parseUpdateResponse(response as UpdateResponseModel)
                break
            case environment.ws.response_types.template_folder:
                this.parseTemplateResponse(response)
                break
            default:
                throw new Error(`Incorrect response code: ${response.code}`)
        }
    }

    private parseErrorResponse(response: BaseResponseModel) {
        if (!response.error) return

        console.log(response)
        response.error.forEach((error) => {
            if (
                error.message === AccessErrors.access0001 ||
                error.message === AccessErrors.access0002 ||
                error.message === AccessErrors.access0003
            ) {
                this.errorNavigatorService.goToSomethingWentWrongPage()
            }
        })

        this.commonFacadeService.setError(JSON.stringify(response))
        this.notificationService.openErrorNotification(response.error)
    }

    private parseUpdateResponse(response: UpdateResponseModel) {
        this.saveDataService.saveUpdatedData(response)
    }

    private parseTemplateResponse(response: FolderTemplateResponseModel) {
        this.saveDataService.saveTemplateData(response)
    }

    private parseInitResponse(response: InitResponseModel) {
        this.saveDataService.saveInitData(response)
    }

    private parseResetResponse(response: InitResponseModel) {
        this.saveDataService.saveResetData(response)
    }
}
