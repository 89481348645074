import { NgOptimizedImage } from '@angular/common'
import { Component } from '@angular/core'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-card-activity',
    standalone: true,
    imports: [TranslocoModule, NgOptimizedImage],
    templateUrl: './card-activity.component.html',
    styleUrl: './card-activity.component.sass',
})
export class CardActivityComponent {}
