<div
    class="p-4 sm:p-0 flex lg:flex-row xsm:flex-col items-center lg:items-start w-full h-[100vh] relative"
>
    <div class="flex lg:w-auto h-full">
        <img
            class="lg:pl-[4.2rem] lg:pb-4 lg:w-auto h-auto lg:h-[100vh] xsm:w-[450px]"
            ngSrc="../../../../assets/img/something-went-wrong.svg"
            alt="something_wrong"
            width="1000"
            height="1000"
            priority
        />
    </div>
    <div
        class="flex flex-col justify-center items-center gap-16 lg:absolute top-[30%] right-0 w-full lg:w-1/2"
        *transloco="let t"
    >
        @if(data) {
        <div class="flex flex-col items-center gap-4">
            <h1 class="display-2 text-center text-newPrimary">{{ t(data.title) }}</h1>
            <p class="w-[380px] body-1 text-center whitespace-pre-wrap">{{ t(data.message) }}</p>
        </div>

        <app-tb-button class="xsm:w-full sm:w-48" (click)="tryAgain()"
            >{{ t(data.button) }}</app-tb-button
        >
        }
    </div>
</div>
