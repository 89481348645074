import { Injectable } from '@angular/core'
import { AddRecordContentComponent } from '@app/feature/add-record/add-record-content.component'
import { PinDraftRecord } from '@app/feature/pin-panel/pin-record.service'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { getBoolean } from '@core/global-util'
import { LogService } from '@services/log.service'
import { FolderFacadeService, SchemaFacadeService } from '@services/store-facade'
import { reduce, without, find } from 'lodash-es'
import { EMPTY, combineLatest } from 'rxjs'
import { switchMap, take } from 'rxjs/operators'
import {
    AppRecord,
    BusinessRecord,
    BusinessRecords,
    Cell,
    CellEntities,
    CreateRecordModel,
    FieldTypes,
    Folder,
    getRecordCells,
    RecordUpdate,
    Schema,
    UpdateLinkModel,
} from '../models'
import { UpdateService } from './update.service'

@Injectable({
    providedIn: 'root',
})
export class RecordsService {
    constructor(
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private updateService: UpdateService,
        private modalFlowManagerService: ModalFlowManagerService,
        private logService: LogService,
    ) {}

    updateLink(data: RecordUpdate) {
        this.schemaFacadeService.selectSelectedTableSchema$
            .pipe(
                take(1),
                switchMap((schema) => {
                    if (!schema) {
                        this.logService.error(new Error('no schema in updateLink'))
                        return EMPTY
                    }
                    const linkData: UpdateLinkModel = {
                        record_guid: data.record.guid!,
                        record_revision: data.record.revision!,
                        object_field_guid: data.cell!.fieldGuid,
                        revision: data.cell!.revision === 0 ? 1 : data.cell!.revision,
                        add_guids: data.value?.add_guids ?? [],
                        delete_guids: data.value?.delete_guids ?? [],
                    }
                    return this.updateService.updateLink([linkData], schema)
                }),
            )
            .subscribe()
    }

    toggleFavorite(record: BusinessRecords, userGuid: string) {
        const watchValue = record.watch.value.length ? record.watch.value.split(',') : []

        if (watchValue.includes(userGuid)) {
            const updatedValue = without(watchValue, userGuid).join(',')

            return this.updateRecord({
                record,
                cell: record.watch,
                value: updatedValue,
            })
        }

        watchValue.push(userGuid)
        return this.updateRecord({
            record,
            cell: record.watch,
            value: watchValue.join(','),
        })
    }

    duplicateRecord(record: BusinessRecord) {
        if (!record.folder_guids) return

        combineLatest([
            this.schemaFacadeService.selectSchemaByGuid$(record.schemaGuid),
            this.folderFacadeService.selectSelectedFolder$,
            this.folderFacadeService.selectFoldersByGuids$(record.folder_guids.split(',')),
        ])
            .pipe(take(1))
            .subscribe(([schema, folder, folders]) => {
                if (!schema) return

                const cells = getRecordCells(record)
                const fieldValues = this.setFieldValues(schema, cells, record.name.fieldGuid)

                const data: PinDraftRecord = {
                    name: `${record.name.value} (copy)`,
                    data: {
                        schema,
                        folders: folders,
                        selectedFolder: folder,
                        name: `${record.name.value} (copy)`,
                        fieldValues,
                    },
                    guid: record.guid,
                }

                this.modalFlowManagerService
                    .openDialog<AddRecordContentComponent, PinDraftRecord>({
                        component: AddRecordContentComponent,
                        data,
                    })
                    .pipe(take(1))
            })
    }

    updateRecord(data: RecordUpdate) {
        return this.updateRecords([data])
    }

    updateRecords(data: RecordUpdate[]) {
        return this.folderFacadeService.selectSelectedFolder$.pipe(
            take(1),
            switchMap((folder: Folder) => {
                return this.updateService.updateRecords(data, folder)
            }),
        )
    }

    createRecord(record: CreateRecordModel) {
        return this.updateService.createRecord(record)
    }

    deleteRecord(data: AppRecord | Array<AppRecord>) {
        return this.updateService.deleteRecord(data)
    }

    private setFieldValues(schema: Schema, cells: CellEntities, nameFieldGuid: string) {
        return reduce(
            cells,
            (acc, cell, guid) => {
                if (getBoolean(schema.fieldEntities[guid].is_readonly)) return acc

                if (cell.fieldType === FieldTypes.STATUS) {
                    acc[guid] = this.setStatusFieldValue(schema, guid, cell)
                    return acc
                }

                acc[guid] = nameFieldGuid === guid ? `${cell.value} (copy)` : cell.value
                return acc
            },
            {} as Record<string, string>,
        )
    }

    private setStatusFieldValue(schema: Schema, guid: string, cell: Cell) {
        const isStatusValueExists = find(
            schema.fieldEntities[guid].select_object_field,
            (option) => {
                return option.guid === cell.value
            },
        )

        if (!isStatusValueExists) {
            return schema.fieldEntities[guid].default_value ?? ''
        }

        return cell.value
    }
}
