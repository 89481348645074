import { UntilDestroy } from '@ngneat/until-destroy'
import { Component } from '@angular/core'
import { AdvancedOptionsSectionComponent } from './advanced-options-section/advanced-options-section.component'
import { MatIconModule } from '@angular/material/icon'
import { MatExpansionModule } from '@angular/material/expansion'
import { TranslocoModule } from '@ngneat/transloco'

@UntilDestroy()
@Component({
    selector: 'app-advanced-options',
    templateUrl: './advanced-options.component.html',
    standalone: true,
    imports: [TranslocoModule, MatExpansionModule, MatIconModule, AdvancedOptionsSectionComponent],
})
export class AdvancedOptionsComponent {}
