<div class="flex items-center edit-mode-form">
    <div [class.active]="!disabled" class="edit-mode-area">
        <div class="table-calendar">
            <input
                ngxDaterangepickerMd
                class="data-input"
                [placeholder]="dateLabel"
                [singleDatePicker]="false"
                [timePicker]="false"
                [customRangeDirection]="false"
                [showDropdowns]="false"
                [showCancel]="true"
                (change)="filterFrom()"
                autocomplete="off"
            />
        </div>
        @if (value) {
        <mat-icon (click)="clearDate()" class="text-muted"> close </mat-icon>
        }
    </div>
    @if (disabled) {
    <div>
        <label> {{dateLabel}} </label>
    </div>
    }
</div>
