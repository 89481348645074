@if (isNoGrouped) {
    <ng-container *transloco="let tTable; read: 'table'">
        @if (isAssignee) {
            <span class="p-2 inline-block"> {{ tTable('unassigned') }} </span>
        }
        @if (isStatus) {
            <app-input-cell-container
                [field]="field"
                [readonly]="true"
                [value]="value"
                [appearance]="'board'"
            ></app-input-cell-container>
        }
        @if (!isAssignee && !isStatus) {
            <span class="p-2 inline-block"> {{ tTable('unknown') }} </span>
        }
    </ng-container>
}
@if (!isNoGrouped && field) {
    @if (hasNewContainerSupport) {
        <app-input-cell-container
            [field]="field"
            [readonly]="true"
            [value]="value"
            [appearance]="'board'"
        ></app-input-cell-container>
    } @else {
        @switch (field.field_type_code) {
            @case ('field_type_rating') {
                <app-rating
                    [disabled]="true"
                    [fieldType]="fieldType"
                    [field]="field"
                    [value]="value"
                ></app-rating>
            }
            @case ('field_type_bool') {
                <app-boolean
                    [disabled]="true"
                    [fieldType]="fieldType"
                    [field]="field"
                    [value]="value"
                >
                </app-boolean>
            }
        }
    }
}
