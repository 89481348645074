<app-modal-layout
    *transloco="let t"
    [containerLayout]="containerLayout"
    (closeModal)="forceWithValidation()"
>
    <ng-container title>{{ t('add_field') }}</ng-container>
    <div class="overflow-hidden w-[784px]">
        @if (fieldTypes$ | async; as fieldTypes) {
            <div>
                @if (fieldTypes.onePerFolder.length) {
                    <ng-container
                        [ngTemplateOutlet]="items"
                        [ngTemplateOutletContext]="{ fieldTypes: fieldTypes.onePerFolder }"
                    ></ng-container>
                    <app-tb-divider></app-tb-divider>
                }
                <ng-container
                    [ngTemplateOutlet]="items"
                    [ngTemplateOutletContext]="{ fieldTypes: fieldTypes.multiple }"
                ></ng-container>
            </div>
        }
    </div>
    <ng-container buttons>
        <div class="flex justify-start">
            <div class="flex sm:gap-1 gap-2 flex-1 sm:flex-none">
                <app-tb-button (click)="close()"> {{ t('buttons.back') }}</app-tb-button>
            </div>
        </div>
    </ng-container>
</app-modal-layout>
<ng-template #items [appTypedTemplate]="contextType" let-fieldTypes="fieldTypes">
    <div class="flex p-4 flex-wrap gap-4 mb-2">
        @for (type of fieldTypes; track type) {
            <div
                (click)="addField(type)"
                class="flex flex-col items-center h-[160px] w-[176px] gap-2 py-5 px-4 rounded-2xl shadow-light cursor-pointer relative"
            >
                @if (!type.status) {
                    <span class="text-red-600 absolute top-0">DEV</span>
                }
                <app-tb-icon [icon]="type.icon"></app-tb-icon>
                <div
                    *transloco="let tFieldTypeName; read: 'field_type.name'"
                    class="leading-6 subheading-2"
                >
                    {{ tFieldTypeName(type.guid) }}
                </div>
                <div
                    *transloco="let tFieldTypeDescription; read: 'field_type.description'"
                    class="text-center caption-1 text-primarySecondary"
                >
                    {{ tFieldTypeDescription(type.guid) }}
                </div>
            </div>
        }
    </div>
</ng-template>
