import { Component } from '@angular/core'
import { AddRecordContentComponent } from '@app/feature/add-record/add-record-content.component'
import { schemaIconByName } from '@app/shared/models/icons'
import { TbMenuComponent } from '@components-library/tb-menu'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { SchemaFacadeService } from '@services/store-facade'
import { map, take } from 'rxjs/operators'
import {
    PinDraftRecord,
    PinObject,
    PinObjectType,
    PinRecord,
    PinRecordService,
} from './pin-record.service'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { MatMenuModule } from '@angular/material/menu'
import { TbButtonComponent } from '../../@components-library/tb-button/tb-button.component'
import { PinRecordComponent } from './pin-record/pin-record.component'
import { AsyncPipe, NgTemplateOutlet } from '@angular/common'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-pin-panel',
    templateUrl: './pin-panel.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        PinRecordComponent,
        TbButtonComponent,
        MatMenuModule,
        AsyncPipe,
        TbMenuComponent,
        TbMenuListComponent,
        TbMenuListItemComponent,
        NgTemplateOutlet,
    ],
})
export class PinPanelComponent {
    protected readonly schemaIconByName = schemaIconByName

    showRecordCount: number = 5

    pinObjects$ = this.pinRecordService.pinObjects$.pipe(
        map((pinObjects) => {
            const pinRecords = pinObjects.filter((object) => object.type === PinObjectType.CREATED)
            const pinDraftRecords = pinObjects.filter(
                (object) => object.type === PinObjectType.DRAFT,
            )
            return [...pinDraftRecords, ...pinRecords]
        }),
    )

    pinRecords$ = this.pinObjects$.pipe(
        map((objects) => objects.slice(0, this.showRecordCount)),
        this.filterRecordByType<PinRecord>(PinObjectType.CREATED),
    )

    pinRecordsDraft$ = this.pinObjects$.pipe(
        map((objects) => objects.slice(0, this.showRecordCount)),
        this.filterRecordByType<PinDraftRecord>(PinObjectType.DRAFT),
    )

    pinRecordsMore$ = this.pinObjects$.pipe(
        map((objects) => objects.slice(this.showRecordCount)),
        this.filterRecordByType<PinRecord>(PinObjectType.CREATED),
    )

    pinRecordsDraftMore$ = this.pinObjects$.pipe(
        map((objects) => objects.slice(this.showRecordCount)),
        this.filterRecordByType<PinDraftRecord>(PinObjectType.DRAFT),
    )

    schemas$ = this.schemaFacadeService.selectTableSchemas$

    constructor(
        private pinRecordService: PinRecordService,
        private modalFlowManagerService: ModalFlowManagerService,
        private schemaFacadeService: SchemaFacadeService,
    ) {}

    openEmptyDialog() {
        this.openDialog()
    }

    openDialogWithSelectedSchema(schemaGuid: string) {
        this.openDialog({ schemaGuid })
    }

    showMoreBtn(pinRecordsMore: PinRecord[], pinDraftRecordsMore: PinDraftRecord[]) {
        return pinRecordsMore.length || pinDraftRecordsMore.length
    }

    removePinItem(guid: string) {
        this.pinRecordService.removePinRecord(guid)
    }

    openPinItem(guid: string) {
        this.pinRecordService.openPinItem(guid)
    }

    openPinDraftItem(draftRecord: PinDraftRecord) {
        this.openDialog(draftRecord)
    }

    private filterRecordByType<T extends PinRecord | PinDraftRecord>(type: PinObjectType) {
        return map<PinObject[], T[]>((objects) => {
            return objects
                .filter((object) => object.type === type)
                .map((object) => object.record) as T[]
        })
    }

    private openDialog(openDialogData?: PinDraftRecord | { schemaGuid: string }) {
        this.modalFlowManagerService
            .openDialog<AddRecordContentComponent, PinDraftRecord | { schemaGuid: string }>({
                component: AddRecordContentComponent,
                data: openDialogData,
            })
            .pipe(take(1))
    }
}
