<div class="flex" [class]="[colorCss]">
    @switch (style) {
        @case ('rounded') {
            <mat-icon [class]="filled ? 'filled' : 'non-filled'"> {{ icon }} </mat-icon>
        }
        @case ('svg') {
            <mat-icon [svgIcon]="icon"></mat-icon>
        }
    }
</div>
