import { NgTemplateOutlet } from '@angular/common'
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core'
import { TbCheckboxComponent } from '../../tb-checkbox/checkbox.component'
import { TbButtonComponent } from '../../tb-button/tb-button.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { CdkDrag, CdkDragEnter, CdkDragExit, CdkDragPlaceholder } from '@angular/cdk/drag-drop'

@Component({
    selector: 'app-tb-board-card',
    templateUrl: './tb-board-card.component.html',
    styleUrls: ['./tb-board-card.component.sass'],
    standalone: true,
    imports: [
        CdkDrag,
        CdkDragPlaceholder,
        TbMenuComponent,
        TbButtonComponent,
        TbCheckboxComponent,
        NgTemplateOutlet,
    ],
})
export class TbBoardCardComponent<Item> {
    @Input({ required: true })
    item!: Item

    @Input({ required: true })
    key!: keyof Item

    @Input()
    title = ''

    @Input()
    moreMenu!: TemplateRef<any>

    @Input()
    favorite!: TemplateRef<any>

    @Input()
    selected = false

    @Input()
    selectedItems: Item[] = []

    @Output()
    selectCard = new EventEmitter<boolean>()

    collapsed = false

    enter(event: CdkDragEnter<{ items: Item[]; collapsed: boolean }>) {
        this.collapsed = event.container.data.collapsed
    }

    exit(event: CdkDragExit<{ items: Item[]; collapsed: boolean }>) {
        this.collapsed = event.container.data.collapsed
    }
}
