@if (currentUser$ | async; as currentUser) { @if (userInfo$ | async; as userInfo) {
<div
    [class.closed]="hideName"
    class="flex text-light items-center justify-content-between w-100 pl-2 h-12 rounded-lg subheading-1 cursor-pointer bg-primaryDark hover:text-secondaryMain"
>
    <div
        class="invisible group-hover:visible absolute left-[5px] h-8 w-8 box-content rounded-full min-w-8 border-[3px] border-solid border-secondaryMain avatarBorder"
    ></div>
    <div
        [matMenuTriggerFor]="userSettingsMenu"
        class="hidden sm:flex items-center pr-2 py-3 cursor-pointer w-100 hover:brightness-90"
    >
        <ng-container *ngTemplateOutlet="buttonInside"></ng-container>
    </div>
    <div
        (click)="openBottomSheet()"
        class="flex sm:hidden items-center pr-2 py-3 cursor-pointer w-100 hover:brightness-90"
    >
        <ng-container *ngTemplateOutlet="buttonInside"></ng-container>
    </div>
    <ng-template #buttonInside>
        <app-user-avatar
            class="flex mr-4"
            [userName]="userInfo.first_name +' '+ userInfo.last_name"
            [userUrl]="userInfo.avatar_url"
        >
        </app-user-avatar>
        @if (!hideName) {
        <div class="subheading-1 overflow-hidden max-w-[16ch] whitespace-nowrap text-ellipsis">
            {{userInfo.first_name}} {{userInfo.last_name}}
        </div>
        }
    </ng-template>
</div>
<mat-menu #userSettingsMenu="matMenu" class="rounded-lg [&>div]:p-0">
    <ng-template matMenuContent #templateBottomSheet>
        <div (click)="$event.stopPropagation()">
            <app-user-settings-content
                [currentUserIsAdmin]="currentUser.is_admin"
                [userInfo]="userInfo"
            ></app-user-settings-content>
        </div>
    </ng-template>
</mat-menu>
} }
