<div
    class="flex px-2 rounded-lg border-newNeutral4 h-[40px] items-center group"
    [class]="containerClasses"
    (click)="onContainerClick()"
    (dblclick)="onContainerDoubleClick()"
>
    <div class="grow" [class]="{ 'pointer-events-none': disabledForActive }">
        @if(!locked) {
        <ng-container #content></ng-container>
        } @else {
        <app-tb-icon icon="lock" color="newTextLight"></app-tb-icon>
        }
    </div>

    <div class="icon flex" (click)="$event.stopPropagation()">
        @if(hoverIcons && !focused && !invalid) {
        <div
            class="group-hover:visible"
            [class.invisible]="!keepHoverIcons"
            (click)="$event.stopPropagation()"
        >
            <ng-container
                *ngTemplateOutlet="hoverIcons; context: { onFocus: onFocusBound, toggleIcons: toggleIcons }"
            ></ng-container>
        </div>
        } @if(invalid && !readonly) {
        <app-tb-tooltip-component [tooltip]="errorMessage">
            <app-tb-icon icon="error_outline" color="red-600"></app-tb-icon>
        </app-tb-tooltip-component>
        } @else if (loading) {
        <mat-spinner diameter="20"></mat-spinner>
        } @else if (editControls && focused) {
        <app-tb-button appearance="icon" icon="check" (click)="applyValue()"></app-tb-button>
        } @if(editControls && (focused || invalid)) {
        <app-tb-button appearance="icon" icon="close" (click)="resetValue()"></app-tb-button>
        }
    </div>
</div>
