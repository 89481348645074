<ng-container *transloco="let tSettings; read: 'status_configuration'">
    <div cdkDropListGroup class="drag-container">
        <div class="flex items-center gap-1">
            <span>{{ tSettings('active_statuses') }}</span>
            <app-tb-tooltip-component class="icon-sm" [tooltip]="'This is active statuses'">
                <app-tb-icon icon="info" color="newTextLight"></app-tb-icon>
            </app-tb-tooltip-component>
        </div>
        <div
            (cdkDropListDropped)="drop($event, 'active')"
            [cdkDropListData]="optionsByTags.active"
            cdkDropList
        >
            @for (option of optionsByTags.active; track option) {
                <app-select-option
                    [option]="option"
                    [isDefault]="option.guid === defaultOption.guid"
                    [dragBoundaryClass]="'.drag-container'"
                    (delete)="delete(option.guid!, 'active')"
                    (optionChanged)="optionChange(option)"
                ></app-select-option>
            }
        </div>
        <app-tb-button appearance="text" icon="add_circle_outline" (click)="add('active')">{{
            tSettings('add_status')
        }}</app-tb-button>

        <div class="flex items-center gap-1">
            <span>{{ tSettings('done_statuses') }}</span>
            <app-tb-tooltip-component class="icon-sm" [tooltip]="'This is done statuses'">
                <app-tb-icon icon="info" color="newTextLight"></app-tb-icon>
            </app-tb-tooltip-component>
        </div>
        <div
            (cdkDropListDropped)="drop($event, 'done')"
            [cdkDropListData]="optionsByTags.done"
            cdkDropList
        >
            @for (option of optionsByTags.done; track option) {
                <app-select-option
                    [option]="option"
                    [isDefault]="option.guid === defaultOption.guid"
                    [dragBoundaryClass]="'.drag-container'"
                    (delete)="delete(option.guid!, 'done')"
                    (optionChanged)="optionChange(option)"
                ></app-select-option>
            }
        </div>
        <app-tb-button appearance="text" icon="add_circle_outline" (click)="add('done')">{{
            tSettings('add_status')
        }}</app-tb-button>
    </div>

    <div class="closed-options">
        <div class="flex items-center gap-1">
            <span>{{ tSettings('closed_statuses') }}</span>
            <app-tb-tooltip-component class="icon-sm" [tooltip]="'This is closed statuses'">
                <app-tb-icon icon="info" color="newTextLight"></app-tb-icon>
            </app-tb-tooltip-component>
        </div>
        @for (option of optionsByTags.close; track option) {
            <app-select-option
                [option]="option"
                [isDefault]="option.guid === defaultOption.guid"
                [dragBoundaryClass]="'.closed-options'"
                (delete)="delete(option.guid!, 'close')"
                (optionChanged)="optionChange(option)"
            ></app-select-option>
        }
    </div>
</ng-container>
